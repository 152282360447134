import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

// import {
//   Tooltip
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import GroupsIcon from '@mui/icons-material/Groups';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  StyledButton,
  StyledIconButton,
  Typography
} from '../../components/interface';

import { Meeting } from '../../models/Meeting';
import { loginAtom } from '../../lib/auth';

const { DateTime } = require("luxon");

type MeetingSettingsProps = {};

export const MeetingSettings: FunctionComponent<MeetingSettingsProps> = () => {
  // const theme = useTheme();
  const navigate = useNavigate();

  const login = useAtomValue(loginAtom);
  // const [login, setLogin] = useAtom(loginAtom);
  // const [myQueues, setMyQueues] = useAtom(queueAtom);

  const [settings, setSettings] = useState<Meeting[]>([]);
  const [settingsCount, setSettingsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  // const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("created_at DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch();
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order]);

  const onSearch = () => {
    // console.log(filters);
    setLoader(true);
    fetch_all<Meeting>('/nl/v3/objects/fetch_all', {
      object: 'meeting',
      fields: ['id', 'created_at', 'updated_at', 'name', 'enabled', 'commercial_meeting_emails|count', 'voting_results|count', 'meeting_attendees|count'],
      per_page: 20,
      page: page,
      order: order
    }, (obs, complete_data) => {
      setSettings(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setSettingsCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const onCalculate = (id: number, is_test: number) => {
    // setRemoveLoader(true);
    fetch_one<Meeting>('/nl/v3/objects/custom_action', {
      object: 'meeting',
      class_action: 'custom_api_engage_emails',
      handler_id: login?.id,
      id: id,
      test: is_test
    }, (obs, complete_data) => {
      onSearch();
      // setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80, sortable: true },
    { field: 'name', headerName: 'Name', width: 300, sortable: true },
    { field: 'commercial_meeting_emails_count', headerName: 'Emails', width: 100, sortable: false, renderCell: params => <>
      <Typography sx={{
        fontSize: '0.9rem',
        ...(params.row.commercial_meeting_emails_count > 0 ? {
          cursor: 'pointer',
          textDecoration: 'underline'
        } : {})
      }} onClick={() => {
        if (params.row.commercial_meeting_emails_count > 0) {
          navigate(`/meeting_emails/${params.row.id}`);
        }
      }}>{params.row.commercial_meeting_emails_count}</Typography>
    </> },
    { field: 'meeting_attendees_count', headerName: 'Attendees', width: 100, sortable: false, renderCell: params => <>
      <Typography sx={{
        fontSize: '0.9rem',
        ...(params.row.meeting_attendees_count > 0 ? {
          cursor: 'pointer',
          textDecoration: 'underline'
        } : {})
      }} onClick={() => {
        if (params.row.meeting_attendees_count > 0) {
          navigate(`/meeting_attendees/${params.row.id}`);
        }
      }}>{params.row.meeting_attendees_count}</Typography>
    </> },
    { field: 'voting_results_count', headerName: 'Votes', width: 100, sortable: false, renderCell: params => <>
      <Typography sx={{
        fontSize: '0.9rem',
        ...(params.row.voting_results_count > 0 ? {
          cursor: 'pointer',
          textDecoration: 'underline'
        } : {})
      }} onClick={() => {
        if (params.row.voting_results_count > 0) {
          navigate(`/meeting_voting/${params.row.id}`);
        }
      }}>{params.row.voting_results_count}</Typography>
    </> },
    { field: 'created_at', headerName: 'Created', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
  ];

  return (
    <Page sx={{}} title="Meetings" helper="settings" old_url="https://old.argenco.be/meetings" actions={<>
    <StyledButton
      label="Create new"
      id='create_new'
      contained
      path={'/meeting_setting/new'}
      // sx={{marginRight: 3}}
      startIcon={<AddCircleOutlineIcon />}
    />
  </>}>
      <DataGrid
        action_count={3}
        actions={(params) => {
          return (<>
            <StyledIconButton color="primary" path={`/meeting_setting/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
            <StyledIconButton color="primary" title="Generate emails for TEST users" onClick={(e) => {
              onCalculate(params.row.id, 1);
            }}>
              <ManageAccountsIcon />
            </StyledIconButton>
            <StyledIconButton color="primary" title="Generate emails for ALL users" onClick={(e) => {
              onCalculate(params.row.id, 0);
            }}>
              <GroupsIcon />
            </StyledIconButton>
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={settingsCount}
        objects={(settings || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
