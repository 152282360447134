import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

// import {
//   Grid,
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ChecklistIcon from '@mui/icons-material/Checklist';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  DataGrid,
  Page,
  StyledButton,
  StyledIconButton,
  Typography
} from '../../components/interface';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import { ExitReason } from '../../models/ExitReason';
import { loginAtom } from '../../lib/auth';

type ExitReasonsProps = {};

export const ExitReasons: FunctionComponent<ExitReasonsProps> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);
  const [reasons, setReasons] = useState<ExitReason[]>([]);
  const [reasonsCount, setReasonsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("weight ASC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onSearch = (s: string) => {
    setLoader(true);
    fetch_all<ExitReason>('/nl/v3/objects/fetch_all', {
      object: 'exit_reason',
      fields: [
        'id', 'name', 'lang_nl', 'lang_fr', 'weight', 'is_hidden', 'last_by', 'curr_by', 'last_by_pct', 'curr_by_pct'
      ],
      per_page: 200,
      page: page,
      order: order,
      filter: {
        search: s
      }
    }, (obs, complete_data) => {
      setReasons(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setReasonsCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 60, sortable: false },
    { field: 'name_nl', headerName: 'Name', width: 400, sortable: false },
    { field: 'last_by', headerName: 'Last BY', width: 100, sortable: false, renderCell: (params) => <>
      <Typography sx={{fontSize: '0.8rem'}}>{params.row.last_by}<br/><span style={{fontSize: '0.7rem'}}>{(params.row.last_by_pct || 0).toFixed(2)}%</span></Typography>
    </> },
    { field: 'curr_by', headerName: 'Curr. BY', width: 100, sortable: false, renderCell: (params) => <>
      <Typography sx={{fontSize: '0.8rem'}}>{params.row.curr_by}<br/><span style={{fontSize: '0.7rem'}}>{(params.row.curr_by_pct || 0).toFixed(2)}%</span></Typography>
    </> },
    { field: 'diff', headerName: 'Diff', width: 100, sortable: false, renderCell: (params) => <>
      <Typography sx={{fontSize: '0.8rem', color: (((params.row.curr_by || 0) - (params.row.last_by || 0)) < 0 ? theme.palette.secondary.main : theme.palette.primary.main)}}>{((((params.row.curr_by || 0) - (params.row.last_by || 0)) / (params.row.last_by || 1)) * 100).toFixed(2)}%<br/>&nbsp;</Typography>
    </> },
    { field: 'weight', headerName: 'Order', width: 100, sortable: true },
    { field: 'lang_nl', headerName: 'NL', width: 40, sortable: false, renderCell: (params) => <>
      {params?.value < 1 && <StarOutlineIcon sx={{color: 'red'}} />}
      {params?.value > 99 && <StarIcon sx={{color: theme.palette.tertiary?.main}} />}
      {params?.value <= 99 && params?.value >= 1 && <StarHalfIcon sx={{color: 'orange'}} />}
    </> },
    { field: 'lang_fr', headerName: 'FR', width: 40, sortable: false, renderCell: (params) => <>
      {params?.value < 1 && <StarOutlineIcon sx={{color: 'red'}} />}
      {params?.value > 99 && <StarIcon sx={{color: theme.palette.tertiary?.main}} />}
      {params?.value <= 99 && params?.value >= 1 && <StarHalfIcon sx={{color: 'orange'}} />}
    </> },
  ];

  return (
    <Page sx={{}} title="ExitReasons" helper="exit_reasons" actions={<>
      <StyledButton
        label="List"
        id='list'
        contained
        path={'/exit_reason/list'}
        sx={{marginRight: 3}}
        startIcon={<ChecklistIcon />}
      />
      <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={'/exit_reason/new'}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}>
      <DataGrid
        action_count={1}
        actions={(params) => {
          return (<>
            <StyledIconButton color="primary" path={`/exit_reason/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={reasonsCount}
        objects={(reasons || []).map(u => ({
          ...u,
          name_nl: u.name?.nl,
          enabled: !u.is_hidden
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
        hidePagination
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
