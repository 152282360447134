import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

import {
  CircularProgress
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CachedIcon from '@mui/icons-material/Cached';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton,
  Typography
} from '../../components/interface';

import { EmailTemplate } from '../../models/EmailTemplate';
import { loginAtom } from '../../lib/auth';
import refresh_queues, { queueAtom } from '../../lib/queues';

const { DateTime } = require("luxon");

type EmailTemplatesProps = {};

export const EmailTemplates: FunctionComponent<EmailTemplatesProps> = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);
  const [myQueues, setMyQueues] = useAtom(queueAtom);

  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [templatesCount, setTemplatesCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [sendLoader, setSendLoader] = useState<boolean>(false);
  const [handleLoader, setHandleLoader] = useState<boolean>(false);
  const [generateLoader, setGenerateLoader] = useState<boolean>(false);
  const [showCommercial, setShowCommercial] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch('');
  }, [page, order, showCommercial]);

  const onCopy = (id: number) => {
    setRemoveLoader(true);
    fetch_one<EmailTemplate>('/nl/v3/objects/custom_action', {
      object: 'v2_email_template',
      class_action: 'custom_api_copy_from',
      base_id: id
    }, (obs, complete_data) => {
      navigate(`/email_template/${obs.custom_result?.new_id}`);
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onGenerate = (id: number) => {
    setGenerateLoader(true);
    fetch_one<EmailTemplate>('/nl/v3/objects/custom_action', {
      object: 'v2_email_template',
      class_action: 'custom_api_generate_emails',
      base_id: id
    }, (obs, complete_data) => {
      setGenerateLoader(false);
      onSearch('');
    }, (z) => {

    }, login);
  };

  const onRemove = (id: number) => {
    setRemoveLoader(true);
    fetch_one<EmailTemplate>('/nl/v3/objects/remove', {
      object: 'v2_email_template',
      ob: {
        id: id
      }
    }, (obs, complete_data) => {
      onSearch('');
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onSend = (id: number) => {
    setSendLoader(true);
    fetch_one<EmailTemplate>('/nl/v3/objects/custom_action', {
      object: 'v2_email_template',
      class_action: 'custom_api_send_test_emails',
      base_id: id
    }, (obs, complete_data) => {
      setSendLoader(false);
      onSearch('');
    }, (z) => {

    }, login);
  };

  const onSendLive = (id: number) => {
    if (!!login) {
      setHandleLoader(true);
      fetch_one<EmailTemplate>('/nl/v3/objects/custom_action', {
        object: 'v2_email_template',
        class_action: 'custom_api_handle_all',
        handler_id: login.id,
        base_id: id
      }, (obs, complete_data) => {
        refresh_queues(login, (res) => {
          if (!!res) setMyQueues(res);
        });
        onSearch('');
        setHandleLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
        setHandleLoader(false);
      }, login);
    }
  };

  const onSearch = (s: string) => {
    // console.log(filters);
    setLoader(true);
    fetch_all<EmailTemplate>('/nl/v3/objects/fetch_all', {
      object: 'v2_email_template',
      fields: ['id', 'created_at', 'updated_at', 'internal_key', 'extra_data', 'is_commercial', 'is_system', 'locale', 'v2_emails|count', 'v2_unsent_emails|count'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          ...(!!showCommercial ? {commercial: 1} : {system: 1}),
          fallback: s
        }
      }
    }, (obs, complete_data) => {
      setTemplates(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setTemplatesCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, sortable: true },
    { field: 'system_name', headerName: 'Name', width: 200, sortable: false },
    { field: 'created_at', headerName: 'Created', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'updated_at', headerName: 'Updated', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'is_commercial', headerName: 'Commercial?', width: 100, sortable: false, renderCell: params => <>
      {!!params.row.is_commercial && <CheckIcon sx={{color: theme.palette.tertiary?.main}} />}
      {!params.row.is_commercial && <CloseIcon sx={{color: theme.palette.secondary.main}} />}
    </> },
    { field: 'is_system', headerName: 'System?', width: 100, sortable: false, renderCell: params => <>
      {!params.row.is_commercial && <CheckIcon sx={{color: theme.palette.tertiary?.main}} />}
      {!!params.row.is_commercial && <CloseIcon sx={{color: theme.palette.secondary.main}} />}
    </> },
    { field: 'v2_emails_count', headerName: 'Emails', width: 200, sortable: false, renderCell: params => <>
      <Typography sx={{
        fontSize: '0.9rem',
        cursor: 'pointer',
        textDecoration: 'underline'
      }} onClick={() => {
        navigate(`/emails/${params.row.id}`);
      }}>{params.row.v2_unsent_emails_count || 0}/{params.row.v2_emails_count || 0}</Typography>
      {params.row.v2_unsent_emails_count > 0 && !handleLoader && <StyledIconButton sx={{scale: '0.8'}} color="primary" title="Send generated emails" onClick={(e) => {
        onSendLive(params.row.id);
      }}>
        <SendIcon />
      </StyledIconButton>}
    </> }
  ];

  return (
    <Page sx={{}} title={(!!showCommercial ? "Commercial Templates" : "System Templates")} helper="email_templates" actions={<>
      <StyledButton
        label="Switch templates"
        id='switch_templates'
        sx={{marginRight: 3}}
        startIcon={<CachedIcon />}
        onClick={() => {
          setShowCommercial(!showCommercial);
        }}
      />
      <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={'/email_template/new'}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}>
      <SearchBox
        simple={true}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
        memo="email_templates"
      />
      <DataGrid
        action_count={5}
        actions={(params) => {
          return (<>
            <StyledIconButton color="primary" path={`/email_template/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
            {!!removeLoader && <CircularProgress />}
            {!removeLoader && <StyledIconButton color="primary" title="Create new template based on this one" onClick={(e) => {
              onCopy(params.row.id);
            }}>
              <ContentCopyIcon />
            </StyledIconButton>}
            {!!sendLoader && <CircularProgress />}
            {!sendLoader && <StyledIconButton color="primary" title="Send test emails" onClick={(e) => {
              onSend(params.row.id);
            }}>
              <ForwardToInboxIcon />
            </StyledIconButton>}
            {!!generateLoader && <CircularProgress />}
            {!generateLoader && !!params.row.is_commercial && <StyledIconButton color="primary" title="Generate live emails" onClick={(e) => {
              onGenerate(params.row.id);
            }}>
              <ScheduleSendIcon />
            </StyledIconButton>}
            {!generateLoader && !params.row.is_commercial && <StyledIconButton color="primary">
              <CircleIcon sx={{color: 'transparent'}} />
            </StyledIconButton>}
            {!!removeLoader && <CircularProgress />}
            {!removeLoader && <StyledIconButton destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={templatesCount}
        objects={(templates || []).map(u => ({
          ...u,
          system_name: `${u.internal_key} (${u.locale})`
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
