import React, { FunctionComponent, useEffect, useState } from 'react';
import { useAtom } from 'jotai';

import { fetch_all } from '../../../../lib/server_helper';

import {
  Grid
} from '@mui/material';

import { StyledSelect } from '../../StyledSelect';
import { Typography } from '../../Typography';

import { BookYear, CURRENT } from '../../../../models/BookYear';
import { loginAtom } from '../../../../lib/auth';

type SearchFilterBookYearProps = {
  filterValue: number;
  setFilterValue: (z:number) => void;
  setEnterPressed?: (v:boolean) => void;
};

export const SearchFilterBookYear: FunctionComponent<SearchFilterBookYearProps> = ({filterValue, setFilterValue, setEnterPressed}) => {
  let default_book_year = CURRENT;

  const [login, setLogin] = useAtom(loginAtom);

  const [bookYears, setBookYears] = useState<BookYear[]>([]);

  useEffect(() => {
    fetch_all<BookYear>('/nl/v3/objects/fetch_all', {
      object: 'book_year',
      fields: ['id', 'start_year', 'visual', 'short_visual'],
      per_page: 20,
      page: 0,
      order: "start_year DESC"
    }, (obs, complete_data) => {
// console.log(obs);
      setBookYears(obs);
      default_book_year = !!obs && !!obs[0] && !!obs[0].id ? obs[0].id : 0;
      setFilterValue(!!obs && !!obs[0] && !!obs[0].id ? obs[0].id : 0);
      if (!!setEnterPressed) setEnterPressed(true);
    }, (z) => {

    }, login);
  }, []);

  return (<>
    <Grid item xs={6} sx={{textAlign: 'right'}}>
      <Typography sx={{marginTop: 1.5}}>Filter (BookYear)</Typography>
    </Grid>
    <Grid item xs={6}>
      <StyledSelect
        label="BookYear"
        value={filterValue.toString()}
        id='book_year'
        onChange={(v) => {
          setFilterValue(parseInt(v, 10));
          if (!!setEnterPressed) setEnterPressed(true);
        }}
        list={bookYears.map(book_year => ({id: (book_year.id || default_book_year), name: book_year.visual}))}
        sx={{backgroundColor: "#a9c2c0"}}
      />
    </Grid>
  </>);
}