import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one, fetch_all } from '../../lib/server_helper';

import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import { loginAtom } from '../../lib/auth';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LaunchIcon from '@mui/icons-material/Launch';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertEmailNotSent } from '../../components/alerts/EmailNotSent';
import { AlertEmailSent } from '../../components/alerts/EmailSent';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import { BankAccount } from '../../components/interface/BankAccount';
import { DetailLine } from '../../components/interface/Detail/DetailLine';
import { NoteModule } from '../../components/interface/NoteModule';
import { Page } from '../../components/interface/Page';
import { StyledButton } from '../../components/interface/StyledButton';
import { StyledDateField } from '../../components/interface/StyledDateField';
import { StyledEditorNoTransl } from '../../components/interface/StyledEditorNoTransl';
import { StyledDropzone } from '../../components/interface/StyledDropzone';
import { StyledIconButton } from '../../components/interface/StyledIconButton';
import { StyledTextField } from '../../components/interface/StyledTextField';
import { Typography } from '../../components/interface/Typography';
import { UserDetailBase } from '../../components/user_detail/Base';

import { CustomStringResult } from '../../models/CustomStringResult';
import { Exit } from '../../models/Exit';
import { User } from '../../models/User';

import SunEditorCore from "suneditor/src/lib/core";

const { DateTime } = require("luxon");

type ExitExitDetailProps = {};

export const ExitExitDetail: FunctionComponent<ExitExitDetailProps> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['login'],
  //   queryFn: async () => {return },
  // });

  const params = useParams();
  const exitId: string | undefined = params.id;

  const [login, setLogin] = useAtom(loginAtom);
  const [oldExitDetail, setOldExitDetail] = useState<Exit|null>(null);
  const [exitDetail, setExitDetail] = useState<Exit|null>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const [editing, setEditing] = useState<boolean>(false);
  const [createMail, setCreateMail] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [refreshNotes, setRefreshNotes] = useState<boolean>(true);

  const [userId, setUserId] = useState<File>();
  const [item, setItem] = useState<File>();

  const [purgeItem, setPurgeItem] = useState<boolean>(false);
  const [purgeIdentity, setPurgeIdentity] = useState<boolean>(false);

  const [checked, setChecked] = useState<boolean>((exitDetail?.extra_data?.checked || "0") === '1');
  const [itemConfirmed, setItemConfirmed] = useState<number>(parseInt(exitDetail?.extra_data?.item_confirmed || "0", 10) || 0);
  const [identityConfirmed, setIdentityConfirmed] = useState<number>(parseInt(exitDetail?.extra_data?.item_confirmed || "0", 10) || 0);
  const [saveConfirm, setSaveConfirm] = useState<boolean>(false);

  const [errorIdUnclear, setErrorIdUnclear] = useState<boolean>(false);
  const [errorItemUnclear, setErrorItemUnclear] = useState<boolean>(false);
  const [errorDocMissing, setErrorDocMissing] = useState<boolean>(false);
  const [errorDocMismatch, setErrorDocMismatch] = useState<boolean>(false);
  const [errorCancel, setErrorCancel] = useState<boolean>(false);
  const editor = useRef<SunEditorCore>();
  const [emailContent, setEmailContent] = useState<string>('');

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setExitSavedSnackOpen] = useState<boolean>(false);
  const [regenBlankSnackOpen, setRegenBlankSnackOpen] = useState<boolean>(false);
  const [userSavedSnackOpen, setUserSavedSnackOpen] = useState<boolean>(false);
  const [errorMailSentOpen, setErrorMailSentOpen] = useState<boolean>(false);
  const [errorMailErrorOpen, setErrorMailErrorOpen] = useState<boolean>(false);

  const fields = [
    'id', 'given_id', 'created_at', 'updated_at', 'extra_data', 'verified', 'get_blank', 'get_item', 'get_identity_front',
    'item_blob|content_type', 'blank_blob|content_type', 'item|filename/byte_size', 'blank|filename/byte_size', 'identity_front_blob|content_type',
    'user|id/first_name/last_name/vennoot_number/birth_date/stock_number/zicht_account/confirmed/locale', 'identity_front|filename/byte_size',
    'user|email/mobile/phone/street/number/zip/city/cor_street/cor_number/cor_zip/cor_city', 'book_year|visual',
    'user|transactions|first|stock_saldo_value', 'cancelled'
  ];

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  const fetchEmailContent = () => {
    fetch_one<CustomStringResult>('/nl/v3/objects/custom_action', {
      object: 'exit',
      class_action: 'custom_api_get_error_email',
      id: exitId,
      reasons: [errorIdUnclear, errorItemUnclear, errorDocMissing, errorDocMismatch, errorCancel],
      handler_id: login?.id
    }, (ob, complete_data) => {
      setEmailContent(ob.custom_result);
      editor.current?.setContents(ob.custom_result);
    }, (z, y) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const fetchExit = () => {
    setLoader(true);
    
    fetch_one<Exit>('/nl/v3/objects/fetch_all', {
      object: 'exit',
      fields: fields,
      id: exitId
    }, (ob, complete_data) => {
      setExitDetail(ob);
      if (!!ob.extra_data && !!ob.extra_data.errors) {
        if (ob.extra_data?.errors.indexOf(1) > -1) setErrorItemUnclear(true);
        if (ob.extra_data?.errors.indexOf(2) > -1) setErrorIdUnclear(true);
        if (ob.extra_data?.errors.indexOf(3) > -1) setErrorDocMissing(true);
        if (ob.extra_data?.errors.indexOf(13) > -1) setErrorDocMismatch(true);
        if (ob.extra_data?.errors.indexOf(16) > -1) setErrorCancel(true);
      }
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login)
  };

  const onRemove = () => {
    fetch_one<Exit>('/nl/v3/objects/remove', {
      object: 'exit',
      ob: {
        id: exitId
      }
    }, (obs, complete_data) => {
      navigate('/exits');
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const regenBlank = () => {
    fetch_one<Exit>('/nl/v3/objects/custom_action', {
      object: 'exit',
      fields: fields,
      id: exitId,
      class_action: 'custom_api_regenerate_blank'
    }, (ob, complete_data) => {
      if (!!ob.custom_result) setExitDetail(ob.custom_result);
      setUserId(undefined);
      setItem(undefined);
      setItem(undefined);
      setErrors([]);
      setEditing(false);
      setLoader(false);

      setRegenBlankSnackOpen(true);
    }, (z, y) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const removeItsme = () => {
    fetch_one<CustomStringResult>('/nl/v3/objects/custom_action', {
      object: 'exit',
      class_action: 'custom_api_remove_itsme',
      id: exitId,
      handler_id: login?.id
    }, (ob, complete_data) => {
      fetchExit();
    }, (z, y) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const saveUser = () => {
    setErrors([]);
    let errors:string[] = [];

    if (typeof(exitDetail?.user_first_name) === 'undefined' || exitDetail?.user_first_name === null || exitDetail?.user_first_name.trim() === "") errors.push('first_name');
    if (typeof(exitDetail?.user_last_name) === 'undefined' || exitDetail?.user_last_name === null || exitDetail?.user_last_name.trim() === "") errors.push('last_name');
    if (typeof(exitDetail?.user_birth_date) === 'undefined' || exitDetail?.user_birth_date === null || exitDetail?.user_birth_date.trim() === "") errors.push('birth_date');
    if (typeof(exitDetail?.user_email) === 'undefined' || exitDetail?.user_email === null || exitDetail?.user_email.trim() === "") errors.push('email');
    if (typeof(exitDetail?.user_street) === 'undefined' || exitDetail?.user_street === null || exitDetail?.user_street.trim() === "") errors.push('street');
    if (typeof(exitDetail?.user_number) === 'undefined' || exitDetail?.user_number === null || exitDetail?.user_number.trim() === "") errors.push('number');
    if (typeof(exitDetail?.user_zip) === 'undefined' || exitDetail?.user_zip === null || exitDetail?.user_zip.trim() === "") errors.push('zip');
    if (typeof(exitDetail?.user_city) === 'undefined' || exitDetail?.user_city === null || exitDetail?.user_city.trim() === "") errors.push('city');
    if ((typeof(exitDetail?.user_phone) === 'undefined' || exitDetail?.user_phone === null || exitDetail?.user_phone.trim() === "") && (typeof(exitDetail?.user_mobile) === 'undefined' || exitDetail?.user_mobile === null || exitDetail?.user_mobile.trim() === "")) {
      errors.push('phone')
      errors.push('mobile')
    };
    if (typeof(exitDetail?.user_zicht_account) === 'undefined' || exitDetail?.user_zicht_account === null || exitDetail?.user_zicht_account.trim() === "") errors.push('zicht_account');
    // if (!!exitDetail?.has_guardianship) {
    //   if (typeof(exitDetail?.guardian_first_name) === 'undefined' || exitDetail?.guardian_first_name === null || exitDetail?.guardian_first_name.trim() === "") errors.push('guardian_first_name');
    //   if (typeof(exitDetail?.guardian_last_name) === 'undefined' || exitDetail?.guardian_last_name === null || exitDetail?.guardian_last_name.trim() === "") errors.push('guardian_last_name');
    //   if (typeof(exitDetail?.guardian_email) === 'undefined' || exitDetail?.guardian_email === null || exitDetail?.guardian_email.trim() === "") errors.push('guardian_email');
    //   if (typeof(exitDetail?.guardian_birth_date) === 'undefined' || exitDetail?.guardian_birth_date === null || exitDetail?.guardian_birth_date.trim() === "") errors.push('guardian_birth_date');
    //   if (typeof(exitDetail?.guardian_street) === 'undefined' || exitDetail?.guardian_street === null || exitDetail?.guardian_street.trim() === "") errors.push('guardian_street');
    //   if (typeof(exitDetail?.guardian_number) === 'undefined' || exitDetail?.guardian_number === null || exitDetail?.guardian_number.trim() === "") errors.push('guardian_number');
    //   if (typeof(exitDetail?.guardian_zip) === 'undefined' || exitDetail?.guardian_zip === null || exitDetail?.guardian_zip.trim() === "") errors.push('guardian_zip');
    //   if (typeof(exitDetail?.guardian_city) === 'undefined' || exitDetail?.guardian_city === null || exitDetail?.guardian_city.trim() === "") errors.push('guardian_city');
    //   if (typeof(exitDetail?.guardian_phone) === 'undefined' || exitDetail?.guardian_phone === null || exitDetail?.guardian_phone.trim() === "") errors.push('guardian_phone');
    // }
    
    if (errors.length < 1) {
      setLoader(true);
  
      let formData = new FormData();
      formData.append('object', 'user');
      formData.append('handler_id', (login?.id || "").toString());
      formData.append('fields[]', 'id');
      if (!!exitDetail?.user_id) formData.append('ob[id]', exitDetail?.user_id.toString());

      formData.append('ob[first_name]', exitDetail?.user_first_name || '');
      formData.append('ob[last_name]', exitDetail?.user_last_name || '');
      formData.append('ob[birth_date]', exitDetail?.user_birth_date || '');
      
      formData.append('ob[email]', exitDetail?.user_email || '');
      formData.append('ob[street]', exitDetail?.user_street || '');
      formData.append('ob[number]', exitDetail?.user_number || '');
      formData.append('ob[zip]', exitDetail?.user_zip || '');
      formData.append('ob[city]', exitDetail?.user_city || '');
      formData.append('ob[phone]', exitDetail?.user_phone || '');
      formData.append('ob[mobile]', exitDetail?.user_mobile || '');
      formData.append('ob[cor_street]', exitDetail?.user_cor_street || '');
      formData.append('ob[cor_number]', exitDetail?.user_cor_number || '');
      formData.append('ob[cor_zip]', exitDetail?.user_cor_zip || '');
      formData.append('ob[cor_city]', exitDetail?.user_cor_city || '');

      // if (!!exitDetail?.has_guardianship && !!guardianItem) formData.append('guardian[identity_front]', guardianItem);

      formData.append('ob[zicht_account]', exitDetail?.user_zicht_account || '');

      if (!!userId) formData.append('ob[identity_front]', userId);

      // if (!!exitDetail?.has_guardianship) {
      //   formData.append('guardian[first_name]', exitDetail?.guardian_first_name || '');
      //   formData.append('guardian[last_name]', exitDetail?.guardian_last_name || '');
      //   formData.append('guardian[email]', exitDetail?.guardian_email || '');
      //   formData.append('guardian[birth_date]', exitDetail?.guardian_birth_date || '');
      //   formData.append('guardian[street]', exitDetail?.guardian_street || '');
      //   formData.append('guardian[number]', exitDetail?.guardian_number || '');
      //   formData.append('guardian[zip]', exitDetail?.guardian_zip || '');
      //   formData.append('guardian[city]', exitDetail?.guardian_city || '');
      //   formData.append('guardian[phone]', exitDetail?.guardian_phone || '');
      //   formData.append('guardian[comment]', exitDetail?.guardian_comment || '');
      // }
      
      fetch_one<User>('/nl/v3/objects/save', formData, (ob, complete_data) => {
        setUserId(undefined);
        setItem(undefined);
        setItem(undefined);
        setErrors([]);
        setEditing(false);
        setLoader(false);

        setUserSavedSnackOpen(true);

        fetchExit();
        setRefreshNotes(true);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, login);
    } else {
      setErrorSnackOpen(true);
      setErrors(errors);
    }
  };

  const saveExit = () => {
    let formData = new FormData();
    formData.append('object', 'exit');
    formData.append('handler_id', (login?.id || "").toString());
    formData.append('fields[]', 'id');
    if (!!exitId) formData.append('ob[id]', exitId.toString());

    if (!!item && itemConfirmed === 0) formData.append('ob[item]', item);
    if (!!userId && itemConfirmed === 0) formData.append('ob[identity_front]', userId);

    if (!!purgeItem) formData.append('purge_item', '1');
    if (!!purgeIdentity) formData.append('purge_identity_front', '1');

    formData.append('ob[extra_data][checked]', (checked ? '1' : '0'));
    formData.append('ob[extra_data][item_confirmed]', itemConfirmed.toString());
    formData.append('ob[extra_data][identity_front_confirmed]', identityConfirmed.toString());
    formData.append('ob[extra_data][sell_amount]', (exitDetail?.extra_data?.sell_amount?.toString() || exitDetail?.user_stock_number?.toString() || ''));

    fetch_one<Exit>('/nl/v3/objects/save', formData, (ob, complete_data) => {
      setUserId(undefined);
      setItem(undefined);
      setItem(undefined);
      setErrors([]);
      setEditing(false);
      setLoader(false);

      setSaveConfirm(false);
      setExitSavedSnackOpen(true);

      setPurgeItem(false);
      setPurgeIdentity(false);

      fetchExit();
      setRefreshNotes(true);
    }, (z) => {
      setSaveConfirm(false);
      setServerErrorSnackOpen(true);
    }, login);
  };

  const sendEmail = () => {
    fetch_one<CustomStringResult>('/nl/v3/objects/custom_action', {
      object: 'exit',
      class_action: 'custom_api_send_error_email',
      id: exitId,
      email_content: emailContent,
      handler_id: login?.id
    }, (ob, complete_data) => {
      if (ob.custom_result === 'success') {
        setErrorMailSentOpen(true);
        setCreateMail(false);
      } else {
        setErrorMailErrorOpen(true);
      }
      setRefreshNotes(true);
    }, (z, y) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  useEffect(() => {
    fetchExit();
    setRefreshNotes(true);
  }, []);

  useEffect(() => {
    if (!!saveConfirm) saveExit();
  }, [saveConfirm]);

  return (
    <Page
      sx={{}}
      title={`${!!exitDetail && [exitDetail.user_first_name, exitDetail.user_last_name, `(${exitDetail.user_vennoot_number})`].join(" ")}`}
      startEndCrumb={<StyledButton
        label="Back to Exits"
        id='back'
        text
        startIcon={<KeyboardBackspaceIcon />}
        path={`/exits`}
        sx={{marginBottom: 1}}
      />}
    >
      <Grid container spacing="8">
        <Grid container item xs={12} md={8} spacing="8" sx={{alignContent: 'baseline', position: 'relative'}}>
          {!!loader && <Grid item xs={12}>
            <CircularProgress />
          </Grid>}

          {!!exitDetail && !!exitDetail?.cancelled && <Grid item xs={12}>
            <Box sx={{
              // width: '100%',
              border: '1px solid transparent',
              borderColor: theme.palette.secondary.main,
              padding: "16px 8px",
              borderRadius: 1,
              backgroundColor: theme.palette.secondary.light,
              marginBottom: 1,
              textAlign: 'center'
            }}>
              <Typography>This exit has been cancelled!</Typography>
            </Box>
          </Grid>}

          {!!exitDetail && exitDetail?.extra_data?.checked?.toString() === '1' && <Grid item xs={12}>
            <Box sx={{
              // width: '100%',
              border: '1px solid transparent',
              borderColor: theme.palette.tertiary?.main,
              padding: "16px 8px",
              borderRadius: 1,
              backgroundColor: theme.palette.tertiary?.main,
              marginBottom: 1,
              textAlign: 'center'
            }}>
              <Typography>This exit has been confirmed!</Typography>
            </Box>
          </Grid>}

          {!createMail && !!editing && <>
            <Grid container item xs={12} md={6} rowSpacing="8" sx={{alignContent: 'baseline'}}>

              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.identity
                }}
                title="Identity"
                wide
              >
                <Grid item xs={12}>
                  <StyledTextField
                    label="First Name"
                    value={exitDetail?.user_first_name || ''}
                    id='first_name'
                    onChange={(v) => {if (!!exitDetail) setExitDetail({...exitDetail, user_first_name: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Last Name"
                    value={exitDetail?.user_last_name || ''}
                    id='last_name'
                    onChange={(v) => {if (!!exitDetail) setExitDetail({...exitDetail, user_last_name: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledDateField
                    label="Birthdate"
                    value={exitDetail?.user_birth_date || ''}
                    id='birth_date'
                    onChange={(v) => {if (!!exitDetail) setExitDetail({...exitDetail, user_birth_date: v.toString()});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
              </UserDetailBase>

              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.register
                }}
                title="Register"
                wide
              >
                <Grid item xs={12}>
                  <BankAccount
                    label="Zicht account"
                    value={exitDetail?.user_zicht_account || ''}
                    id="zicht_account"
                    onChange={(v, checked) => {if (!!exitDetail) setExitDetail({...exitDetail, user_zicht_account: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
              </UserDetailBase>

              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.exit
                }}
                title="Exit"
                wide
              >
                <Grid item xs={12}>
                  <StyledTextField
                    label="Sell amount"
                    value={exitDetail?.extra_data?.sell_amount || exitDetail?.user_stock_number?.toString() || ''}
                    id='sell_amount'
                    onChange={(v) => {if (!!exitDetail) setExitDetail({...exitDetail, extra_data: {...(exitDetail.extra_data || {}), sell_amount: v}});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
              </UserDetailBase>

            </Grid>

            <Grid container item xs={12} md={6} rowSpacing="8" sx={{alignContent: 'baseline'}}>

              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.contact
                }}
                title="Contact"
                wide
              >
                <Grid item xs={12}>
                  <StyledTextField
                    label="E-mail"
                    value={exitDetail?.user_email || ''}
                    id='email'
                    onChange={(v) => {if (!!exitDetail) setExitDetail({...exitDetail, user_email: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label="Street"
                    value={exitDetail?.user_street || ''}
                    id='street'
                    onChange={(v) => {if (!!exitDetail) setExitDetail({...exitDetail, user_street: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledTextField
                    label="Number"
                    value={exitDetail?.user_number || ''}
                    id='number'
                    onChange={(v) => {if (!!exitDetail) setExitDetail({...exitDetail, user_number: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledTextField
                    label="Zip"
                    value={exitDetail?.user_zip || ''}
                    id='zip'
                    onChange={(v) => {if (!!exitDetail) setExitDetail({...exitDetail, user_zip: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label="City"
                    value={exitDetail?.user_city || ''}
                    id='city'
                    onChange={(v) => {if (!!exitDetail) setExitDetail({...exitDetail, user_city: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Phone"
                    value={exitDetail?.user_phone || ''}
                    id='phone'
                    onChange={(v) => {if (!!exitDetail) setExitDetail({...exitDetail, user_phone: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Mobile"
                    value={exitDetail?.user_mobile || ''}
                    id='mobile'
                    onChange={(v) => {if (!!exitDetail) setExitDetail({...exitDetail, user_mobile: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label="COR Street"
                    value={exitDetail?.user_cor_street || ''}
                    id='cor_street'
                    onChange={(v) => {if (!!exitDetail) setExitDetail({...exitDetail, user_cor_street: v});}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledTextField
                    label="COR Number"
                    value={exitDetail?.user_cor_number || ''}
                    id='cor_number'
                    onChange={(v) => {if (!!exitDetail) setExitDetail({...exitDetail, user_cor_number: v});}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledTextField
                    label="COR Zip"
                    value={exitDetail?.user_cor_zip || ''}
                    id='cor_zip'
                    onChange={(v) => {if (!!exitDetail) setExitDetail({...exitDetail, user_cor_zip: v});}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label="COR City"
                    value={exitDetail?.user_cor_city || ''}
                    id='cor_city'
                    onChange={(v) => {if (!!exitDetail) setExitDetail({...exitDetail, user_cor_city: v});}}
                    fullWidth
                  />
                </Grid>
              </UserDetailBase>

            </Grid>

          </>}

          {!createMail && !editing && <Grid container item xs={6} rowSpacing="8" sx={{alignContent: 'baseline'}}>
            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.identity
              }}
              title="Identity"
              wide
            >
              <DetailLine name="First name" value={!!exitDetail ? exitDetail.user_first_name : ''} />
              <DetailLine name="Last name" value={!!exitDetail ? exitDetail.user_last_name : ''} />
              <DetailLine name="Birthdate" date={!!exitDetail ? exitDetail?.user_birth_date : ''} />
              <DetailLine no_divider copyable name="Vennoot Number" value={!!exitDetail ? exitDetail.user_vennoot_number : ''} />
            </UserDetailBase>

            {!!exitDetail?.extra_data?.itsme_data && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.itsme
              }}
              title="Itsme"
              wide
            >
              <DetailLine name="First name" value={!!exitDetail ? exitDetail?.extra_data?.itsme_data.given_name : ''} />
              <DetailLine name="Last name" value={!!exitDetail ? exitDetail?.extra_data?.itsme_data.family_name : ''} />
              <DetailLine name="Birthdate" date={!!exitDetail ? exitDetail?.extra_data?.itsme_data.birthdate : ''} />
              <DetailLine copyable name="E-mail" value={!!exitDetail ? exitDetail.extra_data?.itsme_data.email : ''} />
              <DetailLine no_divider name="Address" value={!!exitDetail ? exitDetail.extra_data?.itsme_data.address.street_address : ''} />
              <DetailLine name="" value={!!exitDetail ? [exitDetail.extra_data?.itsme_data.address.postal_code, exitDetail.extra_data?.itsme_data.address.locality].join(" ") : ''} />
              <DetailLine name="Phone" value={!!exitDetail ? exitDetail.extra_data?.itsme_data.phone_number : ''} />
            </UserDetailBase>}

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.exit
              }}
              title="Exit"
              wide
            >
              <DetailLine name="Given ID" value={!!exitDetail ? exitDetail.given_id.toString() : ''} />
              <DetailLine name="BookYear" value={!!exitDetail ? exitDetail.book_year_visual : ''} />
              <DetailLine name="Sell Amount" value={!!exitDetail ? exitDetail.extra_data?.sell_amount || exitDetail.user_stock_number?.toString() : ''} />
              <DetailLine name="Created" datetime={!!exitDetail ? exitDetail.created_at : ''} />
              <DetailLine name="Updated" datetime={!!exitDetail ? exitDetail.updated_at : ''} />
              <DetailLine name="Checked" bool={!!exitDetail ? exitDetail.extra_data?.checked?.toString() === "1" : false} />
              <DetailLine no_divider name="Verified" bool={!!exitDetail ? exitDetail.verified : false} />
            </UserDetailBase>
          </Grid>}
          {!createMail && !editing && <Grid container item xs={6} rowSpacing="8" sx={{alignContent: 'baseline'}}>
            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.register
              }}
              title="Register"
              wide
            >
              <DetailLine name="Stock Number" value={!!exitDetail ? `${exitDetail.user_stock_number?.toString()} (${exitDetail.user_transactions_first_stock_saldo_value?.toFixed(0)} EUR)` : ''} />
              <DetailLine name="Type" value={!!exitDetail ? (exitDetail.user_vennoot_number ? exitDetail.user_vennoot_number[0] : '') : ''} />
              <DetailLine no_divider name="Zicht account" value={!!exitDetail ? exitDetail.user_zicht_account : ''} />
            </UserDetailBase>

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.account
              }}
              title="Account"
              wide
            >
              <DetailLine no_divider name="Language" value={!!exitDetail ? exitDetail.user_locale : ''} />
            </UserDetailBase>

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.contact
              }}
              title="Contact"
              wide
            >
              <Grid item container xs={12} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine copyable name="E-mail" value={!!exitDetail ? exitDetail.user_email : ''} />
                <DetailLine no_divider name="Address" value={!!exitDetail ? [exitDetail.user_street, exitDetail.user_number].join(" ") : ''} />
                <DetailLine name="" value={!!exitDetail ? [exitDetail.user_zip, exitDetail.user_city].join(" ") : ''} />
                <DetailLine name="Phone" value={!!exitDetail ? exitDetail.user_phone : ''} />
                <DetailLine no_divider={!(!!exitDetail?.user_cor_street && !!exitDetail?.user_cor_zip)} name="Mobile" value={!!exitDetail ? exitDetail.user_mobile : ''} />
                {!!exitDetail?.user_cor_street && !!exitDetail?.user_cor_zip && <DetailLine no_divider name="COR Address" value={!!exitDetail ? [exitDetail.user_cor_street, exitDetail.user_cor_number].join(" ") : ''} />}
                {!!exitDetail?.user_cor_street && !!exitDetail?.user_cor_zip && <DetailLine no_divider name="" value={!!exitDetail ? [exitDetail.user_cor_zip, exitDetail.user_cor_city].join(" ") : ''} />}
              </Grid>
            </UserDetailBase>
          </Grid>}

          {!createMail && <Grid container item xs={12} spacing="8" sx={{alignContent: 'baseline'}}>
            {!editing && !!exitDetail?.get_identity_front && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.identity
              }}
              title="ID"
              confirmable={(confirmed) => {setIdentityConfirmed(confirmed ? 1 : 0); if (!confirmed) setPurgeIdentity(true); setTimeout(() => {setSaveConfirm(true);}, 200);}}
              deleteable={() => {setPurgeIdentity(true); setTimeout(() => {setSaveConfirm(true);}, 200);}}
              confirmed={exitDetail.extra_data?.identity_front_confirmed?.toString() === "1"}
              adornment={!!exitDetail && !!exitDetail.get_identity_front && <>
                <StyledIconButton
                  path={exitDetail.get_identity_front}
                  newTab
                  sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
                ><LaunchIcon /></StyledIconButton>
                {/* <LaunchIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                  e.preventDefault();
                  setTimeout(function() {
                    window.open(exitDetail.get_identity_front);
                  }, 500);
                }} /> */}
              </>}
              turnable={!!exitDetail.identity_front_blob_content_type && exitDetail.identity_front_blob_content_type.indexOf("image") > -1}
            >
              <Grid item xs={12}>
                {!!exitDetail.identity_front_blob_content_type && exitDetail.identity_front_blob_content_type.indexOf("image") > -1 && <img style={{maxWidth: '100%'}} src={exitDetail.get_identity_front} />}
                {!!exitDetail.identity_front_blob_content_type && exitDetail.identity_front_blob_content_type.indexOf("pdf") > -1 && <iframe style={{width: '100%', height: '300px'}} src={exitDetail.get_identity_front} />}
              </Grid>
              <Grid item xs={12}>
                {!!exitDetail?.identity_front_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {exitDetail?.identity_front_filename} ({(parseInt(exitDetail?.identity_front_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>}

            {exitDetail?.extra_data?.item_confirmed?.toString() !== "1" && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.identity
              }}
              title="ID"
            >
              <Grid item xs={12}>
                <Typography sx={{fontSize: '0.75rem'}}>Bijlage</Typography>
                <StyledDropzone
                  acceptedFiles={{
                    'image/*': [],
                    'application/pdf': []
                  }}
                  onDrop={acceptedFiles => {setUserId(acceptedFiles[0]);}}
                  multiple={false}
                  maxFiles={1}
                  maxSize={5 * 1000 * 1000}
                  items={!!userId ? [userId] : []}
                  dropText="Click here, or drop some files."
                  dropHint="Allowed: 1 image (.png, .jpeg, .jpg, .gif) or PDF (.pdf), < 5mb"
                />
                {!!exitDetail?.identity_front_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {exitDetail?.identity_front_filename} ({(parseInt(exitDetail?.identity_front_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>}

            {!!exitDetail?.get_item && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.exit
              }}
              title="Signed Doc"
              confirmable={(confirmed) => {setItemConfirmed(confirmed ? 1 : 0); if (!confirmed) setPurgeItem(true); setTimeout(() => {setSaveConfirm(true);}, 200);}}
              deleteable={() => {setPurgeItem(true); setTimeout(() => {setSaveConfirm(true);}, 200);}}
              confirmed={exitDetail.extra_data?.item_confirmed?.toString() === "1"}
              adornment={!!exitDetail && !!exitDetail.get_item && <>
                <StyledIconButton
                  path={exitDetail.get_item}
                  newTab
                  sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
                ><LaunchIcon /></StyledIconButton>
                {/* <LaunchIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                  e.preventDefault();
                  setTimeout(function() {
                    window.open(exitDetail.get_item);
                  }, 500);
                }} /> */}
              </>}
              turnable={!!exitDetail.item_blob_content_type && exitDetail.item_blob_content_type.indexOf("image") > -1}
              wide={!!editing}
            >
              <Grid item xs={12}>
                {!!exitDetail.item_blob_content_type && exitDetail.item_blob_content_type.indexOf("image") > -1 && <img style={{maxWidth: '100%'}} src={exitDetail.get_item} />}
                {!!exitDetail.item_blob_content_type && exitDetail.item_blob_content_type.indexOf("pdf") > -1 && <iframe style={{width: '100%', height: !!editing ? '500px' : '300px'}} src={exitDetail.get_item} />}
              </Grid>
              <Grid item xs={12}>
                {!!exitDetail?.item_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {exitDetail?.item_filename} ({(parseInt(exitDetail?.item_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>}

            {exitDetail?.extra_data?.item_confirmed?.toString() !== "1" && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.exit
              }}
              title="Signed Doc"
            >
              <Grid item xs={12}>
                <Typography sx={{fontSize: '0.75rem'}}>Bijlage</Typography>
                <StyledDropzone
                  acceptedFiles={{
                    'image/*': [],
                    'application/pdf': []
                  }}
                  onDrop={acceptedFiles => {setItem(acceptedFiles[0]);}}
                  multiple={false}
                  maxFiles={1}
                  maxSize={5 * 1000 * 1000}
                  items={!!item ? [item] : []}
                  dropText="Click here, or drop some files."
                  dropHint="Allowed: 1 image (.png, .jpeg, .jpg, .gif) or PDF (.pdf), < 5mb"
                />
                {!!exitDetail?.item_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {exitDetail?.item_filename} ({(parseInt(exitDetail?.item_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>}

            {!editing && !!exitDetail?.get_blank && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.exit
              }}
              title="Unsigned Exit Document"
              adornment={!!exitDetail && !!exitDetail.get_blank && <>
                <StyledIconButton
                  path={exitDetail.get_blank}
                  newTab
                  sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
                ><LaunchIcon /></StyledIconButton>

                {/* <LaunchIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                  e.preventDefault();
                  setTimeout(function() {
                    window.open(exitDetail.get_blank);
                  }, 500);
                }} /> */}
              </>}
              turnable={!!exitDetail.blank_blob_content_type && exitDetail.blank_blob_content_type.indexOf("image") > -1}
            >
              <Grid item xs={12}>
                {!!exitDetail.blank_blob_content_type && exitDetail.blank_blob_content_type.indexOf("image") > -1 && <img style={{maxWidth: '100%'}} src={exitDetail.get_blank} />}
                {!!exitDetail.blank_blob_content_type && exitDetail.blank_blob_content_type.indexOf("pdf") > -1 && <iframe style={{width: '100%', height: '300px'}} src={exitDetail.get_blank} />}
              </Grid>
              <Grid item xs={12}>
                {!!exitDetail?.blank_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {exitDetail?.blank_filename} ({(parseInt(exitDetail?.blank_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>}
          </Grid>}

          {!!createMail && <Grid container item xs={12} rowSpacing="8">
            <StyledEditorNoTransl
              label="Email content"
              value={emailContent}
              getSunEditorInstance={getSunEditorInstance}
              onChange={(ob) => {
                setEmailContent(ob);
              }}
            />
          </Grid>}

        </Grid>
        <Grid container item xs={12} md={4} sx={{alignContent: 'baseline'}} rowSpacing="4">
          {!createMail && <Grid item xs={12}>
            <Box sx={{
              border: '1px solid transparent',
              // borderColor: theme.palette.primary.main,
              borderRadius: 1,
              padding: 1
            }}>
              <Grid container spacing="8">
                <Grid item xs={12}>
                  <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Actions</Typography>
                </Grid>

                {editing && <>
                  <Grid item xs={6}>
                    <StyledButton
                      label="Update"
                      id='update'
                      contained
                      saveStartIcon
                      onClick={(v) => {
                        saveUser();
                        saveExit();
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledButton
                      label="Cancel"
                      id='cancel'
                      onClick={(v) => {
                        setExitDetail(oldExitDetail);
                        setOldExitDetail(null);
                        setUserId(undefined);
                        setItem(undefined);
                        setItem(undefined);
                        setErrors([]);
                        setEditing(false);
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                </>}

                {!editing && <>
                  {!!exitDetail?.user_id && <Grid item xs={6}>
                    <StyledButton
                      label="UserDetail"
                      id='user_detail'
                      contained
                      onClick={(v) => {
                        window.open(`/user/${exitDetail.user_id}`, "_BLANK");
                      }}
                      sx={{width: '100%'}}
                      startIcon={<LaunchIcon />}
                    />
                  </Grid>}

                  {exitDetail?.extra_data?.checked?.toString() !== '1' && <Grid item xs={6}>
                    <StyledButton
                      label="Edit"
                      id='edit'
                      onClick={(v) => {
                        setOldExitDetail(exitDetail);
                        setEditing(true);
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>}

                  {exitDetail?.extra_data?.checked?.toString() !== '1' && <Grid item xs={6}>
                    <StyledButton
                      label="Destroy"
                      id='destroy'
                      secondary
                      destroyBtn
                      onClick={(v) => {
                        onRemove();
                      }}
                      sx={{width: '100%'}}
                      startIcon={<DeleteForeverIcon />}
                    />
                  </Grid>}
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  {exitDetail?.extra_data?.checked?.toString() !== '1' && <Grid item xs={12}>
                    <StyledButton
                      label="Regenerate unsigned"
                      id='regen_blank'
                      onClick={(v) => {
                        regenBlank();
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>}

                  {exitDetail?.extra_data?.checked?.toString() !== '1' && <>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    {(exitDetail?.extra_data?.item_confirmed?.toString() !== "1" || exitDetail?.extra_data?.identity_front_confirmed?.toString() !== "1") && <Grid item xs={12}>
                      <Typography sx={{textAlign: 'center', fontSize: '0.8rem', fontStyle: 'italic', color: theme.palette.secondary.main}} variant="body1">This will set both the ID and the signed document as confirmed as well!</Typography>
                    </Grid>}
                    <Grid item xs={6}>
                      <StyledButton
                        label="Confirm"
                        id='set_checked'
                        contained
                        startIcon={<CheckCircleOutlineIcon />}
                        onClick={(v) => {
                          setChecked(true);
                          if (!itemConfirmed) setItemConfirmed(1);
                          if (!identityConfirmed) setIdentityConfirmed(1);
                          setTimeout(() => {setSaveConfirm(true);}, 200);
                        }}
                        sx={{width: '100%'}}
                      />
                    </Grid>
                    {!!exitDetail?.extra_data?.itsme_data && <Grid item xs={6}>
                      <StyledButton
                        label="Remove Itsme"
                        id='remove_itsme'
                        onClick={(v) => {
                          removeItsme();
                        }}
                        sx={{width: '100%'}}
                      />
                    </Grid>}
                  </>}

                  {exitDetail?.extra_data?.checked?.toString() === '1' && <>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      <StyledButton
                        label="Unconfirm"
                        id='set_unchecked'
                        onClick={(v) => {
                          setChecked(false);
                          setTimeout(() => {setSaveConfirm(true);}, 200);
                        }}
                        sx={{width: '100%'}}
                      />
                    </Grid>
                  </>}

                  {(!!item || !!userId) && exitDetail?.extra_data?.item_confirmed?.toString() !== "1" && <>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      <StyledButton
                        label="Save files"
                        id='save_doc'
                        contained
                        onClick={(v) => {
                          saveExit();
                        }}
                        sx={{width: '100%'}}
                      />
                    </Grid>
                  </>}

                </>}
              </Grid>
            </Box>
          </Grid>}

          {!editing && exitDetail?.extra_data?.item_confirmed?.toString() !== "1" && <Grid item xs={12} sx={{marginTop: (createMail ? 0 : 4)}}>
            <Box sx={{
              border: '1px solid transparent',
              // borderColor: theme.palette.primary.main,
              borderRadius: 1,
              padding: 1
            }}>
              <Grid container spacing="8">
                <Grid item xs={12}>
                  <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Errors</Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={errorItemUnclear} onChange={(v) => {setErrorItemUnclear(v.target.checked)}} name="errorItemUnclear" />
                    }
                    label="Signed Exit Document unclear"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={errorIdUnclear} onChange={(v) => {setErrorIdUnclear(v.target.checked)}} name="errorIdUnclear" />
                    }
                    label="ID unclear"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={errorDocMissing} onChange={(v) => {setErrorDocMissing(v.target.checked)}} name="errorDocMissing" />
                    }
                    label="Document/ID missing (clarify)"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={errorDocMismatch} onChange={(v) => {setErrorDocMismatch(v.target.checked)}} name="errorDocMismatch" />
                    }
                    label="Document/ID mismatch (clarify)"
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={errorCancel} onChange={(v) => {setErrorCancel(v.target.checked)}} name="errorCancel" />
                    }
                    label="Request'll be cancelled (give reason)"
                  />
                </Grid> */}

                <Grid item xs={6}>
                  <StyledButton
                    label={createMail ? "Hide mail" : "Create mail"}
                    id='create_mail'
                    contained={!createMail}
                    onClick={(v) => {
                      setCreateMail(!createMail);
                      window.scrollTo(0, 0);
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledButton
                    label="Update content"
                    id='update_content'
                    onClick={(v) => {
                      fetchEmailContent();
                    }}
                    saveStartIcon
                    sx={{width: '100%'}}
                  />
                </Grid>

                {!!createMail && <Grid item xs={12}>
                  <StyledButton
                    label="Send email"
                    id='send_email'
                    contained
                    onClick={(v) => {
                      sendEmail();
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>}
                {!!createMail && <Grid item xs={12}>
                  <Typography sx={{textAlign: 'center', fontSize: '0.8rem', fontStyle: 'italic', color: theme.palette.secondary.main}} variant="body1">Email will be sent to {exitDetail?.user_email}!</Typography>
                </Grid>}

              </Grid>
            </Box>
          </Grid>}

          {!!exitId && !!exitDetail && <NoteModule
            hidden={!(!editing && !createMail)}
            handled_id={exitId}
            user_id={exitDetail.user_id}
            handled_type="EmissionDoc::Uitschrijving"
            refreshNotes={refreshNotes}
            setRefreshNotes={(b) => {setRefreshNotes(b);}}
          />}

          <Grid item xs={12}>
            <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Handlers</Typography>
          </Grid>
          {(exitDetail?.extra_data?.handlers || []).length < 1 && <Grid item xs={12}>
            <Typography sx={{color: theme.palette.secondary.main, textAlign: 'center', fontStyle: 'italic'}}>No handlers found...</Typography>
          </Grid>}
          {(exitDetail?.extra_data?.handlers || []).map(handler => <Grid item xs={12}>
            <Typography>{handler[0]} <span style={{fontSize: '0.8rem'}}>({DateTime.fromISO(handler[1]).toFormat("DDD - t")})</span></Typography>
          </Grid>)}

        </Grid>
      </Grid>

      {!!userSavedSnackOpen && <Snackbar
        open={userSavedSnackOpen}
        onClose={() => {setUserSavedSnackOpen(false);}}
        autoHideDuration={6000}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">
          Saved! Do not forget to regenerate the unsigned document, if needed. <StyledButton label="Regenerate now" id='regen_blank' contained onClick={(v) => {
            v.preventDefault();
            v.stopPropagation();

            setUserSavedSnackOpen(false);
            regenBlank();
          }} sx={{marginLeft: 1, paddingTop: 0, paddingBottom: 0, scale: '0.8'}} />
        </MuiAlert>
      </Snackbar>}

      {!!regenBlankSnackOpen && <Snackbar
        open={regenBlankSnackOpen}
        onClose={() => {setRegenBlankSnackOpen(false);}}
        autoHideDuration={2000}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">
          Unsigned document was regenerated!
        </MuiAlert>
      </Snackbar>}

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setExitSavedSnackOpen(b);}} />}
      {!!errorMailSentOpen && <AlertEmailSent open={errorMailSentOpen} setOpen={(b) => {setErrorMailSentOpen(b);}} />}
      {!!errorMailErrorOpen && <AlertEmailNotSent open={errorMailErrorOpen} setOpen={(b) => {setErrorMailErrorOpen(b);}} />}
    </Page>
  );
}
