import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box
} from '@mui/material';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import LaunchIcon from '@mui/icons-material/Launch';

import { UserEmail } from '../../models/UserEmail';

import { Typography } from '../interface/Typography';

const { DateTime } = require("luxon");

type TimelineEmailProps = {
  email: UserEmail;
  handler_name: string;
};

export const TimelineEmail: FunctionComponent<TimelineEmailProps> = ({email, handler_name}) => {
  const theme = useTheme();

  return (<>
    <Box
      sx={{
        display: 'inline-block',
        marginRight: 1,
        paddingRight: 1,
        borderRight: '1px solid lightgrey'
      }}
    >
      <Typography sx={{
        color: 'grey',
        fontSize: 11
      }}><AttachFileIcon sx={{scale: '0.7', marginBottom: '-7px'}} /> {DateTime.fromISO(email.created_at).toFormat("DD TT")}</Typography>
    </Box>
    <Box
      sx={{
        display: 'inline-block',
        marginRight: 1,
        paddingRight: 1,
        borderRight: '1px solid lightgrey'
      }}
    >
      <Typography sx={{
        fontSize: 11
      }}>{handler_name}</Typography>
    </Box>
    <Box
      sx={{
        display: 'inline-block',
        marginRight: 1,
        paddingRight: 1
      }}
    >
      <Typography sx={{
        fontSize: 11
      }}>
        {!email.stock_transfer_id && !email.emission_doc_id && !email.emission_key_id && <>No source</>}
        {!!email.stock_transfer_id && <>StockTransfer#{email.stock_transfer_id}</>}
        {!!email.emission_doc_id && <Typography onClick={() => {
          window.open(`/exit/${email.emission_doc_id}`, "_BLANK");
        }} sx={{
          fontSize: 14,
          cursor: 'pointer'
        }}><LaunchIcon sx={{scale: '0.7', marginBottom: '-7px'}} /> Uitschrijving</Typography>}
        {!!email.emission_key_id && <>EmissionKey#{email.emission_key_id}</>}
      </Typography>
    </Box>
    <Box
      sx={{
        display: 'inline-block',
        width: '100%'
      }}
    >
      <Typography html sx={{
        fontSize: 14
      }}>{email.message}</Typography>
    </Box>
  </>);
}