import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

// import {
//   Box
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import VisibilityIcon from '@mui/icons-material/Visibility';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledIconButton
} from '../../components/interface';

import { MeetingAttendee } from '../../models/MeetingAttendee';
import refresh_queues, { queueAtom } from '../../lib/queues';
import { loginAtom } from '../../lib/auth';

const { DateTime } = require("luxon");

type MeetingAttendeesProps = {};

export const MeetingAttendees: FunctionComponent<MeetingAttendeesProps> = () => {
  // const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);
  const [myQueues, setMyQueues] = useAtom(queueAtom);

  const params = useParams();
  const meetingId: string | undefined = params.id;

  const [attendees, setAttendees] = useState<MeetingAttendee[]>([]);
  const [attendeesCount, setAttendeesCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [handleLoader, setHandleLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [pageFilters, setPageFilters] = useState<any>();

  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onSearch = (s: string, filters?: {enabled:string[], book_year_id:number, meeting_status?: number[]}) => {
    // console.log(filters);
    setLoader(true);
    fetch_all<MeetingAttendee>('/nl/v3/objects/fetch_all', {
      object: 'meeting_attendee',
      fields: ['id', 'status_level', 'user|first_name/last_name/vennoot_number', 'created_at', 'enabled', 'volmacht_id'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          meeting_id: meetingId,
          status_level: pageFilters?.meeting_status
        }
      }
    }, (obs, complete_data) => {
      setAttendees(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setAttendeesCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const sendAttendees = () => {
    if (!!login) {
      setHandleLoader(true);
      fetch_one<MeetingAttendee>('/nl/v3/objects/custom_action', {
        object: 'meeting_attendee',
        class_action: 'custom_api_create_attendee_queue',
        handler_id: login.id
      }, (obs, complete_data) => {
        refresh_queues(login, (res) => {
          if (!!res) setMyQueues(res);
        });
        onSearch('');

        setHandleLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
        setHandleLoader(false);
      }, login);
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, sortable: true },
    { field: 'vennoot_number', headerName: 'VennNr', width: 150, sortable: false },
    { field: 'vennoot_name', headerName: 'Name', width: 200, sortable: false },
    { field: 'status_name', headerName: 'Status', width: 150, sortable: false },
    { field: 'created_at', headerName: 'Created', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") }
  ];

  return (
    <Page sx={{}} title="Meeting Attendees" helper="attendees" old_url="https://old.argenco.be/commercial_meeting_attendees">
      <SearchBox
        onSearch={(s, x) => {
          setPageFilters(x);
          setPage(0);
          onSearch(s, x);
        }}
        simple
        filter_meeting_status
        // memo="meeting_attendees"
      />
      <DataGrid
        action_count={1}
        actions={(params) => {
          return (<>
            <StyledIconButton color="primary" path={`/meeting_attendee/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={attendeesCount}
        objects={(attendees || []).map(u => ({
          ...u,
          vennoot_number: u.user_vennoot_number,
          vennoot_name: [u.user_first_name, u.user_last_name].join(' '),
          status_name: !!u.volmacht_id ? "Blank / Proxy" : ["Blank", "Mailed", "To Post", "Posted"][u.status_level || 0]
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
