import React, { FunctionComponent } from 'react';

import {
  Grid
} from '@mui/material';

import { SearchFilterTrueFalse } from './TrueFalse';
import { StyledButton } from '../../StyledButton';
import { Typography } from '../../Typography';

type SearchFilterDocumentProps = {
  name: string;
  confirmable?: boolean;
  filterValue: string[];
  setFilterValue: (z:string[]) => void;
  setEnterPressed?: (v:boolean) => void;
};

export const SearchFilterDocument: FunctionComponent<SearchFilterDocumentProps> = ({name, confirmable, filterValue, setFilterValue, setEnterPressed}) => {

  return (<>
    <Grid item xs={!!confirmable ? 4 : 6} sx={{textAlign: 'right'}}>
      <Typography sx={{marginTop: 0.5}}>Filter ({name})</Typography>
    </Grid>
    <SearchFilterTrueFalse
      name="hidden"
      hideName
      activeName="Attached"
      inactiveName="Missing"
      filterValue={filterValue}
      setFilterValue={setFilterValue}
      setEnterPressed={(b) => {if (!!setEnterPressed) setEnterPressed(b);}}
    />
    {!!confirmable && <SearchFilterTrueFalse
      name="hidden"
      hideName
      activeName="Confirmed"
      activeValue="2"
      inactiveName="Unconfirmed"
      inactiveValue="3"
      filterValue={filterValue}
      setFilterValue={setFilterValue}
      setEnterPressed={(b) => {if (!!setEnterPressed) setEnterPressed(b);}}
    />}
  </>);
}