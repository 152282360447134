import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one } from '../../lib/server_helper';

import {
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  Page,
  StyledButton,
  StyledTextField,
  Typography
} from '../../components/interface';

import { Notary } from '../../models/Notary';

type ExitNotaryDetailProps = {};

export const ExitNotaryDetail: FunctionComponent<ExitNotaryDetailProps> = () => {
  const navigate = useNavigate();
  // const theme = useTheme();

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [notaryDetail, setNotaryDetail] = useState<Notary|null>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [exitId, setDividendId] = useState<string | undefined>(params.id);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setDividendSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'created_at', 'first_name', 'last_name', 'email', 'street', 'city', 'zip', 'email',
    'number', 'office_number', 'office_name', 'phone', 'replyto'
  ];

  const saveSetting = () => {
    setSaveLoader(true);
    fetch_one<Notary>('/nl/v3/objects/save', {
      object: 'notary',
      fields: fields,
      id: exitId,
      ob: {
        ...notaryDetail,
        id: exitId
      },
      handler_id: login?.id
    }, (ob, complete_data) => {
      setDividendId(ob.id?.toString());
      openCalculation(ob);
      setSaveLoader(false);
      setDividendSavedSnackOpen(true);
      navigate("/notaries");
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const fetchCalculation = () => {
    setLoader(true);
    
    fetch_one<Notary>('/nl/v3/objects/fetch_all', {
      object: 'notary',
      fields: fields,
      id: exitId
    }, (ob, complete_data) => {
      openCalculation(ob);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  useEffect(() => {
    fetchCalculation();
  }, []);

  const openCalculation = (ob: Notary) => {
    setNotaryDetail(ob);
  }

  return (
    <Page sx={{}} title={`Notary Detail`}>
      <Grid container spacing="8">

        <Grid item xs={6}>
          <StyledTextField
            label="Email"
            disabled
            value={notaryDetail?.email || ''}
            id="email"
            onChange={(v) => {}}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <StyledTextField
            label="Alternate email"
            value={notaryDetail?.replyto || ''}
            id="replyto"
            onChange={(v) => {if (!!notaryDetail) setNotaryDetail({
              ...notaryDetail,
              replyto: v});
            }}
            fullWidth
          />
        </Grid>
        
        <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center'}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!!loader && <CircularProgress />}
          {!loader && <StyledButton
            label="Cancel"
            id='cancel'
            // contained
            onClick={(v) => {
              navigate("/notaries");
            }}
            sx={{marginLeft: 1}}
          />}
          {!loader && <StyledButton
            label="Save"
            id='save'
            contained
            onClick={(v) => {
              saveSetting();
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setDividendSavedSnackOpen(b);}} />}
    </Page>
  );
}
