import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  Grid
} from '@mui/material';

import {
  Typography
} from '../../components/interface';
import { PaymentCard as PaymentCrd } from './PaymentCard';

const { DateTime } = require("luxon");

type Props = {
  payment: any;
};

export const PaymentCard: FunctionComponent<Props> = ({payment}) => {
  const theme = useTheme();

  if (!payment) return (<></>);

  let color = 'gold';
  if (!!payment && payment.status === 'confirmed') color = theme.palette.tertiary?.main || theme.palette.primary.main;
  if (!!payment && payment.status === 'repaid') color = 'red';
  if (!!payment && payment.status === 'mark_repay') color = 'red';

  return (<><Box sx={{
    border: '2px solid lightgrey',
    borderColor: color,
    padding: 2,
    "& p": {
      fontSize: '0.8rem'
    },
    marginBottom: 1
  }}>
    <Grid container spacing={1} sx={{position: 'relative'}}>
      {payment.status === 'repaid' && <Typography sx={{
        position: 'absolute',
        top: -6,
        right: -12,
        fontStyle: 'italic',
        fontSize: '0.6rem !important'
      }}>Repaid on {DateTime.fromISO(payment.repay_at).toFormat("D")}</Typography>}
      {payment.status === 'mark_repay' && <Typography sx={{
        position: 'absolute',
        top: -6,
        right: -12,
        fontStyle: 'italic',
        fontSize: '0.6rem !important'
      }}>Marked for repay</Typography>}
      {payment.status === 'confirmed' && <Typography sx={{
        position: 'absolute',
        top: -6,
        right: -12,
        fontStyle: 'italic',
        fontSize: '0.6rem !important'
      }}>{payment.children.length > 0 && 'Split / '}Confirmed</Typography>}
      {payment.status !== 'mark_repay' && payment.status !== 'repaid' && payment.status !== 'confirmed' && <Typography sx={{
        position: 'absolute',
        top: -6,
        right: -12,
        fontStyle: 'italic',
        fontSize: '0.6rem !important'
      }}>Open</Typography>}
      <Grid item xs={4}>
        <Typography sx={{fontWeight: 'bold'}}>Reference</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{payment.reference}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{height: '2px', backgroundColor: color, marginTop: 0.5, marginBottom: 0.5}} />
      </Grid>
      <Grid item xs={4}>
        <Typography sx={{fontWeight: 'bold'}}>Amount</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{parseFloat(payment.amount).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography sx={{fontWeight: 'bold'}}>Account</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{payment.account}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography sx={{fontWeight: 'bold'}}>Date</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{DateTime.fromISO(payment.date).toFormat("D")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{height: '2px', backgroundColor: color, marginTop: 0.5, marginBottom: 0.5}} />
      </Grid>
      <Grid item xs={4}>
        <Typography sx={{fontWeight: 'bold'}}>Description</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{payment.description}</Typography>
      </Grid>
    </Grid>
  </Box>

  {payment.children.length > 0 && <Box sx={{
    paddingLeft: 2,
    position: 'relative'
  }}>
    <Box sx={{
      width: '6px',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }} />
    {payment.children.map((child:any) => <PaymentCrd payment={child} />)}
  </Box>}</>);
}