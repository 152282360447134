import React, { FunctionComponent, useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';

import {
  Box,
  Grid
} from '@mui/material';

import {
  StyledDropzone,
  StyledTextField
} from '../interface';

type ImagePickerProps = {
  sx?: object;
  existing?: string;
  onSelect: (e:File|undefined, f:string|undefined) => void;
  item?: File;
  itemUrl?: string;
};

export const ImagePicker: FunctionComponent<ImagePickerProps> = ({existing, sx, onSelect, item, itemUrl}) => {
  // const theme = useTheme();

  const [itemm, setItem] = useState<File|undefined>(item);
  const [assetUrl, setAssetUrl] = useState<string>();
  const [itemmUrl, setItemUrl] = useState<string|undefined>(itemUrl);

  useEffect(() => {
    onSelect(itemm, assetUrl || itemmUrl || undefined);
  }, [itemm, assetUrl, itemmUrl]);

  return (<Grid container item xs={12}>
    <Grid item xs={3} sx={{paddingBottom: 0.5}}>
      <StyledDropzone
        acceptedFiles={{
          'image/*': []
        }}
        onDrop={acceptedFiles => {setItem(acceptedFiles[0]);}}
        multiple={false}
        maxFiles={1}
        maxSize={3 * 1000 * 1000}
        items={!!itemm ? [itemm] : []}
        dropText="Click/Drop"
        dropHint="All.: 1 img, < 3mb"
        noPreview
      />
    </Grid>
    <Grid container item xs={6} sx={{paddingLeft: 0.5, paddingRight: 0.5, alignContent: 'baseline'}}>
      <Grid item xs={12}>
        <StyledTextField
          label="Asset URL"
          value={assetUrl || ''}
          id="item_url"
          onChange={(v) => {setAssetUrl(v);}}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="Web URL"
          value={itemmUrl || ''}
          id="web_url"
          onChange={(v) => {setItemUrl(v);}}
          fullWidth
        />
      </Grid>
    </Grid>
    <Grid item xs={3} sx={{paddingBottom: 0.5}}>
      <Box sx={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        ...(!!itemm ? 
          {backgroundImage: `url('${URL.createObjectURL(itemm)}')`} : 
          !!assetUrl ? 
            {backgroundImage: `url('${assetUrl}')`} : 
            !!itemUrl ? 
              {backgroundImage: `url('${itemUrl}')`} : 
              !!existing ? 
                {backgroundImage: `url('${existing}')`} : 
                {}),
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
      }}></Box>
    </Grid>
  </Grid>);
}