import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one, fetch_all } from '../../lib/server_helper';
import { loginAtom } from '../../lib/auth';

// import {
//   Box
// } from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import EuroIcon from '@mui/icons-material/Euro';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from '@mui/icons-material/Receipt';

// import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
// import { AlertSaved } from '../../components/alerts/Saved';
// import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  Page,
  // StyledButton,
  StyledTabs,
  Typography
} from '../../components/interface';

import { EmissionDetailTabsDetailBorderel } from './DetailTabs/DetailBorderel';
import { EmissionDetailTabsDetailCommunication } from './DetailTabs/DetailCommunication';
import { EmissionDetailTabsDetailPayment } from './DetailTabs/DetailPayment';
import { EmissionDetailTabsDetailProfile } from './DetailTabs/DetailProfile';

import { EmissionKey } from '../../models/EmissionKey';

// const { DateTime } = require("luxon");

type Props = {};

export const EmissionChecklistDetail: FunctionComponent<Props> = () => {
  // const navigate = useNavigate();
  const theme = useTheme();
  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [emissionKeyId, setEmissionKeyId] = useState<string | undefined>(params.id);
  const [emissionKey, setEmissionKey] = useState<EmissionKey>();
  const [loader, setLoader] = useState<boolean>(true);

  const [activeTab, setActivTab] = useState<number>(0);

  // const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  // const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  // const [exitSavedSnackOpen, setExitSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'created_at', 'updated_at', 'asn_key', 'proposed_value', 'proposed_amount', 'asn_key', 'modded_asn_key', 'modded_asn_key_of_user',
    'activated', 'is_verified', 'flag_email', 'handler|full_name', 'extra_data', 'user_pre_emission_saldo', 'user_during_emission_saldo',
    'check_profile', 'check_profile_updated_at', 'check_profile_confirmed_at',
    'check_borderel', 'check_borderel_updated_at', 'check_borderel_confirmed_at',
    'check_payment', 'check_payment_updated_at', 'check_payment_confirmed_at',
    'emails|last|created_at',
    'borderel|get_blank/get_item', 'borderel|blank|blob|content_type', 'borderel|blank|filename/byte_size',
    'borderel|item|blob|content_type', 'borderel|item|filename/byte_size',
    'user|id/first_name/last_name/birth_date/enabled/stock_number/vennoot_number/itsme_sub/zicht_account/stock_number/has_guardianship',
    'user|locale/gender/email/phone/mobile/street/zip/city/number/cor_street_2/cor_street/cor_city/cor_number/cor_zip/possible_frauds',
    'user|get_identity_front/extra_data/stock_id',
    'user|identity_front|blob|content_type', 'user|identity_front|filename/byte_size',
    'user|transactions|first|stock_saldo_value',
    'user|guardian|first_name/last_name/email/birth_date/street/number/zip/city/phone/comment/get_identity_front',
    'user|guardian|identity_front|blob|content_type', 'user|guardian|identity_front|filename/byte_size',
    'payments|last|root_parent|to_tree'
  ];

  const fetchKey = () => {
    if (!!emissionKeyId) {
      setLoader(true);
      fetch_one<EmissionKey>('/nl/v3/objects/fetch_all', {
        object: 'emission_key',
        fields: fields,
        id: emissionKeyId
      }, (ob, complete_data) => {
        setEmissionKey(ob);
        setLoader(false);
      }, (z) => {
  
      }, login);
    }
  }

  useEffect(() => {
    fetchKey();
  }, []);

  return (
    <Page
      helper="emissionchecklistitem"
      // startEndCrumb={<StyledButton
      //   label="Back to checklist"
      //   id='back'
      //   text
      //   startIcon={<KeyboardBackspaceIcon />}
      //   path={`/emission/checklist`}
      // />}
    >
      <StyledTabs
        tabs={[
          <Typography sx={{
            color: !!emissionKey?.check_profile ? 
            theme.palette.tertiary?.main : 
            (
              (!!emissionKey?.check_profile_updated_at && (!emissionKey?.check_profile_confirmed_at || (emissionKey?.check_profile_updated_at > emissionKey?.check_profile_confirmed_at))) ? 
              'gold' : 
              theme.palette.secondary.main
            ),
            fontWeight: 'bold',
            fontSize: '0.9rem'
          }}><PersonIcon /><br/>Profile</Typography>,
          <Typography sx={{
            color: !!emissionKey?.check_borderel ? 
            theme.palette.tertiary?.main : 
            (
              (!!emissionKey?.check_borderel_updated_at && (!emissionKey?.check_borderel_confirmed_at || (emissionKey?.check_borderel_updated_at > emissionKey?.check_borderel_confirmed_at))) ? 
              'gold' : 
              theme.palette.secondary.main
            ),
            fontWeight: 'bold',
            fontSize: '0.9rem'
          }}><ReceiptIcon /><br/>Borderel</Typography>,
          <Typography sx={{
            color: !!emissionKey?.check_payment ? 
            theme.palette.tertiary?.main : 
            (
              (!!emissionKey?.check_payment_updated_at && (!emissionKey?.check_payment_confirmed_at || (emissionKey?.check_payment_updated_at > emissionKey?.check_payment_confirmed_at))) ? 
              'gold' : 
              theme.palette.secondary.main
            ),
            fontWeight: 'bold',
            fontSize: '0.9rem'
          }}><EuroIcon /><br/>Payment</Typography>,
          <Typography sx={{
            color: !!emissionKey?.flag_email ? theme.palette.tertiary?.main : theme.palette.secondary.main,
            fontWeight: 'bold',
            fontSize: '0.9rem'
          }}><EmailIcon /><br/>Communication</Typography>
        ]}
        onChange={(n) => {setActivTab(n);}}
        value={activeTab}
      />

      {!!emissionKey && <EmissionDetailTabsDetailProfile
        emissionKey={emissionKey}
        isHidden={activeTab !== 0}
        fields={fields}
        refreshKey={() => {
          fetchKey();
        }}
      />}

      {!!emissionKey && <EmissionDetailTabsDetailBorderel
        emissionKey={emissionKey}
        isHidden={activeTab !== 1}
        fields={fields}
        refreshKey={() => {
          fetchKey();
        }}
      />}

      {!!emissionKey && <EmissionDetailTabsDetailPayment
        emissionKey={emissionKey}
        isHidden={activeTab !== 2}
        fields={fields}
        refreshKey={() => {
          fetchKey();
        }}
      />}

      {!!emissionKey && <EmissionDetailTabsDetailCommunication
        emissionKey={emissionKey}
        isHidden={activeTab !== 3}
        fields={fields}
        refreshKey={() => {
          fetchKey();
        }}
      />}
    </Page>
  );
}
