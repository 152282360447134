import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Avatar,
  Box
} from '@mui/material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { BlockTranslatableSettingsImage } from './Image';
import { BlockTranslatableSettingsImageLeft } from './ImageLeft';
import { BlockTranslatableSettingsImageRight } from './ImageRight';
import { BlockTranslatableSettingsImageTitleText } from './ImageTitleText';
import { BlockTranslatableSettingsSeparator } from './Separator';
import { BlockTranslatableSettingsText } from './Text';
import { BlockTranslatableSettingsTextCustomFooter } from './TextCustomFooter';
import { BlockTranslatableSettingsTextCustomSalutation } from './TextCustomSalutation';
import { BlockTranslatableSettingsTitleText } from './TitleText';
import { BlockTranslatableSettingsTitleText2Col } from './TitleText2Col';

import { BenefitBlock } from '../../../models/BenefitBlock';

type BlockTranslatableSettingsBaseProps = {
  forceClose: boolean;
  isFirst: boolean;
  isLast: boolean;
  setActive: (e:boolean) => void;
  setBlock: (eb:BenefitBlock) => void;
  deleteFromList: () => void;
  setNewPosition: (change:number) => void;
  block: BenefitBlock;
  activeLocale?: 'nl' | 'fr';
};

export const BlockTranslatableSettingsBase: FunctionComponent<BlockTranslatableSettingsBaseProps> = ({activeLocale, isFirst, isLast, forceClose, block, setBlock, setActive, setNewPosition, deleteFromList}) => {
  const theme = useTheme();
  
  return (<>
    {!!forceClose && <Box sx={{position: 'relative'}}>
      <DeleteForeverIcon sx={{cursor: 'pointer', position: 'absolute', right: 72, top: 8, zIndex: 1001, height: 24, width: 24, color: theme.palette.secondary.main}} onClick={() => {
        deleteFromList();
      }} />
      {!isLast && <Avatar sx={{cursor: 'pointer', position: 'absolute', right: 40, top: 8, zIndex: 1001, height: 24, width: 24}} onClick={() => {
        setNewPosition(1);
      }}><ExpandMoreIcon /></Avatar>}
      {!isFirst && <Avatar sx={{cursor: 'pointer', position: 'absolute', right: 8, top: 8, zIndex: 1001, height: 24, width: 24}} onClick={() => {
        setNewPosition(-1);
      }}><ExpandLessIcon /></Avatar>}
    </Box>}
    {block.type === "image" && <BlockTranslatableSettingsImage
      forceClose={forceClose}
      setActive={setActive}
      block={block}
      setBlock={setBlock}
      activeLocale={activeLocale || 'nl'}
    />}
    {block.type === "text_custom_footer" && <BlockTranslatableSettingsTextCustomFooter
      forceClose={forceClose}
      setActive={setActive}
      block={block}
      setBlock={setBlock}
      activeLocale={activeLocale || 'nl'}
    />}
    {block.type === "text_custom_salutation" && <BlockTranslatableSettingsTextCustomSalutation
      forceClose={forceClose}
      setActive={setActive}
      block={block}
      setBlock={setBlock}
      activeLocale={activeLocale || 'nl'}
    />}
    {block.type === "text" && <BlockTranslatableSettingsText
      forceClose={forceClose}
      setActive={setActive}
      block={block}
      setBlock={setBlock}
      activeLocale={activeLocale || 'nl'}
    />}
    {block.type === "title_text" && <BlockTranslatableSettingsTitleText
      forceClose={forceClose}
      setActive={setActive}
      block={block}
      setBlock={setBlock}
      activeLocale={activeLocale || 'nl'}
    />}
    {block.type === "image_title_text" && <BlockTranslatableSettingsImageTitleText
      forceClose={forceClose}
      setActive={setActive}
      block={block}
      setBlock={setBlock}
      activeLocale={activeLocale || 'nl'}
    />}
    {block.type === "image_left" && <BlockTranslatableSettingsImageLeft
      forceClose={forceClose}
      setActive={setActive}
      block={block}
      setBlock={setBlock}
      activeLocale={activeLocale || 'nl'}
    />}
    {block.type === "image_right" && <BlockTranslatableSettingsImageRight
      forceClose={forceClose}
      setActive={setActive}
      block={block}
      setBlock={setBlock}
      activeLocale={activeLocale || 'nl'}
    />}
    {block.type === "title_text_2col" && <BlockTranslatableSettingsTitleText2Col
      forceClose={forceClose}
      setActive={setActive}
      block={block}
      setBlock={setBlock}
      activeLocale={activeLocale || 'nl'}
    />}
    {block.type === "separator" && <BlockTranslatableSettingsSeparator
      forceClose={forceClose}
      setActive={setActive}
      block={block}
      setBlock={setBlock}
    />}
  </>);
};