import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one, fetch_all } from '../../lib/server_helper';
import { loginAtom } from '../../lib/auth';

import {
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  StyledButton,
  StyledIconButton,
  StyledTextField
} from '../../components/interface';

import { WaitingList } from '../../models/WaitingList';

const { DateTime } = require("luxon");

type Props = {};

export const EmissionWaitingList: FunctionComponent<Props> = () => {
  // const navigate = useNavigate();
  // const theme = useTheme();

  const params = useParams();
  const emissionId: string | undefined = params.id;

  const [login, setLogin] = useAtom(loginAtom);
  const [waitingLists, setWaitingLists] = useState<WaitingList[]>([]);
  const [listItem, setListItem] = useState<WaitingList>({email: ''});
  const [waitingListsCount, setWaitingListsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [activeListItem, setActiveListItem] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("created_at DESC");

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setExitSavedSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onRemove = (id: number) => {
    setRemoveLoader(true);
    fetch_one<WaitingList>('/nl/v3/objects/remove', {
      object: 'waiting_list',
      ob: {
        id: id
      }
    }, (obs, complete_data) => {
      onSearch('');
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onSave = () => {
    fetch_one<WaitingList>('/nl/v3/objects/save', {
      object: 'waiting_list',
      fields: [],
      id: listItem?.id || null,
      ob: {
        ...listItem,
        emission_id: emissionId
      },
      handler_id: login?.id
    }, (ob, complete_data) => {
      setActiveListItem(false);
      setListItem({email: ''});
      onSearch('');
      setExitSavedSnackOpen(true);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const onSearch = (s: string) => {
    setLoader(true);
    fetch_all<WaitingList>('/nl/v3/objects/fetch_all', {
      object: 'waiting_list',
      fields: [
        'id', 'created_at', 'email'
      ],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        advanced: {
          emission_id: emissionId
        }
      }
    }, (obs, complete_data) => {
      setWaitingLists(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setWaitingListsCount(count);

      setLoader(false);
    }, (z) => {

    }, login)
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'created_at', headerName: 'Created', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'email', headerName: 'Email', width: 250 },
  ];

  return (
    <Page sx={{}} title="WaitingList" helper="waitingLists" actions={<>
      <StyledButton
        label="Create new"
        id='create_new'
        contained
        onClick={() => {
          setListItem({email: ''});
          setActiveListItem(true);
        }}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}
    startEndCrumb={<StyledButton
      label="Back to Emissions"
      id='back'
      text
      startIcon={<KeyboardBackspaceIcon />}
      path={`/emissions`}
      sx={{marginBottom: 1}}
    />}>
      {!!activeListItem && <Grid container spacing="8">
        <Grid item xs={12}>
          <StyledTextField
            label="Email"
            value={listItem.email || ''}
            id='name'
            onChange={(v) => {
              setListItem({
                ...listItem,
                email: v
              });
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} spacing="8" sx={{alignContent: 'baseline', textAlign: 'center'}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!!loader && <CircularProgress />}
          {!loader && <StyledButton
            label="Save"
            id='save'
            contained
            saveStartIcon
            onClick={(v) => {
              onSave();
            }}
            sx={{marginLeft: 1}}
          />}
          {!loader && <StyledButton
            label="Cancel"
            id='cancel'
            // contained
            onClick={(v) => {
              setListItem({email: ''});
              setActiveListItem(false);
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>
      </Grid>}
      {!activeListItem && <DataGrid
        action_count={2}
        actions={(params) => {
          return (<>
            <StyledIconButton title="Open Detail" color="primary" onClick={() => {
              setListItem(params.row);
              setActiveListItem(true);
            }}>
              <VisibilityIcon />
            </StyledIconButton>
            {!!removeLoader && <CircularProgress />}
            {!removeLoader && <StyledIconButton destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
          </>);
        }}
        objectCount={waitingListsCount}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objects={(waitingLists || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />}

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setExitSavedSnackOpen(b);}} />}
    </Page>
  );
}
