import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Avatar,
  Grid,
  Tab,
  Tabs
} from '@mui/material';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  ImagePicker,
  StyledTextField,
  StyledTextFieldTransl,
  Typography
} from '../../interface';

import { BenefitBlock } from '../../../models/BenefitBlock';

type BlockTranslatableSettingsImageProps = {
  forceClose: boolean;
  setActive: (e:boolean) => void;
  setBlock: (eb:BenefitBlock) => void;
  block: BenefitBlock;
  activeLocale: 'nl' | 'fr';
};

export const BlockTranslatableSettingsImage: FunctionComponent<BlockTranslatableSettingsImageProps> = ({activeLocale, forceClose, block, setBlock, setActive}) => {
  const theme = useTheme();

  const [mtop, setMtop] = useState<string>(block.settings?.mtop || '0px');
  const [mbot, setMbot] = useState<string>(block.settings?.mbot || '0px');
  const [imageUrl, setImageUrl] = useState<{nl?: string, fr?: string}>({...block.settings?.image_url});

  const [item, setItem] = useState<File>();
  const [itemUrl, setItemUrl] = useState<string>();
  const [itemFr, setItemFr] = useState<File>();
  const [itemUrlFr, setItemUrlFr] = useState<string>();

  const [activeTab, setActiveTab] = useState<number>(0);
  const [isHidden, setIsHidden] = useState<boolean>(true);

  useEffect(() => {
    let new_block = block;
    
    if (!new_block.settings) new_block.settings = {};
    new_block.settings.mtop = mtop;
    new_block.settings.mbot = mbot;
    new_block.settings.image_url = imageUrl;

    new_block.set_item_url_nl = itemUrl;
    new_block.item_nl = item;
    new_block.set_item_url_fr = itemUrlFr;
    new_block.item_fr = itemFr;

    setBlock(new_block);
  }, [item, itemUrl, itemFr, itemUrlFr, mtop, mbot, imageUrl]);

  return (<Grid item container xs={12} sx={{border: '1px solid lightgrey', marginBottom: 1}} onMouseEnter={() => {setActive(true);}} onMouseLeave={() => {setActive(false);}}>
    <Grid item xs={12} sx={{marginBottom: 0.5, position: 'relative', minHeight: '32px'}} onClick={() => {
      if (!!isHidden) setIsHidden(!isHidden);
    }}>
      {!forceClose && isHidden && <Avatar sx={{cursor: 'pointer', position: 'absolute', right: 8, top: 8, zIndex: 1000, height: 24, width: 24}}><ExpandLessIcon /></Avatar>}
      {!forceClose && !isHidden && <Avatar sx={{cursor: 'pointer', position: 'absolute', right: 8, top: 8, zIndex: 1000, height: 24, width: 24}} onClick={() => {
        setIsHidden(!isHidden);
      }}><ExpandMoreIcon /></Avatar>}

      {(!!forceClose || isHidden) && <Typography sx={{paddingLeft: 1, paddingTop: 1}}>Image</Typography>}

      {!forceClose && !isHidden && <Tabs value={activeTab} sx={{backgroundColor: theme.palette.opacitated?.main_01}} onChange={(event: React.SyntheticEvent, newValue: number) => {setActiveTab(newValue);}}>
        <Tab label="Content" />
        <Tab label="Settings" />
      </Tabs>}
    </Grid>
    {!forceClose && !isHidden && <>
      {activeTab === 0 && <Grid container item xs={12} hidden={activeTab !== 0} sx={{paddingLeft: 1, paddingRight: 1}}>
        {activeLocale === 'nl' && <Grid item xs={12}>
          <ImagePicker existing={block.get_item_nl} item={item} itemUrl={itemUrl} onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
            setItem(_item);
            setItemUrl(_itemUrl);
          }} />
        </Grid>}
        {activeLocale !== 'nl' && <Grid item xs={12}>
          <ImagePicker existing={block.get_item_fr} item={itemFr} itemUrl={itemUrlFr} onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
            setItemFr(_item);
            setItemUrlFr(_itemUrl);
          }} />
        </Grid>}
        <Grid item xs={12}>
          <StyledTextFieldTransl
            label="Image url (if it should be clickable)"
            activeLocale={activeLocale}
            value={{nl: imageUrl.nl || '', fr: imageUrl.fr || ''}}
            id="imageUrl"
            onChange={(v) => {setImageUrl(v);}}
            fullWidth
          />
        </Grid>
      </Grid>}
      {activeTab === 1 && <Grid container item xs={12} sx={{paddingLeft: 1, paddingRight: 1}}>
        <Grid item xs={12}>
          <StyledTextField
            label="Margin Top"
            value={mtop || '0px'}
            id="mtop"
            onChange={(v:string) => {setMtop(v);}}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            label="Margin Bottom"
            value={mbot || '0px'}
            id="mbot"
            onChange={(v:string) => {setMbot(v);}}
            fullWidth
          />
        </Grid>
      </Grid>}
    </>}
  </Grid>);
};