import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';

import { fetch_all, fetch_one } from '../lib/server_helper';

import {
  Box,
  CircularProgress,
  Grid
} from '@mui/material';

import { Page } from '../components/interface/Page';
import { StyledButton } from '../components/interface/StyledButton';
import { StyledDropzone } from '../components/interface/StyledDropzone';
import { StyledTextField } from '../components/interface/StyledTextField';
import { TimelineBase } from '../components/timeline/Base';
import { Typography } from '../components/interface/Typography';

import { UserEmail } from '../models/UserEmail';
import { loginAtom } from '../lib/auth';
import { useParams } from 'react-router-dom';

type UserDetailEmailsProps = {};

export const UserDetailEmails: FunctionComponent<UserDetailEmailsProps> = () => {
  const theme = useTheme();
  
  const params = useParams();
  const userId: string | undefined = params.id;

  const [login, setLogin] = useAtom(loginAtom);
  const [emails, setEmails] = useState<UserEmail[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);

  const [item, setItem] = useState<File>();
  const [comment, setComment] = useState<string>('');

  // const saveEmail = () => {
  //   setSaveLoader(true);

  //   let formData = new FormData();
  //   formData.append('object', 'email');
  //   formData.append('ob[comment]', comment);
  //   if (!!userId) formData.append('ob[user_id]', userId.toString());
  //   if (!!login?.id) formData.append('ob[admin_id]', login.id.toString());
  //   if (!!item) formData.append('files[item]', item);
    
  //   fetch_one<UserEmail>('/nl/v3/objects/save', formData, (ob, complete_data) => {
  //     fetchEmails();
  //     setSaveLoader(false);

  //     setComment('');
  //     setItem(undefined);
  //   }, (z) => {

  //   }, login)
  // };

  const fetchEmails = () => {
    setLoader(true);
    
    fetch_all<UserEmail>('/nl/v3/objects/fetch_all', {
      object: 'email',
      fields: [
        'id', 'type', 'message', 'created_at', 'emission_doc_id', 'stock_transfer_id',
        'user|first_name/last_name/vennoot_number/created_at', 'emission_key_id',
        'emission_doc|user|first_name/last_name/vennoot_number', 'emission_key|user|first_name/last_name/vennoot_number',
        'stock_transfer|user|first_name/last_name/vennoot_number', 'emission_doc|class|name'
      ],
      filter: {
        advanced: {
          user_id: userId
        }
      }
    }, (ob, complete_data) => {
      setEmails(ob);
      setLoader(false);
    }, (z) => {

    }, login)
  };

  useEffect(() => {
    fetchEmails();
  }, []);

  let user = '';
  emails.map(email => {
    if (!user) {
      if (!!email.emission_doc_id) {
        user = [email.emission_doc_user_first_name, email.emission_doc_user_last_name, `(${email.emission_doc_user_vennoot_number})`].join(" ");
      }
      if (!user) {
        if (!!email.emission_key_id) {
          user = [email.emission_key_user_first_name, email.emission_key_user_last_name, `(${email.emission_key_user_vennoot_number})`].join(" ");
        }
        if (!user) {
          if (!!email.stock_transfer_id) {
            user = [email.stock_transfer_user_first_name, email.stock_transfer_user_last_name, `(${email.stock_transfer_user_vennoot_number})`].join(" ");
          }
          if (!user) {
            user = [email.user_first_name, email.user_last_name, `(${email.user_vennoot_number})`].join(" ");
          }
        }
      }
    }
  });

  return (
    <Page sx={{}} title={user}>
      <Grid container spacing="8">
        {!!loader && <Grid item xs={12}>
          <CircularProgress />
        </Grid>}

        {/* <Grid item xs={12} sx={{paddingTop: '0px !important'}}>
          <Box
            sx={{
              padding: 1,
              paddingLeft: 8,
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            <Box sx={{
              width: '2px',
              backgroundColor: theme.palette.primary.main,
              height: '100%',
              position: 'absolute',
              top: 0,
              left: '8px',
              zIndex: 998
            }} />
            <Box sx={{
              width: '9px',
              border: '2px solid transparent',
              borderColor: theme.palette.primary.main,
              backgroundColor: 'white',
              height: '9px',
              position: 'absolute',
              top: 'calc(50% - 5px)',
              left: '3px',
              borderRadius: '8px',
              zIndex: 999
            }} />
            <Box sx={{
              width: '64px',
              backgroundColor: theme.palette.primary.main,
              height: '1px',
              position: 'absolute',
              top: '50%',
              left: 0,
              zIndex: 997
            }} />
            <Box
              sx={{
                padding: 1,
                border: '1px solid transparent',
                borderRadius: '10px 0px 0px 10px',
                borderColor: theme.palette.primary.main
              }}
            >
              <Grid container spacing="8">
                <Grid item xs={12}>
                  <Typography>New email</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    label="Comment"
                    value={comment}
                    id='comment'
                    onChange={(v) => {setComment(v);}}
                    sx={{width: '98%'}}
                    multiline
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography sx={{fontSize: '0.75rem'}}>Bijlage</Typography>
                  <StyledDropzone
                    acceptedFiles={{
                      'image/*': [],
                      'application/pdf': []
                    }}
                    onDrop={acceptedFiles => {setItem(acceptedFiles[0]);}}
                    multiple={false}
                    maxFiles={1}
                    maxSize={3 * 1000 * 1000}
                    items={!!item ? [item] : []}
                    dropText="Click here, or drop some files."
                    dropHint="Allowed: 1 image (.png, .jpeg, .jpg, .gif) or PDF (.pdf), < 3mb"
                  />
                </Grid>
                {!saveLoader && <Grid item xs={12}>
                  <StyledButton
                    label="save"
                    id='save'
                    contained
                    onClick={(v) => {
                      saveEmail();
                    }}
                    sx={{alignSelf: 'center'}}
                  />
                </Grid>}
              </Grid>
            </Box>
          </Box>
        </Grid> */}

        {!loader && emails.length < 1 && <>No emails were found</>}
        {emails.map(email => <TimelineBase email={email} />)}

      </Grid>
    </Page>
  );
}
