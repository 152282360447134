import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtomValue } from 'jotai';

import { loginAtom } from '../lib/auth';
import { fetch_one } from '../lib/server_helper';

import {
  Badge,
  Box
} from '@mui/material';

import AltRouteIcon from '@mui/icons-material/AltRoute';
import BarChartIcon from '@mui/icons-material/BarChart';
import BusinessIcon from '@mui/icons-material/Business';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import EmailIcon from '@mui/icons-material/Email';
import EngineeringIcon from '@mui/icons-material/Engineering';
import EuroIcon from '@mui/icons-material/Euro';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import SpeedIcon from '@mui/icons-material/Speed';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

import { MenuItem } from '../components/sidebar/MenuItem';
import { Profile } from '../components/sidebar/Profile';
import { SidebarCount } from '../models/SidebarCount';
import { SidebarQueues } from './SidebarQueues';

type SidebarProps = {};

export const Sidebar: FunctionComponent<SidebarProps> = () => {
  const theme = useTheme();
  const login = useAtomValue(loginAtom);
  let is_admin:boolean = login?.is_admin || false;
  let is_helper_admin:boolean = !!is_admin || (login?.is_helper_admin || false);

  const [badgeCounts, setBadgeCounts] = useState<SidebarCount|undefined>(undefined);
  const [searchInterval, setSearchInterval] = useState(setInterval(() => {}, 30 * 1000));

  useEffect(() => {
    clearTimeout(searchInterval);
    if (!!login) {
      onSearch();
      setSearchInterval(setInterval(onSearch, 30 * 1000));
    }
  }, [login]);

  const onSearch = () => {
    if (!!login) {
      fetch_one<SidebarCount>('/nl/v3/objects/custom_action', {
        object: 'user',
        class_action: 'custom_api_get_sidebar_counts',
        handler_id: login.id
      }, (obs, complete_data) => {
        setBadgeCounts(obs.custom_result);
      }, (z) => {
  
      }, login);
    }
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height:'100%',
      backgroundColor: theme.palette.primary?.main,
      margin: -1,
      padding: 1
    }}>
      <Profile />

      <Box sx={{height: 40, paddingTop: 2, textAlign: 'right'}}>
        <SidebarQueues />
      </Box>

      <MenuItem path="/" label="Administration" icon={<SpeedIcon />} />
      <MenuItem hidden={!is_helper_admin} path="/users" label="Users" icon={<PeopleAltIcon />} />
      <MenuItem hidden={!is_admin} path="/transactions" label="Transactions" icon={<SyncAltIcon />} />
      <MenuItem hidden={!is_admin} path="/give_me_pain" label="PAIN sender" icon={<ShoppingCartCheckoutIcon />} />
      
      <MenuItem hidden={!is_admin} label="Content" icon={<FormatListBulletedIcon />}>
        <MenuItem hidden={!is_admin} path="/assets" label="Assets" child />
        <MenuItem hidden={!is_admin} path="/banners" label="Banners" child />
        <MenuItem hidden={!is_admin} path="/benefits" label="Benefits" child />
        {/* <MenuItem hidden={!is_admin} path="/nonexisting" label="Educationals" child /> */}
        <MenuItem hidden={!is_admin} path="/faqs" label="FAQ" child />
        <MenuItem hidden={!is_admin} path="/frontpagers" label="Frontpagers" child />
        <MenuItem hidden={!is_admin} path="/investments" label="Investments" child />
{/* <MenuItem hidden={!is_admin} path="/nonexisting" label="Landing Pages" child /> */}
        <MenuItem hidden={!is_admin} path="/news" label="News" child />
        <MenuItem hidden={!is_admin} path="/raffles" label="Raffles" child />
        <MenuItem hidden={!is_admin} path="/security_questions" label="Sec.Q" child />
        <MenuItem hidden={!is_admin} path="/statics" label="Statics" child />
        {/* <MenuItem hidden={!is_admin} path="/nonexisting" label="Surveys" child /> */}
      </MenuItem>
      
      <MenuItem hidden={!is_admin} label="Emails" icon={<EmailIcon />}>
        {/* <MenuItem hidden={!is_admin} path="/nonexisting" label="Emails" child /> */}
        <MenuItem hidden={!is_admin} path="/prints" label="Prints" child />
        <MenuItem hidden={!is_admin} path="/email_templates" label="Templates" child />
      </MenuItem>
      
      <MenuItem hidden={!is_admin} label="Exits" icon={<PersonOffIcon />} count={(badgeCounts?.death_reports || 0) + (badgeCounts?.exits || 0)}>
        <Badge badgeContent={badgeCounts?.exits || 0} color="secondary" sx={{width: '100%', '& span': {top: '16px'}}}>
          <MenuItem hidden={!is_admin} path="/exits" label="Yearly" child />
        </Badge>
        {/* <MenuItem hidden={!is_admin} path="/nonexisting" label="Yearly done" child />
        <MenuItem hidden={!is_admin} path="/nonexisting" label="Yearly cancelled" child />
        <MenuItem hidden={!is_admin} path="/nonexisting" label="Unwilling todo" child /> */}
        <Badge badgeContent={badgeCounts?.death_reports || 0} color="secondary" sx={{width: '100%', '& span': {top: '16px'}}}>
          <MenuItem hidden={!is_admin} path="/deaths" label="Deathmessages" child />
        </Badge>
        <MenuItem hidden={!is_admin} path="/fod_lists" label="FOD lists" child />
        <MenuItem hidden={!is_admin} path="/custom201s" label="Custom 201" child />
        <MenuItem hidden={!is_admin} path="/downloadables" label="Downloadables" child />
        <MenuItem hidden={!is_admin} path="/notaries" label="Notaries" child />
        {/* <MenuItem hidden={!is_admin} path="/nonexisting" label="Reason List" child /> */}
        <MenuItem hidden={!is_admin} path="/exit_reasons" label="Reasons" child />
      </MenuItem>
      
      <Badge badgeContent={badgeCounts?.stock_transfers || 0} color="secondary" sx={{width: '100%', '& span': {top: '32px', right: '16px'}}}>
        <MenuItem hidden={!is_admin} path="/stock_transfers" label="Transfers" icon={<AltRouteIcon sx={{transform: 'rotate(90deg)'}} />} />
      </Badge>
      
      <MenuItem hidden={!is_admin} label="Offices" icon={<BusinessIcon />} count={badgeCounts?.user_changes || 0}>
        <Badge badgeContent={badgeCounts?.user_changes || 0} color="secondary" sx={{width: '100%', '& span': {top: '16px'}}}>
          <MenuItem hidden={!is_admin} path="/user_changes" label="ChangeReq." child />
        </Badge>
        <MenuItem hidden={!is_admin} path="/offices" label="Offices" child />
        <MenuItem hidden={!is_admin} path="/office_statics" label="OfficeStatics" child />
      </MenuItem>
      
      <Badge badgeContent={badgeCounts?.meeting_emails || 0} color="secondary" sx={{width: '100%', '& span': {top: '32px'}}}>
        <MenuItem hidden={!is_admin} path="/meeting_settings" label="Meetings" icon={<GroupsIcon />} />
      </Badge>
      {/* <MenuItem hidden={!is_admin} label="Meetings" icon={<GroupsIcon />}>
        <MenuItem hidden={!is_admin} path="/meeting_settings" label="Meetings" child />
        <MenuItem hidden={!is_admin} path="/nonexisting" label="Meeting Emails" child />
        <MenuItem hidden={!is_admin} path="/nonexisting" label="Meeting Subscr." child />
      </MenuItem> */}
      
      <MenuItem hidden={!is_admin} label="Dividend" icon={<EuroIcon />}>
        <MenuItem hidden={!is_admin} path="/dividend_settings" label="Calc. Settings" child />
        <MenuItem hidden={!is_admin} path="/dividend_calculations" label="Calculations" child />
        <MenuItem hidden={!is_admin} path="/dividend_pains" label="PAINs" child />
      </MenuItem>
      
      <MenuItem hidden={!is_admin} label="Emission" icon={<GroupAddIcon />}>
        <MenuItem hidden={!is_admin} path="/emission/checklist" label="Checklist" child />
        <MenuItem hidden={!is_admin} path="/emissions" label="Settings" child />
        <MenuItem hidden={!is_admin} path="/emission/payments" label="Payments" child />
        <MenuItem hidden={!is_admin} path="/emission/payment_files" label="Payment Files" child />
        <MenuItem hidden={!is_admin} path="/emission/repayments" label="Repayments" child />
      </MenuItem>

      <MenuItem hidden={!is_admin} path="/exports" label="Exports" icon={<BarChartIcon />} />
      
      <MenuItem hidden={!is_admin} label="Platform" icon={<EngineeringIcon />}>
        {/* <MenuItem hidden={!is_admin} path="/nonexisting" label="Email Blocks" child /> */}
        <MenuItem hidden={!is_admin} path="/shares" label="Shares" child />
        <MenuItem hidden={!is_admin} path="/system_settings" label="System Settings" child />
        <MenuItem hidden={!is_admin} path="/tags" label="Tags" child />
        {/* <MenuItem hidden={!is_admin} path="/nonexisting" label="Test PAINs" child /> */}
{/* <MenuItem hidden={!is_admin} path="/nonexisting" label="Landing Sources" child /> */}
{/* <MenuItem hidden={!is_admin} path="/nonexisting" label="Log Sources" child /> */}
      </MenuItem>
      
      {/* <MenuItem hidden={!is_admin} label="Vuilbak" icon={<DeleteSweepIcon />}>
        <MenuItem hidden={!is_admin} path="/nonexisting" label="Unwilling done" child />
      </MenuItem> */}
    </Box>
  );
}
