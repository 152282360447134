import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one, fetch_all } from '../../lib/server_helper';
import { loginAtom } from '../../lib/auth';

import {
  Box,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  StyledButton,
  StyledIconButton,
  StyledSwitch,
  StyledTextField,
  Typography
} from '../../components/interface';

import { WaitingList } from '../../models/WaitingList';

const { DateTime } = require("luxon");

type Props = {};

export const EmissionStats: FunctionComponent<Props> = () => {
  // const navigate = useNavigate();
  const theme = useTheme();

  const params = useParams();
  const emissionId: string | undefined = params.id;

  const [login, setLogin] = useAtom(loginAtom);
  const [data, setData] = useState<any[]>([]);
  const [listItem, setListItem] = useState<WaitingList>({email: ''});
  const [waitingListsCount, setWaitingListsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [activeListItem, setActiveListItem] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("created_at DESC");
  const [index, setIndex] = useState<number>(0);

  const [filterNew, setFilterNew] = useState<boolean>(true);
  const [filterAge, setFilterAge] = useState<boolean>(true);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setExitSavedSnackOpen] = useState<boolean>(false);



  const getStats = () => {
    fetch_one<{success: boolean, custom_result: {success: boolean; data: any[]}}>('/nl/v3/objects/custom_action', {
      object: 'emission',
      class_action: 'custom_api_create_report',
      emission_id: emissionId,
    }, (ob, complete_data) => {
      setData(ob.custom_result.data);
      console.log(ob);
      setLoader(false);
    }, (z, y) => {
      
    }, login);
  };

  const createExport = () => {
    fetch_one<{success: boolean, custom_result: string}>('/nl/v3/objects/custom_action', {
      object: 'emission',
      class_action: 'custom_api_send_report',
      emission_id: emissionId,
      handler_id: login?.id
    }, (ob, complete_data) => {
      window.open(ob.custom_result, "_BLANK");
    }, (z, y) => {
      
    }, login);
  }





  let titleValueRow = () => {
    return <>{contentHolder(
      <Typography sx={{fontWeight: 'bold', fontSize: '0.9rem'}}>Amount</Typography>,
      <Typography sx={{fontWeight: 'bold', fontSize: '0.9rem'}}>Saldo</Typography>
    )}</>;
  }
  let valueRow = (amount:number, value:number) => {
    return <>{contentHolder(
      <Typography sx={{fontSize: '0.8rem'}}>{amount.toLocaleString("nl-BE")}</Typography>,
      <Typography sx={{fontSize: '0.8rem'}}>{value.toLocaleString("nl-BE")}&nbsp;€</Typography>
    )}</>;
  }
  let contentHolder = (left:ReactElement, right:ReactElement, border?:boolean) => {
    return <Box sx={{
      width: "calc(100% - 16px);",
      padding: 0.25,
      display: 'block;'
    }}>
      <Box sx={{
        width: !border ? '39%' : '49%',
        display: 'inline-block',
        textAlign: 'right'
      }}>
        {left}
      </Box>
      <Box sx={{
        width: !border ? '59%' : '49%',
        display: 'inline-block',
        textAlign: 'right',
        borderLeft: !!border ? '1px dotted transparent' : 'none',
        borderLeftColor: theme.palette.primary.main
      }}>
        {right}
      </Box>
    </Box>;
  }

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      setIndex(index + 1);
    }, 2000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [index]);
  useEffect(() => {
    getStats();
  }, []);

  return (
    <Page sx={{}} title="Stats" helper="emissionstats">
      <Grid container spacing={1}>
        {!!loader && <Grid item xs={12} sx={{textAlign: 'center'}}>
          <CircularProgress />
        </Grid>}
        {!!loader && (index % 10) === 0 && <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography>Loading stats...</Typography>
        </Grid>}
        {!!loader && (index % 10) === 1 && <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography>Constructing frames...</Typography>
        </Grid>}
        {!!loader && (index % 10) === 2 && <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography>Checking users...</Typography>
        </Grid>}
        {!!loader && (index % 10) === 3 && <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography>Checking transactions...</Typography>
        </Grid>}
        {!!loader && (index % 10) === 4 && <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography>Checking the stars...</Typography>
        </Grid>}
        {!!loader && (index % 10) === 5 && <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography>Checking planetary alignment...</Typography>
        </Grid>}
        {!!loader && (index % 10) === 6 && <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography>Consulting GM...</Typography>
        </Grid>}
        {!!loader && (index % 10) === 7 && <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography>Compressing data...</Typography>
        </Grid>}
        {!!loader && (index % 10) === 8 && <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography>Filling black hole...</Typography>
        </Grid>}
        {!!loader && (index % 10) === 9 && <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography>Restarting reality...</Typography>
        </Grid>}

        {!loader && !!data && <Grid container spacing={1} item xs={12}>
          <Grid item xs={12}>
            <Typography variant="h3">Settings</Typography>
            <Divider />
          </Grid>
          <Grid item xs={5}>
            <StyledSwitch
              label="Show New/Existing split"
              offLabel="No"
              onLabel="Yes"
              value={!!filterNew}
              onChange={(v) => {
                setFilterNew(!!v);
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <StyledSwitch
              label="Show Age split"
              offLabel="No"
              onLabel="Yes"
              value={!!filterAge}
              onChange={(v) => {
                setFilterAge(!!v);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <StyledButton
              label="Export"
              id='emission_stats'
              contained
              onClick={(v) => {
                createExport();
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{marginTop: 5}}>
            
          </Grid>





          <Grid item xs={12}>
            <Typography variant="h3">A vennoten</Typography>
            <Divider />
          </Grid>

          <Grid item xs={2} sx={{textAlign: 'left', backgroundColor: theme.palette.opacitated?.main_02}}>
            <Typography sx={{fontWeight: 'bold'}}>Month</Typography>
          </Grid>
          {!!filterNew && <Grid item xs={4} sx={{textAlign: 'center', backgroundColor: theme.palette.opacitated?.main_02, borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
            <Typography sx={{fontWeight: 'bold'}}>Existing</Typography>
          </Grid>}
          {!!filterNew && <Grid item xs={4} sx={{textAlign: 'center', backgroundColor: theme.palette.opacitated?.main_02, borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
            <Typography sx={{fontWeight: 'bold'}}>New</Typography>
          </Grid>}
          <Grid item xs={!!filterNew ? 2 : 10} sx={{textAlign: 'center', backgroundColor: theme.palette.opacitated?.main_02, borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
            <Typography sx={{fontWeight: 'bold'}}>Total</Typography>
          </Grid>

          <Grid item xs={2} sx={{backgroundColor: theme.palette.opacitated?.main_02}}>
            &nbsp;
          </Grid>
          {!!filterNew && <Grid item xs={4} sx={{textAlign: 'center', backgroundColor: theme.palette.opacitated?.main_02, borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
            {titleValueRow()}
          </Grid>}
          {!!filterNew && <Grid item xs={4} sx={{textAlign: 'center', backgroundColor: theme.palette.opacitated?.main_02, borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
            {titleValueRow()}
          </Grid>}
          <Grid item xs={!!filterNew ? 2 : 10} sx={{fontWeight: 'bold', backgroundColor: theme.palette.opacitated?.main_02, borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
            {titleValueRow()}
          </Grid>

          {data.map(row => <>
            <Grid item xs={2} sx={{height: '24px'}}>
              <Typography sx={{fontSize: '0.8rem'}}>{row[0].month}</Typography>
            </Grid>
            {!!filterNew && <Grid item xs={4} sx={{height: '24px', borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
              {valueRow(row[0].exist.users, row[0].exist.saldo)}
            </Grid>}
            {!!filterNew && <Grid item xs={4} sx={{height: '24px', borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
              {valueRow(row[0].new.users, row[0].new.saldo)}
            </Grid>}
            <Grid item xs={!!filterNew ? 2 : 10} sx={{height: '24px', borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
              {valueRow(row[0].total.users, row[0].total.saldo)}
            </Grid>
          </>)}
          <Grid item xs={12} sx={{marginTop: 5}}>
            
          </Grid>





          <Grid item xs={12}>
            <Typography variant="h3">B vennoten</Typography>
            <Divider />
          </Grid>

          <Grid item xs={!!filterNew && !!filterAge ? 1 : 2} sx={{textAlign: 'left', backgroundColor: theme.palette.opacitated?.main_02}}>
            <Typography sx={{fontWeight: 'bold'}}>Month</Typography>
          </Grid>
          {!!filterNew && <Grid item xs={!!filterAge ? 3 : 4} sx={{textAlign: 'center', backgroundColor: theme.palette.opacitated?.main_02, borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
            <Typography sx={{fontWeight: 'bold'}}>Existing</Typography>
          </Grid>}
          {!!filterNew && <Grid item xs={!!filterAge ? 3 : 4} sx={{textAlign: 'center', backgroundColor: theme.palette.opacitated?.main_02, borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
            <Typography sx={{fontWeight: 'bold'}}>New</Typography>
          </Grid>}
          {!!filterAge && <Grid item xs={!!filterNew ? 3 : 8} sx={{textAlign: 'center', backgroundColor: theme.palette.opacitated?.main_02, borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
            <Typography sx={{fontWeight: 'bold'}}>Total</Typography>
          </Grid>}
          <Grid item xs={!!filterNew || !!filterAge ? 2 : 10} sx={{textAlign: 'center', backgroundColor: theme.palette.opacitated?.main_02, borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
            <Typography sx={{fontWeight: 'bold'}}>Total</Typography>
          </Grid>

          {!!filterAge && <>
            <Grid item xs={!!filterNew && !!filterAge ? 1 : 2} sx={{backgroundColor: theme.palette.opacitated?.main_02}}>
              &nbsp;
            </Grid>
            {!!filterNew && <Grid item xs={!!filterAge ? 3 : 4} sx={{textAlign: 'center', backgroundColor: theme.palette.opacitated?.main_02, borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
              {!filterAge && titleValueRow()}
              {!!filterAge && contentHolder(
                <Typography sx={{fontWeight: 'bold', fontSize: '0.9rem'}}>{`&lt;60`}</Typography>,
                <Typography sx={{fontWeight: 'bold', fontSize: '0.9rem'}}>&ge;60</Typography>,
                true
              )}
            </Grid>}
            {!!filterNew && <Grid item xs={!!filterAge ? 3 : 4} sx={{textAlign: 'center', backgroundColor: theme.palette.opacitated?.main_02, borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
              {!filterAge && titleValueRow()}
              {!!filterAge && contentHolder(
                <Typography sx={{fontWeight: 'bold', fontSize: '0.9rem'}}>{`&lt;60`}</Typography>,
                <Typography sx={{fontWeight: 'bold', fontSize: '0.9rem'}}>&ge;60</Typography>,
                true
              )}
            </Grid>}
            {!!filterAge && <Grid item xs={!!filterNew ? 3 : 8} sx={{textAlign: 'center', backgroundColor: theme.palette.opacitated?.main_02, borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
              {!!filterAge && contentHolder(
                <Typography sx={{fontWeight: 'bold', fontSize: '0.9rem'}}>{`&lt;60`}</Typography>,
                <Typography sx={{fontWeight: 'bold', fontSize: '0.9rem'}}>&ge;60</Typography>,
                true
              )}
            </Grid>}
            <Grid item xs={!!filterNew || !!filterAge ? 2 : 10} sx={{fontWeight: 'bold', backgroundColor: theme.palette.opacitated?.main_02, borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
              {/* {titleValueRow()} */}
            </Grid>
          </>}

          <Grid item xs={!!filterNew && !!filterAge ? 1 : 2} sx={{backgroundColor: theme.palette.opacitated?.main_02}}>
            &nbsp;
          </Grid>
          {!!filterNew && <Grid item xs={!!filterAge ? 3 : 4} sx={{textAlign: 'center', backgroundColor: theme.palette.opacitated?.main_02, borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
            {!filterAge && titleValueRow()}
            {!!filterAge && contentHolder(
              titleValueRow(),
              titleValueRow(),
              true
            )}
          </Grid>}
          {!!filterNew && <Grid item xs={!!filterAge ? 3 : 4} sx={{textAlign: 'center', backgroundColor: theme.palette.opacitated?.main_02, borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
            {!filterAge && titleValueRow()}
            {!!filterAge && contentHolder(
              titleValueRow(),
              titleValueRow(),
              true
            )}
          </Grid>}
          {!!filterAge && <Grid item xs={!!filterNew ? 3 : 8} sx={{textAlign: 'center', backgroundColor: theme.palette.opacitated?.main_02, borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
            {!!filterAge && contentHolder(
              titleValueRow(),
              titleValueRow(),
              true
            )}
          </Grid>}
          <Grid item xs={!!filterNew || !!filterAge ? 2 : 10} sx={{fontWeight: 'bold', backgroundColor: theme.palette.opacitated?.main_02, borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
            {titleValueRow()}
          </Grid>

          {data.map(row => <>
            <Grid item xs={!!filterNew && !!filterAge ? 1 : 2} sx={{height: '24px'}}>
              <Typography sx={{fontSize: '0.8rem'}}>{row[0].month}</Typography>
            </Grid>
            {!!filterNew && <Grid item xs={!!filterAge ? 3 : 4} sx={{height: '24px', borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
              {/* @ts-ignore */}
              {!filterAge && valueRow(Object.values(row[1].exist).reduce((acc:number, curr:{users: number}) => acc + curr.users, 0), Object.values(row[1].exist).reduce((acc:number, curr:{saldo: number}) => acc + curr.saldo, 0))}
              {/* @ts-ignore */}
              {!!filterAge && contentHolder(valueRow(Object.values(row[1].exist).filter(fff => fff.age < 60).reduce((acc:number, curr:{users: number}) => acc + curr.users, 0), Object.values(row[1].exist).filter(fff => fff.age < 60).reduce((acc:number, curr:{saldo: number}) => acc + curr.saldo, 0)), valueRow(Object.values(row[1].exist).filter(fff => fff.age >= 60).reduce((acc:number, curr:{users: number}) => acc + curr.users, 0), Object.values(row[1].exist).filter(fff => fff.age >= 60).reduce((acc:number, curr:{saldo: number}) => acc + curr.saldo, 0)), true)}
            </Grid>}
            {!!filterNew && <Grid item xs={!!filterAge ? 3 : 4} sx={{height: '24px', borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
              {/* @ts-ignore */}
              {!filterAge && valueRow(Object.values(row[1].new).reduce((acc:number, curr:{users: number}) => acc + curr.users, 0), Object.values(row[1].new).reduce((acc:number, curr:{saldo: number}) => acc + curr.saldo, 0))}
              {/* @ts-ignore */}
              {!!filterAge && contentHolder(valueRow(Object.values(row[1].new).filter(fff => fff.age < 60).reduce((acc:number, curr:{users: number}) => acc + curr.users, 0), Object.values(row[1].new).filter(fff => fff.age < 60).reduce((acc:number, curr:{saldo: number}) => acc + curr.saldo, 0)), valueRow(Object.values(row[1].new).filter(fff => fff.age >= 60).reduce((acc:number, curr:{users: number}) => acc + curr.users, 0), Object.values(row[1].new).filter(fff => fff.age >= 60).reduce((acc:number, curr:{saldo: number}) => acc + curr.saldo, 0)), true)}
            </Grid>}
            {!!filterAge && <Grid item xs={!!filterNew ? 3 : 8} sx={{height: '24px', borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
              {/* {!filterAge && titleValueRow()} */}
              {/* @ts-ignore */}
              {!!filterAge && contentHolder(valueRow(Object.values(row[1].total).filter(fff => fff.age < 60).reduce((acc:number, curr:{users: number}) => acc + curr.users, 0), Object.values(row[1].total).filter(fff => fff.age < 60).reduce((acc:number, curr:{saldo: number}) => acc + curr.saldo, 0)), valueRow(Object.values(row[1].total).filter(fff => fff.age >= 60).reduce((acc:number, curr:{users: number}) => acc + curr.users, 0), Object.values(row[1].total).filter(fff => fff.age >= 60).reduce((acc:number, curr:{saldo: number}) => acc + curr.saldo, 0)), true)}
            </Grid>}
            <Grid item xs={!!filterNew || !!filterAge ? 2 : 10} sx={{height: '24px', borderLeft: '1px solid transparent', borderLeftColor: theme.palette.primary.main}}>
              {/* @ts-ignore */}
              {valueRow(Object.values(row[1].total).reduce((acc:number, curr:{users: number}) => acc + curr.users, 0), Object.values(row[1].total).reduce((acc:number, curr:{saldo: number}) => acc + curr.saldo, 0))}
            </Grid>
          </>)}
        </Grid>}
      </Grid>
    </Page>
  );
}
