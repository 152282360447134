import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';

import { fetch_one } from '../lib/server_helper';

import {
  CircularProgress,
  Divider,
  Grid,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import { loginAtom, renew_sign_in } from '../lib/auth';

import { Page } from '../components/interface/Page';
import { StyledButton } from '../components/interface/StyledButton';
import { StyledSwitch } from '../components/interface/StyledSwitch';
import { StyledTextField } from '../components/interface/StyledTextField';
import { UserDetailBase } from '../components/user_detail/Base';

import { User } from '../models/User';

type SettingsProps = {};

export const Settings: FunctionComponent<SettingsProps> = () => {
  const theme = useTheme();

  const [login, setLogin] = useAtom(loginAtom);
  const [userDetail, setUserDetail] = useState<User|null>(null);
  const [loader, setLoader] = useState<boolean>(true);

  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [settingsSavedSnackOpen, setSettingsSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'extra_data'
  ];

  const fetchUser = () => {
    setLoader(true);
    
    if (!!login) {
      fetch_one<User>('/nl/v3/objects/fetch_all', {
        object: 'user',
        fields: fields,
        id: login.id
      }, (ob, complete_data) => {
        setUserDetail(ob);
        setLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, login);
    }
  };

  const saveUser = () => {
    setLoader(true);

    if (!!userDetail) {
      fetch_one<User>('/nl/v3/objects/save', {
        object: 'user',
        fields: fields,
        ob: {
          id: userDetail?.id,
          extra_data: {
            ...(userDetail.extra_data || {}),
            settings: {
              ...(userDetail.extra_data?.settings || {}),
              hide_dashboard: (userDetail?.extra_data?.settings.hide_dashboard || '0'),
              editor_height: (userDetail?.extra_data?.settings.editor_height || '300px'),
              languages_split: (userDetail?.extra_data?.settings.languages_split || '0'),
              tile_statics: (userDetail?.extra_data?.settings.tile_statics || '0'),
              tile_exits: (userDetail?.extra_data?.settings.tile_exits || '0'),
              tile_deaths: (userDetail?.extra_data?.settings.tile_deaths || '0'),
              destroy_confirm_ignore: (userDetail?.extra_data?.settings.destroy_confirm_ignore || '0'),
              search_use_autosearch: (userDetail?.extra_data?.settings.search_use_autosearch || '0'),
              search_use_enter: (userDetail?.extra_data?.settings.search_use_enter || '0')
            }
          }
        }
      }, (ob, complete_data) => {
        setUserDetail(ob);
        setLoader(false);
        setSettingsSavedSnackOpen(true);
        renew_sign_in((x) => {
          setLogin(x);
          console.log('renew_sign_in from settings');
        });
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, login);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <Page sx={{}} title="My settings">
      <Grid container spacing="8">
        <Grid container item xs={12} md={6} rowSpacing="8" sx={{alignContent: 'baseline'}}>
          <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.identity
            }}
            title="Dashboard"
            wide
          >
            <Grid item xs={12}>
              <StyledSwitch
                label="'Marc'-stats on dashboard"
                offLabel="Visible"
                onLabel="Hidden"
                value={userDetail?.extra_data?.settings?.hide_dashboard !== '0'}
                onChange={(v) => {if (!!userDetail) setUserDetail({
                  ...userDetail,
                  extra_data: {
                    ...(userDetail.extra_data || {}),
                    settings: {
                      ...userDetail.extra_data?.settings || {},
                      hide_dashboard: (!!v ? '1' : '0')
                    }
                  }
                });}}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledSwitch
                label="Dashboard tile 'Statics'"
                offLabel="No"
                onLabel="Yes"
                value={userDetail?.extra_data?.settings?.tile_statics === '1'}
                onChange={(v) => {if (!!userDetail) setUserDetail({
                  ...userDetail,
                  extra_data: {
                    ...(userDetail.extra_data || {}),
                    settings: {
                      ...userDetail.extra_data?.settings || {},
                      tile_statics: (!!v ? '1' : '0')
                    }
                  }
                });}}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledSwitch
                label="Dashboard tile 'Exits'"
                offLabel="No"
                onLabel="Yes"
                value={userDetail?.extra_data?.settings?.tile_exits === '1'}
                onChange={(v) => {if (!!userDetail) setUserDetail({
                  ...userDetail,
                  extra_data: {
                    ...(userDetail.extra_data || {}),
                    settings: {
                      ...userDetail.extra_data?.settings || {},
                      tile_exits: (!!v ? '1' : '0')
                    }
                  }
                });}}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledSwitch
                label="Dashboard tile 'Deaths'"
                offLabel="No"
                onLabel="Yes"
                value={userDetail?.extra_data?.settings?.tile_deaths === '1'}
                onChange={(v) => {if (!!userDetail) setUserDetail({
                  ...userDetail,
                  extra_data: {
                    ...(userDetail.extra_data || {}),
                    settings: {
                      ...userDetail.extra_data?.settings || {},
                      tile_deaths: (!!v ? '1' : '0')
                    }
                  }
                });}}
              />
            </Grid>
          </UserDetailBase>

          <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.register
            }}
            title="Search"
            wide
          >
            <Grid item xs={12}>
              <StyledSwitch
                label="Use autosearch (500 ms after typing)"
                offLabel="Do not use"
                onLabel="Use"
                value={userDetail?.extra_data?.settings?.search_use_autosearch === '1'}
                onChange={(v) => {if (!!userDetail) setUserDetail({
                  ...userDetail,
                  extra_data: {
                    ...(userDetail.extra_data || {}),
                    settings: {
                      ...userDetail.extra_data?.settings || {},
                      search_use_autosearch: (!!v ? '1' : '0')
                    }
                  }
                });}}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledSwitch
                label="Use 'enter'-search"
                offLabel="Do not use"
                onLabel="Use"
                value={userDetail?.extra_data?.settings?.search_use_enter === '1'}
                onChange={(v) => {if (!!userDetail) setUserDetail({
                  ...userDetail,
                  extra_data: {
                    ...(userDetail.extra_data || {}),
                    settings: {
                      ...userDetail.extra_data?.settings || {},
                      search_use_enter: (!!v ? '1' : '0')
                    }
                  }
                });}}
              />
            </Grid>
          </UserDetailBase>

        </Grid>
        <Grid container item xs={12} md={6} rowSpacing="8" sx={{alignContent: 'baseline'}}>

          <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.security
            }}
            title="Editors"
            wide
          >
            <Grid item xs={12}>
              <StyledTextField
                label="Editor height"
                helperText="Notation = '300px'"
                value={userDetail?.extra_data?.settings?.editor_height || '300px'}
                id='editor_height'
                onChange={(v) => {if (!!userDetail) setUserDetail({
                  ...userDetail,
                  extra_data: {
                    ...(userDetail.extra_data || {}),
                    settings: {
                      ...userDetail.extra_data?.settings || {},
                      editor_height: v
                    }
                  }
                });}}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <StyledSwitch
                label="Show all language editors at the same time"
                offLabel="1 language at a time"
                onLabel="All languages visible"
                value={userDetail?.extra_data?.settings?.languages_split === '1'}
                onChange={(v) => {if (!!userDetail) setUserDetail({
                  ...userDetail,
                  extra_data: {
                    ...(userDetail.extra_data || {}),
                    settings: {
                      ...userDetail.extra_data?.settings || {},
                      languages_split: (!!v ? '1' : '0')
                    }
                  }
                });}}
              />
            </Grid>
          </UserDetailBase>

          <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.exit
            }}
            title="Security"
            wide
          >
            <Grid item xs={12}>
              <StyledSwitch
                label="Skip destroy confirmation popup"
                offLabel="Show"
                onLabel="Skip"
                value={userDetail?.extra_data?.settings?.destroy_confirm_ignore === '1'}
                onChange={(v) => {if (!!userDetail) setUserDetail({
                  ...userDetail,
                  extra_data: {
                    ...(userDetail.extra_data || {}),
                    settings: {
                      ...userDetail.extra_data?.settings || {},
                      destroy_confirm_ignore: (!!v ? '1' : '0')
                    }
                  }
                });}}
              />
            </Grid>
          </UserDetailBase>

        </Grid>

        <Grid item xs={12} spacing="8" sx={{alignContent: 'baseline', textAlign: 'center'}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!!loader && <CircularProgress />}
          {!loader && <StyledButton
            label="Save"
            id='save'
            contained
            onClick={(v) => {
              saveUser();
            }}
          />}
        </Grid>
      </Grid>

      {!!settingsSavedSnackOpen && <Snackbar
        open={settingsSavedSnackOpen}
        onClose={() => {setSettingsSavedSnackOpen(false);}}
        autoHideDuration={2000}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">
          Saved!
        </MuiAlert>
      </Snackbar>}

      {!!serverErrorSnackOpen && <Snackbar
        open={serverErrorSnackOpen}
        onClose={() => {setServerErrorSnackOpen(false);}}
        autoHideDuration={6000}
      >
        <MuiAlert elevation={6} variant="filled" severity="error">
          Something went wrong!
        </MuiAlert>
      </Snackbar>}
    </Page>
  );
}
