import React, { FunctionComponent } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAtomValue } from 'jotai';

import { loginAtom } from '../lib/auth';

import {Layout} from './Layout';

import {ContentAssets} from './content/Assets';
import {ContentBanners} from './content/Banners';
import {ContentBenefitCodes} from './content/BenefitCodes';
import {ContentBenefits} from './content/Benefits';
import {ContentBenefitDetail} from './content/BenefitDetail';
import {ContentEditPage} from './content/EditPage';
import {ContentFaqs} from './content/Faqs';
import {ContentFrontpagers} from './content/Frontpagers';
import {ContentInvestments} from './content/Investments';
import {ContentInvestmentDetail} from './content/InvestmentDetail';
import {ContentNews} from './content/News';
import {ContentNewsDetail} from './content/NewsDetail';
import {ContentRaffles} from './content/Raffles';
import {ContentSecurityQuestions} from './content/SecurityQuestions';
import {ContentStatics} from './content/Statics';
import {Dashboard} from './Dashboard';
import {DividendCalculations} from './dividend/Calculations';
import {DividendCalculationDetail} from './dividend/CalculationDetail';
import {DividendPains} from './dividend/Pains';
import {DividendSettings} from './dividend/Settings';
import {DividendSettingDetail} from './dividend/SettingDetail';
import {EmailEmails} from './email/Emails';
import {EmailPrintDetail} from './email/PrintDetail';
import {EmailPrints} from './email/Prints';
import {EmailTemplates} from './email/Templates';
import {EmailTemplateDetail} from './email/TemplateDetail';
import {EmissionChecklistDetail} from './emission/ChecklistDetail';
import {EmissionChecklist} from './emission/Checklist';
import {EmissionPaymentFiles} from './emission/PaymentFiles';
import {EmissionPaymentDetail} from './emission/PaymentDetail';
import {EmissionPayments} from './emission/Payments';
import {EmissionRepayments} from './emission/Repayments';
import {EmissionSettings} from './emission/Settings';
import {EmissionStats} from './emission/Stats';
import {EmissionWaitingList} from './emission/WaitingList';
import {ExitCustom201} from './exit/Custom201';
import {ExitCustom201Detail} from './exit/Custom201Detail';
import {ExitDeathDetail} from './exit/DeathDetail';
import {ExitDeaths} from './exit/Deaths';
import {ExitDownloadables} from './exit/Downloadables';
import {ExitExitDetail} from './exit/ExitDetail';
import {ExitExits} from './exit/Exits';
import {ExitFodLists} from './exit/FodLists';
import {ExitNotaries} from './exit/Notaries';
import {ExitNotaryDetail} from './exit/NotaryDetail';
import {ExitReasons} from './exit/ExitReasons';
import {ExitLinkList} from './exit/LinkList';
import {ExitLinkDetail} from './exit/LinkDetail';
import {ExitStats} from './ExitStats';
import {Exports} from './Exports';
import {GenericError} from './GenericError';
import {Help} from './Help';
import {Login} from './Login';
import {MeetingAttendeeDetail} from './meeting/AttendeeDetail';
import {MeetingAttendees} from './meeting/Attendees';
import {MeetingEmails} from './meeting/Emails';
import {MeetingSettings} from './meeting/Settings';
import {MeetingSettingDetail} from './meeting/SettingDetail';
import {MeetingVoteDetail} from './meeting/VoteDetail';
import {MeetingVotes} from './meeting/Votes';
import {MeetingVoting} from './meeting/Voting';
import {OfficeOffices} from './office/Offices';
import {OfficeOfficeAccounts} from './office/OfficeAccounts';
import {OfficeOfficeLogs} from './office/OfficeLogs';
import {OfficeStatics} from './office/Statics';
import {OfficeUserChanges} from './office/UserChanges';
import {OfficeUserChangeDetail} from './office/UserChangeDetail';
import {PainSender} from './PainSender';
import {PlatformShares} from './platform/Shares';
import {PlatformSystemSettings} from './platform/SystemSettings';
import {PlatformTags} from './platform/Tags';
import {Settings} from './Settings';
import {StockStats} from './StockStats';
import {Transactions} from './Transactions';
import {TransfersStockTransfers} from './transfers/StockTransfers';
import {TransfersStockTransferDetail} from './transfers/StockTransferDetail';
import {Users} from './Users';
import {UserDetail} from './UserDetail';
import {UserDetailAudits} from './UserDetailAudits';
import {UserDetailEmails} from './UserDetailEmails';
import {UserInvalidAddress} from './UserInvalidAddress';
import {UserDetailNotes} from './UserDetailNotes';

type MainProps = {}

export const Main: FunctionComponent<MainProps> = () => {
  const login = useAtomValue(loginAtom);
  let is_admin:boolean = login?.is_admin || login?.is_helper_admin || false;
  
  return (<Layout version="0.6.5" loginId={login?.id}>
    <Routes>
      <Route path='/' element={<Dashboard/>} />

      <Route path='/deaths' element={is_admin ? <ExitDeaths/> : <GenericError detail="invalid_rights" />} />
      <Route path='/death/:id' element={is_admin ? <ExitDeathDetail/> : <GenericError detail="invalid_rights" />} />
      <Route path='/downloadables' element={is_admin ? <ExitDownloadables/> : <GenericError detail="invalid_rights" />} />
      <Route path='/exits' element={is_admin ? <ExitExits/> : <GenericError detail="invalid_rights" />} />
      <Route path='/exit/:id' element={is_admin ? <ExitExitDetail/> : <GenericError detail="invalid_rights" />} />
      <Route path='/fod_lists' element={is_admin ? <ExitFodLists/> : <GenericError detail="invalid_rights" />} />
      <Route path='/notaries' element={is_admin ? <ExitNotaries/> : <GenericError detail="invalid_rights" />} />
      <Route path='/notary/:id' element={is_admin ? <ExitNotaryDetail/> : <GenericError detail="invalid_rights" />} />
      <Route path='/custom201s' element={is_admin ? <ExitCustom201/> : <GenericError detail="invalid_rights" />} />
      <Route path='/custom201/new' element={is_admin ? <ExitCustom201Detail/> : <GenericError detail="invalid_rights" />} />
      <Route path='/custom201/:id' element={is_admin ? <ExitCustom201Detail/> : <GenericError detail="invalid_rights" />} />

      <Route path='/dividend_calculations' element={is_admin ? <DividendCalculations/> : <GenericError detail="invalid_rights" />} />
      <Route path='/dividend_calculation/:id' element={is_admin ? <DividendCalculationDetail/> : <GenericError detail="invalid_rights" />} />
      <Route path='/dividend_pains' element={is_admin ? <DividendPains/> : <GenericError detail="invalid_rights" />} />
      <Route path='/dividend_settings' element={is_admin ? <DividendSettings/> : <GenericError detail="invalid_rights" />} />
      <Route path='/dividend_setting/new' element={is_admin ? <DividendSettingDetail/> : <GenericError detail="invalid_rights" />} />
      <Route path='/dividend_setting/:id' element={is_admin ? <DividendSettingDetail/> : <GenericError detail="invalid_rights" />} />

      <Route path='/exports' element={is_admin ? <Exports/> : <GenericError detail="invalid_rights" />} />

      <Route path='/assets' element={is_admin ? <ContentAssets/> : <GenericError detail="invalid_rights" />} />

      <Route path='/banners' element={is_admin ? <ContentBanners/> : <GenericError detail="invalid_rights" />} />
      <Route path='/banner/new' element={is_admin ? <ContentEditPage object_type="banner" return_path="/banners" /> : <GenericError detail="invalid_rights" />} />
      <Route path='/banner/:id' element={is_admin ? <ContentEditPage object_type="banner" return_path="/banners" /> : <GenericError detail="invalid_rights" />} />

      <Route path='/benefits' element={is_admin ? <ContentBenefits/> : <GenericError detail="invalid_rights" />} />
      <Route path='/benefit/new' element={is_admin ? <ContentBenefitDetail /> : <GenericError detail="invalid_rights" />} />
      <Route path='/benefit/:id' element={is_admin ? <ContentBenefitDetail /> : <GenericError detail="invalid_rights" />} />
      <Route path='/benefit_codes/:id' element={is_admin ? <ContentBenefitCodes /> : <GenericError detail="invalid_rights" />} />

      <Route path='/email_templates' element={is_admin ? <EmailTemplates/> : <GenericError detail="invalid_rights" />} />
      <Route path='/email_template/new' element={is_admin ? <EmailTemplateDetail/> : <GenericError detail="invalid_rights" />} />
      <Route path='/email_template/:id' element={is_admin ? <EmailTemplateDetail/> : <GenericError detail="invalid_rights" />} />
      <Route path='/emails/:id' element={is_admin ? <EmailEmails/> : <GenericError detail="invalid_rights" />} />

      <Route path='/emissions' element={is_admin ? <EmissionSettings/> : <GenericError detail="invalid_rights" />} />
      <Route path='/emission/checklist' element={is_admin ? <EmissionChecklist /> : <GenericError detail="invalid_rights" />} />
      <Route path='/emission/checklist/:id' element={is_admin ? <EmissionChecklistDetail /> : <GenericError detail="invalid_rights" />} />
      <Route path='/emission/new' element={is_admin ? <ContentEditPage object_type="emission" return_path="/emissions" /> : <GenericError detail="invalid_rights" />} />
      <Route path='/emission/:id' element={is_admin ? <ContentEditPage object_type="emission" return_path="/emissions" /> : <GenericError detail="invalid_rights" />} />
      <Route path='/emission/:id/waiting_list' element={is_admin ? <EmissionWaitingList /> : <GenericError detail="invalid_rights" />} />
      <Route path='/emission/:id/stats' element={is_admin ? <EmissionStats /> : <GenericError detail="invalid_rights" />} />
      <Route path='/emission/payment_files' element={is_admin ? <EmissionPaymentFiles /> : <GenericError detail="invalid_rights" />} />
      <Route path='/emission/payments' element={is_admin ? <EmissionPayments /> : <GenericError detail="invalid_rights" />} />
      <Route path='/emission/payment/:id' element={is_admin ? <EmissionPaymentDetail /> : <GenericError detail="invalid_rights" />} />
      <Route path='/emission/repayments' element={is_admin ? <EmissionRepayments /> : <GenericError detail="invalid_rights" />} />

      <Route path='/exit_reasons' element={is_admin ? <ExitReasons/> : <GenericError detail="invalid_rights" />} />
      <Route path='/exit_reason/list' element={is_admin ? <ExitLinkList /> : <GenericError detail="invalid_rights" />} />
      <Route path='/exit_reason/list/:id' element={is_admin ? <ExitLinkDetail /> : <GenericError detail="invalid_rights" />} />
      <Route path='/exit_reason/new' element={is_admin ? <ContentEditPage object_type="exit_reason" return_path="/exit_reasons" /> : <GenericError detail="invalid_rights" />} />
      <Route path='/exit_reason/:id' element={is_admin ? <ContentEditPage object_type="exit_reason" return_path="/exit_reasons" /> : <GenericError detail="invalid_rights" />} />

      <Route path='/faqs' element={is_admin ? <ContentFaqs/> : <GenericError detail="invalid_rights" />} />
      <Route path='/faq/new' element={is_admin ? <ContentEditPage object_type="faq" return_path="/faqs" /> : <GenericError detail="invalid_rights" />} />
      <Route path='/faq/:id' element={is_admin ? <ContentEditPage object_type="faq" return_path="/faqs" /> : <GenericError detail="invalid_rights" />} />

      <Route path='/frontpagers' element={is_admin ? <ContentFrontpagers/> : <GenericError detail="invalid_rights" />} />
      <Route path='/frontpager/new' element={is_admin ? <ContentEditPage object_type="frontpager" return_path="/frontpagers" /> : <GenericError detail="invalid_rights" />} />
      <Route path='/frontpager/:id' element={is_admin ? <ContentEditPage object_type="frontpager" return_path="/frontpagers" /> : <GenericError detail="invalid_rights" />} />
      
      <Route path='/investments' element={is_admin ? <ContentInvestments/> : <GenericError detail="invalid_rights" />} />
      <Route path='/investment/new' element={is_admin ? <ContentInvestmentDetail /> : <GenericError detail="invalid_rights" />} />
      <Route path='/investment/:id' element={is_admin ? <ContentInvestmentDetail /> : <GenericError detail="invalid_rights" />} />

      <Route path='/meeting_settings' element={is_admin ? <MeetingSettings/> : <GenericError detail="invalid_rights" />} />
      <Route path='/meeting_setting/new' element={is_admin ? <MeetingSettingDetail/> : <GenericError detail="invalid_rights" />} />
      <Route path='/meeting_setting/:id' element={is_admin ? <MeetingSettingDetail/> : <GenericError detail="invalid_rights" />} />
      <Route path='/meeting_emails/:id' element={is_admin ? <MeetingEmails/> : <GenericError detail="invalid_rights" />} />
      <Route path='/meeting_attendees/:id' element={is_admin ? <MeetingAttendees/> : <GenericError detail="invalid_rights" />} />
      <Route path='/meeting_attendee/:id' element={is_admin ? <MeetingAttendeeDetail/> : <GenericError detail="invalid_rights" />} />
      <Route path='/meeting_votes/:id' element={is_admin ? <MeetingVotes/> : <GenericError detail="invalid_rights" />} />
      <Route path='/meeting_vote/:id' element={is_admin ? <MeetingVoteDetail/> : <GenericError detail="invalid_rights" />} />
      <Route path='/meeting_voting/:id' element={is_admin ? <MeetingVoting/> : <GenericError detail="invalid_rights" />} />
      
      <Route path='/news' element={is_admin ? <ContentNews/> : <GenericError detail="invalid_rights" />} />
      <Route path='/news/new' element={is_admin ? <ContentNewsDetail /> : <GenericError detail="invalid_rights" />} />
      <Route path='/news/:id' element={is_admin ? <ContentNewsDetail /> : <GenericError detail="invalid_rights" />} />

      <Route path='/offices' element={is_admin ? <OfficeOffices/> : <GenericError detail="invalid_rights" />} />
      <Route path='/office/:id' element={is_admin ? <ContentEditPage object_type="office" return_path="/offices" /> : <GenericError detail="invalid_rights" />} />
      <Route path='/office_accounts/:id' element={is_admin ? <OfficeOfficeAccounts/> : <GenericError detail="invalid_rights" />} />
      <Route path='/office_logs/:office_id' element={is_admin ? <OfficeOfficeLogs/> : <GenericError detail="invalid_rights" />} />
      <Route path='/office_logs/:office_id/:id' element={is_admin ? <OfficeOfficeLogs/> : <GenericError detail="invalid_rights" />} />

      <Route path='/office_statics' element={is_admin ? <OfficeStatics /> : <GenericError detail="invalid_rights" />} />
      <Route path='/office_static/new' element={is_admin ? <ContentEditPage object_type="office_static" return_path="/office_statics" /> : <GenericError detail="invalid_rights" />} />
      <Route path='/office_static/:id' element={is_admin ? <ContentEditPage object_type="office_static" return_path="/office_statics" /> : <GenericError detail="invalid_rights" />} />

      <Route path='/give_me_pain' element={is_admin ? <PainSender/> : <GenericError detail="invalid_rights" />} />

      <Route path='/prints' element={is_admin ? <EmailPrints /> : <GenericError detail="invalid_rights" />} />
      <Route path='/print/new' element={is_admin ? <EmailPrintDetail /> : <GenericError detail="invalid_rights" />} />

      <Route path='/raffles' element={is_admin ? <ContentRaffles/> : <GenericError detail="invalid_rights" />} />
      <Route path='/raffle/new' element={is_admin ? <ContentEditPage object_type="raffle" return_path="/raffles" /> : <GenericError detail="invalid_rights" />} />
      <Route path='/raffle/:id' element={is_admin ? <ContentEditPage object_type="raffle" return_path="/raffles" /> : <GenericError detail="invalid_rights" />} />
      
      <Route path='/security_questions' element={is_admin ? <ContentSecurityQuestions/> : <GenericError detail="invalid_rights" />} />
      <Route path='/security_question/new' element={is_admin ? <ContentEditPage object_type="security_question" return_path="/security_questions" /> : <GenericError detail="invalid_rights" />} />
      <Route path='/security_question/:id' element={is_admin ? <ContentEditPage object_type="security_question" return_path="/security_questions" /> : <GenericError detail="invalid_rights" />} />

      <Route path='/exit_stats' element={is_admin ? <ExitStats/> : <GenericError detail="invalid_rights" />} />
      <Route path='/stock_stats' element={is_admin ? <StockStats/> : <GenericError detail="invalid_rights" />} />

      <Route path='/system_settings' element={is_admin ? <PlatformSystemSettings/> : <GenericError detail="invalid_rights" />} />

      <Route path='/shares' element={is_admin ? <PlatformShares/> : <GenericError detail="invalid_rights" />} />
      <Route path='/share/new' element={is_admin ? <ContentEditPage object_type="share" return_path="/shares" /> : <GenericError detail="invalid_rights" />} />
      <Route path='/share/:id' element={is_admin ? <ContentEditPage object_type="share" return_path="/shares" /> : <GenericError detail="invalid_rights" />} />

      <Route path='/tags' element={is_admin ? <PlatformTags/> : <GenericError detail="invalid_rights" />} />
      <Route path='/tag/new' element={is_admin ? <ContentEditPage object_type="tag" return_path="/tags" /> : <GenericError detail="invalid_rights" />} />
      <Route path='/tag/:id' element={is_admin ? <ContentEditPage object_type="tag" return_path="/tags" /> : <GenericError detail="invalid_rights" />} />

      <Route path='/statics' element={is_admin ? <ContentStatics/> : <GenericError detail="invalid_rights" />} />
      <Route path='/static/new' element={is_admin ? <ContentEditPage object_type="static" return_path="/statics" /> : <GenericError detail="invalid_rights" />} />
      <Route path='/static/:id' element={is_admin ? <ContentEditPage object_type="static" return_path="/statics" /> : <GenericError detail="invalid_rights" />} />

      <Route path='/settings' element={is_admin ? <Settings/> : <GenericError detail="invalid_rights" />} />
      <Route path='/login' element={<Login/>} />
      <Route path='/help/:slug' element={is_admin ? <Help/> : <GenericError detail="invalid_rights" />} />

      <Route path='/transactions' element={is_admin ? <Transactions/> : <GenericError detail="invalid_rights" />} />

      <Route path='/stock_transfers' element={is_admin ? <TransfersStockTransfers/> : <GenericError detail="invalid_rights" />} />
      <Route path='/stock_transfer/:id' element={is_admin ? <TransfersStockTransferDetail/> : <GenericError detail="invalid_rights" />} />

      <Route path='/users' element={is_admin ? <Users/> : <GenericError detail="invalid_rights" />} />
      <Route path='/user/:id' element={is_admin ? <UserDetail/> : <GenericError detail="invalid_rights" />} />
      <Route path='/user/:id/emails' element={is_admin ? <UserDetailEmails/> : <GenericError detail="invalid_rights" />} />
      <Route path='/user/:id/invalid_address' element={is_admin ? <UserInvalidAddress/> : <GenericError detail="invalid_rights" />} />
      <Route path='/user/:id/notes' element={is_admin ? <UserDetailNotes/> : <GenericError detail="invalid_rights" />} />
      <Route path='/user/:id/audits' element={is_admin ? <UserDetailAudits/> : <GenericError detail="invalid_rights" />} />

      <Route path='/user_changes' element={is_admin ? <OfficeUserChanges/> : <GenericError detail="invalid_rights" />} />
      <Route path='/user_change/:id' element={is_admin ? <OfficeUserChangeDetail/> : <GenericError detail="invalid_rights" />} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  </Layout>);
}

