import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

// import {
//   IconButton
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { DataGrid } from '../../components/interface/DataGrid';
import { Page } from '../../components/interface/Page';
import { StyledButton } from '../../components/interface/StyledButton';
import { StyledIconButton } from '../../components/interface/StyledIconButton';

import { BenefitTag } from '../../models/BenefitTag';
import { loginAtom } from '../../lib/auth';

type PlatformTagsProps = {};

export const PlatformTags: FunctionComponent<PlatformTagsProps> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();

  const login = useAtomValue(loginAtom);
  // const [login, setLogin] = useAtom(loginAtom);
  const [tags, setTags] = useState<BenefitTag[]>([]);
  const [tagsCount, setTagsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");

  useEffect(() => {
    onSearch('');
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order]);

  const onRemove = (id: number) => {
    setRemoveLoader(true);
    fetch_one<BenefitTag>('/nl/v3/objects/remove', {
      object: 'v2_benefit_tag',
      ob: {
        id: id
      }
    }, (obs, complete_data) => {
      onSearch('');
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onSearch = (s: string, filters?:{enabled:string[]}) => {
    setLoader(true);
    fetch_all<BenefitTag>('/nl/v3/objects/fetch_all', {
      object: 'v2_benefit_tag',
      fields: ['id', 'name', 'lang_nl', 'lang_fr'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s
      }
    }, (obs, complete_data) => {
      setTags(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setTagsCount(count);

      setLoader(false);
    }, (z) => {

    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'name_nl', headerName: 'Name', width: 400, sortable: false },
    { field: 'lang_nl', headerName: 'NL', width: 40, sortable: false, renderCell: (params) => <>
      {params?.value < 1 && <StarOutlineIcon sx={{color: 'red'}} />}
      {params?.value > 99 && <StarIcon sx={{color: theme.palette.tertiary?.main}} />}
      {params?.value <= 99 && params?.value >= 1 && <StarHalfIcon sx={{color: 'orange'}} />}
    </> },
    { field: 'lang_fr', headerName: 'FR', width: 40, sortable: false, renderCell: (params) => <>
      {params?.value < 1 && <StarOutlineIcon sx={{color: 'red'}} />}
      {params?.value > 99 && <StarIcon sx={{color: theme.palette.tertiary?.main}} />}
      {params?.value <= 99 && params?.value >= 1 && <StarHalfIcon sx={{color: 'orange'}} />}
    </> },
  ];

  return (
    <Page sx={{}} title="Tags" helper="tags" actions={<>
      <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={'/tag/new'}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}>
      {/* <SearchBox
        simple={true}
        onSearch={(s, x) => {
          setPage(0);
          onSearch(s, x);
        }}
        filter_enabled
        memo="tags"
      /> */}
      <DataGrid
        action_count={2}
        actions={(params) => {
          return (<>
            <StyledIconButton color="primary" path={`/tag/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
            {!removeLoader && <StyledIconButton destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={tagsCount}
        objects={(tags || []).map(u => ({
          ...u,
          name_nl: u.name?.nl || ''
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />
    </Page>
  );
}
