import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all } from '../../lib/server_helper';

// import {
//   Avatar,
//   Box
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { DataGrid } from '../../components/interface/DataGrid';
import { Page } from '../../components/interface/Page';
import { StyledIconButton } from '../../components/interface/StyledIconButton';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import { Downloadable } from '../../models/Downloadable';
import { loginAtom } from '../../lib/auth';

const { DateTime } = require("luxon");

type ExitDownloadablesProps = {};

export const ExitDownloadables: FunctionComponent<ExitDownloadablesProps> = () => {
  // const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);
  const [downloadables, setDownloadables] = useState<Downloadable[]>([]);
  const [downloadablesCount, setDownloadablesCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("updated_at DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch();
  }, [page, order]);

  const onSearch = () => {
    setLoader(true);
    fetch_all<Downloadable>('/nl/v3/objects/fetch_all', {
      object: 'downloadable',
      fields: ['id', 'created_at', 'get_item', 'name'],
      per_page: 20,
      page: page,
      order: order
    }, (obs, complete_data) => {
      setDownloadables(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setDownloadablesCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150, sortable: false },
    { field: 'name', headerName: 'Name', width: 350, sortable: false },
    { field: 'created_at', headerName: 'Created', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") }
  ];

  return (
    <Page sx={{}} title="Downloadables" helper="downloadables" old_url="https://old.argenco.be/fod_lists">
      <DataGrid
        action_count={1}
        actions={(params) => {
          return (<>
            {!!params.row.get_item && <StyledIconButton color="primary" onClick={() => {
              window.open(params.row.get_item, "_BLANK");
            }}>
              <FileDownloadIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={downloadablesCount}
        objects={(downloadables || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
