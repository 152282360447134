import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

// import {
//   IconButton
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { DataGrid } from '../../components/interface/DataGrid';
import { Page } from '../../components/interface/Page';
import { SearchBox } from '../../components/interface/SearchBox';
import { StyledButton } from '../../components/interface/StyledButton';
import { StyledIconButton } from '../../components/interface/StyledIconButton';

import { Banner } from '../../models/Banner';
import { loginAtom } from '../../lib/auth';

type ContentBannersProps = {};

export const ContentBanners: FunctionComponent<ContentBannersProps> = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);
  const [banners, setBanners] = useState<Banner[]>([]);
  const [bannersCount, setBannersCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");

  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onRemove = (id: number) => {
    setRemoveLoader(true);
    fetch_one<Banner>('/nl/v3/objects/remove', {
      object: 'banner',
      ob: {
        id: id
      }
    }, (obs, complete_data) => {
      onSearch('');
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onSearch = (s: string, filters?:{enabled:string[]}) => {
    setLoader(true);
    fetch_all<Banner>('/nl/v3/objects/fetch_all', {
      object: 'banner',
      fields: ['id', 'body', 'lang_nl', 'lang_fr', 'is_stock', 'is_who'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          enabled: (filters?.enabled || ['1'])
        }
      }
    }, (obs, complete_data) => {
      setBanners(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setBannersCount(count);

      setLoader(false);
    }, (z) => {

    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'body_nl', headerName: 'Body', width: 400, sortable: false },
    { field: 'is_stock', headerName: 'Stock', width: 40, sortable: false, renderCell: params => <>
      {!!params.row.is_stock && <CheckIcon sx={{color: theme.palette.tertiary?.main}} />}
      {!params.row.is_stock && <CloseIcon sx={{color: theme.palette.secondary.main}} />}
    </> },
    { field: 'is_who', headerName: 'Who', width: 40, sortable: false, renderCell: params => <>
      {!!params.row.is_who && <CheckIcon sx={{color: theme.palette.tertiary?.main}} />}
      {!params.row.is_who && <CloseIcon sx={{color: theme.palette.secondary.main}} />}
    </> },
    { field: 'lang_nl', headerName: 'NL', width: 40, sortable: false, renderCell: (params) => <>
      {params?.value < 1 && <StarOutlineIcon sx={{color: 'red'}} />}
      {params?.value > 99 && <StarIcon sx={{color: theme.palette.tertiary?.main}} />}
      {params?.value <= 99 && params?.value >= 1 && <StarHalfIcon sx={{color: 'orange'}} />}
    </> },
    { field: 'lang_fr', headerName: 'FR', width: 40, sortable: false, renderCell: (params) => <>
      {params?.value < 1 && <StarOutlineIcon sx={{color: 'red'}} />}
      {params?.value > 99 && <StarIcon sx={{color: theme.palette.tertiary?.main}} />}
      {params?.value <= 99 && params?.value >= 1 && <StarHalfIcon sx={{color: 'orange'}} />}
    </> },
  ];

  return (
    <Page sx={{}} title="Banners" helper="banners" old_url="https://old.argenco.be/banners" actions={<>
      <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={'/banner/new'}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}>
      {/* <SearchBox
        simple={true}
        onSearch={onSearch}
        filter_enabled
        memo="banners"
      /> */}
      <DataGrid
        action_count={2}
        actions={(params) => {
          return (<>
            <StyledIconButton color="primary" path={`/banner/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
            {!removeLoader && <StyledIconButton destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.is_stock || params.row.is_who}`}
        objectCount={bannersCount}
        objects={(banners || []).map(u => ({
          ...u,
          body_nl: u.body.nl.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 99)
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />
    </Page>
  );
}
