import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

import {
  CircularProgress,
  Grid
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton,
  StyledTextField,
  Typography
} from '../../components/interface';

import { Benefit } from '../../models/Benefit';
import { BenefitCode } from '../../models/BenefitCode';
import { loginAtom } from '../../lib/auth';

const { DateTime } = require("luxon");

type ContentBenefitCodesProps = {};

export const ContentBenefitCodes: FunctionComponent<ContentBenefitCodesProps> = () => {
  // const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);

  const params = useParams();
  const benefitId: string | undefined = params.id;

  const [benefit, setBenefit] = useState<Benefit>();
  const [templates, setTemplates] = useState<BenefitCode[]>([]);
  const [templatesCount, setTemplatesCount] = useState<number>(0);
  const [codes, setCodes] = useState<string>("");
  const [codesHidden, setCodesHidden] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch('');
  }, [page, order]);

  useEffect(() => {
    fetchBenefit(parseInt(benefitId || '', 10));
  }, [benefitId]);

  const onImport = () => {
    setRemoveLoader(true);
    fetch_one<BenefitCode>('/nl/v3/objects/custom_action', {
      object: 'benefit_code',
      class_action: 'custom_api_import_list',
      base_id: benefitId,
      code_list: codes
    }, (obs, complete_data) => {
      setRemoveLoader(false);
      setCodes('');
      setCodesHidden(true);
      onSearch('');
    }, (z) => {

    }, login);
  };

  const onRemove = (id: number) => {
    setRemoveLoader(true);
    fetch_one<BenefitCode>('/nl/v3/objects/remove', {
      object: 'benefit_code',
      ob: {
        id: id
      }
    }, (obs, complete_data) => {
      onSearch('');
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const fetchBenefit = (id: number) => {
    fetch_one<Benefit>('/nl/v3/objects/fetch_all', {
      object: 'v2_benefit',
      fields: ['id', 'name'],
      id: id
    }, (obs, complete_data) => {
      setBenefit(obs)
    }, (z) => {

    }, login);
  };

  const onSearch = (s: string) => {
    // console.log(filters);
    setLoader(true);
    fetch_all<BenefitCode>('/nl/v3/objects/fetch_all', {
      object: 'benefit_code',
      fields: ['id', 'created_at', 'updated_at', 'code', 'user|first_name/last_name/vennoot_number'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          v2_benefit_id: benefitId,
          catch_all: s
        }
      }
    }, (obs, complete_data) => {
      setTemplates(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setTemplatesCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, sortable: true },
    { field: 'code', headerName: 'Code', width: 200, sortable: true },
    { field: 'user_data', headerName: 'User', width: 250, renderCell: params => <>
      {!params.row.user_last_name && <Typography> </Typography>}
      {!!params.row.user_last_name && <Typography sx={{fontSize: '0.9rem'}}>{params.row.user_first_name} {params.row.user_last_name} {`<${params.row.user_vennoot_number}>`}</Typography>}
    </> },
    { field: 'created_at', headerName: 'Created', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'updated_at', headerName: 'Updated', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
  ];

  return (
    <Page
      sx={{}}
      title={`BenefitCodes for ${benefit?.name}`}
      helper="benefit_codes"
      actions={<>
        <StyledButton
          label="Import new codes"
          id='import_new'
          sx={{marginRight: 3}}
          contained
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            setCodesHidden(!codesHidden);
          }}
        />
      </>}
      startEndCrumb={!!benefitId ? <StyledButton
        label="Back to benefit overview"
        id='back'
        text
        startIcon={<KeyboardBackspaceIcon />}
        path={`/benefits`}
        // sx={{width: '100%'}}
      /> : <></>}
    >
      {!codesHidden && <Grid container xs={12}>
        <Grid item xs={12}>
          <StyledTextField
            label="Codes (no header)"
            value={codes}
            id='codes'
            onChange={(v) => {
              setCodes(v);
            }}
            fullWidth
            multiline
            sx={{marginTop: 1}}
          />
        </Grid>
        <Grid item xs={12}>
          {!removeLoader && <StyledButton
            label="Import list"
            id='import_list'
            contained
            onClick={() => {
              onImport();
            }}
          />}
        </Grid>
      </Grid>}

      {/* <SearchBox
        simple={true}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
        memo="benefit_codes"
      /> */}
      <DataGrid
        action_count={1}
        actions={(params) => {
          return (<>
            {!removeLoader && <StyledIconButton destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={templatesCount}
        objects={(templates || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
