import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  Grid
} from '@mui/material';

type AuditLayoutProps = {
  children: React.ReactNode;
};

export const AuditLayout: FunctionComponent<AuditLayoutProps> = ({children}) => {
  const theme = useTheme();

  return (<Grid item xs={12} sx={{paddingTop: '0px !important'}}>
    <Box
      sx={{
        padding: 1,
        paddingLeft: 8,
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <Box sx={{
        width: '2px',
        backgroundColor: theme.palette.primary.main,
        height: '100%',
        position: 'absolute',
        top: 0,
        left: '8px',
        zIndex: 998
      }} />
      <Box sx={{
        width: '9px',
        border: '2px solid transparent',
        borderColor: theme.palette.primary.main,
        backgroundColor: 'white',
        height: '9px',
        position: 'absolute',
        top: 'calc(50% - 5px)',
        left: '3px',
        borderRadius: '8px',
        zIndex: 999
      }} />
      <Box sx={{
        width: '64px',
        backgroundColor: theme.palette.primary.main,
        height: '1px',
        position: 'absolute',
        top: '50%',
        left: 0,
        zIndex: 997
      }} />
      <Box
        sx={{
          padding: 1,
          border: '1px solid transparent',
          borderRadius: '10px 0px 0px 10px',
          borderLeftColor: theme.palette.primary.main
        }}
      >
        {children}
      </Box>
    </Box>
  </Grid>);
}