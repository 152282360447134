import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useLocation } from 'react-router-dom';

import { fetch_all } from '../lib/server_helper';

// import {
//   Box
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// import VisibilityIcon from '@mui/icons-material/Visibility';

import { DataGrid } from '../components/interface/DataGrid';
import { Page } from '../components/interface/Page';
import { SearchBox } from '../components/interface/SearchBox';
import { StyledButton } from '../components/interface/StyledButton';
import { Typography } from '../components/interface/Typography';

import { Transaction } from '../models/Transaction';
import { loginAtom } from '../lib/auth';

const { DateTime } = require("luxon");

type TransactionsProps = {
  route?: any
};

export const Transactions: FunctionComponent<TransactionsProps> = (route) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [login, setLogin] = useAtom(loginAtom);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [transactionsCount, setTransactionsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("transaction_date DESC, id DESC");

  const [searchCatchAll, setSearchCatchAll] = useState('');
  const [searchName, setSearchName] = useState('');
  const [searchVennootNumber, setSearchVennootNumber] = useState('');

  const [userId, setUserId] = useState<number>();
  const [inited, setInited] = useState<boolean>(false);

  if (!!state?.user_id && !inited) {
    setTimeout(() => {
      setUserId(state.user_id);
      setInited(true);
    }, 500);
  }

  useEffect(() => {
    onSearch('');
  }, [searchName, searchVennootNumber, searchCatchAll]);
  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onSearch = (s: string) => {
    setLoader(true);
    fetch_all<Transaction>('/nl/v3/objects/fetch_all', {
      object: 'transaction',
      fields: ['id', 'user_id', 'user|vennoot_number/first_name/last_name', 'transaction_date', 'type', 'reason', 'action', 'stock_saldo', 'stock_saldo_value', 'linked_transfer|id/full_name/vennoot_number'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          name: searchName,
          user_id: state?.user_id,
          vennoot_number: searchVennootNumber,
          catch_all: searchCatchAll
        }
      }
    }, (obs, complete_data) => {
      setTransactions(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setTransactionsCount(count);

      setLoader(false);
    }, (z) => {

    }, login)
  };

  const columns: GridColDef[] = [
    { field: 'vennoot_number', headerName: 'VenNR', width: 150, sortable: false },
    { field: 'name', headerName: 'Name', width: 250, sortable: false },
    { field: 'action', headerName: 'Action', width: 100, sortable: false, valueFormatter: params => `${params?.value?.type} ${params?.value?.tag} ${params?.value?.amount}` },
    { field: 'stock_saldo', headerName: 'Saldo', width: 150, renderCell: params => <>
      <Typography sx={{fontSize: '0.9rem'}}>
        {params.row.stock_saldo} ({`${params.row.stock_saldo_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR`})
      </Typography>
    </> },
    { field: 'transaction_date', headerName: 'Date', width: 130, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'note', headerName: 'Note', width: 400, sortable: false, renderCell: params => <>
      {(params.row.type === "Transaction::TransferBought" || params.row.type === "Transaction::TransferSold") && <>
        <Typography sx={{fontSize: '0.9rem', cursor: 'pointer'}} onClick={() => {navigate(`/user/${params.row.linked_transfer_id}`);}}>
          Overdracht {params.row.type === "Transaction::TransferBought" ? "IN - van" : "UIT - naar"} {params.row.linked_transfer_full_name} ({params.row.linked_transfer_vennoot_number})
        </Typography>
      </>}
      {!(params.row.type === "Transaction::TransferBought" || params.row.type === "Transaction::TransferSold") && <>
        <Typography sx={{fontSize: '0.9rem', cursor: 'pointer'}}>
          {params.row.reason}
        </Typography>
      </>}
    </> }
  ];

  return (
    <Page
      sx={{}}
      title={`Transactions${!!userId ? ` (${transactions[0].user_first_name} ${transactions[0].user_last_name})` : ''}`}
      helper="transactions"
      old_url="https://old.argenco.be/transactions"
      startEndCrumb={!!userId ? <StyledButton
        label="Back to user detail"
        id='back'
        text
        startIcon={<KeyboardBackspaceIcon />}
        path={`/user/${userId}`}
        // sx={{width: '100%'}}
      /> : <></>}
    >
      {!userId && <SearchBox
        simple={false}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
        transactionSearch={(advanced: {name:string, vennoot_number:string, catch_all:string}) => {
          setSearchName(advanced.name);
          setSearchVennootNumber(advanced.vennoot_number);
          setSearchCatchAll(advanced.catch_all);
          setPage(0);
        }}
        memo="transactions"
      />}

      <DataGrid
        // actions={(params) => {
        //   return (<>
        //     {/* <StyledIconButton color="primary" path={`/transaction/${params.row.id}`}>
        //       <VisibilityIcon />
        //     </StyledIconButton> */}
        //   </>);
        // }}
        objectCount={transactionsCount}
        // getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objects={(transactions || []).map(u => ({
          ...u,
          name: [u.user_first_name, u.user_last_name].join(" "),
          vennoot_number: u.user_vennoot_number
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          // if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />
    </Page>
  );
}
