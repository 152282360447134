import { atom } from "jotai";

export const searchAtom = atom<{
  'users': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'transactions': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'faqs': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'security_questions': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'statics': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'custom201s': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'deaths': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'exits': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'shares': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'dividend_calculations': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'banners': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'dividend_pains': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'user_changes': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'email_templates': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'benefits': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'news': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'stock_transfers': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'offices': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'office_accounts': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'office_logs': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'frontpagers': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  },
  'office_statics': {
    catch_all: string;
    birth_date: string;
    email: string;
    name: string;
    street: string;
    number: string;
    zip: string;
    vennoot_number: string;
    enabled: string[];
    checked: string[];
    verified: string[];
    cancelled: string[];
    identity: string[];
    item: string[];
    book_year: number;
    meeting_status: number[];
    transfer_status: string[];
  }
}>({
  'users': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: []
  },
  'transactions': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: []
  },
  'faqs': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: []
  },
  'security_questions': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: []
  },
  'statics': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: []
  },
  'custom201s': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: []
  },
  'deaths': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: []
  },
  'exits': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: []
  },
  'shares': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: []
  },
  'dividend_calculations': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: []
  },
  'banners': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: []
  },
  'dividend_pains': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: []
  },
  'user_changes': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: []
  },
  'email_templates': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: []
  },
  'benefits': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: []
  },
  'news': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: []
  },
  'stock_transfers': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: ['', 'open', 'wait']
  },
  'offices': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: ['', 'open', 'wait']
  },
  'office_accounts': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: ['', 'open', 'wait']
  },
  'office_logs': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: ['', 'open', 'wait']
  },
  'frontpagers': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: ['', 'open', 'wait']
  },
  'office_statics': {
    catch_all: '',
    birth_date: '',
    email: '',
    name: '',
    street: '',
    number: '',
    zip: '',
    vennoot_number: '',
    enabled: ['1'],
    checked: ['0'],
    verified: ['0'],
    cancelled: ['0'],
    identity: ['0', '1', '2', '3'],
    item: ['0', '1', '2', '3'],
    book_year: 0,
    meeting_status: [0, 1, 2, 3],
    transfer_status: ['', 'open', 'wait']
  }
});