import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom, useAtomValue } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { fetch_one } from '../lib/server_helper';

import {
  Avatar,
  Badge,
  CircularProgress
} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmailIcon from '@mui/icons-material/Email';
import EuroIcon from '@mui/icons-material/Euro';
import IconTombstone from "../images/tombstone.svg";
import RequestPageIcon from '@mui/icons-material/RequestPage';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

import { Death } from '../models/Death';
import { FoQueue } from '../models/FoQueue';
import { loginAtom } from '../lib/auth';
import refresh_queues, { queueAtom } from '../lib/queues';

type SidebarQueuesProps = {};

export const SidebarQueues: FunctionComponent<SidebarQueuesProps> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const login = useAtomValue(loginAtom);

  const [myQueues, setMyQueues] = useAtom(queueAtom);

  const [queues, setQueues] = useState<FoQueue[]>([]);
  const [deathQueues, setDeathQueues] = useState<FoQueue[]>([]);
  const [deathDoneQueues, setDeathDoneQueues] = useState<FoQueue[]>([]);
  const [divcalcQueues, setDivcalcQueues] = useState<FoQueue[]>([]);
  const [painQueues, setPainQueues] = useState<FoQueue[]>([]);
  const [meetingEmailQueues, setMeetingEmailQueues] = useState<FoQueue[]>([]);
  const [v2EmailQueues, setV2EmailQueues] = useState<FoQueue[]>([]);
  const [loader, setLoader] = useState<boolean>(!!login);
  const [searchInterval, setSearchInterval] = useState(setInterval(() => {}, 30 * 1000));

  useEffect(() => {
    clearTimeout(searchInterval);
    if (!!login) {
      onSearch();
      setSearchInterval(setInterval(onSearch, 30 * 1000));
    }
  }, [login]);

  const onSearch = () => {
    setLoader(true);
    if (!!login) {
      refresh_queues(login, (res) => {
        if (!!res) {
          setMyQueues(res);
          setQueues(res);
          setDeathQueues(res.filter(q => (q.type === "FoQueue::Death")));
          setDeathDoneQueues(res.filter(q => (q.type === "FoQueue::DeathDone")));
          setDivcalcQueues(res.filter(q => (q.type === "FoQueue::DivCalc")));
          setMeetingEmailQueues(res.filter(q => (q.type === "FoQueue::MeetingEmail")));
          setV2EmailQueues(res.filter(q => (q.type === "FoQueue::V2Email")));
          setPainQueues(res.filter(q => (q.type === "FoQueue::Pain")));
        }
        setLoader(false);
      });
    }
  };

  const fetchDownloadable = (id: number) => {
    if (!!login) {
      fetch_one<Death>('/nl/v3/objects/custom_action', {
        object: 'downloadable',
        class_action: 'custom_api_handle_all',
        handler_id: login.id,
        base_id: id
      }, (obs, complete_data) => {
        refresh_queues(login, (res) => {
          if (!!res) setMyQueues(res);
        });

        if (!!obs && !!obs?.custom_result && !!obs.custom_result?.downloadable) window.open(obs.custom_result?.downloadable, "_BLANK");
      }, (z) => {

      }, login);
    }
  };

  return (<>
    {!!loader && <CircularProgress sx={{color: theme.palette.tertiary?.main}} />}

    {!loader && v2EmailQueues.length > 0 && v2EmailQueues.map(q => (<>
      <Badge badgeContent={(q.extra_data?.emails || 0)} color="secondary" max={99999} sx={{
        marginLeft: '8px',
        '& .MuiBadge-badge': {
          right: 8,
          top: 8
        }
      }}>
        <Avatar sx={{
          backgroundColor: 'white',
          height: 40,
          width: 40,
          "& img": {
            width: 32,
            height: 32
          },
          border: '2px solid transparent',
          borderColor: theme.palette.tertiary?.main
        }}><ScheduleSendIcon sx={{
          color: theme.palette.tertiary?.main
        }} /></Avatar>
      </Badge>
    </>))}

    {!loader && meetingEmailQueues.length > 0 && meetingEmailQueues.map(q => (<>
      <Badge badgeContent={(q.extra_data?.emails || 0)} color="secondary" max={99999} sx={{
        marginLeft: '8px',
        '& .MuiBadge-badge': {
          right: 8,
          top: 8
        }
      }}>
        <Avatar sx={{
          backgroundColor: 'white',
          height: 40,
          width: 40,
          "& img": {
            width: 32,
            height: 32
          },
          border: '2px solid transparent',
          borderColor: theme.palette.tertiary?.main
        }}><EmailIcon sx={{
          color: theme.palette.tertiary?.main
        }} /></Avatar>
      </Badge>
    </>))}

    {!loader && painQueues.length > 0 && painQueues.map(q => (<>
      <Badge badgeContent={(q.extra_data?.total || 0) - (q.extra_data?.done || 0)} color="secondary" max={99999} sx={{
        marginLeft: '8px',
        '& .MuiBadge-badge': {
          right: 8,
          top: 8
        }
      }}>
        <Avatar sx={{
          backgroundColor: 'white',
          height: 40,
          width: 40,
          "& img": {
            width: 32,
            height: 32
          },
          border: '2px solid transparent',
          borderColor: theme.palette.tertiary?.main
        }}><RequestPageIcon sx={{
          color: theme.palette.tertiary?.main
        }} /></Avatar>
      </Badge>
    </>))}

    {!loader && divcalcQueues.length > 0 && divcalcQueues.map(q => (<>
      <Badge badgeContent={(q.extra_data?.users || 0)} color="secondary" max={99999} sx={{
        marginLeft: '8px',
        '& .MuiBadge-badge': {
          right: 8,
          top: 8
        }
      }}>
        <Avatar sx={{
          backgroundColor: 'white',
          height: 40,
          width: 40,
          "& img": {
            width: 32,
            height: 32
          },
          border: '2px solid transparent',
          borderColor: theme.palette.tertiary?.main
        }}><EuroIcon sx={{
          color: theme.palette.tertiary?.main
        }} /></Avatar>
      </Badge>
    </>))}

    {!loader && deathQueues.length > 0 && <Badge badgeContent={deathQueues.length} color="secondary" max={99999} sx={{
      marginLeft: '8px',
      '& .MuiBadge-badge': {
        right: 8,
        top: 8
      },
      cursor: 'pointer'
    }} onClick={() => {
      navigate("/deaths");
    }}>
      <Avatar src={IconTombstone} sx={{
        backgroundColor: 'white',
        height: 40,
        width: 40,
        "& img": {
          width: 32,
          height: 32
        },
        border: '2px solid transparent',
        borderColor: theme.palette.tertiary?.main
      }} />
    </Badge>}

    {!loader && deathDoneQueues.length > 0 && <Badge badgeContent={deathDoneQueues.length} color="secondary" max={99999} sx={{
      marginLeft: '8px',
      '& .MuiBadge-badge': {
        right: 8,
        top: 8
      },
      cursor: 'pointer',
      position: 'relative'
    }} onClick={() => {
      fetchDownloadable(deathDoneQueues[0].id);
    }}>
      <Avatar src={IconTombstone} sx={{
        backgroundColor: 'white',
        height: 40,
        width: 40,
        "& img": {
          width: 32,
          height: 32
        },
        border: '2px solid transparent',
        borderColor: theme.palette.tertiary?.main
      }} />
      <CheckCircleIcon sx={{
        color: theme.palette.tertiary?.main,
        position: 'absolute',
        bottom: '25%',
        right: '22%',
        scale: '1.6'
      }} />
    </Badge>}
  </>);
}
