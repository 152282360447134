import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box
} from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';
import PersonIcon from '@mui/icons-material/Person';

import { UserNote } from '../../models/UserNote';

import { Typography } from '../interface/Typography';

const { DateTime } = require("luxon");

type TimelineUserProps = {
  note: UserNote;
};

export const TimelineUser: FunctionComponent<TimelineUserProps> = ({note}) => {
  const theme = useTheme();

  return (<>
    <Box
      sx={{
        display: 'inline-block',
        marginRight: 1,
        paddingRight: 1,
        borderRight: '1px solid lightgrey'
      }}
    >
      <Typography sx={{
        color: 'grey',
        fontSize: 11
      }}><PersonIcon sx={{scale: '0.7', marginBottom: '-7px'}} /> {DateTime.fromISO(note.created_at).toFormat("DD TT")}</Typography>
    </Box>
    {!!note.get_identity_front && <Box
      sx={{
        display: 'inline-block',
        marginRight: 1,
        paddingRight: 1,
        borderRight: '1px solid lightgrey'
      }}
    >
      <Typography sx={{
        fontSize: 14
      }}><DownloadIcon
        sx={{scale: '0.7', marginBottom: '-7px', cursor: 'pointer'}}
        onClick={(e) => {e.preventDefault(); window.open(note.get_identity_front, "_BLANK");}}
      /></Typography>
    </Box>}
    <Box
      sx={{
        display: 'inline-block'
      }}
    >
      <Typography sx={{
        fontSize: 14
      }}>Paspoort (voorkant) (profiel)</Typography>
    </Box>
  </>);
}