import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

// import {
//   Grid
// } from '@mui/material';

import { UserEmail } from '../../models/UserEmail';
import { UserNote } from '../../models/UserNote';

import { TimelineBorderel } from './Borderel';
import { TimelineBorderelBlank } from './BorderelBlank';
import { TimelineDivCalc } from './DivCalc';
import { TimelineDivCalcFiscal } from './DivCalcFiscal';
import { TimelineEmail } from './Email';
import { TimelineLayout } from './Layout';
import { TimelineNote } from './Note';
import { TimelineRegister } from './Register';
import { TimelineUitschrijving } from './Uitschrijving';
import { TimelineUitschrijvingBlank } from './UitschrijvingBlank';
import { TimelineUitschrijving201 } from './Uitschrijving201';
import { TimelineUser } from './User';
import { TimelineUserCreated } from './UserCreated';

type TimelineBaseProps = {
  note?: UserNote;
  email?: UserEmail;
  creation?: string;
};

export const TimelineBase: FunctionComponent<TimelineBaseProps> = ({note, email, creation}) => {
  if (!!creation) {
    return (<TimelineLayout><TimelineUserCreated creation={creation} /></TimelineLayout>);
  } else {
    if (!!email) {
      let handler_name = `${email.user_first_name} ${email.user_last_name}`;
      if (!email.emission_key_id && !email.emission_doc_id && !email.stock_transfer_id) handler_name = "System";

      return (<TimelineLayout>
        <TimelineEmail handler_name={handler_name} email={email} />
      </TimelineLayout>);
    } else {
      if (!!note) {
        let singular = ['Note', 'EmissionDoc::VennootRegister', 'StockTransfer', 'User', 'Email'];
        let multiple = ['EmissionDoc::ToBeVerified', 'EmissionDoc::Uitschrijving', 'DividendCalculation'];
        let type = note.type || note.class_name;

        if (singular.indexOf(type) > -1) {
          return (<TimelineLayout>
            {type === "Note" && <TimelineNote note={note} />}
            {type === "EmissionDoc::VennootRegister" && <TimelineRegister note={note} />}
            {/* {type === "StockTransfer" && <TimelineNote note={note} />} */}
            {type === "User" && <TimelineUser note={note} />}
            {/* {type === "Email" && <TimelineNote note={note} />} */}
          </TimelineLayout>);
        } else {
          return (<>
            {type === "EmissionDoc::ToBeVerified" && <>
              {!!note.get_item && <TimelineLayout><TimelineBorderel note={note} /></TimelineLayout>}
              <TimelineLayout><TimelineBorderelBlank note={note} /></TimelineLayout>
            </>}
            {type === "EmissionDoc::Uitschrijving" && <>
              {!!note.get_identity_front && <TimelineLayout><TimelineUitschrijvingBlank note={note} /></TimelineLayout>}
              <TimelineLayout><TimelineUitschrijving note={note} /></TimelineLayout>
              {!!note.get_lijst201 && <TimelineLayout><TimelineUitschrijving201 note={note} /></TimelineLayout>}
            </>}
            {type === "DividendCalculation" && note.enabled && <>
            <TimelineLayout><TimelineDivCalc note={note} /></TimelineLayout>
              {!!note.get_fiscal_item && <TimelineLayout><TimelineDivCalcFiscal note={note} /></TimelineLayout>}
            </>}
          </>);
        }
      } else {
        return (<></>);
      }
    }
  }
}