import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one } from '../../lib/server_helper';

import {
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  Page,
  StyledButton,
  StyledDateField,
  StyledSwitch,
  StyledTextField,
  Typography
} from '../../components/interface';
import { UserDetailBase } from '../../components/user_detail/Base';

import { Dividend } from '../../models/Dividend';

// const { DateTime } = require("luxon");

type DividendSettingDetailProps = {};

export const DividendSettingDetail: FunctionComponent<DividendSettingDetailProps> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['login'],
  //   queryFn: async () => {return },
  // });

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [settingDetail, setSettingDetail] = useState<Dividend|null>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [exitId, setDividendId] = useState<string | undefined>(params.id);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setDividendSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'created_at', 'updated_at', 'extra_data', 'book_year|short_visual', 'pain_downloaded'
  ];

  const fetchSetting = () => {
    setLoader(true);
    
    fetch_one<Dividend>('/nl/v3/objects/fetch_all', {
      object: 'dividend',
      fields: fields,
      id: exitId
    }, (ob, complete_data) => {
      openSetting(ob);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const saveSetting = () => {
    setSaveLoader(true);
    fetch_one<Dividend>('/nl/v3/objects/save', {
      object: 'dividend',
      fields: fields,
      id: exitId,
      ob: {
        ...settingDetail,
        id: exitId
      },
      handler_id: login?.id
    }, (ob, complete_data) => {
      setDividendId(ob.id?.toString());
      openSetting(ob);
      setSaveLoader(false);
      setDividendSavedSnackOpen(true);
      navigate("/dividend_settings");
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  useEffect(() => {
    fetchSetting();
  }, []);

  const openSetting = (ob: Dividend) => {
    setSettingDetail(ob);
  }

  return (
    <Page sx={{}} title="Dividend Setting">
      <Grid container spacing="8">
        <Grid container item xs={12} spacing="8" sx={{alignContent: 'baseline'}}>
          {!!loader && <Grid item xs={12}>
            <CircularProgress />
          </Grid>}

          <Grid container item xs={12} spacing="8">
            
            <Grid item xs={6} sx={{marginTop: 0}}>
              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.identity
                }}
                title="General data"
                wide
              >
                <Grid item xs={12}>
                  <Typography sx={{fontSize: '0.75rem'}}>Book Year</Typography>
                  <Typography>{settingDetail?.book_year_short_visual}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <StyledSwitch
                    label="Visible to the public"
                    value={!!settingDetail?.pain_downloaded}
                    onChange={(v) => {if (!!settingDetail) setSettingDetail({
                      ...settingDetail,
                      pain_downloaded: !!v
                    });}}
                  />
                </Grid>

              </UserDetailBase>
            </Grid>
            
            <Grid item xs={6}>
              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.account
                }}
                title="PAIN settings"
                wide
              >
                <Grid item xs={12}>
                  <StyledTextField
                    label="IBAN"
                    value={settingDetail?.extra_data?.iban || ''}
                    id='pain_iban'
                    onChange={(v) => {if (!!settingDetail) setSettingDetail({
                      ...settingDetail,
                      extra_data: {
                        ...(settingDetail?.extra_data || {}),
                        iban: v
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="BIC"
                    value={settingDetail?.extra_data?.bic || ''}
                    id='pain_bic'
                    onChange={(v) => {if (!!settingDetail) setSettingDetail({
                      ...settingDetail,
                      extra_data: {
                        ...(settingDetail?.extra_data || {}),
                        bic: v
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledDateField
                    label="Execution Date"
                    value={settingDetail?.extra_data?.execution_date || ''}
                    id='pain_execution_date'
                    onChange={(v) => {if (!!settingDetail) setSettingDetail({
                      ...settingDetail,
                      extra_data: {
                        ...(settingDetail?.extra_data || {}),
                        execution_date: v.toString()
                      }
                    });}}
                    fullWidth
                  />
                </Grid>

              </UserDetailBase>
            </Grid>
            
            <Grid item xs={6} sx={{marginTop: 2}}>
              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.contact
                }}
                title={`Stock settings - A`}
                wide
              >
                <Grid item xs={12}>
                  <StyledTextField
                    label="RV"
                    value={settingDetail?.extra_data?.settings?.a?.rv_pct || ''}
                    id="rv_a"
                    onChange={(v) => {if (!!settingDetail) setSettingDetail({
                      ...settingDetail,
                      extra_data: {
                        ...(settingDetail?.extra_data || {}),
                        settings: {
                          ...(settingDetail?.extra_data?.settings || {}),
                          a: {
                            ...(settingDetail?.extra_data?.settings?.a || {}),
                            rv_pct: v
                          }
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="DIV"
                    value={settingDetail?.extra_data?.settings?.a?.div_pct || ''}
                    id='div_a'
                    onChange={(v) => {if (!!settingDetail) setSettingDetail({
                      ...settingDetail,
                      extra_data: {
                        ...(settingDetail?.extra_data || {}),
                        settings: {
                          ...(settingDetail?.extra_data?.settings || {}),
                          a: {
                            ...(settingDetail?.extra_data?.settings?.a || {}),
                            div_pct: v
                          }
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>

              </UserDetailBase>
            </Grid>
            <Grid item xs={6} sx={{marginTop: 2}}>
              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.contact
                }}
                title={`Stock settings - B`}
                wide
              >
                <Grid item xs={12}>
                  <StyledTextField
                    label="RV"
                    value={settingDetail?.extra_data?.settings?.b?.rv_pct || ''}
                    id="rv_b"
                    onChange={(v) => {if (!!settingDetail) setSettingDetail({
                      ...settingDetail,
                      extra_data: {
                        ...(settingDetail?.extra_data || {}),
                        settings: {
                          ...(settingDetail?.extra_data?.settings || {}),
                          b: {
                            ...(settingDetail?.extra_data?.settings?.b || {}),
                            rv_pct: v
                          }
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="DIV"
                    value={settingDetail?.extra_data?.settings?.b?.div_pct || ''}
                    id='div_b'
                    onChange={(v) => {if (!!settingDetail) setSettingDetail({
                      ...settingDetail,
                      extra_data: {
                        ...(settingDetail?.extra_data || {}),
                        settings: {
                          ...(settingDetail?.extra_data?.settings || {}),
                          b: {
                            ...(settingDetail?.extra_data?.settings?.b || {}),
                            div_pct: v
                          }
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>

              </UserDetailBase>
            </Grid>

          </Grid>
        
          <Grid item xs={12} spacing="8" sx={{alignContent: 'baseline', textAlign: 'center'}}>
            <Divider sx={{marginTop: 1, marginBottom: 2}} />

            {!!loader && <CircularProgress />}
            {!loader && !saveLoader && <StyledButton
              label="Save"
              id='save'
              contained
              saveStartIcon
              onClick={(v) => {
                saveSetting();
              }}
              sx={{marginLeft: 1}}
            />}
            {!loader && <StyledButton
              label="Cancel"
              id='cancel'
              // contained
              onClick={(v) => {
                navigate("/dividend_settings");
              }}
              sx={{marginLeft: 1}}
            />}
          </Grid>
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setDividendSavedSnackOpen(b);}} />}
    </Page>
  );
}
