import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';
import { loginAtom } from '../../lib/auth';

import {
  Box,
  Grid
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

import {
  DataGrid,
  Page,
  StyledButton,
  StyledDropzone,
  StyledIconButton,
  StyledSelect
} from '../../components/interface';

import { Emission } from '../../models/Emission';
import { PaymentFile } from '../../models/PaymentFile';

const { DateTime } = require("luxon");

type Props = {};

export const EmissionPaymentFiles: FunctionComponent<Props> = () => {
  // const navigate = useNavigate();
  // const theme = useTheme();

  const [login, setLogin] = useAtom(loginAtom);
  const [paymentFiles, setPaymentFiles] = useState<PaymentFile[]>([]);
  const [emissions, setEmissions] = useState<Emission[]>([]);
  const [paymentFilesCount, setPaymentFilesCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("created_at DESC");
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [item, setItem] = useState<File>();
  const [emissionId, setEmissionId] = useState<number>();

  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const doSave = () => {
    let formData = new FormData();
    formData.append('object', 'payment_file');
    formData.append('ob[fake_field]', '');

    if (!!item) formData.append('item', item);

    fetch_one<PaymentFile>('/nl/v3/objects/save', formData, (obs, complete_data) => {
      setShowEdit(false);
      setItem(undefined);
      onSearch('');
    }, (z) => {

    }, login);
  };

  const getEmissions = () => {
    fetch_all<Emission>('/nl/v3/objects/fetch_all', {
      object: 'emission',
      fields: ['id', 'start_date'],
      per_page: 1000,
      page: 0,
      order: "start_date DESC"
    }, (obs, complete_data) => {
      setEmissions(obs);
      setEmissionId(obs[0].id)
    }, (z) => {
      // setServerErrorSnackOpen(true);
    }, login);
  };

  const onRemove = (id: number) => {
    setRemoveLoader(true);
    fetch_one<PaymentFile>('/nl/v3/objects/remove', {
      object: 'payment_file',
      ob: {
        id: id
      }
    }, (obs, complete_data) => {
      onSearch('');
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onSearch = (s: string) => {
    setLoader(true);
    fetch_all<PaymentFile>('/nl/v3/objects/fetch_all', {
      object: 'payment_file',
      fields: [
        'id', 'created_at', 'started_at', 'handled_at', 'extra_data', 'emission|id', 'get_item', 'payments|last|row_number'
      ],
      per_page: 20,
      page: page,
      order: order
    }, (obs, complete_data) => {
      setPaymentFiles(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setPaymentFilesCount(count);

      setLoader(false);
    }, (z) => {

    }, login)
  };

  const runIt = (id: number) => {
    setLoader(true);
    fetch_one<PaymentFile>('/nl/v3/objects/custom_action', {
      object: 'payment_file',
      class_action: 'custom_api_run_it',
      handler_id: login?.id,
      id: id
    }, (obs, complete_data) => {
      onSearch('');
    }, (z) => {
      onSearch('');
    }, login);
  };

  useEffect(() => {
    getEmissions();
  }, []);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'created_at', headerName: 'Created', width: 200, renderCell: params => {
      return (<>
        <Box>
          {!!params.row.created_at && DateTime.fromISO(params?.value).toFormat("D TT")}{!params.row.created_at && "-"}<br/>
        </Box>
      </>);
    } },
    { field: 'started_at', headerName: 'Started', width: 200, renderCell: params => {
      return (<>
        <Box>
          {!!params.row.started_at && DateTime.fromISO(params?.value).toFormat("D TT")}{!params.row.started_at && "-"}<br/>
        </Box>
      </>);
    } },
    { field: 'handled_at', headerName: 'Handled', width: 200, renderCell: params => {
      let diff = DateTime.fromISO(params.row.handled_at).diff(DateTime.fromISO(params.row.started_at), ['hours', 'minutes', 'seconds']).toObject();
      return (<>
        <Box>
          {!!params.row.handled_at && DateTime.fromISO(params?.value).toFormat("D TT")}{!params.row.handled_at && "-"}<br/>
          {!!params.row.handled_at && <span style={{fontSize: '0.6rem'}}>
            {diff.hours > 0 && <>{diff.hours} hours, </>}
            {diff.minutes > 0 && <>{diff.minutes} minutes, </>}
            {diff.seconds > 0 && <>{diff.seconds} seconds</>}
          </span>}
        </Box>
      </>);
    } },
    { field: 'payments_last_row_number', headerName: 'Last line', width: 120 },
  ];

  return (
    <Page sx={{}} title="Payment Files" helper="payment_files" actions={<>
      <StyledButton
        label="Create new"
        id='create_new'
        contained
        onClick={() => {
          setShowEdit(true);
        }}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}>
      {!!showEdit && <>
        <Grid container sx={{position: 'relative'}}>
          <Grid item xs={12}>
            <StyledSelect
              label="Emission"
              value={emissionId?.toString() || ''}
              id='emission_id'
              onChange={(v) => {
                setEmissionId(!!v ? parseInt(v, 10) : undefined);
              }}
              list={[
                {id: '', name: "--- None ---"},
                ...emissions.map(m => {return {id: m.id?.toString() || '', name: `Emission of ${DateTime.fromISO(m.start_date).toFormat("D")}`};})
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <CloseIcon sx={{position: 'absolute', top: 0, right: 0, cursor: 'pointer'}} onClick={() => {setShowEdit(false);}} />
            <StyledDropzone
              acceptedFiles={{
                '*/*': []
              }}
              onDrop={acceptedFiles => {setItem(acceptedFiles[0]);}}
              multiple={false}
              maxSize={250 * 1000 * 1000}
              items={!!item ? [item] : []}
              dropText="Click/Drop"
              dropHint="All.: files, < 25mb"
            />
          </Grid>
          <Grid item xs={12}>
            <StyledButton
              label="Save"
              id='save'
              contained
              onClick={() => {
                doSave();
              }}
              sx={{marginRight: 3}}
              startIcon={<AddCircleOutlineIcon />}
            />
          </Grid>
        </Grid>
      </>}
      
      <DataGrid
        action_count={2}
        actions={(params) => {
          return (<>
            {(params.row.payments_last_row_number || 0) < 1 && <StyledIconButton color="primary" onClick={() => {
              runIt(params.row.id);
            }}>
              <DirectionsRunIcon />
            </StyledIconButton>}
            {!removeLoader && (params.row.payments_last_row_number || 0) < 1 && <StyledIconButton destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
          </>);
        }}
        objectCount={paymentFilesCount}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objects={(paymentFiles || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />
    </Page>
  );
}
