import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one } from '../../../lib/server_helper';
import { loginAtom } from '../../../lib/auth';

import {
  Box,
  Divider,
  Grid,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import { AlertCheckInputs } from '../../../components/alerts/CheckInputs';
import { AlertSaved } from '../../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../../components/alerts/SomethingWentWrong';

import {
  StyledButton,
  StyledTextField,
  Typography
} from '../../../components/interface';
import { PaymentCard } from '../../../components/interface/PaymentCard';

import { EmissionKey } from '../../../models/EmissionKey';

type Props = {
  emissionKey: EmissionKey;
  isHidden: boolean;
  fields: string[];
  refreshKey: () => void;
};

export const EmissionDetailTabsDetailPayment: FunctionComponent<Props> = ({emissionKey, isHidden, fields, refreshKey}) => {
  // const navigate = useNavigate();
  const theme = useTheme();
  // const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);

  const [newAmount, setNewAmount] = useState<number>(emissionKey?.proposed_amount || 0);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [userSavedSnackOpen, setUserSavedSnackOpen] = useState<boolean>(false);
  const [regenBlankSnackOpen, setRegenBlankSnackOpen] = useState<boolean>(false);

  const saveAmount = () => {
    fetch_one<EmissionKey>('/nl/v3/objects/custom_action', {
      object: 'emission_key',
      class_action: 'custom_api_change_amount',
      id: emissionKey.id,
      amount: newAmount
    }, (ob, complete_data) => {
      refreshKey();
    }, (z, y) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  return (
    <Box hidden={isHidden} sx={{paddingTop: 2}}>
      <Grid container spacing="8">

        <Grid item xs={12} md={6} sx={{alignContent: 'baseline'}}>
          <PaymentCard payment={emissionKey.payments_last_root_parent_to_tree} />
        </Grid>

        <Grid container item xs={12} md={6} sx={{alignContent: 'baseline'}} spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography>ASN - that users can see</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>{emissionKey.modded_asn_key_of_user}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>ASN - unique for this order</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>{emissionKey.modded_asn_key}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Saldo before start</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>{emissionKey.user_pre_emission_saldo}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Bought during emission</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>{emissionKey.user_during_emission_saldo}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Open in this ASN</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>{emissionKey.is_verified ? 0 : emissionKey.proposed_amount}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{marginTop: 2}}>Change amount</Typography>
          </Grid>
          <Grid item xs={4}>
            <StyledTextField
              label="Amount"
              value={newAmount.toString() || ''}
              id='new_amount'
              type="number"
              min={1}
              max={250}
              onChange={(v) => {
                setNewAmount(!!v ? parseInt(v, 10) : 0);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <StyledButton
              label="Save amount"
              id='save_amount'
              disabled={newAmount === emissionKey.proposed_amount}
              contained
              onClick={(v) => {
                saveAmount();
              }}
              sx={{
                marginTop: 1
              }}
            />
          </Grid>
        </Grid>

      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!userSavedSnackOpen && <AlertSaved open={userSavedSnackOpen} setOpen={(b) => {setUserSavedSnackOpen(b);}} />}

      {!!regenBlankSnackOpen && <Snackbar
        open={regenBlankSnackOpen}
        onClose={() => {setRegenBlankSnackOpen(false);}}
        autoHideDuration={2000}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">
          Unsigned document was regenerated!
        </MuiAlert>
      </Snackbar>}
    </Box>
  );
}
