import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all } from '../../lib/server_helper';

import {
  Box,
  Chip,
  Tooltip
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import QuizIcon from '@mui/icons-material/Quiz';
import RemoveIcon from '@mui/icons-material/Remove';

import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton,
  Typography
} from '../../components/interface';

import { StockTransfer } from '../../models/StockTransfer';
import { loginAtom } from '../../lib/auth';

const { DateTime } = require("luxon");

type TransfersStockTransfersProps = {};

export const TransfersStockTransfers: FunctionComponent<TransfersStockTransfersProps> = () => {
  // const navigate = useNavigate();
  const theme = useTheme();
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['login'],
  //   queryFn: async () => {return },
  // });

  const login = useAtomValue(loginAtom);
  // const [login, setLogin] = useAtom(loginAtom);
  const [stocktransfers, setStockTransfers] = useState<StockTransfer[]>([]);
  const [stocktransfersCount, setStockTransfersCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("updated_at DESC");
  const [searchBirthdate, setSearchBirthdate] = useState('');
  const [searchCatchAll, setSearchCatchAll] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [searchName, setSearchName] = useState('');
  const [searchNumber, setSearchNumber] = useState('');
  const [searchStreet, setSearchStreet] = useState('');
  const [searchStatus, setSearchStatus] = useState<string[]>([]);
  const [searchVennootNumber, setSearchVennootNumber] = useState('');
  const [searchZipCity, setSearchZipCity] = useState('');
  const [initialValues, setInitialValues] = useState({vennoot_number: '', street: '', number: '', zip: '', resettable: true});

  const onSearch = (s: string) => {
    setLoader(true);
    fetch_all<StockTransfer>('/nl/v3/objects/fetch_all', {
      object: 'stock_transfer',
      fields: [
        'id', 'updated_at', 'stock_number', 'extra_data', 'notes|last|admin_name/created_at', 'updated_at', 'get_status',
        'user|id/vennoot_number/first_name/last_name', 'has_guardian',
        'existing_user|id/vennoot_number/first_name/last_name',
        'new_user|id/vennoot_number/first_name/last_name',
        'first_name', 'last_name', 'birth_date', 'status', 'open_others|count',
        'item|filename', 'id_user|filename', 'id_new_user|filename', 'id_voogd|filename',
        'get_item', 'get_id_user', 'get_id_new_user', 'get_id_voogd'
      ],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          name: searchName,
          email: searchEmail,
          birth_date: searchBirthdate,
          vennoot_number: searchVennootNumber,
          street: searchStreet,
          number: searchNumber,
          zip: searchZipCity,
          catch_all: searchCatchAll,
          status: searchStatus
        }
      }
    }, (obs, complete_data) => {
      setStockTransfers(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setStockTransfersCount(count);

      setLoader(false);
    }, (z) => {

    }, login)
  };

  useEffect(() => {
    onSearch('');
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, searchName, searchEmail, searchBirthdate, searchVennootNumber, searchStreet, searchNumber, searchZipCity, searchCatchAll, searchStatus]);

  const columns: GridColDef[] = [
    { field: 'updated_at', headerName: 'Updated', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'user_id', headerName: 'Vennoot', width: 350, renderCell: params => <>
      {params.row.open_others_count > 0 && <Chip
        sx={{marginRight: 1, cursor: 'pointer'}}
        label={params.row.open_others_count}
        variant="outlined"
        onClick={() => {
          setInitialValues({vennoot_number: params.row.user_vennoot_number, street: '', number: '', zip: '', resettable: true});
        }}
      />} {params.row.user_first_name} {params.row.user_last_name} ({params.row.user_vennoot_number})
      
    </> },
    { field: 'existing_user_id', headerName: 'Receiver', width: 300, sortable: false, renderCell: params => <>
      {!!params.row.new_user_id && <>
        <HowToRegIcon sx={{marginRight: 1}} /> {params.row.new_user_first_name} {params.row.new_user_last_name} ({params.row.new_user_vennoot_number})
      </>}
      {!params.row.new_user_id && !!params.row.existing_user_id && <>
        <PersonIcon sx={{marginRight: 1}} /> {params.row.existing_user_first_name} {params.row.existing_user_last_name} ({params.row.existing_user_vennoot_number})
      </>}
      {!params.row.new_user_id && !params.row.existing_user_id && !!params.row.birth_date && <>
        <PersonAddIcon sx={{marginRight: 1}} /> {params.row.first_name} {params.row.last_name} - {DateTime.fromISO(params.row.birth_date).toFormat("D")}
      </>}
      {!params.row.new_user_id && !params.row.existing_user_id && !params.row.birth_date && <>
        <PsychologyAltIcon sx={{marginRight: 1}} />
      </>}
    </> },
    { field: 'get_status', headerName: 'Status', width: 120 },
    { field: 'files', headerName: 'Files', width: 110, sortable: false, renderCell: params => <>
      {params.row.extra_data.item_confirmed === '1' && !!params.row.item_filename && <Tooltip title="Signed doc"><CheckIcon sx={{color: theme.palette.tertiary?.main}} /></Tooltip>}
      {params.row.extra_data.item_confirmed !== '1' && !!params.row.item_filename && <Tooltip title="Signed doc"><QuizIcon sx={{color: 'orange'}} /></Tooltip>}
      {params.row.extra_data.item_confirmed !== '1' && !params.row.item_filename && <Tooltip title="Signed doc"><CloseIcon sx={{color: theme.palette.secondary.main}} /></Tooltip>}

      {params.row.extra_data.id_user_confirmed === '1' && !!params.row.id_user_filename && <Tooltip title="ID User"><CheckIcon sx={{color: theme.palette.tertiary?.main}} /></Tooltip>}
      {params.row.extra_data.id_user_confirmed !== '1' && !!params.row.id_user_filename && <Tooltip title="ID User"><QuizIcon sx={{color: 'orange'}} /></Tooltip>}
      {params.row.extra_data.id_user_confirmed !== '1' && !params.row.id_user_filename && <Tooltip title="ID User"><CloseIcon sx={{color: theme.palette.secondary.main}} /></Tooltip>}

      {params.row.extra_data.id_new_user_confirmed === '1' && !!params.row.id_new_user_filename && <Tooltip title="ID New User"><CheckIcon sx={{color: theme.palette.tertiary?.main}} /></Tooltip>}
      {params.row.extra_data.id_new_user_confirmed !== '1' && !!params.row.id_new_user_filename && <Tooltip title="ID New User"><QuizIcon sx={{color: 'orange'}} /></Tooltip>}
      {params.row.extra_data.id_new_user_confirmed !== '1' && !params.row.id_new_user_filename && <Tooltip title="ID New User"><CloseIcon sx={{color: theme.palette.secondary.main}} /></Tooltip>}

      {!params.row?.has_guardian && <Tooltip title="ID Voogd"><RemoveIcon sx={{color: 'grey'}} /></Tooltip>}
      {!!params.row?.has_guardian && <>
        {params.row.extra_data.id_voogd_confirmed === '1' && !!params.row.id_voogd_filename && <Tooltip title="ID Voogd"><CheckIcon sx={{color: theme.palette.tertiary?.main}} /></Tooltip>}
        {params.row.extra_data.id_voogd_confirmed !== '1' && !!params.row.id_voogd_filename && <Tooltip title="ID Voogd"><QuizIcon sx={{color: 'orange'}} /></Tooltip>}
        {params.row.extra_data.id_voogd_confirmed !== '1' && !params.row.id_voogd_filename && <Tooltip title="ID Voogd"><CloseIcon sx={{color: theme.palette.secondary.main}} /></Tooltip>}
      </>}
    </> },
    { field: 'handler', headerName: 'Handler', width: 200, sortable: false, renderCell: params => <>
      {!!params.row.notes_last_admin_name && <>
        <Typography sx={{fontSize: '0.9rem'}}>
          {params.row.notes_last_admin_name}<br/>
          <Box sx={{fontSize: '0.8rem', fontStyle: 'italic'}}>{!!params.row.notes_last_created_at ? DateTime.fromISO(params.row.notes_last_created_at).toFormat("D") : ''}</Box>
        </Typography>
      </>}
    </> }
  ];

  return (
    <Page sx={{}} title="Stock Transfers" helper="stock_transfers" actions={<>
      <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={'/stock_transfer/new'}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}>
      <SearchBox
        simple={false}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
        initialValues={initialValues}
        transferSearch={(advanced: {name:string, email:string, birth_date:string, vennoot_number:string, street:string, number:string, zip:string, catch_all:string, status:string[]}) => {
          setSearchName(advanced.name);
          setSearchEmail(advanced.email);
          setSearchBirthdate(advanced.birth_date);
          setSearchVennootNumber(advanced.vennoot_number);
          setSearchStreet(advanced.street);
          setSearchNumber(advanced.number);
          setSearchZipCity(advanced.zip);
          setSearchCatchAll(advanced.catch_all);
          setSearchStatus(advanced.status);
          setPage(0);
        }}
        memo="stock_transfers"
      />
      <DataGrid
        action_count={1}
        actions={(params) => {
          return (<>
            <StyledIconButton title="Open Detail" color="primary" path={`/stock_transfer/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
          </>);
        }}
        objectCount={stocktransfersCount}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objects={(stocktransfers || []).map(u => ({
          ...u,
          name: [u.first_name, u.last_name].join(" "),
          address: u.readable_address
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />
    </Page>
  );
}
