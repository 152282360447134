import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box
} from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import UpdateIcon from '@mui/icons-material/Update';

import { Audit } from '../../models/Audit';

import { AuditLayout } from './Layout';
import { Typography } from '../interface/Typography';

const { DateTime } = require("luxon");

type AuditBaseProps = {
  audit: Audit;
};

export const AuditBase: FunctionComponent<AuditBaseProps> = ({audit}) => {
  let showable = true;
  let keys = Object.keys(audit.audited_changes);
  [
    'auth_token', 'admin_auth_token', 'encrypted_password', 'email_history', 'extra_data', 'last_import', 'sign_in_count', 'current_sign_in_at', 'last_sign_in_at', 'current_sign_in_ip',
    'last_sign_in_ip', 'last_activity', 'auth_time', 'last_updated', 'otp_secret_key'
  ].map(field => {
    if (keys.indexOf(field) > -1) keys.splice(keys.indexOf(field), 1);
  });

  showable = keys.length > 0;

  return (showable ? <>
    <AuditLayout>
      <Box
        sx={{
          display: 'inline-block',
          marginRight: 1,
          paddingRight: 1,
          borderRight: '1px solid lightgrey'
        }}
      >
        <Typography sx={{
          color: 'grey',
          fontSize: 11
        }}>{audit.action === "create" && <AddCircleOutlineIcon sx={{scale: '0.7', marginBottom: '-7px'}} />} {audit.action === "update" && <UpdateIcon sx={{scale: '0.7', marginBottom: '-7px'}} />} {DateTime.fromISO(audit.created_at).toFormat("DD TT")}</Typography>
      </Box>
      <Box
        sx={{
          display: 'inline-block',
          marginRight: 1,
          paddingRight: 1,
          borderRight: '1px solid lightgrey'
        }}
      >
        <Typography sx={{
          color: 'grey',
          fontSize: 11
        }}>{audit.version}</Typography>
      </Box>
      <Box
        sx={{
          display: 'inline-block',
          marginRight: 1,
          paddingRight: 1
        }}
      >
        {keys.map(key => <>
          <Typography sx={{
            fontSize: 13,
            "&:hover": {
              fontSize: 16
            }
          }}>{key}{key !== 'email_history' && key !== 'extra_data' && <>&nbsp;({audit.audited_changes[key][0]} =&gt; {audit.audited_changes[key][1]})</>}</Typography>
        </>)}
      </Box>
    </AuditLayout>
  </> : <></>);
}