import React, { FunctionComponent } from 'react';

import { BenefitBlock } from '../../../models/BenefitBlock';
import { EmailBlock } from '../../../models/EmailBlock';

type BlockPublicBaseImageProps = {
  block: EmailBlock | BenefitBlock;
  activeLocale: 'nl' | 'fr';
};

// function isBenefitBlock(block: EmailBlock | BenefitBlock): block is BenefitBlock {
//   return true
// }

export const BlockPublicBaseImage: FunctionComponent<BlockPublicBaseImageProps> = ({activeLocale, block}) => {
  let item = undefined;
  let item_url = undefined;
  let get_item = undefined;

  const isBenBlock = (x: any): x is BenefitBlock => !block.hasOwnProperty('get_item');

  if(isBenBlock(block)){
    if (activeLocale === 'fr') {
      item = block.item_fr;
      item_url = block.set_item_url_fr;
      get_item = block.get_item_fr;
    }
    if (activeLocale !== 'fr') {
      item = block.item_nl;
      item_url = block.set_item_url_nl;
      get_item = block.get_item_nl;
    }
  }
  if(!isBenBlock(block)){
    item = block.item;
    item_url = block.set_item_url;
    get_item = block.get_item;
  }

  return (<>
    {!!item && <img style={{width: '100%', height: 'auto', marginTop: '10px', marginBottom: '10px', borderRadius: '10px'}} width="600" src={URL.createObjectURL(item)}></img>}
    {!item && !!item_url && <img style={{width: '100%', height: 'auto', marginTop: '10px', marginBottom: '10px', borderRadius: '10px'}} width="600" src={item_url}></img>}
    {!item  && !item_url && !!get_item && <img style={{width: '100%', height: 'auto', marginTop: '10px', marginBottom: '10px', borderRadius: '10px'}} width="600" src={get_item}></img>}
  </>);
};
