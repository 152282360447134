import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { useParams } from 'react-router-dom';

import { fetch_all } from '../../lib/server_helper';

// import {
//   IconButton
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import DescriptionIcon from '@mui/icons-material/Description';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton
} from '../../components/interface';

import { OfficeAccount } from '../../models/OfficeAccount';
import { loginAtom } from '../../lib/auth';

const { DateTime } = require("luxon");

type OfficeOfficeAccountsProps = {};

export const OfficeOfficeAccounts: FunctionComponent<OfficeOfficeAccountsProps> = () => {
  // const navigate = useNavigate();
  const params = useParams();

  const login = useAtomValue(loginAtom);
  // const [login, setLogin] = useAtom(loginAtom);
  const [objects, setObjects] = useState<OfficeAccount[]>([]);
  const [objectsCount, setObjectsCount] = useState<number>(0);
  const [officeId, setOfficeId] = useState<string | undefined>(params.id);
  const [loader, setLoader] = useState<boolean>(true);
  // const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id ASC");

  useEffect(() => {
    if (params.id !== officeId) setOfficeId(params.id);
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    onSearch('');
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order]);

  const onSearch = (s: string) => {
    setLoader(true);
    fetch_all<OfficeAccount>('/nl/v3/objects/fetch_all', {
      object: 'office_account',
      fields: ['id', 'enabled', 'full_name', 'email', 'created_at', 'office_id', 'office_logs|count'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          office_id: officeId
        }
      }
    }, (obs, complete_data) => {
      setObjects(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setObjectsCount(count);

      setLoader(false);
    }, (z) => {

    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'full_name', headerName: 'Name', width: 250 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'created_at', headerName: 'Created', width: 200, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("f") }
  ];

  return (
    <Page sx={{}} title="Office Accounts" helper="office_accounts" startEndCrumb={<StyledButton
      label="Back to offices"
      id='back'
      text
      startIcon={<KeyboardBackspaceIcon />}
      path={`/offices`}
    />}>
      <SearchBox
        simple={true}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
        memo="office_accounts"
      />
      <DataGrid
        action_count={1}
        actions={(params) => {
          return (<>
            {(params.row.office_logs_count || 0) > 0 && <StyledIconButton title="Office Logs" color="primary" path={`/office_logs/${params.row.office_id || 0}/${params.row.id}`}>
              <DescriptionIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={objectsCount}
        objects={(objects || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />
    </Page>
  );
}
