import server from './server';
import { getCookie, setCookie } from 'react-use-cookie';

export function fetch_all<Type>(
  url: string,
  params: unknown,
  callback: (z:Type[], y:ApiMultiResult<Type>) => void,
  error_callback: (z:string) => void,
  login?: {admin_auth_token?: string, auth_token: string, email: string}
) {
  let h = {};
  if (!!login) {
    h = {
      "Auth-Token": login.admin_auth_token || login.auth_token,
      "Auth-Email": login.email
    };
    // h = {auth_token: getCookie('argenco_token'), email: getCookie('argenco_email')};
  }

  return server.post<ApiMultiResult<Type>>(url, params, {
    headers: h
  }).then((_response) => {
    var _data = _response.data;
    if (_data.success) {
      let r = _data.data;
      if (!!r && typeof(r) === 'object' && 'result' in r) r = r.result;
      if (callback !== undefined && typeof(r) === 'object') callback(r, _data);
    } else {
      if (error_callback !== undefined && typeof(_data.data) === 'string') error_callback(_data.data);
    }
  }).catch(_error => {
    if (error_callback !== undefined) error_callback(!!_error.response ? _error.response.data : {});
  });
}

export function fetch_one<Type>(
  url: string,
  params: unknown,
  callback: (z:Type, y:ApiSingleResult<Type>) => void,
  error_callback: (z:string, y:any) => void,
  login?: {admin_auth_token?: string, auth_token: string, email: string}
) {
  let h = {};
  if (!!login) {
    h = {
      "Auth-Token": login.admin_auth_token || login.auth_token,
      "Auth-Email": login.email
    };
    // h = {auth_token: getCookie('argenco_token'), email: getCookie('argenco_email')};
  }

  return server.post<ApiSingleResult<Type>>(url, params, {
    headers: h
  }).then((_response) => {
    var _data = _response.data;
    if (_data.success) {
      let r = _data.data;
      if (!!r && typeof(r) === 'object' && 'result' in r) r = r.result;
      if (callback !== undefined && typeof(r) === 'object') callback(r, _data);
      if (callback !== undefined && typeof(r) === 'boolean') callback(r, _data);
    } else {
      if (error_callback !== undefined && typeof(_data.data) === 'string') error_callback(_data.data, {});
    }
  }).catch(_error => {
    if (error_callback !== undefined) error_callback(!!_error.response ? _error.response.data : {}, _error);
  });
}

export type ApiResult = {
  data: unknown;
  server_timestamp: string;
  success: boolean;
}

export type ApiMultiResult<Type> = ApiResult & {
  data: Type[] | string | {result : Type[]; count: number;};
}

export type ApiSingleResult<Type> = ApiResult & {
  data: Type | string | {result : Type; count: number;};
}