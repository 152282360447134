import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

// import {
//   IconButton
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { DataGrid } from '../../components/interface/DataGrid';
import { Page } from '../../components/interface/Page';
import { SearchBox } from '../../components/interface/SearchBox';
import { StyledButton } from '../../components/interface/StyledButton';
import { StyledIconButton } from '../../components/interface/StyledIconButton';

import { SecurityQuestion } from '../../models/SecurityQuestion';
import { loginAtom } from '../../lib/auth';

type ContentSecurityQuestionsProps = {};

export const ContentSecurityQuestions: FunctionComponent<ContentSecurityQuestionsProps> = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);
  const [securityQuestions, setSecurityQuestions] = useState<SecurityQuestion[]>([]);
  const [securityQuestionsCount, setSecurityQuestionsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");

  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onRemove = (id: number) => {
    setRemoveLoader(true);
    fetch_one<SecurityQuestion>('/nl/v3/objects/remove', {
      object: 'security_question',
      ob: {
        id: id
      }
    }, (obs, complete_data) => {
      onSearch('');
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onSearch = (s: string) => {
    setLoader(true);
    fetch_all<SecurityQuestion>('/nl/v3/objects/fetch_all', {
      object: 'security_question',
      fields: ['id', 'question_nl', 'question_fr'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s
      }
    }, (obs, complete_data) => {
      setSecurityQuestions(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setSecurityQuestionsCount(count);

      setLoader(false);
    }, (z) => {

    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'question_nl', headerName: 'Question (nl)', width: 400 },
    { field: 'question_fr', headerName: 'Question (fr)', width: 400 },
  ];

  return (
    <Page sx={{}} title="Security Questions" helper="security_questions" old_url="https://old.argenco.be/support/security_questions" actions={<>
      <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={'/security_question/new'}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}>
      <SearchBox
        simple={true}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
        memo="security_questions"
      />
      <DataGrid
        action_count={2}
        actions={(params) => {
          return (<>
            <StyledIconButton color="primary" path={`/security_question/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
            {!removeLoader && <StyledIconButton destroyBtn color="primary" onClick={(e) => {
              // onRemove(params.row.id);
              alert('discuss what to do before implementation');
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={securityQuestionsCount}
        objects={(securityQuestions || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />
    </Page>
  );
}
