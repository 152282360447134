import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one } from '../../lib/server_helper';

import {
  Box,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LaunchIcon from '@mui/icons-material/Launch';
import LinkOffIcon from '@mui/icons-material/LinkOff';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import { DetailLine } from '../../components/interface/Detail/DetailLine';
import { Page } from '../../components/interface/Page';
import { NotarySearch } from '../../components/interface/NotarySearch';
import { StyledButton } from '../../components/interface/StyledButton';
import { StyledDateField } from '../../components/interface/StyledDateField';
import { StyledDropzone } from '../../components/interface/StyledDropzone';
import { StyledIconButton } from '../../components/interface/StyledIconButton';
import { StyledSwitch } from '../../components/interface/StyledSwitch';
import { StyledTextField } from '../../components/interface/StyledTextField';
import { Typography } from '../../components/interface/Typography';
import { UserDetailBase } from '../../components/user_detail/Base';
import { UserSearch } from '../../components/interface/UserSearch';

import { Death } from '../../models/Death';
import { Notary } from '../../models/Notary';
import { User } from '../../models/User';

const { DateTime } = require("luxon");

type ExitDeathDetailProps = {};

export const ExitDeathDetail: FunctionComponent<ExitDeathDetailProps> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['login'],
  //   queryFn: async () => {return },
  // });

  const params = useParams();
  const exitId: string | undefined = params.id;

  const [login, setLogin] = useAtom(loginAtom);
  const [selectedNotary, setSelectedNotary] = useState<Notary|null>(null);
  const [selectedUser, setSelectedUser] = useState<User|null>(null);
  const [selectedSpouse, setSelectedSpouse] = useState<User|null>(null);
  const [deathDetail, setDeathDetail] = useState<Death|null>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const [item, setItem] = useState<File>();

  const [reporterVisible, setReporterVisible] = useState<boolean>(false);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setExitSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'created_at', 'updated_at', 'extra_data', 'status', 'first_name', 'last_name', 'get_item', 'birth_date',
    'item_blob|content_type', 'item|filename/byte_size',
    'deceased_data', 'reporter_data', 'notary_data', 'email',
    'user|id/first_name/last_name/vennoot_number/birth_date/street/number/zip/city/open_stock_transfer_count/locale',
    'user|transactions|last|transaction_date',
    'spouse|id/first_name/last_name/vennoot_number/birth_date/street/number/zip/city',
    'notary|id/first_name/last_name/office_name/office_number/street/number/zip/city/email/language/not_imported',
    'office|email/phone',
    'error_transactions',
    'emission_doc|get_lijst201/get_lijst201_kort/get_lijst201_fod',
    'emission_doc|lijst201|blob|content_type', 'emission_doc|lijst201|filename/byte_size',
    'emission_doc|lijst201_kort|blob|content_type', 'emission_doc|lijst201_kort|filename/byte_size',
    'emission_doc|lijst201_fod|blob|content_type', 'emission_doc|lijst201_fod|filename/byte_size'
  ];

  const fetchDeath = () => {
    setLoader(true);
    
    fetch_one<Death>('/nl/v3/objects/fetch_all', {
      object: 'death',
      fields: fields,
      id: exitId
    }, (ob, complete_data) => {
      openDeath(ob);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const onRemove = () => {
    // setRemoveLoader(true);
    fetch_one<Death>('/nl/v3/objects/remove', {
      object: 'death',
      dnr: 1,
      ob: {
        id: exitId
      }
    }, (obs, complete_data) => {
      navigate("/deaths");
      // setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const saveDeath = (override_status?: number) => {
    setSaveLoader(true);
    fetch_one<Death>('/nl/v3/objects/save', {
      object: 'death',
      fields: fields,
      id: exitId,
      ob: {
        ...deathDetail,
        ...(!!override_status ? {
          status: override_status
        } : {})
      },
      handler_id: login?.id
    }, (ob, complete_data) => {
      openDeath(ob);
      setSaveLoader(false);
      setExitSavedSnackOpen(true);

      if (!!override_status) navigate("/deaths")
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const saveFile = () => {
    setSaveLoader(true);

    let formData = new FormData();
    formData.append('object', 'death');
    formData.append('handler_id', (login?.id || "").toString());
    fields.map(field => {
      formData.append('fields[]', field);
    });
    if (!!exitId) formData.append('ob[id]', exitId.toString());
    if (!!exitId) formData.append('id', exitId.toString());
    if (!!item) formData.append('ob[item]', item);
    formData.append('skip_after_save', '1');

    fetch_one<Death>('/nl/v3/objects/save', formData, (ob, complete_data) => {
      openDeath(ob);
      setSaveLoader(false);
      setExitSavedSnackOpen(true);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  useEffect(() => {
    fetchDeath();
  }, []);

  const openDeath = (ob: Death) => {
    if (!ob.extra_data?.confirmable) {
      if (!ob.extra_data) ob.extra_data = {};
      if (!ob.extra_data?.confirmable) ob.extra_data.confirmable = {
        checked: '0',
        pain: '1',
        fod: '1',
        notify_email: '1',
        notary_email: '1',
        download: '1'
      };
    }
    if (!ob.extra_data?.locale) {
      if (!ob.extra_data) ob.extra_data = {};
      ob.extra_data.locale = ob.user_locale;
    }
    if (!!ob.email && !!ob?.reporter_data) ob.reporter_data.email = ob.email;
    if (!!ob.office_email && !!ob?.reporter_data) ob.reporter_data.email = ob.office_email;
    if (!ob.status) ob.status = 1;
    ob.original_status = ob.status;
    if (!!ob.deceased_data?.marital_status) ob.deceased_data.marital_status = ob.deceased_data.marital_status.toString();

    setDeathDetail(ob);
    if (!!ob.user_id) {
      let u:User = {auth_token: '', email: ''};
      u.first_name = ob.user_first_name;
      u.last_name = ob.user_last_name;
      u.vennoot_number = ob.user_vennoot_number;
      u.birth_date = ob.user_birth_date;
      u.street = ob.user_street;
      u.number = ob.user_number;
      u.zip = ob.user_zip;
      u.city = ob.user_city;
      setSelectedUser(u);
    }
    if (!!ob.spouse_id) {
      let u:User = {auth_token: '', email: ''};
      u.first_name = ob.spouse_first_name;
      u.last_name = ob.spouse_last_name;
      u.vennoot_number = ob.spouse_vennoot_number;
      u.birth_date = ob.spouse_birth_date;
      u.street = ob.spouse_street;
      u.number = ob.spouse_number;
      u.zip = ob.spouse_zip;
      u.city = ob.spouse_city;
      setSelectedSpouse(u);
    }
    if (!!ob.notary_id) {
      let u:Notary = {};
      u.first_name = ob.notary_first_name;
      u.last_name = ob.notary_last_name;
      u.street = ob.notary_street;
      u.zip = ob.notary_zip;
      u.city = ob.notary_city;
      u.office_name = ob.notary_office_name;
      u.email = ob.notary_email;
      u.not_imported = ob.notary_not_imported;
      u.number = ob.notary_number;
      u.office_number = ob.notary_office_number;
      setSelectedNotary(u);
    }
  }

  const regenerateFiles = () => {
    fetch_one<User>('/nl/v3/objects/custom_action', {
      object: 'death',
      class_action: 'custom_api_regenerate_201',
      // fields: fields,
      id: deathDetail?.id,
      new_locale: deathDetail?.extra_data?.locale,
      handler_id: login?.id
    }, (ob, complete_data) => {
      fetchDeath();
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  }

  return (
    <Page
      sx={{}}
      title={`${!!deathDetail && [deathDetail.first_name, deathDetail.last_name].join(" ")} [${!!deathDetail && !!deathDetail.original_status ? ['open', 'pending', 'closed', 'removed'][deathDetail.original_status] : 'open'}]`}
      startEndCrumb={<StyledButton
        label="Back to Deathmessages"
        id='back'
        text
        startIcon={<KeyboardBackspaceIcon />}
        path={`/deaths`}
        sx={{marginBottom: 1}}
      />}
    >
      <Grid container spacing="8">
        <Grid container item xs={12} spacing="8" sx={{alignContent: 'baseline'}}>
          {!!loader && <Grid item xs={12}>
            <CircularProgress />
          </Grid>}

          <Grid container item xs={6}>
            
            {!!selectedUser && <Grid item xs={12}>
              <Box sx={{
                // width: '100%',
                padding: 1,
                border: '1px solid transparent',
                borderColor: theme.palette.primary.main,
                backgroundColor: '#98B6B4',
                marginBottom: 0.5,
                position: 'relative'
              }}>
                <Typography sx={{fontWeight: 'bold'}}>{selectedUser.first_name} {selectedUser.last_name} ({selectedUser.vennoot_number})</Typography>
                <Typography>{selectedUser.street} {selectedUser.number}, {selectedUser.zip} {selectedUser.city}</Typography>
                <LinkOffIcon sx={{position: 'absolute', top: 8, right: 8, cursor: 'pointer', color: theme.palette.secondary.main}} onClick={() => {setSelectedUser(null); if (!!deathDetail) setDeathDetail({...deathDetail, user_id: undefined});}} />
              </Box>
            </Grid>}
            {!selectedUser && <Grid item xs={12} sx={{paddingRight: 0.5, backgroundColor: '#98B6B4', marginLeft: 0.5}}>
              <UserSearch
                initialValues={{birth_date: deathDetail?.birth_date}}
                customUserLayout={["padding", "padding", "padding", "birthdate"]}
                fields={['id', 'first_name', 'last_name', 'vennoot_number', 'birth_date', 'open_stock_transfer_count', 'transactions|last|transaction_date', 'locale']}
                resultLayout={(user) => <Box sx={{
                  padding: 1,
                  cursor: 'pointer',
                  border: '1px solid transparent',
                  borderColor: theme.palette.primary.main,
                  marginBottom: 0.5
                }} onClick={() => {
                  setSelectedUser(user);
                  if (!!deathDetail) setDeathDetail({...deathDetail, user_id: user.id, extra_data: {...(deathDetail?.extra_data || {}), locale: user.locale}});
                }}>
                  <Typography sx={{fontWeight: 'bold'}}>{user.first_name} {user.last_name}</Typography>
                  <Typography>{user.street} {user.number}, {user.zip} {user.city}</Typography>
                </Box>}
              />
            </Grid>}

            {!selectedUser && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.exit,
                marginTop: 2
              }}
              title="Override certificate"
              wide
            >
              <Grid item xs={12}>
                <Typography sx={{fontSize: '0.75rem'}}>Bijlage</Typography>
                <StyledDropzone
                  acceptedFiles={{
                    'image/*': [],
                    'application/pdf': []
                  }}
                  onDrop={acceptedFiles => {setItem(acceptedFiles[0]);}}
                  multiple={false}
                  maxFiles={1}
                  maxSize={3 * 1000 * 1000}
                  items={!!item ? [item] : []}
                  dropText="Click here, or drop some files."
                  dropHint="Allowed: 1 image (.png, .jpeg, .jpg, .gif) or PDF (.pdf), < 3mb"
                />
                {!!deathDetail?.item_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {deathDetail?.item_filename} ({(parseInt(deathDetail?.item_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>}

            {!selectedUser && <Grid item xs={12} sx={{marginTop: 2}}>
              <Divider/><br/>
              <StyledButton
                label="Mark as closed"
                secondary
                id='close'
                contained
                onClick={(v) => {
                  // if (!!deathDetail) setDeathDetail({...deathDetail, status: 3});
                  setTimeout(() => {
                    saveDeath(3);
                  }, 1000);
                }}
              />
              {!!item && <StyledButton
                label="Save (only file)"
                id='save_file'
                contained
                sx={{marginLeft: 2}}
                onClick={(v) => {
                  saveFile();
                }}
              />}
            </Grid>}

            <Grid container item xs={12}>
              <Grid item xs={12} sx={{marginTop: 2}}>
                <UserDetailBase
                  sx={{
                    backgroundColor: theme.palette.profile_blox?.guardian
                  }}
                  title={`Reporter: <span style={{fontWeight: 'bold'}}>${['Heir', 'Office', 'Notary', 'Other'][deathDetail?.reporter_data?.mode || 0]}</span>`}
                  wide
                  adornment={<>
                    {!reporterVisible && <ExpandMoreIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={() => {
                      setReporterVisible(!reporterVisible);
                    }} />}
                    {!!reporterVisible && <ExpandLessIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={() => {
                      setReporterVisible(!reporterVisible);
                    }} />}
                  </>}
                >
                  <Grid item xs={12}>
                    {!!reporterVisible && <React.Fragment>
                      {((deathDetail?.reporter_data?.mode || 0) === 0 || (deathDetail?.reporter_data?.mode || 0) === 3) && <Grid container item xs={12}>
                        <DetailLine name="First name" value={deathDetail?.reporter_data?.first_name || ''} />
                        <DetailLine name="Last name" value={deathDetail?.reporter_data?.last_name || ''} />
                        <DetailLine name="Phone" value={deathDetail?.reporter_data?.phone || ''} />
                        <DetailLine name="Mobile" value={deathDetail?.reporter_data?.mobile || ''} />
                        <DetailLine copyable name="Email" value={deathDetail?.email || ''} />
                        <DetailLine no_divider name="Address" value={!!deathDetail ? [deathDetail?.reporter_data?.street, deathDetail?.reporter_data?.number].join(" ") : ''} />
                        <DetailLine no_divider name="" value={!!deathDetail ? [deathDetail?.reporter_data?.zip, deathDetail?.reporter_data?.city].join(" ") : ''} />
                      </Grid>}
                      {(deathDetail?.reporter_data?.mode || 0) === 1 && <>
                        <DetailLine name="Employee" value={deathDetail?.reporter_data?.employee_name || ''} />
                        <DetailLine name="Office" value={deathDetail?.reporter_data?.office_number?.toString() || ''} />
                        <DetailLine name="Phone" value={deathDetail?.office_phone || ''} />
                        <DetailLine copyable no_divider name="Email" value={deathDetail?.office_email || ''} />
                      </>}
                      {(deathDetail?.reporter_data?.mode || 0) === 2 && <Grid container item xs={12}>
                        <DetailLine name="Employee" value={deathDetail?.reporter_data?.employee_name || ''} />
                        <DetailLine name="Office" value={deathDetail?.reporter_data?.office_name || ''} />
                        <DetailLine name="Zip" value={deathDetail?.reporter_data?.zip || ''} />
                        <DetailLine name="Phone" value={deathDetail?.reporter_data?.phone || ''} />
                        <DetailLine copyable name="Email" value={deathDetail?.email || ''} />
                      </Grid>}
                      <Grid container item xs={12} sx={{marginTop: 1}}>
                        <Grid item xs={12}>
                          <StyledTextField
                            label="E-mail"
                            value={deathDetail?.reporter_data?.email || ''}
                            id='email'
                            onChange={(v) => {if (!!deathDetail) setDeathDetail({
                              ...deathDetail,
                              reporter_data: {
                                ...(deathDetail?.reporter_data || {}),
                                email: v
                              }
                            });}}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment>}
                  </Grid>
                </UserDetailBase>
              </Grid>
            </Grid>

            {!!selectedUser && <Grid container item xs={12}>

              <Grid item xs={12} sx={{marginTop: 2}}>
                <UserDetailBase
                  sx={{
                    backgroundColor: theme.palette.profile_blox?.identity
                  }}
                  title="Deceased"
                  wide
                >
                  <Grid item xs={6}>
                    <StyledTextField
                      label="First name"
                      value={deathDetail?.user_first_name || selectedUser?.first_name || ''}
                      id='first_name'
                      onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, first_name: v});}}
                      fullWidth
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledTextField
                      label="Last name"
                      value={deathDetail?.user_last_name || selectedUser?.last_name || ''}
                      id='last_name'
                      onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, last_name: v});}}
                      fullWidth
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <StyledDateField
                      label="Birth date"
                      value={deathDetail?.birth_date || selectedUser?.birth_date || ''}
                      id='birth_date'
                      onChange={(v) => {}}
                      fullWidth
                      errorArray={errors}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <StyledTextField
                      label="Birth place"
                      value={deathDetail?.deceased_data?.birth_place || ''}
                      id='birth_place'
                      onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, deceased_data: {...(deathDetail?.deceased_data || {}), birth_place: v}});}}
                      fullWidth
                      capitalaction
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <StyledDateField
                      label="Death date"
                      value={deathDetail?.deceased_data?.death_date?.split("/").reverse().join("-") || ''}
                      id='death_date'
                      onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, deceased_data: {...(deathDetail?.deceased_data || {}), death_date: v.toString()}});}}
                      fullWidth
                      errorArray={errors}
                      sx={{
                        ...(!!selectedUser && !!deathDetail?.deceased_data && DateTime.fromISO(selectedUser?.birth_date) > DateTime.fromFormat(deathDetail?.deceased_data?.death_date, "dd/LL/yyyy") ? {
                          'input': {
                            padding: 1,
                            backgroundColor: 'red'
                          }
                        } : {})
                      }}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <StyledTextField
                      label="Death place"
                      value={deathDetail?.deceased_data?.death_place || ''}
                      id='death_place'
                      onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, deceased_data: {...(deathDetail?.deceased_data || {}), death_place: v}});}}
                      fullWidth
                      capitalaction
                      errorArray={errors}
                    />
                  </Grid>
                </UserDetailBase>
              </Grid>

              <Grid item xs={12} sx={{marginTop: 2}}>
                <UserDetailBase
                  sx={{
                    backgroundColor: theme.palette.profile_blox?.email_history
                  }}
                  title="Marital status"
                  wide
                >
                  <Grid item xs={4}>
                    <StyledButton
                      label="Not married"
                      id='married_0'
                      contained={(deathDetail?.deceased_data?.marital_status || '0') === '0'}
                      // secondary={(deathDetail?.deceased_data?.marital_status || '0') !== '0'}
                      onClick={(v) => {
                        if (!!deathDetail) setDeathDetail({...deathDetail, deceased_data: {...(deathDetail?.deceased_data || {}), marital_status: '0'}});
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <StyledButton
                      label="Married"
                      id='married_1'
                      contained={(deathDetail?.deceased_data?.marital_status || '0') === '1'}
                      // secondary={(deathDetail?.deceased_data?.marital_status || '0') !== '1'}
                      onClick={(v) => {
                        if (!!deathDetail) setDeathDetail({...deathDetail, deceased_data: {...(deathDetail?.deceased_data || {}), marital_status: '1'}});
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <StyledButton
                      label="Widowed"
                      id='married_2'
                      contained={(deathDetail?.deceased_data?.marital_status || '0') === '2'}
                      // secondary={(deathDetail?.deceased_data?.marital_status || '0') !== '2'}
                      onClick={(v) => {
                        if (!!deathDetail) setDeathDetail({...deathDetail, deceased_data: {...(deathDetail?.deceased_data || {}), marital_status: '2'}});
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <StyledSelect
                      label="Marital status"
                      value={deathDetail?.deceased_data?.marital_status || '0'}
                      id='marital_status'
                      onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, deceased_data: {...(deathDetail?.deceased_data || {}), marital_status: v}});}}
                      list={[
                        {id: '0', name: "Not married"},
                        {id: '1', name: "Married"},
                        {id: '2', name: "Widowed"}
                      ]}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <StyledTextField
                      label="Spouse name"
                      value={deathDetail?.deceased_data?.spouse_name || ''}
                      id='spouse_name'
                      onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, deceased_data: {...(deathDetail?.deceased_data || {}), spouse_name: v}});}}
                      fullWidth
                      errorArray={errors}
                    />
                  </Grid>
                  {!selectedSpouse && <Grid item xs={12} sx={{paddingRight: 0.5, backgroundColor: '#98B6B4', marginLeft: 0.5}}>
                    <UserSearch
                      initialValues={{street: selectedUser?.street, number: selectedUser?.number, zip: selectedUser?.zip}}
                      customUserLayout={["padding", "birthdate", "address"]}
                      fields={['id', 'first_name', 'last_name', 'vennoot_number', 'birth_date']}
                      resultLayout={(user) => <Box sx={{
                        padding: 1,
                        cursor: 'pointer',
                        border: '1px solid transparent',
                        borderColor: theme.palette.primary.main,
                        marginBottom: 0.5
                      }} onClick={() => {
                        if (user.id !== selectedUser.id) {
                          setSelectedSpouse(user);
                          if (!!deathDetail) setDeathDetail({...deathDetail, spouse_id: user.id, deceased_data: {...(deathDetail?.deceased_data || {}), spouse_name: [user.first_name, user.last_name].join(' ')}});
                        }
                      }}>
                        <Typography sx={{fontWeight: 'bold'}}>{user.first_name} {user.last_name} - {DateTime.fromISO(user.birth_date).toFormat("dd/LL/yyyy")}</Typography>
                        <Typography>{user.street} {user.number}, {user.zip} {user.city}</Typography>
                      </Box>}
                    />
                  </Grid>}
                  {!!selectedSpouse && <Grid item xs={12}>
                    <Box sx={{
                      // width: '100%',
                      padding: 1,
                      border: '1px solid transparent',
                      borderColor: theme.palette.primary.main,
                      backgroundColor: '#98B6B4',
                      marginBottom: 0.5,
                      position: 'relative'
                    }}>
                      <Typography sx={{fontWeight: 'bold'}}>{selectedSpouse.first_name} {selectedSpouse.last_name} ({selectedSpouse.vennoot_number}) - {DateTime.fromISO(selectedSpouse.birth_date).toFormat("dd/LL/yyyy")}</Typography>
                      <Typography>{selectedSpouse.street} {selectedSpouse.number}, {selectedSpouse.zip} {selectedSpouse.city}</Typography>
                      <LinkOffIcon sx={{position: 'absolute', top: 8, right: 8, cursor: 'pointer', color: theme.palette.secondary.main}} onClick={() => {setSelectedSpouse(null); if (!!deathDetail) setDeathDetail({...deathDetail, spouse_id: undefined, deceased_data: {...(deathDetail?.deceased_data || {}), spouse_name: ''}});}} />
                    </Box>
                  </Grid>}
                </UserDetailBase>
              </Grid>

              <Grid item xs={12} sx={{marginTop: 2}}>
                <UserDetailBase
                  sx={{
                    backgroundColor: theme.palette.profile_blox?.internal
                  }}
                  title="Notary"
                  wide
                >
                  <Grid item xs={6}>
                    <StyledButton
                      label="No notary"
                      id='notary_0'
                      contained={(deathDetail?.notary_data?.status?.toString() || '0') === '0'}
                      // secondary={(deathDetail?.notary_data?.status || '0') !== '0'}
                      onClick={(v) => {
                        if (!!deathDetail) setDeathDetail({...deathDetail, notary_data: {...(deathDetail?.notary_data || {}), status: '0'}});
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledButton
                      label="Notary provided"
                      id='notary_1'
                      contained={(deathDetail?.notary_data?.status?.toString() || '0') === '1'}
                      // secondary={(deathDetail?.notary_data?.status || '0') !== '1'}
                      onClick={(v) => {
                        if (!!deathDetail) setDeathDetail({...deathDetail, notary_data: {...(deathDetail?.notary_data || {}), status: '1'}});
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <StyledSelect
                      label="Notary status"
                      value={deathDetail?.notary_data?.status || '0'}
                      id='notary_status'
                      onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, notary_data: {...(deathDetail?.notary_data || {}), status: v}});}}
                      list={[
                        {id: '0', name: "None given"},
                        {id: '1', name: "Notary data provided"}
                      ]}
                    />
                  </Grid> */}
                  {deathDetail?.notary_data?.status?.toString() === '1' && <>
                    {!!selectedNotary && <Grid item xs={12} sx={{marginTop: 1}}>
                      <Box sx={{
                        // width: '100%',
                        padding: 1,
                        border: '1px solid transparent',
                        borderColor: theme.palette.primary.main,
                        backgroundColor: '#98B6B4',
                        marginBottom: 0.5,
                        position: 'relative'
                      }}>
                        <Typography sx={{fontStyle: 'italic', ...(selectedNotary.not_imported ? {textDecoration: 'line-through'} : {})}}>{selectedNotary.first_name} {selectedNotary.last_name} [{selectedNotary.number}]</Typography>
                        <Typography sx={{fontWeight: 'bold'}}>{selectedNotary.office_name} [{selectedNotary.office_number}]</Typography>
                        <Typography>{selectedNotary.street}, {selectedNotary.zip} {selectedNotary.city}</Typography>
                        <LinkOffIcon sx={{position: 'absolute', top: 8, right: 8, cursor: 'pointer', color: theme.palette.secondary.main}} onClick={() => {setSelectedNotary(null); if (!!deathDetail) setDeathDetail({...deathDetail, notary_id: undefined, notary_data: {...(deathDetail?.notary_data || {}), email: ''}});}} />
                      </Box>
                    </Grid>}
                    {!selectedNotary && <Grid item xs={12} sx={{paddingRight: 0.5, backgroundColor: '#98B6B4', marginLeft: 0.5, marginTop: 1}}>
                      <NotarySearch
                        initialValues={{catch_all: (deathDetail?.notary_data?.name || '')}}
                        fields={['id', 'office_name', 'office_number', 'email', 'replyto', 'street', 'number', 'zip', 'city', 'not_imported', 'first_name', 'last_name']}
                        resultLayout={(notary) => <Box sx={{
                          padding: 1,
                          cursor: 'pointer',
                          border: '1px solid transparent',
                          borderColor: theme.palette.primary.main,
                          marginBottom: 0.5
                        }} onClick={() => {
                          setSelectedNotary(notary);
                          if (!!deathDetail) setDeathDetail({...deathDetail, notary_id: notary.id, notary_data: {...(deathDetail?.notary_data || {}), email: (notary.replyto || notary.email), locale: notary.language}});
                        }}>
                          <Typography sx={{fontStyle: 'italic', ...(notary.not_imported ? {textDecoration: 'line-through'} : {})}}>{notary.first_name} {notary.last_name} [{notary.number}]</Typography>
                          <Typography sx={{fontWeight: 'bold'}}>{notary.office_name} [{notary.office_number}]</Typography>
                          <Typography>{notary.street}, {notary.zip} {notary.city}</Typography>
                        </Box>}
                      />
                    </Grid>}
                    {!selectedNotary && <Grid item xs={12}>
                      <StyledTextField
                        label="Name"
                        value={deathDetail?.notary_data?.name || ''}
                        id='notary_name'
                        onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, notary_data: {...(deathDetail?.notary_data || {}), name: v}});}}
                        fullWidth
                        errorArray={errors}
                      />
                    </Grid>}
                    <Grid item xs={12}>
                      <StyledTextField
                        label="Email"
                        value={deathDetail?.notary_data?.email || ''}
                        id='notary_email'
                        onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, notary_data: {...(deathDetail?.notary_data || {}), email: v}});}}
                        fullWidth
                        errorArray={errors}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <StyledButton
                        label="NL"
                        id='notary_nl'
                        contained={(deathDetail?.notary_data?.locale || 'nl') === 'nl'}
                        // secondary={(deathDetail?.notary_data?.locale || 'nl') !== 'nl'}
                        onClick={(v) => {
                          if (!!deathDetail) setDeathDetail({...deathDetail, notary_data: {...(deathDetail?.notary_data || {}), locale: 'nl'}});
                        }}
                        sx={{width: '100%'}}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <StyledButton
                        label="FR"
                        id='notary_fr'
                        contained={(deathDetail?.notary_data?.locale || 'nl') === 'fr'}
                        // secondary={(deathDetail?.notary_data?.locale || 'nl') !== 'fr'}
                        onClick={(v) => {
                          if (!!deathDetail) setDeathDetail({...deathDetail, notary_data: {...(deathDetail?.notary_data || {}), locale: 'fr'}});
                        }}
                        sx={{width: '100%'}}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <StyledSelect
                        label="Language"
                        value={deathDetail?.notary_data?.locale || 'nl'}
                        id='notary_locale'
                        onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, notary_data: {...(deathDetail?.notary_data || {}), locale: v}});}}
                        list={[
                          {id: 'nl', name: "NL"},
                          {id: 'fr', name: "FR"}
                        ]}
                      />
                    </Grid> */}
                  </>}
                </UserDetailBase>
              </Grid>

              {(deathDetail?.status || 0) < 2 && <Grid container item xs={12} sx={{marginTop: 2}}>
                {!!selectedUser?.open_stock_transfer_count && <Box sx={{
                  // width: '100%',
                  border: '1px solid transparent',
                  borderColor: theme.palette.secondary.main,
                  padding: 1,
                  borderRadius: 1,
                  backgroundColor: theme.palette.secondary.light,
                  marginBottom: 1
                }}>
                  <Typography>There are {selectedUser?.open_stock_transfer_count} open transfers for this user!</Typography>
                </Box>}

                {DateTime.fromISO(selectedUser.transactions_first_transaction_date) > DateTime.fromFormat(deathDetail?.deceased_data?.death_date, "dd/LL/yyyy") && <Box sx={{
                  // width: '100%',
                  border: '1px solid transparent',
                  borderColor: theme.palette.secondary.main,
                  padding: 1,
                  borderRadius: 1,
                  backgroundColor: theme.palette.secondary.light,
                  marginBottom: 1
                }}>
                  <Typography>There are existing transactions AFTER the death date!</Typography>
                </Box>}
                {DateTime.fromISO("2020-08-28") > DateTime.fromFormat(deathDetail?.deceased_data?.death_date, "dd/LL/yyyy") && <Box sx={{
                  // width: '100%',
                  border: '1px solid transparent',
                  borderColor: theme.palette.secondary.main,
                  padding: 1,
                  borderRadius: 1,
                  backgroundColor: theme.palette.secondary.light,
                  marginBottom: 1
                }}>
                  <Typography>This user died before the 2020 conversion!</Typography>
                  {!!selectedUser && <StyledButton
                    label="To User Detail"
                    id='userdetail1'
                    contained
                    onClick={(v) => {
                      window.open(`/user/${selectedUser.id}`, "_BLANK");
                    }}
                    startIcon={<LaunchIcon />}
                  />}
                </Box>}
              </Grid>}

              {(deathDetail?.original_status || 0) < 2 && <Grid container item xs={12} sx={{marginTop: 2}}>
                <UserDetailBase
                  sx={{
                    backgroundColor: theme.palette.profile_blox?.register
                  }}
                  title="Confirmables"
                  wide
                >
                  <Grid item xs={6}>
                    <Typography sx={{fontSize: '0.8rem', paddingTop: 1}}>Files in language</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <StyledButton
                      label="NL"
                      id='file_nl'
                      contained={(deathDetail?.extra_data?.locale || 'nl') === 'nl'}
                      // secondary={(deathDetail?.notary_data?.locale || 'nl') !== 'nl'}
                      onClick={(v) => {
                        if (!!deathDetail) setDeathDetail({...deathDetail, extra_data: {...(deathDetail?.extra_data || {}), locale: 'nl'}});
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StyledButton
                      label="FR"
                      id='file_fr'
                      contained={(deathDetail?.extra_data?.locale || 'nl') === 'fr'}
                      // secondary={(deathDetail?.notary_data?.locale || 'nl') !== 'fr'}
                      onClick={(v) => {
                        if (!!deathDetail) setDeathDetail({...deathDetail, extra_data: {...(deathDetail?.extra_data || {}), locale: 'fr'}});
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                  <Grid item xs={12}><Divider /></Grid>
                  <Grid item xs={12}>
                    <StyledSwitch
                      label="Finalisation"
                      offLabel="Do not finish"
                      onLabel="Complete"
                      value={deathDetail?.extra_data?.confirmable?.checked === '1'}
                      onChange={(v) => {if (!!deathDetail) setDeathDetail({
                        ...deathDetail,
                        status: !!v ? 2 : 1,
                        extra_data: {
                          ...(deathDetail.extra_data || {}),
                          checked: (!!v ? '1' : '0'),
                          confirmable: {
                            ...deathDetail.extra_data?.confirmable || {},
                            checked: (!!v ? '1' : '0')
                          }
                        }
                      });}}
                    />
                    <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem', color: 'grey'}}>Create a transaction, and disable the user if it results in having 0 stock.</Typography>
                  </Grid>
                  <Grid item xs={12}><Divider /></Grid>
                  <Grid item xs={6}>
                    <StyledSwitch
                      label="Add letter to download queue"
                      readonly={deathDetail?.extra_data?.confirmable?.checked === '0'}
                      offLabel="No"
                      onLabel="Yes"
                      value={deathDetail?.extra_data?.confirmable?.download === '1'}
                      onChange={(v) => {if (!!deathDetail) setDeathDetail({
                        ...deathDetail,
                        extra_data: {
                          ...(deathDetail.extra_data || {}),
                          confirmable: {
                            ...deathDetail.extra_data?.confirmable || {},
                            download: (!!v ? '1' : '0')
                          }
                        }
                      });}}
                    />
                    <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem', color: 'grey'}}>Letter version of list201 in the user's language.</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledSwitch
                      label="Add to PAIN (queued)"
                      readonly={deathDetail?.extra_data?.confirmable?.checked === '0'}
                      offLabel="No"
                      onLabel="Yes"
                      value={deathDetail?.extra_data?.confirmable?.pain === '1'}
                      onChange={(v) => {if (!!deathDetail) setDeathDetail({
                        ...deathDetail,
                        extra_data: {
                          ...(deathDetail.extra_data || {}),
                          confirmable: {
                            ...deathDetail.extra_data?.confirmable || {},
                            pain: (!!v ? '1' : '0')
                          }
                        }
                      });}}
                    />
                    <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem', color: 'grey'}}>99.9% of the cases this should be 'yes'.</Typography>
                  </Grid>
                  <Grid item xs={12}><Divider /></Grid>
                  <Grid item xs={6}>
                    <StyledSwitch
                      label="Add to FOD list (queued)"
                      readonly={deathDetail?.extra_data?.confirmable?.checked === '0'}
                      offLabel="No"
                      onLabel="Yes"
                      value={deathDetail?.extra_data?.confirmable?.fod === '1'}
                      onChange={(v) => {if (!!deathDetail) setDeathDetail({
                        ...deathDetail,
                        extra_data: {
                          ...(deathDetail.extra_data || {}),
                          confirmable: {
                            ...deathDetail.extra_data?.confirmable || {},
                            fod: (!!v ? '1' : '0')
                          }
                        }
                      });}}
                    />
                    <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem', color: 'grey'}}>Add to today's FOD list queue.</Typography>
                  </Grid>
                  <Grid item xs={12}><Divider /></Grid>
                  <Grid item xs={12}>
                    <StyledSwitch
                      label={`Send email to reporter (${deathDetail?.reporter_data?.email || '-'})`}
                      readonly={deathDetail?.extra_data?.confirmable?.checked === '0' || !deathDetail?.reporter_data?.email}
                      onLabel="Send"
                      offLabel="Do not send"
                      value={deathDetail?.extra_data?.confirmable?.notify_email === '1'}
                      onChange={(v) => {if (!!deathDetail) setDeathDetail({
                        ...deathDetail,
                        extra_data: {
                          ...(deathDetail.extra_data || {}),
                          confirmable: {
                            ...deathDetail.extra_data?.confirmable || {},
                            notify_email: (!!v ? '1' : '0')
                          }
                        }
                      });}}
                    />
                  </Grid>
                  <Grid item xs={12}><Divider /></Grid>
                  <Grid item xs={12}>
                    <StyledSwitch
                      label={`Send email to notary (${deathDetail?.notary_data?.email || '-'})`}
                      readonly={deathDetail?.extra_data?.confirmable?.checked === '0' || !deathDetail?.notary_data?.email}
                      onLabel="Send"
                      offLabel="Do not send"
                      value={deathDetail?.extra_data?.confirmable?.notary_email === '1'}
                      onChange={(v) => {if (!!deathDetail) setDeathDetail({
                        ...deathDetail,
                        extra_data: {
                          ...(deathDetail.extra_data || {}),
                          confirmable: {
                            ...deathDetail.extra_data?.confirmable || {},
                            notary_email: (!!v ? '1' : '0')
                          }
                        }
                      });}}
                    />
                  </Grid>
                </UserDetailBase>
              </Grid>}

              {(deathDetail?.original_status || 0) >= 2 && <Grid container item xs={12} sx={{marginTop: 2}}>
                <UserDetailBase
                  sx={{
                    backgroundColor: theme.palette.profile_blox?.register
                  }}
                  title="Confirmables"
                  wide
                >
                  <Grid item xs={6}>
                    <Typography sx={{fontSize: '0.8rem', paddingTop: 1}}>Files in language</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <StyledButton
                      label="NL"
                      id='file_nl'
                      contained={(deathDetail?.extra_data?.locale || 'nl') === 'nl'}
                      // secondary={(deathDetail?.notary_data?.locale || 'nl') !== 'nl'}
                      onClick={(v) => {
                        if (!!deathDetail) setDeathDetail({...deathDetail, extra_data: {...(deathDetail?.extra_data || {}), locale: 'nl'}});
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StyledButton
                      label="FR"
                      id='file_fr'
                      contained={(deathDetail?.extra_data?.locale || 'nl') === 'fr'}
                      // secondary={(deathDetail?.notary_data?.locale || 'nl') !== 'fr'}
                      onClick={(v) => {
                        if (!!deathDetail) setDeathDetail({...deathDetail, extra_data: {...(deathDetail?.extra_data || {}), locale: 'fr'}});
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                  <Grid item xs={12}><Divider /></Grid>
                  <Grid item xs={12} sx={{textAlign: 'center'}}>
                    <Typography sx={{fontSize: '0.8rem', color: theme.palette.primary.main, cursor: 'pointer', textDecoration: 'underline'}} onClick={(e) => {regenerateFiles();}}>Regenerate files</Typography>
                  </Grid>
                  <Grid item xs={12}><Divider /></Grid>
                  <Grid item xs={6}>
                    <Typography sx={{fontSize: '0.8rem'}}>Finalisation</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{fontSize: '0.8rem'}}>{deathDetail?.extra_data?.confirmable?.checked === '1' ? 'Completed' : 'Unfinished'}</Typography>
                  </Grid>
                  <Grid item xs={12}><Divider /></Grid>
                  <Grid item xs={6}>
                    <Typography sx={{fontSize: '0.8rem'}}>Add letter to download queue</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{fontSize: '0.8rem'}}>{deathDetail?.extra_data?.confirmable?.download === '1' ? 'Yes' : 'No'}</Typography>
                  </Grid>
                  {!!deathDetail?.extra_data?.confirmable?.download_updated_at && <Grid item xs={12}>
                    <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem', color: 'grey'}}>Last update - {DateTime.fromISO(deathDetail?.extra_data?.confirmable?.download_updated_at).toFormat("dd/LL/yyyy")}</Typography>
                  </Grid>}
                  <Grid item xs={12}><Divider /></Grid>
                  <Grid item xs={6}>
                    <Typography sx={{fontSize: '0.8rem'}}>Add to PAIN (queued)</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{fontSize: '0.8rem'}}>{deathDetail?.extra_data?.confirmable?.pain === '1' ? 'Yes' : 'No'}</Typography>
                  </Grid>
                  {!!deathDetail?.extra_data?.confirmable?.pain_updated_at && <Grid item xs={12}>
                    <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem', color: 'grey'}}>Last update - {DateTime.fromISO(deathDetail?.extra_data?.confirmable?.pain_updated_at).toFormat("dd/LL/yyyy")}</Typography>
                  </Grid>}
                  <Grid item xs={12}><Divider /></Grid>
                  <Grid item xs={6}>
                    <Typography sx={{fontSize: '0.8rem'}}>Add to FOD list (queued)</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{fontSize: '0.8rem'}}>{deathDetail?.extra_data?.confirmable?.fod === '1' ? 'Yes' : 'No'}</Typography>
                  </Grid>
                  {!!deathDetail?.extra_data?.confirmable?.fod_updated_at && <Grid item xs={12}>
                    <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem', color: 'grey'}}>Last update - {DateTime.fromISO(deathDetail?.extra_data?.confirmable?.fod_updated_at).toFormat("dd/LL/yyyy")}</Typography>
                  </Grid>}
                  <Grid item xs={12}><Divider /></Grid>
                  <Grid item xs={6}>
                    <Typography sx={{fontSize: '0.8rem'}}>Send email to reporter ({deathDetail?.reporter_data?.email || '-'})</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{fontSize: '0.8rem'}}>{deathDetail?.extra_data?.confirmable?.notify_email === '1' ? 'Sent' : 'DNA'}</Typography>
                  </Grid>
                  {!!deathDetail?.extra_data?.confirmable?.notify_email_updated_at && <Grid item xs={12}>
                    <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem', color: 'grey'}}>Last update - {DateTime.fromISO(deathDetail?.extra_data?.confirmable?.notify_email_updated_at).toFormat("dd/LL/yyyy")}</Typography>
                  </Grid>}
                  <Grid item xs={12}><Divider /></Grid>
                  <Grid item xs={6}>
                    <Typography sx={{fontSize: '0.8rem'}}>Send email to notary ({deathDetail?.notary_data?.email || '-'})</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{fontSize: '0.8rem'}}>{deathDetail?.extra_data?.confirmable?.notary_email === '1' ? 'Sent' : 'DNA'}</Typography>
                  </Grid>
                  {!!deathDetail?.extra_data?.confirmable?.notary_email_updated_at && <Grid item xs={12}>
                    <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem', color: 'grey'}}>Last update - {DateTime.fromISO(deathDetail?.extra_data?.confirmable?.notary_email_updated_at).toFormat("dd/LL/yyyy")}</Typography>
                  </Grid>}
                </UserDetailBase>
              </Grid>}

              {!!deathDetail?.emission_doc_get_lijst201 && <Grid container item xs={12} sx={{marginTop: 2}}>
                <UserDetailBase
                  sx={{
                    backgroundColor: theme.palette.profile_blox?.exit
                  }}
                  title="List 201"
                  wide
                  adornment={!!deathDetail && !!deathDetail.emission_doc_get_lijst201 && <>
                    <StyledIconButton
                      path={deathDetail.emission_doc_get_lijst201}
                      newTab
                      sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
                    ><LaunchIcon /></StyledIconButton>
                    {/* <LaunchIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                      e.preventDefault();
                      window.open(deathDetail.emission_doc_get_lijst201, "_BLANK");
                    }} /> */}
                  </>}
                  turnable={!!deathDetail.emission_doc_lijst201_blob_content_type && deathDetail.emission_doc_lijst201_blob_content_type.indexOf("image") > -1}
                >
                  <Grid item xs={12}>
                    {!!deathDetail.emission_doc_lijst201_blob_content_type && deathDetail.emission_doc_lijst201_blob_content_type.indexOf("image") > -1 && <img style={{maxWidth: '100%'}} src={deathDetail.emission_doc_get_lijst201} />}
                    {!!deathDetail.emission_doc_lijst201_blob_content_type && deathDetail.emission_doc_lijst201_blob_content_type.indexOf("pdf") > -1 && <iframe style={{width: '100%', height: '300px'}} src={deathDetail.emission_doc_get_lijst201} />}
                  </Grid>
                  <Grid item xs={12}>
                    {!!deathDetail?.emission_doc_lijst201_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {deathDetail?.emission_doc_lijst201_filename} ({(parseInt(deathDetail?.emission_doc_lijst201_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
                  </Grid>
                </UserDetailBase>
              </Grid>}

              {!!deathDetail?.emission_doc_get_lijst201_kort && <Grid container item xs={12} sx={{marginTop: 2}}>
                <UserDetailBase
                  sx={{
                    backgroundColor: theme.palette.profile_blox?.exit
                  }}
                  title="List 201 (short)"
                  wide
                  adornment={!!deathDetail && !!deathDetail.emission_doc_get_lijst201_kort && <>
                    <StyledIconButton
                      path={deathDetail.emission_doc_get_lijst201_kort}
                      newTab
                      sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
                    ><LaunchIcon /></StyledIconButton>
                    {/* <LaunchIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                      e.preventDefault();
                      window.open(deathDetail.emission_doc_get_lijst201_kort, "_BLANK");
                    }} /> */}
                  </>}
                  turnable={!!deathDetail.emission_doc_lijst201_kort_blob_content_type && deathDetail.emission_doc_lijst201_kort_blob_content_type.indexOf("image") > -1}
                >
                  <Grid item xs={12}>
                    {!!deathDetail.emission_doc_lijst201_kort_blob_content_type && deathDetail.emission_doc_lijst201_kort_blob_content_type.indexOf("image") > -1 && <img style={{maxWidth: '100%'}} src={deathDetail.emission_doc_get_lijst201_kort} />}
                    {!!deathDetail.emission_doc_lijst201_kort_blob_content_type && deathDetail.emission_doc_lijst201_kort_blob_content_type.indexOf("pdf") > -1 && <iframe style={{width: '100%', height: '300px'}} src={deathDetail.emission_doc_get_lijst201_kort} />}
                  </Grid>
                  <Grid item xs={12}>
                    {!!deathDetail?.emission_doc_lijst201_kort_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {deathDetail?.emission_doc_lijst201_kort_filename} ({(parseInt(deathDetail?.emission_doc_lijst201_kort_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
                  </Grid>
                </UserDetailBase>
              </Grid>}

              {!!deathDetail?.emission_doc_get_lijst201_fod && <Grid container item xs={12} sx={{marginTop: 2}}>
                <UserDetailBase
                  sx={{
                    backgroundColor: theme.palette.profile_blox?.exit
                  }}
                  title="List 201 (FOD)"
                  wide
                  adornment={!!deathDetail && !!deathDetail.emission_doc_get_lijst201_fod && <>
                    <StyledIconButton
                      path={deathDetail.emission_doc_get_lijst201_fod}
                      newTab
                      sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
                    ><LaunchIcon /></StyledIconButton>
                    {/* <LaunchIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                      e.preventDefault();
                      window.open(deathDetail.emission_doc_get_lijst201_fod, "_BLANK");
                    }} /> */}
                  </>}
                  turnable={!!deathDetail.emission_doc_lijst201_fod_blob_content_type && deathDetail.emission_doc_lijst201_fod_blob_content_type.indexOf("image") > -1}
                >
                  <Grid item xs={12}>
                    {!!deathDetail.emission_doc_lijst201_fod_blob_content_type && deathDetail.emission_doc_lijst201_fod_blob_content_type.indexOf("image") > -1 && <img style={{maxWidth: '100%'}} src={deathDetail.emission_doc_get_lijst201_fod} />}
                    {!!deathDetail.emission_doc_lijst201_fod_blob_content_type && deathDetail.emission_doc_lijst201_fod_blob_content_type.indexOf("pdf") > -1 && <iframe style={{width: '100%', height: '300px'}} src={deathDetail.emission_doc_get_lijst201_fod} />}
                  </Grid>
                  <Grid item xs={12}>
                    {!!deathDetail?.emission_doc_lijst201_fod_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {deathDetail?.emission_doc_lijst201_fod_filename} ({(parseInt(deathDetail?.emission_doc_lijst201_fod_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
                  </Grid>
                </UserDetailBase>
              </Grid>}

              <Grid item xs={12} sx={{marginTop: 2}}>
                {!!saveLoader && <CircularProgress />}
                {!saveLoader && <StyledButton
                  label="Save (no file)"
                  id='save'
                  contained
                  onClick={(v) => {
                    saveDeath();
                  }}
                />}
                {!saveLoader && <StyledIconButton destroyBtn color="secondary" sx={{marginLeft: 2}} onClick={(e) => {
                  onRemove();
                }}>
                  <DeleteForeverIcon />
                </StyledIconButton>}
              </Grid>
              
            </Grid>}

          </Grid>
          <Grid item xs={6}>
            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.identity
              }}
              title="Certificate of Death"
              adornment={!!deathDetail && !!deathDetail.get_item && <>
                <StyledIconButton
                  path={deathDetail.get_item}
                  newTab
                  sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
                ><LaunchIcon /></StyledIconButton>
                {/* <LaunchIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                  e.preventDefault();
                  window.open(deathDetail.get_item, "_BLANK");
                }} /> */}
              </>}
              wide
              fixed
            >
              <Grid item xs={12} sx={{height: 'calc(100% - 50px)'}}>
                {!!deathDetail?.item_blob_content_type && deathDetail.item_blob_content_type.indexOf("image") > -1 && <img style={{maxWidth: '100%'}} src={deathDetail.get_item} />}
                {!!deathDetail?.item_blob_content_type && deathDetail.item_blob_content_type.indexOf("pdf") > -1 && <iframe style={{width: '100%', height: '100%'}} src={deathDetail.get_item} />}
              </Grid>
              <Grid item xs={12}>
                {!!deathDetail?.item_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {deathDetail?.item_filename} ({(parseInt(deathDetail?.item_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>
          </Grid>
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setExitSavedSnackOpen(b);}} />}
    </Page>
  );
}
