import React, { FunctionComponent } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useAtom } from 'jotai';

import {
  Box,
  CircularProgress
} from '@mui/material';

import { Typography } from './Typography';

type LanguageProgressProps = {
  value: number;
  name: string;
  sx: object;
};

export const LanguageProgress: FunctionComponent<LanguageProgressProps> = ({name, value, sx}) => {
  // const theme = useTheme();

  return (<React.Fragment>
    <Box sx={{ position: 'relative', display: 'inline-flex', ...sx }}>
      <CircularProgress variant="determinate" value={value} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption">{name}</Typography>
      </Box>
    </Box>
  </React.Fragment>);
}