import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { useParams } from 'react-router-dom';

import { fetch_all } from '../../lib/server_helper';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton,
  StyledSelect,
  Typography
} from '../../components/interface';

import { OfficeLog } from '../../models/OfficeLog';
import { loginAtom } from '../../lib/auth';

const { DateTime } = require("luxon");

type OfficeOfficeLogsProps = {};

export const OfficeOfficeLogs: FunctionComponent<OfficeOfficeLogsProps> = () => {
  // const navigate = useNavigate();
  const params = useParams();

  const login = useAtomValue(loginAtom);
  // const [login, setLogin] = useAtom(loginAtom);
  const [open, setOpen] = useState(false);
  const [objects, setObjects] = useState<OfficeLog[]>([]);
  const [openLog, setOpenLog] = useState<OfficeLog>();
  const [objectsCount, setObjectsCount] = useState<number>(0);
  const [accountId, setAccountId] = useState<string | undefined>(params.id);
  const [officeId, setOfficeId] = useState<string | undefined>(params.office_id);
  const [loader, setLoader] = useState<boolean>(true);
  // const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [logType, setLogType] = useState<string>();

  useEffect(() => {
    if (params.id !== accountId) setAccountId(params.id);
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (params.office_id !== officeId) setOfficeId(params.office_id);
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    onSearch('');
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, logType]);

  const onSearch = (s: string) => {
    setLoader(true);
    fetch_all<OfficeLog>('/nl/v3/objects/fetch_all', {
      object: 'office_log',
      fields: ['id', 'created_at', 'office_id', 'log_type', 'office_account|full_name', 'extra_data'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          office_id: officeId,
          office_account_id: accountId,
          log_type: logType
        }
      }
    }, (obs, complete_data) => {
      setObjects(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setObjectsCount(count);

      setLoader(false);
    }, (z) => {

    }, login);
  };

  const columns: GridColDef[] = [
    ...(!!accountId ? [] : [{ field: 'office_account_full_name', headerName: 'User', width: 250 }]),
    { field: 'log_type', headerName: 'Type', width: 160 },
    { field: 'created_at', headerName: 'Time', width: 200, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("f") },
    { field: 'extra_data', headerName: 'Result', width: 200, sortable: false, renderCell: params => <>
      <Typography sx={{fontSize: '0.8rem'}}>{params.row.extra_data.success.toString() || ''}</Typography>
    </> }
  ];

  return (
    <Page sx={{}} title="Office Logs" helper="office_logs" startEndCrumb={<StyledButton
      label="Back to offices"
      id='back'
      text
      startIcon={<KeyboardBackspaceIcon />}
      path={`/offices`}
    />}>
      <SearchBox
        simple={true}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
        memo="office_logs"
      />
      <Grid container>
        <Grid item xs={12}>
          <StyledSelect
            label="Type filter"
            value={logType || 'all'}
            id="type_filter"
            onChange={(v) => {setLogType(v !== 'all' ? v : undefined);}}
            list={[
              {id: 'all', name: "All"},
              {id: 'search', name: "search"},
              {id: 'find', name: "find"},
              {id: 'signin', name: "signin"},
              {id: 'change_request', name: "change_request"},
              {id: 'report_death', name: "report_death"},
              {id: 'account_creation', name: "account_creation"},
              {id: 'report_resign', name: "report_resign"},
              {id: 'subscribe_meeting', name: "subscribe_meeting"},
              {id: 'subscribe_meeting_confirm', name: "subscribe_meeting_confirm"},
              {id: 'account_reset', name: "account_reset"},
              {id: 'transfer_create', name: "transfer_create"},
              {id: 'find_transfer', name: "find_transfer"},
              {id: 'transfer_create_success', name: "transfer_create_success"},
              {id: 'transfer_update', name: "transfer_update"},
              {id: 'transfer_update_success', name: "transfer_update_success"},
              {id: 'transfer_create_fail', name: "transfer_create_fail"},
              {id: 'transfer_update_fail', name: "transfer_update_fail"}
            ]}
          />
        </Grid>
      </Grid>
      <DataGrid
        action_count={1}
        actions={(params) => {
          return (<>
            <StyledIconButton title="Check content" color="primary" onClick={() => {
              setOpenLog(params.row as OfficeLog);
              setOpen(true);
            }}>
              <OpenInFullIcon />
            </StyledIconButton>
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={objectsCount}
        objects={(objects || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!openLog && <Dialog
        open={open}
        onClose={() => {setOpen(false);}}
      >
        <DialogTitle>Details of OfficeLog</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Parameters:<br/>
            <pre>{JSON.stringify(openLog.extra_data?.parameters, null, 2)}</pre>
            <Divider />
            Result:<br/>
            <pre>{JSON.stringify(openLog.extra_data?.result, null, 2)}</pre>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setOpen(false);}}>Close</Button>
        </DialogActions>
      </Dialog>}
    </Page>
  );
}
