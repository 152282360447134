import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';

import { fetch_all } from '../../lib/server_helper';

import {
  Box,
  Divider
} from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NextPlanIcon from '@mui/icons-material/NextPlan';

import { StyledIconButton } from '../../components/interface/StyledIconButton';
import { Typography } from '../../components/interface/Typography';

import { CURRENT } from '../../models/BookYear';
import { Death } from '../../models/Death';
import { Exit } from '../../models/Exit';
import { loginAtom } from '../../lib/auth';

type DashboardBaseProps = {
  tile_type: 'statics' | 'exits' | 'deaths';
};

export const DashboardBase: FunctionComponent<DashboardBaseProps> = ({tile_type}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [login, setLogin] = useAtom(loginAtom);

  const [exits, setExits] = useState<Exit[]>([]);
  const [exitCount, setExitCount] = useState<number|undefined>(undefined);

  const [deaths, setDeaths] = useState<Death[]>([]);
  const [deathCount, setDeathCount] = useState<number|undefined>(undefined);

  useEffect(() => {
    if (!!login) {
      if (tile_type === 'exits') {
        fetch_all<Exit>('/nl/v3/objects/fetch_all', {
          object: 'exit',
          fields: ['id', 'updated_at', 'given_id', 'user|vennoot_number/first_name/last_name', 'last_handler', 'extra_data', 'get_item', 'get_identity_front'],
          per_page: 3,
          page: 0,
          order: 'updated_at DESC',
          filter: {
            search: '',
            advanced: {
              book_year_id: CURRENT,
              unchecked: 1
            }
          }
        }, (obs, complete_data) => {
          setExits(obs);
          let count:number = 0;
          if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
          setExitCount(count);
        }, (z) => {

        }, login);
      }
      if (tile_type === 'deaths') {
        fetch_all<Death>('/nl/v3/objects/fetch_all', {
          object: 'death',
          fields: ['id', 'updated_at', 'first_name', 'last_name'],
          per_page: 3,
          page: 0,
          order: 'updated_at DESC',
          filter: {
            search: '',
            advanced: {
              unchecked: 1
            }
          }
        }, (obs, complete_data) => {
          setDeaths(obs);
          let count:number = 0;
          console.log(complete_data, typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data);
          if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
          setDeathCount(count);
        }, (z) => {

        }, login);
      }
    }
  }, [login]);

  let tileColor = theme.palette.primary.main;
  let tileBgColor = theme.palette.opacitated?.main_02;

  let tile_title = 'XYZ';
  let tile_url = '/';
  let tile_new_url = '/';
  let actions = false;
  let content = false;
  let tile_content = <></>;

  if (tile_type === 'statics') {
    tile_title = 'Statics';
    tile_url = '/statics';
    tile_new_url = '/static/new';
    actions = true;
  }

  if (tile_type === 'exits') {
    tile_title = 'Exits';
    tile_url = '/exits';
    actions = true;
    content = true;
    tile_content = <>
      <Typography variant="body2"><span style={{fontWeight: 'bold'}}>{exitCount}</span> unchecked cases</Typography>
      {exits.map(eee => <Typography variant="body2">{eee.user_first_name} {eee.user_last_name}</Typography>)}
    </>;
  }

  if (tile_type === 'deaths') {
    tile_title = 'Deaths';
    tile_url = '/deaths';
    actions = true;
    content = true;
    tile_content = <>
      <Typography variant="body2"><span style={{fontWeight: 'bold'}}>{deathCount}</span> unchecked cases</Typography>
      {deaths.map(eee => <Typography variant="body2">{eee.first_name} {eee.last_name}</Typography>)}
    </>;
  }

  return (<Box sx={{
    border: '1px solid transparent',
    borderColor: tileColor,
    backgroundColor: tileBgColor,
    padding: 1,
    borderRadius: 1,
    position: 'relative',
  }}>
    <Typography variant="h4">{tile_title}</Typography>

    {content && <Divider sx={{marginTop: 1, marginBottom: 1}} />}
    {content && <Typography variant="body2">{tile_content}</Typography>}

    {!!actions && <Divider sx={{marginTop: 1, marginBottom: 1}} />}
    {!!actions && <Box sx={{
      textAlign: 'right',
      margin: -1
    }}>
      {tile_url !== "/" && <StyledIconButton title="Goto" color="primary" path={tile_url}><NextPlanIcon /></StyledIconButton>}
      {tile_new_url !== "/" && <StyledIconButton title="Create new" color="primary" path={tile_new_url}><AddCircleOutlineIcon /></StyledIconButton>}
    </Box>}
  </Box>);
}