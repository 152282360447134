import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

import {
  Box,
  Tooltip,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import LaunchIcon from '@mui/icons-material/Launch';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton,
  Typography
} from '../../components/interface';

import { Death } from '../../models/Death';
import { loginAtom } from '../../lib/auth';
import refresh_queues, { queueAtom } from '../../lib/queues';
import { CircularProgress } from '@mui/material';

const { DateTime } = require("luxon");

type ExitDeathsProps = {};

export const ExitDeaths: FunctionComponent<ExitDeathsProps> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);
  const [myQueues, setMyQueues] = useAtom(queueAtom);

  const [deaths, setDeaths] = useState<Death[]>([]);
  const [deathsCount, setDeathsCount] = useState<number>(0);
  const [filters, setFilters] = useState<{checked?: string[]}>();
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [handleLoader, setHandleLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("updated_at DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch('', filters);
  }, [page, order]);

  useEffect(() => {
    if (!!myQueues?.filter(x => x.type === "FoQueue::Death") && myQueues?.filter(x => x.type === "FoQueue::Death").length > 0) {
      onSearch('', filters);
    } else {
      if (!!handleLoader) {
        setHandleLoader(false);
        onSearch('', filters);
      }
    }
  }, [myQueues]);

  const onRemove = (id: number) => {
    setRemoveLoader(true);
    fetch_one<Death>('/nl/v3/objects/remove', {
      object: 'death',
      dnr: 1,
      ob: {
        id: id
      }
    }, (obs, complete_data) => {
      onSearch('', filters);
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onSearch = (s: string, filters?:{checked?: string[]}) => {
    // console.log(filters);
    setLoader(true);
    fetch_all<Death>('/nl/v3/objects/fetch_all', {
      object: 'death',
      fields: ['id', 'created_at', 'updated_at', 'first_name', 'last_name', 'extra_data', 'get_item', 'user|vennoot_number', 'status', 'is_confirmed', 'emission_doc|get_lijst201/get_lijst201_kort/get_lijst201_fod', 'death_message_id'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          ...(!filters?.checked ? {unchecked: 1} : (filters?.checked?.length === 1 ? (filters?.checked[0] === '0' ? {unchecked: 1} : {checked: 1}) : {})),
        }
      }
    }, (obs, complete_data) => {
      setDeaths(obs);
      setFilters(filters);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setDeathsCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const handleReports = () => {
    if (!!login) {
      setHandleLoader(true);
      fetch_one<Death>('/nl/v3/objects/custom_action', {
        object: 'death',
        class_action: 'custom_api_handle_all',
        handler_id: login.id
      }, (obs, complete_data) => {
        refresh_queues(login, (res) => {
          if (!!res) setMyQueues(res);
        });
        onSearch('', filters);

        // if (!!obs && !!obs?.custom_result && !!obs.custom_result?.downloadable) window.open(obs.custom_result?.downloadable, "_BLANK");
        setHandleLoader(false);
      }, (z) => {
        // setServerErrorSnackOpen(true);
        setHandleLoader(false);
        onSearch('', filters);
      }, login);
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80, sortable: true },
    { field: 'death_message_id', headerName: 'DM ID', width: 80, sortable: true },
    { field: 'created_at', headerName: 'Created', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'updated_at', headerName: 'Updated', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'first_name', headerName: 'First name', width: 100, sortable: true },
    { field: 'last_name', headerName: 'Last name', width: 150, sortable: true },
    { field: 'linked', headerName: 'Vennoot Link', width: 160, sortable: false },
    { field: 'handler', headerName: 'Handler', width: 200, sortable: false, renderCell: params => <>
      {!!params.row.handler && <>
        <Typography sx={{fontSize: '0.9rem'}}>
          {params.row.handler[1]}<br/>
          <Box sx={{fontSize: '0.8rem', fontStyle: 'italic'}}>{DateTime.fromISO(params.row.handler[0]).toFormat("D")}</Box>
        </Typography>
      </>}
    </> },
    { field: 'files', headerName: 'Files', width: 80, sortable: false, renderCell: params => <>
      {params.row.extra_data.checked === '1' && !!params.row.emission_doc_get_lijst201 && <Tooltip title="Lijst 201"><CheckIcon sx={{color: theme.palette.tertiary?.main}} /></Tooltip>}
      {params.row.extra_data.checked === '1' && !params.row.emission_doc_get_lijst201 && <Tooltip title="Lijst 201"><CloseIcon sx={{color: theme.palette.secondary.main}} /></Tooltip>}
      {params.row.extra_data.checked === '1' && !!params.row.emission_doc_get_lijst201_kort && <Tooltip title="Lijst 201 KORT"><CheckIcon sx={{color: theme.palette.tertiary?.main}} /></Tooltip>}
      {params.row.extra_data.checked === '1' && !params.row.emission_doc_get_lijst201_kort && <Tooltip title="Lijst 201 KORT"><CloseIcon sx={{color: theme.palette.secondary.main}} /></Tooltip>}
      {params.row.extra_data.checked === '1' && !!params.row.emission_doc_get_lijst201_fod && <Tooltip title="Lijst 201 FOD"><CheckIcon sx={{color: theme.palette.tertiary?.main}} /></Tooltip>}
      {params.row.extra_data.checked === '1' && !params.row.emission_doc_get_lijst201_fod && <Tooltip title="Lijst 201 FOD"><CloseIcon sx={{color: theme.palette.secondary.main}} /></Tooltip>}
    </> },
    { field: 'conf_download', headerName: 'Down', width: 40, sortable: false, renderCell: params => <>
      {params.row.extra_data.checked === '1' && !!params.row.conf_download && <CheckIcon sx={{color: theme.palette.tertiary?.main}} />}
      {params.row.extra_data.checked === '1' && !params.row.conf_download && <CloseIcon sx={{color: theme.palette.secondary.main}} />}
    </> },
    { field: 'conf_pain', headerName: 'Pain', width: 40, sortable: false, renderCell: params => <>
      {params.row.extra_data.checked === '1' && !!params.row.conf_pain && <CheckIcon sx={{color: theme.palette.tertiary?.main}} />}
      {params.row.extra_data.checked === '1' && !params.row.conf_pain && <CloseIcon sx={{color: theme.palette.secondary.main}} />}
    </> },
    { field: 'conf_fod', headerName: 'FOD', width: 40, sortable: false, renderCell: params => <>
      {params.row.extra_data.checked === '1' && !!params.row.conf_fod && <CheckIcon sx={{color: theme.palette.tertiary?.main}} />}
      {params.row.extra_data.checked === '1' && !params.row.conf_fod && <CloseIcon sx={{color: theme.palette.secondary.main}} />}
    </> },
    { field: 'conf_notify_mail', headerName: 'Rep', width: 40, sortable: false, renderCell: params => <>
      {params.row.extra_data.checked === '1' && !!params.row.conf_notify_mail && <CheckIcon sx={{color: theme.palette.tertiary?.main}} />}
      {params.row.extra_data.checked === '1' && !params.row.conf_notify_mail && <CloseIcon sx={{color: theme.palette.secondary.main}} />}
    </> },
    { field: 'conf_notary_mail', headerName: 'Not', width: 40, sortable: false, renderCell: params => <>
      {params.row.extra_data.checked === '1' && !!params.row.conf_notary_mail && <CheckIcon sx={{color: theme.palette.tertiary?.main}} />}
      {params.row.extra_data.checked === '1' && !params.row.conf_notary_mail && <CloseIcon sx={{color: theme.palette.secondary.main}} />}
    </> }
  ];

  return (
    <Page sx={{}} title="Deaths" helper="deaths" actions={<>
      {!handleLoader && !!myQueues?.filter(x => x.type === "FoQueue::Death") && myQueues?.filter(x => x.type === "FoQueue::Death").length > 0 && <StyledButton
        label={`Handle ${myQueues?.filter(x => x.type === "FoQueue::Death")?.length}`}
        id='handle_reports'
        contained
        sx={{marginRight: 3}}
        startIcon={<HourglassBottomIcon />}
        onClick={() => {
          handleReports();
        }}
      />}
      {!!handleLoader && <StyledButton
        label="Handling..."
        id='handling_reports'
        contained
        disabled
        sx={{marginRight: 3}}
        startIcon={<CircularProgress sx={{width: '20px !important', height: '20px !important'}} />}
        onClick={() => {}}
      />}
      {/* Open all knop */}
      {(!filters?.checked || (filters?.checked?.length === 1 && filters?.checked[0] === '0')) && deaths.length > 0 && <StyledButton
        label="Open all"
        id='open_all'
        contained
        sx={{marginRight: 3}}
        startIcon={<LaunchIcon />}
        onClick={() => {
          deaths.map(d => {
            window.open(`/death/${d.id}`, "_BLANK");
          })
        }}
      />}
    </>}>
      <SearchBox
        deathSearch={(s, x) => {
          setPage(0);
          onSearch(s, x);
        }}
        collapsable
        memo="deaths"
      />
      <DataGrid
        action_count={2}
        actions={(params) => {
          return (<>
            <StyledIconButton color="primary" path={`/death/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
            {!removeLoader && !params.row.is_confirmed && <StyledIconButton destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={deathsCount}
        objects={(deaths || []).map(u => ({
          ...u,
          vennoot: `${u.first_name} ${u.last_name}`,
          linked: u.user_vennoot_number,
          conf_download: (u.extra_data?.confirmable?.download === '1'),
          conf_pain: (u.extra_data?.confirmable?.pain === '1'),
          conf_fod: (u.extra_data?.confirmable?.fod === '1'),
          conf_notify_mail: (u.extra_data?.confirmable?.notify_email === '1'),
          conf_notary_mail: (u.extra_data?.confirmable?.notary_email === '1'),
          handler: u.extra_data?.handlers?.reverse()[0],
          enabled: u.status !== 3
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
