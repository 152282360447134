import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';

import { fetch_all, fetch_one } from '../../lib/server_helper';

import {
  Box,
  Divider,
  Grid
} from '@mui/material';

import {
  StyledButton,
  StyledTextField,
  Typography
} from '../interface';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import { CustomStringResult } from '../../models/CustomStringResult';
import { Note } from '../../models/Note';
import { loginAtom } from '../../lib/auth';

const { DateTime } = require("luxon");

type NoteModuleProps = {
  handled_id: string | number;
  handled_type: string;
  user_id: string | number;
  hidden?: boolean;
  refreshNotes: boolean;
  setRefreshNotes: (b:boolean) => void;
  hideTitle?: boolean;
};

export const NoteModule: FunctionComponent<NoteModuleProps> = ({hideTitle, refreshNotes, setRefreshNotes, hidden, handled_id, handled_type, user_id}) => {
  const theme = useTheme();

  const [login, setLogin] = useAtom(loginAtom);

  const [loader, setLoader] = useState<boolean>(true);
  const [notes, setNotes] = useState<Note[]>([]);
  const [comment, setComment] = useState<string>('');

  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  const fetchNotes = () => {
    setLoader(true);
    
    fetch_all<Note>('/nl/v3/objects/fetch_all', {
      object: 'note',
      fields: ['id', 'comment', 'created_at', 'admin_name'],
      per_page: 100,
      page: 0,
      order: "created_at ASC",
      ignore_selection_override: 1,
      filter: {
        advanced: {
          handled_id: handled_id,
          handled_type: handled_type
        }
      }
    }, (obs, complete_data) => {
      setNotes(obs.reverse());
      setLoader(false);
      setRefreshNotes(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login)
  };

  const saveNote = () => {
    fetch_one<CustomStringResult>('/nl/v3/objects/custom_action', {
      object: 'note',
      class_action: 'custom_api_create',
      user_id: user_id,
      handled_id: handled_id,
      handled_type: handled_type,
      comment: comment,
      handler_id: login?.id
    }, (ob, complete_data) => {
      setComment('');
      fetchNotes();
    }, (z, y) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  useEffect(() => {
    if (!!refreshNotes) fetchNotes();
  }, [refreshNotes]);

  return (<React.Fragment>
    {!hidden && <Grid item xs={12} sx={{marginTop: 4}}>
      <Box sx={{
        border: '1px solid transparent',
        // borderColor: theme.palette.primary.main,
        borderRadius: 1,
        padding: 1
      }}>
        <Grid container spacing="8">
          {!hideTitle && <Grid item xs={12}>
            <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Comments</Typography>
          </Grid>}

          {notes.map(note => (<Grid item xs={12}>
            <Typography sx={{fontSize: '0.8rem'}}>{note.admin_name} <span style={{fontStyle: 'italic'}}>({DateTime.fromISO(note.created_at).toFormat("dd/LL/yyyy HH:mm:ss")})</span></Typography>
            <Typography html sx={{fontSize: '0.9rem', paddingLeft: 1}}>{note.comment.replace(/(?:\r\n|\r|\n)/g, "<br />")}</Typography>
            <Divider />
          </Grid>))}

          <Grid item xs={12}>
            <StyledTextField
              label="New note"
              value={comment || ''}
              id='comment'
              onChange={(v) => {setComment(v);}}
              fullWidth
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <StyledButton
              label="Save note"
              id='save_note'
              saveStartIcon
              contained
              onClick={(v) => {
                saveNote();
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>}

    {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
  </React.Fragment>);
}