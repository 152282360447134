import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box
} from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';
import EuroIcon from '@mui/icons-material/Euro';

import { UserNote } from '../../models/UserNote';

import { Typography } from '../interface/Typography';

const { DateTime } = require("luxon");

type TimelineDivCalcFiscalProps = {
  note: UserNote;
};

export const TimelineDivCalcFiscal: FunctionComponent<TimelineDivCalcFiscalProps> = ({note}) => {
  const theme = useTheme();

  return (<>
    <Box
      sx={{
        display: 'inline-block',
        marginRight: 1,
        paddingRight: 1,
        borderRight: '1px solid lightgrey'
      }}
    >
      <Typography sx={{
        color: 'grey',
        fontSize: 11
      }}><EuroIcon sx={{scale: '0.7', marginBottom: '-7px', border: '1px solid grey', borderRadius: '4px'}} /> {DateTime.fromISO(note.created_at).toFormat("DD TT")}</Typography>
    </Box>
    {!!note.get_fiscal_item && <Box
      sx={{
        display: 'inline-block',
        marginRight: 1,
        paddingRight: 1,
        borderRight: '1px solid lightgrey'
      }}
    >
      <Typography sx={{
        fontSize: 14
      }}><DownloadIcon
        sx={{scale: '0.7', marginBottom: '-7px', cursor: 'pointer'}}
        onClick={(e) => {e.preventDefault(); window.open(note.get_fiscal_item, "_BLANK");}}
      /></Typography>
    </Box>}
    <Box
      sx={{
        display: 'inline-block'
      }}
    >
      <Typography sx={{
        fontSize: 14
      }}>Fiscaal attest {!!note.dividend_book_year_start_year && note.dividend_book_year_start_year + 1}</Typography>
    </Box>
  </>);
}