import React, { FunctionComponent } from 'react';

import {
  Grid
} from '@mui/material';

import {
  Typography
} from '../../interface';

import { BenefitBlock } from '../../../models/BenefitBlock';
import { EmailBlock } from '../../../models/EmailBlock';

type BlockPublicTextCustomSalutationProps = {
  block: EmailBlock | BenefitBlock;
  marked: boolean;
  activeLocale: 'nl' | 'fr';
};

export const BlockPublicTextCustomSalutation: FunctionComponent<BlockPublicTextCustomSalutationProps> = ({block, marked, activeLocale}) => {
  return ( <Grid xs={12} sx={{
    marginTop: block.settings?.mtop || 0,
    marginBottom: block.settings?.mbot || 0,
    border: `1px solid ${block.settings?.border || 'transparent'}`,
    padding: '12px',
    ...(!!marked ? {backgroundColor: 'rgba(255, 255, 0, 0.3)'} : {})
  }}>
    <Typography html nw_text sx={{textAlign: 'left'}}>
      {activeLocale === 'nl' && <p>Ter attentie van meneer Jan Modaal,<br/>lidnummer B010666-12</p>}
      {activeLocale === 'fr' && <p>À l'attention de monsieur Martin Dupont,<br/>numéro d'associé B010666-12</p>}
    </Typography>
  </Grid>);
};
