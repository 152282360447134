import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

import {
  Avatar,
  CircularProgress,
  Divider,
  Grid,
  Popover,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import FolderIcon from '@mui/icons-material/Folder';
import HomeIcon from '@mui/icons-material/Home';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  Page,
  StyledButton,
  StyledDropzone,
  StyledIconButton,
  StyledSelect,
  StyledTextField,
  Typography
} from '../../components/interface';

import { Asset } from '../../models/Asset';
import { Folder } from '../../models/Folder';
import { loginAtom } from '../../lib/auth';

type ContentAssetsProps = {};

export const ContentAssets: FunctionComponent<ContentAssetsProps> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [assetsCount, setAssetsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [folders, setFolders] = useState<Folder[]>([]);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("item_file_name ASC");
  const [activeFolder, setActiveFolder] = useState<number>(0);
  const [currentFolder, setCurrentFolder] = useState<Folder>();
  const [changingFolder, setChangingFolder] = useState<Folder>();
  const [changingAsset, setChangingAsset] = useState<Asset>();
  const [folderTree, setFolderTree] = useState<[string, number][]>([]);
  const [item, setItem] = useState<File>();
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [popOpen, setPopOpen] = useState<boolean>(false);
  const [popContent, setPopContent] = useState<string>();

  // useEffect(() => {
  //   onSearch();
  // }, [page, order]);

  useEffect(() => {
    onFetchTree();
  }, []);

  useEffect(() => {
    onSearch();
    onSearchFolder();
    onSearchCurrentFolder();
  }, [activeFolder]);

  const onFetchTree = () => {
    fetch_one<Folder>('/nl/v3/objects/custom_action', {
      object: 'folder',
      fields: ['id'],
      class_action: 'custom_api_get_folder_tree',
      handler_id: login?.id
    }, (ob, complete_data) => {
      if (!!ob.custom_result) setFolderTree(ob.custom_result.tree);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const onRemoveAsset = (id: number) => {
    setRemoveLoader(true);
    fetch_one<Asset>('/nl/v3/objects/remove', {
      object: 'asset',
      ob: {
        id: id
      }
    }, (obs, complete_data) => {
      onSearch();
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onRemoveFolder = (id: number) => {
    setRemoveLoader(true);
    fetch_one<Folder>('/nl/v3/objects/remove', {
      object: 'folder',
      ob: {
        id: id
      }
    }, (obs, complete_data) => {
      onSearch();
      onSearchFolder();
      onSearchCurrentFolder();
      onFetchTree();
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onSearch = () => {
    setLoader(true);
    fetch_all<Asset>('/nl/v3/objects/fetch_all', {
      object: 'asset',
      fields: ['id', 'folder_id', 'get_item', 'item_file_name', 'v2_item_blob|content_type', 'v2_item|filename/byte_size'],
      per_page: 1000,
      page: page,
      order: order,
      filter: {
        search: '',
        advanced: {
          folder_id: (activeFolder === 0 ? '' : activeFolder)
        }
      }
    }, (obs, complete_data) => {
      setAssets(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setAssetsCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const onSearchFolder = () => {
    fetch_all<Folder>('/nl/v3/objects/fetch_all', {
      object: 'folder',
      fields: ['id', 'key', 'parent_id', 'type', 'children|count', 'assets|count', 'path'],
      per_page: 1000,
      page: 0,
      order: "tags.key ASC",
      filter: {
        search: '',
        advanced: {
          folder_id: (activeFolder === 0 ? '' : activeFolder)
        }
      }
    }, (obs, complete_data) => {
      setFolders(obs);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const onSearchCurrentFolder = () => {
    fetch_one<Folder>('/nl/v3/objects/fetch_all', {
      object: 'folder',
      fields: ['id', 'key', 'parent_id', 'type', 'get_crumb', 'path'],
      per_page: 1000,
      page: 0,
      order: "tags.key ASC",
      id: (activeFolder === 0 ? '' : activeFolder)
    }, (ob, complete_data) => {
      setCurrentFolder(ob);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const saveAsset = () => {
    setLoader(true);

    let formData = new FormData();
    formData.append('object', 'asset');
    formData.append('handler_id', (login?.id || "").toString());
    formData.append('fields[]', 'id');
    formData.append('fields[]', 'get_item');
    formData.append('fields[]', 'item_file_name');
    formData.append('ob[placeholder]', '1');
    if (!!changingAsset?.id) formData.append('ob[id]', changingAsset?.id.toString());
    if (!!changingAsset?.folder_id) formData.append('ob[folder_id]', changingAsset?.folder_id.toString());

    if (!!item) formData.append('item', item);

    fetch_one<Asset>('/nl/v3/objects/save', formData, (ob, complete_data) => {
      setChangingAsset(undefined);
      onSearch();
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const saveFolder = () => {
    setLoader(true);
    fetch_one<Folder>('/nl/v3/objects/save', {
      object: 'folder',
      fields: ['id', 'key', 'parent_id', 'type', 'children|count', 'assets|count', 'path'],
      id: changingFolder?.id,
      ob: {
        key: changingFolder?.key,
        parent_id: changingFolder?.parent_id === 0 ? '' : changingFolder?.parent_id,
        id: changingFolder?.id
      },
      handler_id: login?.id
    }, (ob, complete_data) => {
      setChangingFolder(undefined);
      onSearchFolder();
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  return (
    <Page sx={{}} title="Assets" helper="assets" old_url="https://old.argenco.be/assets" actions={<>
      <StyledButton
        label="Create asset"
        id='create_asset'
        contained
        sx={{marginRight: 3}}
        startIcon={<FilePresentIcon />}
        onClick={() => {
          let ob:Asset = {};
          setChangingAsset(ob);
        }}
      />
      <StyledButton
        label="Create folder"
        id='create_folder'
        contained
        sx={{marginRight: 3}}
        startIcon={<FolderIcon />}
        onClick={() => {
          let ob:Folder = {};
          setChangingFolder(ob);
        }}
      />
    </>}>
      {(!!changingFolder) && <Grid container spacing="4" sx={{marginBottom: 4}}>
        <Grid item xs={12} sx={{marginBottom: 2}}>
          <Typography variant="h3">Editing folder</Typography>
        </Grid>
        <Grid item xs={6}>
          <StyledTextField
            label="Folder name"
            value={changingFolder?.key || ''}
            id="name"
            onChange={(v) => {if (!!changingFolder) setChangingFolder({
              ...changingFolder,
              key: v});
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <StyledSelect
            label="Parent folder"
            value={changingFolder?.parent_id?.toString() || '0'}
            id='parent_id'
            onChange={(v) => {if (!!changingFolder) setChangingFolder({
              ...changingFolder,
              parent_id: parseInt(v, 10)
            });}}
            list={[
              {id: '0', name: '/'},
              ...folderTree.map(path => {return {id: path[1], name: path[0]};})
            ]}
          />
        </Grid>
        <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center'}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!!loader && <CircularProgress />}
          {!loader && <StyledButton
            label="Cancel"
            id='cancel'
            // contained
            onClick={(v) => {
              setChangingFolder(undefined);
            }}
            sx={{marginLeft: 1}}
          />}
          {!loader && <StyledButton
            label="Save"
            id='save'
            contained
            onClick={(v) => {
              saveFolder();
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>
      </Grid>}
      {(!!changingAsset) && <Grid container spacing="4" sx={{marginBottom: 4}}>
        <Grid item xs={12} sx={{marginBottom: 2}}>
          <Typography variant="h3">Editing asset</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{fontSize: '0.75rem'}}>Asset</Typography>
          <StyledDropzone
            acceptedFiles={{}}
            onDrop={acceptedFiles => {setItem(acceptedFiles[0]);}}
            multiple={false}
            maxFiles={1}
            maxSize={30 * 1000 * 1000}
            items={!!item ? [item] : []}
            dropText="Click here, or drop some files."
            dropHint="Allowed: 1 item < 30mb"
          />
          {!!changingAsset?.v2_item_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {changingAsset?.v2_item_filename} ({(parseInt(changingAsset?.v2_item_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
        </Grid>
        <Grid item xs={6}>
          <StyledSelect
            label="Folder"
            value={changingAsset?.folder_id?.toString() || activeFolder?.toString() || '0'}
            id='folder_id'
            onChange={(v) => {if (!!changingAsset) setChangingAsset({
              ...changingAsset,
              folder_id: parseInt(v, 10)
            });}}
            list={[
              {id: '0', name: '/'},
              ...folderTree.map(path => {return {id: path[1], name: path[0]};})
            ]}
          />
        </Grid>
        <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center'}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!!loader && <CircularProgress />}
          {!loader && <StyledButton
            label="Cancel"
            id='cancel'
            // contained
            onClick={(v) => {
              setChangingAsset(undefined);
            }}
            sx={{marginLeft: 1}}
          />}
          {!loader && <StyledButton
            label="Save"
            id='save'
            contained
            onClick={(v) => {
              saveAsset();
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>
      </Grid>}

      {!changingFolder && <>
        <Grid container spacing="4">
          <Grid item xs={12} sx={{marginBottom: 2}}>
            <Typography>Current folder: <HomeIcon style={{cursor: 'pointer', width: 20, height: 20, marginBottom: -4}} onClick={() => {
              setActiveFolder(0);
            }} />{!!currentFolder?.get_crumb && currentFolder.get_crumb.map(crumb => <>
              <DoubleArrowIcon sx={{
                width: 16, height: 16, color: 'lightgrey', marginLeft: 0.5, marginRight: 0.5, marginBottom: -0.5
              }} /><span style={{cursor: 'pointer'}} onClick={() => {
                setActiveFolder(crumb[0]);
              }}>{crumb[1]}</span>
            </>)}</Typography>
            <Divider />
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={1}></Grid>
            <Grid item xs={8}>Name</Grid>
            <Grid item xs={1} sx={{textAlign: 'right'}}>Subf.</Grid>
            <Grid item xs={1} sx={{textAlign: 'right'}}>Assets</Grid>
            <Grid item xs={1}></Grid>
          </Grid>

          {folders.map((folder, i) => <Grid container item xs={12} sx={{
            ...(i%2===0 ? {backgroundColor: theme.palette.opacitated?.main_01} : {}),
            cursor: 'pointer'
          }} onClick={() => {
            setActiveFolder(folder.id || 0);
          }}>
            <Grid item xs={1}>
              <Avatar sx={{width: 24, height: 24, backgroundColor: 'transparent', marginLeft: 'auto', marginRight: 2}}><FolderIcon color="primary" /></Avatar>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{fontWeight: 'bold'}}>{folder.key}</Typography>
            </Grid>
            <Grid item xs={1} sx={{textAlign: 'right'}}><Typography>{folder.children_count && folder.children_count > 0 ? folder.children_count : ''}</Typography></Grid>
            <Grid item xs={1} sx={{textAlign: 'right'}}><Typography>{folder.assets_count && folder.assets_count > 0 ? folder.assets_count : ''}</Typography></Grid>
            <Grid item xs={1} sx={{textAlign: 'right'}}>
              <StyledIconButton color="primary" sx={{padding: 0}} onClick={(e) => {
                e.stopPropagation();
                setChangingFolder(folder);
              }}>
                <VisibilityIcon />
              </StyledIconButton>
              {!!folder.id && (!folder.children_count || folder.children_count < 1) && <StyledIconButton color="primary" destroyBtn onClick={(e) => {
                e.stopPropagation();
                if (!!folder.id) onRemoveFolder(folder.id);
              }} sx={{
                padding: 0,
                marginLeft: 0.5
              }}>
                <DeleteForeverIcon />
              </StyledIconButton>}
              {!!folder.children_count && folder.children_count > 0 && <StyledIconButton title="Can't delete when there are subfolders" color="primary" onClick={(e) => {
                e.stopPropagation();
              }} sx={{
                padding: 0,
                marginLeft: 0.5,
                color: 'lightgrey'
              }}>
                <DeleteForeverIcon />
              </StyledIconButton>}
            </Grid>
          </Grid>)}
          {assets.map((asset, i) => <Grid container item xs={12} sx={{...(((folders.length + i)%2)===0 ? {backgroundColor: theme.palette.opacitated?.main_01} : {})}}>
            <Grid item xs={1} sx={{textAlign: 'right'}}>
              {asset.v2_item_blob_content_type && asset.v2_item_blob_content_type?.indexOf("image") > -1 && <Avatar onMouseEnter={(e) => {
                setPopOpen(true);
                setPopContent(asset.get_item);
              }} onMouseLeave={(e) => {
                setPopOpen(false);
                setPopContent('');
              }} sx={{width: 24, height: 24, backgroundColor: 'transparent', marginLeft: 'auto', marginRight: 1, display: 'inline-block', cursor: 'pointer'}}><CameraAltIcon color="primary" /></Avatar>}
              <Avatar sx={{width: 24, height: 24, backgroundColor: 'transparent', marginLeft: 'auto', marginRight: 2, display: 'inline-block'}}><FilePresentIcon color="primary" /></Avatar>
            </Grid>
            <Grid item xs={9}>
              <Typography sx={{}}>{asset.item_file_name}</Typography>
            </Grid>
            <Grid item xs={2} sx={{textAlign: 'right'}}>
              <StyledIconButton color="primary" sx={{padding: 0}} onClick={(e) => {
                e.stopPropagation();
                setChangingAsset(asset);
              }}>
                <VisibilityIcon />
              </StyledIconButton>
              <StyledIconButton color="primary" onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                navigator.clipboard.writeText(asset.get_item || "error occurred");
                setOpen(true);
              }} sx={{
                padding: 0,
                marginLeft: 0.5
              }}>
                <ContentCopyIcon />
              </StyledIconButton>
              <StyledIconButton color="primary" destroyBtn onClick={(e) => {
                e.stopPropagation();
                if (!!asset.id) onRemoveAsset(asset.id);
              }} sx={{
                padding: 0,
                marginLeft: 0.5
              }}>
                <DeleteForeverIcon />
              </StyledIconButton>
            </Grid>
          </Grid>)}
        </Grid>
      </>}

      <Popover
        open={popOpen}
        anchorReference={"none"}
        style={{ pointerEvents: 'none'}}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={popContent} style={{maxHeight: '80vh', maxWidth: '80vw'}} />
      </Popover>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}

      {!!open && <Snackbar
        open={open}
        onClose={() => {setOpen(false);}}
        autoHideDuration={2000}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">Copied!</MuiAlert>
      </Snackbar>}
    </Page>
  );
}
