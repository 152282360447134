import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';

import { fetch_all } from '../../lib/server_helper';

import {
  Badge,
  Box,
  Divider
} from '@mui/material';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import UndoIcon from '@mui/icons-material/Undo';

import { StyledIconButton } from './StyledIconButton';
import { Typography } from './Typography';

import { Help as HelpOb } from '../../models/Help';
import { loginAtom } from '../../lib/auth';

type PageProps = {
  sx?: object;
  actions?: React.ReactNode;
  children: React.ReactNode;
  startEndCrumb?: React.ReactNode;
  title?: string;
  helper?: string;
  old_url?: string;
};

export const Page: FunctionComponent<PageProps> = ({children, sx, title, helper, startEndCrumb, old_url, actions}) => {
  // const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);

  const [helps, setHelps] = useState<HelpOb[]>([]);

  useEffect(() => {
    if (!!helper) {
      onSearch();
    }
  }, [helper]);

  const onSearch = () => {
    if (!!helper) {
      fetch_all<HelpOb>('/nl/v3/objects/fetch_all', {
        object: 'help',
        fields: ['id', 'slug', 'user|first_name/last_name', 'content', 'created_at', 'weight'],
        filter:{
          advanced: {
            slug: helper
          }
        },
        order: "weight DESC, id DESC"
      }, (obs, complete_data) => {
        setHelps(obs);
      }, (z) => {
  
      }, login);
    }
  };

  return (<Box
    sx={{
      position: 'relative',
      ...sx
    }}>
      <Box sx={{ position: 'relative' }}>
        {!!title && <Typography variant="h1">{title}</Typography>}
        {!!title && <Divider sx={{marginBottom: 2}} />}
        <Box sx={{position: 'absolute', top: -4, right: 0, width: 'auto', textAlign: 'right', zIndex: 1000}}>
          {!!actions && actions}

          {!!old_url && <StyledIconButton title="Back to old website" color="primary" onClick={(e) => {
            window.location.href = old_url;
          }}>
            <UndoIcon />
          </StyledIconButton>}
          
          {!!helper && <StyledIconButton title="Helper module" color="primary" path={`/help/${helper}`}>
            <Badge badgeContent={helps.length} color="secondary" sx={{
              '& .MuiBadge-badge': {
                right: 4,
                top: 4
              },
              cursor: 'pointer'
            }}>
              <HelpOutlineIcon />
            </Badge>
          </StyledIconButton>}
        </Box>
      </Box>
      <Box>
        {!!startEndCrumb && <Box>{startEndCrumb}</Box>}
        
        {children}

        {!!startEndCrumb && <Box sx={{marginTop: 4}}>{startEndCrumb}</Box>}
      </Box>
    </Box>);
}