import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one } from '../../lib/server_helper';

import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import { loginAtom } from '../../lib/auth';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LaunchIcon from '@mui/icons-material/Launch';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertEmailNotSent } from '../../components/alerts/EmailNotSent';
import { AlertEmailSent } from '../../components/alerts/EmailSent';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import { BankAccount } from '../../components/interface/BankAccount';
import { DetailLine } from '../../components/interface/Detail/DetailLine';
import { NoteModule } from '../../components/interface/NoteModule';
import { Page } from '../../components/interface/Page';
import { StyledButton } from '../../components/interface/StyledButton';
import { StyledDateField } from '../../components/interface/StyledDateField';
import { StyledEditorNoTransl } from '../../components/interface/StyledEditorNoTransl';
import { StyledDropzone } from '../../components/interface/StyledDropzone';
import { StyledIconButton } from '../../components/interface/StyledIconButton';
import { StyledSelect } from '../../components/interface/StyledSelect';
import { StyledTextField } from '../../components/interface/StyledTextField';
import { Typography } from '../../components/interface/Typography';
import { UserDetailBase } from '../../components/user_detail/Base';
import { UserSearch } from '../../components/interface/UserSearch';

import { CustomStringResult } from '../../models/CustomStringResult';
import { StockTransfer } from '../../models/StockTransfer';
import { User } from '../../models/User';

import SunEditorCore from "suneditor/src/lib/core";

const { DateTime } = require("luxon");

type TransfersStockTransferDetailProps = {};

export const TransfersStockTransferDetail: FunctionComponent<TransfersStockTransferDetailProps> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['login'],
  //   queryFn: async () => {return },
  // });

  const params = useParams();
  const stockTransferId: string | undefined = params.id;

  const login = useAtomValue(loginAtom);
  // const [login, setLogin] = useAtom(loginAtom);
  const [oldStockTransferDetail, setOldStockTransferDetail] = useState<StockTransfer|null>(null);
  const [stockTransferDetail, setStockTransferDetail] = useState<StockTransfer|null>(stockTransferId === 'new' ? {} as StockTransfer : null);
  const [loader, setLoader] = useState<boolean>(stockTransferId !== 'new');
  const [editing, setEditing] = useState<boolean>(stockTransferId === 'new');
  const [createMail, setCreateMail] = useState<boolean>(false);
  const [fraudsHidden, setFraudsHidden] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [refreshNotes, setRefreshNotes] = useState<boolean>(true);

  const [linkedUser, setLinkedUser] = useState<User>();
  const [linkedNewUser, setLinkedNewUser] = useState<User>();

  const [sendToSender, setSendToSender] = useState<boolean>(true);
  const [sendToReceiver, setSendToReceiver] = useState<boolean>(false);

  const [userId, setUserId] = useState<File>();
  const [newUserId, setNewUserId] = useState<File>();
  const [voogdUserId, setVoogdUserId] = useState<File>();
  const [item, setItem] = useState<File>();

  const [purgeItem, setPurgeItem] = useState<boolean>(false);
  const [purgeIdentity, setPurgeIdentity] = useState<boolean>(false);
  const [purgeIdentityNew, setPurgeIdentityNew] = useState<boolean>(false);
  const [purgeIdentityVoogd, setPurgeIdentityVoogd] = useState<boolean>(false);

  const [checked, setChecked] = useState<boolean>((stockTransferDetail?.extra_data?.checked || "0") === '1');
  const [itemConfirmed, setItemConfirmed] = useState<number>(parseInt(stockTransferDetail?.extra_data?.item_confirmed || "0", 10) || 0);
  const [identityConfirmed, setIdentityConfirmed] = useState<number>(parseInt(stockTransferDetail?.extra_data?.id_user_confirmed || "0", 10) || 0);
  const [identityNewConfirmed, setIdentityNewConfirmed] = useState<number>(parseInt(stockTransferDetail?.extra_data?.id_new_user_confirmed || "0", 10) || 0);
  const [identityVoogdConfirmed, setIdentityVoogdConfirmed] = useState<number>(parseInt(stockTransferDetail?.extra_data?.id_voogd_confirmed || "0", 10) || 0);
  const [saveConfirm, setSaveConfirm] = useState<boolean>(false);

  const [errorIdUnclear, setErrorIdUnclear] = useState<boolean>(false);
  const [errorIdNewUnclear, setErrorIdNewUnclear] = useState<boolean>(false);
  const [errorItemUnclear, setErrorItemUnclear] = useState<boolean>(false);
  const [errorDocMissing, setErrorDocMissing] = useState<boolean>(false);
  const [errorDocMismatch, setErrorDocMismatch] = useState<boolean>(false);
  const [errorCancel, setErrorCancel] = useState<boolean>(false);
  const editor = useRef<SunEditorCore>();
  const [emailContent, setEmailContent] = useState<string>('');

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [stockTransferSavedSnackOpen, setStockTransferSavedSnackOpen] = useState<boolean>(false);
  const [regenBlankSnackOpen, setRegenBlankSnackOpen] = useState<boolean>(false);
  const [userSavedSnackOpen, setUserSavedSnackOpen] = useState<boolean>(false);
  const [errorMailSentOpen, setErrorMailSentOpen] = useState<boolean>(false);
  const [errorMailErrorOpen, setErrorMailErrorOpen] = useState<boolean>(false);

  const fields = [
    'id', 'given_id', 'created_at', 'updated_at', 'extra_data', 'verified', 'get_blank', 'get_item', 'get_id_user', 'get_id_new_user', 'get_id_voogd',
    'item_blob|content_type', 'blank_blob|content_type', 'item|filename/byte_size', 'blank|filename/byte_size', 'id_user_blob|content_type',
    'id_user|filename/byte_size', 'id_new_user_blob|content_type', 'id_new_user|filename/byte_size', 'id_voogd_blob|content_type', 'id_voogd|filename/byte_size',
    'user|id/first_name/last_name/vennoot_number/birth_date/stock_number/zicht_account/confirmed/locale',
    'user|email/mobile/phone/street/number/zip/city/cor_street/cor_number/cor_zip/cor_city',
    'existing_user|id/first_name/last_name/vennoot_number/birth_date/stock_number/zicht_account/confirmed/locale',
    'existing_user|email/mobile/phone/street/number/zip/city/cor_street/cor_number/cor_zip/cor_city/has_guardian/guardian',
    'new_user|id/first_name/last_name/vennoot_number/birth_date/stock_number/zicht_account/confirmed/locale',
    'new_user|email/mobile/phone/street/number/zip/city/cor_street/cor_number/cor_zip/cor_city',
    'id_user|filename/byte_size', 'book_year|visual', 'has_guardian', 'stock_number',
    'user|share_type|amount', 'other_open_amount', 'transaction_date',
    'user|transactions|first|stock_saldo_value', 'cancelled', 'status', 'gender', 'zicht_account', 'guardian_info', 'get_status',
    'first_name', 'last_name', 'birth_date', 'street', 'number', 'zip', 'city', 'phone', 'mobile', 'email', 'possible_frauds'
  ];

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  const fetchEmailContent = () => {
    fetch_one<CustomStringResult>('/nl/v3/objects/custom_action', {
      object: 'stock_transfer',
      class_action: 'custom_api_get_error_email',
      id: stockTransferId,
      reasons: [errorItemUnclear, errorIdUnclear, errorIdNewUnclear, errorDocMissing, errorDocMismatch, errorCancel],
      handler_id: login?.id
    }, (ob, complete_data) => {
      setEmailContent(ob.custom_result);
      editor.current?.setContents(ob.custom_result);
    }, (z, y) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const fetchStockTransfer = () => {
    if (stockTransferId !== 'new') {
      setLoader(true);

      fetch_one<StockTransfer>('/nl/v3/objects/fetch_all', {
        object: 'stock_transfer',
        fields: fields,
        id: stockTransferId
      }, (ob, complete_data) => {
        setStockTransferDetail(ob);
        if (!!ob.extra_data && !!ob.extra_data.errors) {
          if (ob.extra_data?.errors.indexOf(1) > -1) setErrorItemUnclear(true);
          if (ob.extra_data?.errors.indexOf(2) > -1) setErrorIdUnclear(true);
          if (ob.extra_data?.errors.indexOf(3) > -1) setErrorDocMissing(true);
          if (ob.extra_data?.errors.indexOf(13) > -1) setErrorDocMismatch(true);
          if (ob.extra_data?.errors.indexOf(16) > -1) setErrorCancel(true);
        }
        setLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, login);
    }
  };

  const onRemove = () => {
    fetch_one<StockTransfer>('/nl/v3/objects/remove', {
      object: 'stock_transfer',
      ob: {
        id: stockTransferId
      }
    }, (obs, complete_data) => {
      navigate('/stock_transfers');
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const regenBlank = () => {
    fetch_one<StockTransfer>('/nl/v3/objects/custom_action', {
      object: 'stock_transfer',
      fields: fields,
      id: stockTransferId,
      class_action: 'custom_api_regenerate_blank'
    }, (ob, complete_data) => {
      if (!!ob.custom_result) setStockTransferDetail(ob.custom_result);
      setUserId(undefined);
      setNewUserId(undefined);
      setVoogdUserId(undefined);
      setItem(undefined);
      setItem(undefined);
      setErrors([]);
      setEditing(false);
      setLoader(false);

      setRegenBlankSnackOpen(true);
    }, (z, y) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const saveUser = () => {
    // setErrors([]);
    // let errors:string[] = [];

    // if (typeof(stockTransferDetail?.user_first_name) === 'undefined' || stockTransferDetail?.user_first_name === null || stockTransferDetail?.user_first_name.trim() === "") errors.push('first_name');
    // if (typeof(stockTransferDetail?.user_last_name) === 'undefined' || stockTransferDetail?.user_last_name === null || stockTransferDetail?.user_last_name.trim() === "") errors.push('last_name');
    // if (typeof(stockTransferDetail?.user_birth_date) === 'undefined' || stockTransferDetail?.user_birth_date === null || stockTransferDetail?.user_birth_date.trim() === "") errors.push('birth_date');
    // if (typeof(stockTransferDetail?.user_email) === 'undefined' || stockTransferDetail?.user_email === null || stockTransferDetail?.user_email.trim() === "") errors.push('email');
    // if (typeof(stockTransferDetail?.user_street) === 'undefined' || stockTransferDetail?.user_street === null || stockTransferDetail?.user_street.trim() === "") errors.push('street');
    // if (typeof(stockTransferDetail?.user_number) === 'undefined' || stockTransferDetail?.user_number === null || stockTransferDetail?.user_number.trim() === "") errors.push('number');
    // if (typeof(stockTransferDetail?.user_zip) === 'undefined' || stockTransferDetail?.user_zip === null || stockTransferDetail?.user_zip.trim() === "") errors.push('zip');
    // if (typeof(stockTransferDetail?.user_city) === 'undefined' || stockTransferDetail?.user_city === null || stockTransferDetail?.user_city.trim() === "") errors.push('city');
    // if ((typeof(stockTransferDetail?.user_phone) === 'undefined' || stockTransferDetail?.user_phone === null || stockTransferDetail?.user_phone.trim() === "") && (typeof(stockTransferDetail?.user_mobile) === 'undefined' || stockTransferDetail?.user_mobile === null || stockTransferDetail?.user_mobile.trim() === "")) {
    //   errors.push('phone')
    //   errors.push('mobile')
    // };
    // if (typeof(stockTransferDetail?.user_zicht_account) === 'undefined' || stockTransferDetail?.user_zicht_account === null || stockTransferDetail?.user_zicht_account.trim() === "") errors.push('zicht_account');
    // // if (!!stockTransferDetail?.has_guardianship) {
    // //   if (typeof(stockTransferDetail?.guardian_first_name) === 'undefined' || stockTransferDetail?.guardian_first_name === null || stockTransferDetail?.guardian_first_name.trim() === "") errors.push('guardian_first_name');
    // //   if (typeof(stockTransferDetail?.guardian_last_name) === 'undefined' || stockTransferDetail?.guardian_last_name === null || stockTransferDetail?.guardian_last_name.trim() === "") errors.push('guardian_last_name');
    // //   if (typeof(stockTransferDetail?.guardian_email) === 'undefined' || stockTransferDetail?.guardian_email === null || stockTransferDetail?.guardian_email.trim() === "") errors.push('guardian_email');
    // //   if (typeof(stockTransferDetail?.guardian_birth_date) === 'undefined' || stockTransferDetail?.guardian_birth_date === null || stockTransferDetail?.guardian_birth_date.trim() === "") errors.push('guardian_birth_date');
    // //   if (typeof(stockTransferDetail?.guardian_street) === 'undefined' || stockTransferDetail?.guardian_street === null || stockTransferDetail?.guardian_street.trim() === "") errors.push('guardian_street');
    // //   if (typeof(stockTransferDetail?.guardian_number) === 'undefined' || stockTransferDetail?.guardian_number === null || stockTransferDetail?.guardian_number.trim() === "") errors.push('guardian_number');
    // //   if (typeof(stockTransferDetail?.guardian_zip) === 'undefined' || stockTransferDetail?.guardian_zip === null || stockTransferDetail?.guardian_zip.trim() === "") errors.push('guardian_zip');
    // //   if (typeof(stockTransferDetail?.guardian_city) === 'undefined' || stockTransferDetail?.guardian_city === null || stockTransferDetail?.guardian_city.trim() === "") errors.push('guardian_city');
    // //   if (typeof(stockTransferDetail?.guardian_phone) === 'undefined' || stockTransferDetail?.guardian_phone === null || stockTransferDetail?.guardian_phone.trim() === "") errors.push('guardian_phone');
    // // }
    
    // if (errors.length < 1) {
    //   setLoader(true);
  
    //   let formData = new FormData();
    //   formData.append('object', 'user');
    //   formData.append('handler_id', (login?.id || "").toString());
    //   formData.append('fields[]', 'id');
    //   if (!!stockTransferDetail?.user_id) formData.append('ob[id]', stockTransferDetail?.user_id.toString());

    //   formData.append('ob[first_name]', stockTransferDetail?.user_first_name || '');
    //   formData.append('ob[last_name]', stockTransferDetail?.user_last_name || '');
    //   formData.append('ob[birth_date]', stockTransferDetail?.user_birth_date || '');
      
    //   formData.append('ob[email]', stockTransferDetail?.user_email || '');
    //   formData.append('ob[street]', stockTransferDetail?.user_street || '');
    //   formData.append('ob[number]', stockTransferDetail?.user_number || '');
    //   formData.append('ob[zip]', stockTransferDetail?.user_zip || '');
    //   formData.append('ob[city]', stockTransferDetail?.user_city || '');
    //   formData.append('ob[phone]', stockTransferDetail?.user_phone || '');
    //   formData.append('ob[mobile]', stockTransferDetail?.user_mobile || '');
    //   formData.append('ob[cor_street]', stockTransferDetail?.user_cor_street || '');
    //   formData.append('ob[cor_number]', stockTransferDetail?.user_cor_number || '');
    //   formData.append('ob[cor_zip]', stockTransferDetail?.user_cor_zip || '');
    //   formData.append('ob[cor_city]', stockTransferDetail?.user_cor_city || '');

    //   // if (!!stockTransferDetail?.has_guardianship && !!guardianItem) formData.append('guardian[id_user]', guardianItem);

    //   formData.append('ob[zicht_account]', stockTransferDetail?.user_zicht_account || '');

    //   if (!!userId) formData.append('ob[id_user]', userId);

    //   // if (!!stockTransferDetail?.has_guardianship) {
    //   //   formData.append('guardian[first_name]', stockTransferDetail?.guardian_first_name || '');
    //   //   formData.append('guardian[last_name]', stockTransferDetail?.guardian_last_name || '');
    //   //   formData.append('guardian[email]', stockTransferDetail?.guardian_email || '');
    //   //   formData.append('guardian[birth_date]', stockTransferDetail?.guardian_birth_date || '');
    //   //   formData.append('guardian[street]', stockTransferDetail?.guardian_street || '');
    //   //   formData.append('guardian[number]', stockTransferDetail?.guardian_number || '');
    //   //   formData.append('guardian[zip]', stockTransferDetail?.guardian_zip || '');
    //   //   formData.append('guardian[city]', stockTransferDetail?.guardian_city || '');
    //   //   formData.append('guardian[phone]', stockTransferDetail?.guardian_phone || '');
    //   //   formData.append('guardian[comment]', stockTransferDetail?.guardian_comment || '');
    //   // }
      
    //   fetch_one<User>('/nl/v3/objects/save', formData, (ob, complete_data) => {
    //     setUserId(undefined);
    //     setItem(undefined);
    //     setItem(undefined);
    //     setErrors([]);
    //     setEditing(false);
    //     setLoader(false);

    //     setUserSavedSnackOpen(true);

    //     fetchStockTransfer();
    //     setRefreshNotes(true);
    //   }, (z) => {
    //     setServerErrorSnackOpen(true);
    //   }, login);
    // } else {
    //   setErrorSnackOpen(true);
    //   setErrors(errors);
    // }
  };

  const FormData_append_object = (fd:FormData, obj:any, key:string) => {
    var i:string, k:string;
    for(i in obj) {
      k = key ? key + '[' + i + ']' : i;
      if(typeof obj[i] == 'object')
        FormData_append_object(fd, obj[i], k);
      else
        fd.append(k, obj[i]);
    }
  }

  const saveStockTransfer = () => {
    setErrors([]);
    let errors:string[] = [];

    if (typeof(stockTransferDetail?.user_id) === 'undefined' || stockTransferDetail?.user_id === null) errors.push('user_id');
    if (!stockTransferDetail?.existing_user_id) {
      if (typeof(stockTransferDetail?.first_name) === 'undefined' || stockTransferDetail?.first_name === null || stockTransferDetail?.first_name.trim() === "") errors.push('first_name');
      if (typeof(stockTransferDetail?.last_name) === 'undefined' || stockTransferDetail?.last_name === null || stockTransferDetail?.last_name.trim() === "") errors.push('last_name');
      // if (typeof(stockTransferDetail?.email) === 'undefined' || stockTransferDetail?.email === null || stockTransferDetail?.email.trim() === "") errors.push('email');
      if (typeof(stockTransferDetail?.birth_date) === 'undefined' || stockTransferDetail?.birth_date === null || stockTransferDetail?.birth_date.trim() === "") errors.push('birth_date');
      if (typeof(stockTransferDetail?.street) === 'undefined' || stockTransferDetail?.street === null || stockTransferDetail?.street.trim() === "") errors.push('street');
      if (typeof(stockTransferDetail?.number) === 'undefined' || stockTransferDetail?.number === null || stockTransferDetail?.number.trim() === "") errors.push('number');
      if (typeof(stockTransferDetail?.zip) === 'undefined' || stockTransferDetail?.zip === null || stockTransferDetail?.zip.trim() === "") errors.push('zip');
      if (typeof(stockTransferDetail?.city) === 'undefined' || stockTransferDetail?.city === null || stockTransferDetail?.city.trim() === "") errors.push('city');
      // if (typeof(stockTransferDetail?.phone) === 'undefined' || stockTransferDetail?.phone === null || stockTransferDetail?.phone.trim() === "") errors.push('phone');
      if (typeof(stockTransferDetail?.zicht_account) === 'undefined' || stockTransferDetail?.zicht_account === null || stockTransferDetail?.zicht_account.trim() === "") {
        errors.push('zicht_account');
      } else {
        let iban = stockTransferDetail?.zicht_account.toUpperCase().replace(/[^A-Z0-9]/g, '');
        let code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
        let digits;
        let checked = false;

        if (!!code && !!code[1] && !!code[2] && !!code[3]) {
          digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
            return (letter.charCodeAt(0) - 55).toString();
          });
          var checksum = digits.slice(0, 2), fragment;
          for (var offset = 2; offset < digits.length; offset += 7) {
            fragment = String(checksum) + digits.substring(offset, offset + 7);
            checksum = (parseInt(fragment, 10) % 97).toString();
          }
          if (checksum === "1") checked = true;
        }

        if (!checked) errors.push('zicht_account');
      }
    }
    if (!!stockTransferDetail?.has_guardian) {
      if (typeof(stockTransferDetail?.guardian_info?.first_name) === 'undefined' || stockTransferDetail?.guardian_info?.first_name === null || stockTransferDetail?.guardian_info?.first_name.trim() === "") errors.push('guardian_first_name');
      if (typeof(stockTransferDetail?.guardian_info?.last_name) === 'undefined' || stockTransferDetail?.guardian_info?.last_name === null || stockTransferDetail?.guardian_info?.last_name.trim() === "") errors.push('guardian_last_name');
      // if (typeof(stockTransferDetail?.guardian_info?.email) === 'undefined' || stockTransferDetail?.guardian_info?.email === null || stockTransferDetail?.guardian_info?.email.trim() === "") errors.push('guardian_email');
      if (typeof(stockTransferDetail?.guardian_info?.birth_date) === 'undefined' || stockTransferDetail?.guardian_info?.birth_date === null || stockTransferDetail?.guardian_info?.birth_date.trim() === "") errors.push('guardian_birth_date');
      if ((DateTime.fromFormat(stockTransferDetail?.guardian_info?.birth_date, "dd/LL/yyyy").isValid && (DateTime.fromFormat(stockTransferDetail?.guardian_info?.birth_date, "dd/LL/yyyy") > DateTime.now().minus({year: 18})))) errors.push('guardian_birth_date')
      if (typeof(stockTransferDetail?.guardian_info?.street) === 'undefined' || stockTransferDetail?.guardian_info?.street === null || stockTransferDetail?.guardian_info?.street.trim() === "") errors.push('guardian_street');
      if (typeof(stockTransferDetail?.guardian_info?.number) === 'undefined' || stockTransferDetail?.guardian_info?.number === null || stockTransferDetail?.guardian_info?.number.trim() === "") errors.push('guardian_number');
      if (typeof(stockTransferDetail?.guardian_info?.zip) === 'undefined' || stockTransferDetail?.guardian_info?.zip === null || stockTransferDetail?.guardian_info?.zip.trim() === "") errors.push('guardian_zip');
      if (typeof(stockTransferDetail?.guardian_info?.city) === 'undefined' || stockTransferDetail?.guardian_info?.city === null || stockTransferDetail?.guardian_info?.city.trim() === "") errors.push('guardian_city');
      // if (typeof(stockTransferDetail?.guardian_info?.phone) === 'undefined' || stockTransferDetail?.guardian_info?.phone === null || stockTransferDetail?.guardian_info?.phone.trim() === "") errors.push('guardian_phone');
    }

    if (!((stockTransferDetail?.stock_number || 0) <= current_user_stock_number && (stockTransferDetail?.stock_number || 0) <= (200 - new_user_stock_number) && (stockTransferDetail?.stock_number || 0) > 0)) {
      errors.push('stock_number');
    }
    
    if (errors.length < 1) {
      let formData = new FormData();
      formData.append('object', 'stock_transfer');
      formData.append('handler_id', (login?.id || "").toString());
      formData.append('fields[]', 'id');
      if (!!stockTransferId && stockTransferId !== 'new') formData.append('ob[id]', stockTransferId.toString());

      FormData_append_object(formData, stockTransferDetail, 'ob');

      if (!!item && itemConfirmed === 0) formData.append('ob[item]', item);
      if (!!userId && identityConfirmed === 0) formData.append('ob[id_user]', userId);
      if (!!newUserId && identityNewConfirmed === 0) formData.append('ob[id_new_user]', newUserId);
      if (!!voogdUserId && identityVoogdConfirmed === 0) formData.append('ob[id_voogd]', voogdUserId);

      if (!!purgeItem) formData.append('purge_item', '1');
      if (!!purgeIdentity) formData.append('purge_id_user', '1');
      if (!!purgeIdentityNew) formData.append('purge_id_new_user', '1');
      if (!!purgeIdentityVoogd) formData.append('purge_id_voogd', '1');

      if (!!saveConfirm) formData.append('can_confirm', '1');

      formData.append('ob[extra_data][checked]', (checked ? '1' : '0'));
      formData.append('ob[extra_data][item_confirmed]', itemConfirmed.toString());
      formData.append('ob[extra_data][id_user_confirmed]', identityConfirmed.toString());
      formData.append('ob[extra_data][id_new_user_confirmed]', identityNewConfirmed.toString());
      formData.append('ob[extra_data][id_voogd_confirmed]', identityVoogdConfirmed.toString());
    
      fetch_one<StockTransfer>('/nl/v3/objects/save', formData, (ob, complete_data) => {
        if (stockTransferId === 'new' && !!ob.id) window.location.href = `/stock_transfer/${ob.id}`;
        setUserId(undefined);
        setNewUserId(undefined);
        setVoogdUserId(undefined);
        setItem(undefined);
        setItem(undefined);
        setErrors([]);
        setEditing(false);
        setLoader(false);

        setSaveConfirm(false);
        setStockTransferSavedSnackOpen(true);

        setPurgeItem(false);
        setPurgeIdentity(false);
        setPurgeIdentityNew(false);
        setPurgeIdentityVoogd(false);

        fetchStockTransfer();
        setRefreshNotes(true);
      }, (z) => {
        setSaveConfirm(false);
        setServerErrorSnackOpen(true);
      }, login);
    } else {
      setErrors(errors);
    }
  };

  const sendEmail = () => {
    fetch_one<CustomStringResult>('/nl/v3/objects/custom_action', {
      object: 'stock_transfer',
      class_action: 'custom_api_send_error_email',
      id: stockTransferId,
      email_content: emailContent,
      handler_id: login?.id,
      send_to_sender: sendToSender ? '1' : '0',
      send_to_receiver: sendToReceiver ? '1' : '0'
    }, (ob, complete_data) => {
      if (ob.custom_result === 'success') {
        setErrorMailSentOpen(true);
        setCreateMail(false);
      } else {
        setErrorMailErrorOpen(true);
      }
      setRefreshNotes(true);
    }, (z, y) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  useEffect(() => {
    fetchStockTransfer();
    setRefreshNotes(true);
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!saveConfirm) saveStockTransfer();
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveConfirm]);

  const [share_value, setShareValue] = useState<number>(0);
  const [current_user_stock_number, setCurrentUserStockNumber] = useState<number>(0);
  const [new_user_stock_number, setNewUserStockNumber] = useState<number>(0);

  useEffect(() => {
    if (!!stockTransferDetail && (stockTransferDetail?.status !== 'approved' && stockTransferDetail?.status !== 'disapproved')) {
      if (!!stockTransferDetail.user_share_type_amount) setShareValue(stockTransferDetail.user_share_type_amount || 0);
      if (!!stockTransferDetail.user_stock_number) setCurrentUserStockNumber(stockTransferDetail.user_stock_number || 0);
      if (!!stockTransferDetail.existing_user_stock_number) setNewUserStockNumber(stockTransferDetail.existing_user_stock_number || 0);
    }
  }, [stockTransferDetail]);

  useEffect(() => {
    if (!!linkedUser && (stockTransferDetail?.status !== 'approved' && stockTransferDetail?.status !== 'disapproved')) {
      setShareValue(linkedUser.share_type_amount || 0);
      setCurrentUserStockNumber(linkedUser.stock_number || 0);
    }
  }, [linkedUser, stockTransferDetail?.status]);

  useEffect(() => {
    if (!!linkedNewUser && (stockTransferDetail?.status !== 'approved' && stockTransferDetail?.status !== 'disapproved')) {
      setNewUserStockNumber(linkedNewUser.stock_number || 0);
    }
  }, [linkedNewUser, stockTransferDetail?.status]);

  return (
    <Page
      sx={{}}
      title={`${!!stockTransferDetail && [stockTransferDetail.user_first_name, stockTransferDetail.user_last_name, `(${stockTransferDetail.user_vennoot_number})`].join(" ")}`}
      startEndCrumb={<StyledButton
        label="Back to StockTransfers"
        id='back'
        text
        startIcon={<KeyboardBackspaceIcon />}
        path={`/stock_transfers`}
        sx={{marginBottom: 1}}
      />}
    >
      <Grid container spacing="8">
        <Grid container item xs={12} md={8} spacing="8" sx={{alignContent: 'baseline', position: 'relative'}}>
          {!!loader && <Grid item xs={12}>
            <CircularProgress />
          </Grid>}

          {!!stockTransferDetail && <Grid item xs={12}>
            <Box sx={{
              // width: '100%',
              border: '1px solid transparent',
              borderColor: theme.palette.tertiary?.main,
              padding: "16px 8px",
              borderRadius: 1,
              backgroundColor: theme.palette.tertiary?.main,
              marginBottom: 1,
              textAlign: 'center'
            }}>
              <Typography>This stock transfer is currently '{stockTransferDetail.get_status || 'blank'}'!</Typography>
            </Box>
          </Grid>}

          {!!stockTransferDetail && !createMail && !!editing && <>
            <Grid container item xs={12} rowSpacing="8" sx={{alignContent: 'baseline'}}>

              {stockTransferId !== 'new' && <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.identity
                }}
                title="Giving party"
                wide
              >
                <Grid item xs={12} sx={{textAlign: 'center'}}>
                  <Typography sx={{fontStyle: 'italic', marginBottom: 2, marginTop: 1, fontSize: '0.8rem', textAlign: 'center'}}>Change the user details in the user's profile</Typography>
                  <StyledButton
                    label="To User Detail"
                    id='userdetail1'
                    contained
                    onClick={(v) => {
                      window.open(`/user/${stockTransferDetail.existing_user_id}`, "_BLANK");
                    }}
                    startIcon={<LaunchIcon />}
                  />
                </Grid>
              </UserDetailBase>}

              {stockTransferId === 'new' && !stockTransferDetail.user_id && <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.identity
                }}
                title="Giving party"
                wide
              >
                <Grid item container xs={12} sx={{alignContent: 'baseline'}} spacing="4">
                  <Grid item xs={12} sx={{paddingRight: 0.5, backgroundColor: '#98B6B4', marginLeft: 0.5}}>
                    <UserSearch
                      initialValues={{birth_date: stockTransferDetail?.birth_date}}
                      customUserLayout={["padding", "padding", "vennr", "birthdate"]}
                      fields={['id', 'first_name', 'stock_number', 'last_name', 'vennoot_number', 'birth_date', 'open_stock_transfer_count', 'transactions|last|transaction_date', 'transactions|first|stock_saldo_value', 'locale', 'share_type|amount']}
                      resultLayout={(user) => <Box sx={{
                        padding: 1,
                        cursor: 'pointer',
                        border: '1px solid transparent',
                        borderColor: theme.palette.primary.main,
                        marginBottom: 0.5
                      }} onClick={() => {
                        if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, user_id: user.id});
                        setLinkedUser(user);
                      }}>
                        <Typography sx={{fontWeight: 'bold'}}>{user.first_name} {user.last_name}</Typography>
                        <Typography>{user.street} {user.number}, {user.zip} {user.city}</Typography>
                      </Box>}
                    />
                  </Grid>
                </Grid>
              </UserDetailBase>}

              {stockTransferId === 'new' && !!stockTransferDetail.user_id && <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.identity
                }}
                title="Giving party"
                wide
              >
                <Grid item container xs={12} sx={{alignContent: 'baseline', flexDirection: 'column'}} spacing="4">
                  {!linkedUser && <Typography>Something fucky...</Typography>}
                  {!!linkedUser && <Typography>{linkedUser.first_name} {linkedUser.last_name} ({linkedUser.vennoot_number})</Typography>}
                  {!!linkedUser && <Typography>{linkedUser.street} {linkedUser.number}, {linkedUser.zip} {linkedUser.city}</Typography>}
                  {!!linkedUser && <Typography>{linkedUser.stock_number} ({linkedUser.transactions_first_stock_saldo_value} EUR)</Typography>}
                </Grid>
              </UserDetailBase>}

              {!!stockTransferDetail.new_user_id && <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.account
                }}
                title="Receiving party"
                wide
              >
                <Grid item xs={12} sx={{textAlign: 'center'}}>
                  <Typography sx={{fontStyle: 'italic', marginBottom: 2, marginTop: 1, fontSize: '0.8rem', textAlign: 'center'}}>Change the user details in the user's profile</Typography>
                  <StyledButton
                    label="To User Detail"
                    id='userdetail2'
                    contained
                    onClick={(v) => {
                      window.open(`/user/${stockTransferDetail.new_user_id}`, "_BLANK");
                    }}
                    startIcon={<LaunchIcon />}
                  />
                </Grid>
              </UserDetailBase>}

              {!!stockTransferDetail.existing_user_id && !stockTransferDetail.new_user_id && stockTransferId !== 'new' && <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.contact
                }}
                title="Receiving party - existing user"
                wide
              >
                <Grid item xs={12} sx={{textAlign: 'center'}}>
                  <Typography sx={{fontStyle: 'italic', marginBottom: 2, marginTop: 1, fontSize: '0.8rem', textAlign: 'center'}}>Change the user details in the user's profile</Typography>
                  <StyledButton
                    label="To User Detail"
                    id='userdetail3'
                    contained
                    onClick={(v) => {
                      window.open(`/user/${stockTransferDetail.existing_user_id}`, "_BLANK");
                    }}
                    startIcon={<LaunchIcon />}
                  />
                </Grid>
              </UserDetailBase>}

              {!stockTransferDetail.existing_user_id && !stockTransferDetail.new_user_id && <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.internal
                }}
                title="Receiving party - new user"
                wide
              >
                <Grid item container xs={6} sx={{alignContent: 'baseline'}} spacing="4">
                  <Grid item xs={12}>
                    <StyledTextField
                      label="First name"
                      value={stockTransferDetail?.first_name || ''}
                      id='first_name'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, first_name: v});}}
                      fullWidth
                      capitalaction
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTextField
                      label="Email"
                      value={stockTransferDetail?.email || ''}
                      id='email'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, email: v});}}
                      fullWidth
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <StyledTextField
                      label="Street"
                      value={stockTransferDetail?.street || ''}
                      id='street'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, street: v});}}
                      fullWidth
                      capitalaction
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <StyledTextField
                      label="Number"
                      value={stockTransferDetail?.number || ''}
                      id='number'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, number: v});}}
                      fullWidth
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <StyledTextField
                      label="Zip"
                      value={stockTransferDetail?.zip || ''}
                      id='zip'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, zip: v});}}
                      fullWidth
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <StyledTextField
                      label="City"
                      value={stockTransferDetail?.city || ''}
                      id='city'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, city: v});}}
                      fullWidth
                      capitalaction
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BankAccount
                      label="Zicht account"
                      value={stockTransferDetail?.zicht_account || ''}
                      id="zicht_account"
                      onChange={(v, checked) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, zicht_account: v});}}
                      fullWidth
                      errorArray={errors}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                  <Grid item xs={12}>
                    <StyledTextField
                      label="Last name"
                      value={stockTransferDetail?.last_name || ''}
                      id='last_name'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, last_name: v});}}
                      fullWidth
                      capitalaction
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledDateField
                      label="Birthdate"
                      value={stockTransferDetail?.birth_date || ''}
                      id='birth_date'
                      onChange={(v) => {
                        if (!!stockTransferDetail) {
                          if (stockTransferId === 'new') {
                            setStockTransferDetail({
                              ...stockTransferDetail,
                              birth_date: v.toString(),
                              has_guardian: (DateTime.fromFormat(v, "dd/LL/yyyy").isValid && (DateTime.fromFormat(v, "dd/LL/yyyy") > DateTime.now().minus({year: 18}))) ? 1 : 0
                            });
                          } else {
                            setStockTransferDetail({...stockTransferDetail, birth_date: v.toString()});
                          }
                        }
                      }}
                      fullWidth
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTextField
                      label="Phone"
                      value={stockTransferDetail?.phone || ''}
                      id='phone'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, phone: v});}}
                      fullWidth
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTextField
                      label="Mobile"
                      value={stockTransferDetail?.mobile || ''}
                      id='mobile'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, mobile: v});}}
                      fullWidth
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledSelect
                      label="Gender"
                      value={stockTransferDetail?.gender || 'M'}
                      id='gender'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, gender: v});}}
                      list={[
                        {id: 'M', name: "Male"},
                        {id: 'F', name: "Female"}
                      ]}
                    />
                  </Grid>
                </Grid>
              </UserDetailBase>}

              {!stockTransferDetail.existing_user_id && !stockTransferDetail.new_user_id && <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.internal
                }}
                title="Receiving party - switch to existing user"
                wide
              >
                <Grid item container xs={12} sx={{alignContent: 'baseline'}} spacing="4">
                  <Grid item xs={12} sx={{paddingRight: 0.5, backgroundColor: '#98B6B4', marginLeft: 0.5}}>
                    <UserSearch
                      initialValues={{birth_date: stockTransferDetail?.birth_date}}
                      customUserLayout={["padding", "padding", "vennr", "birthdate"]}
                      fields={['id', 'first_name', 'last_name', 'vennoot_number', 'birth_date', 'stock_number', 'open_stock_transfer_count', 'transactions|last|transaction_date', 'transactions|first|stock_saldo_value', 'locale']}
                      resultLayout={(user) => <Box sx={{
                        padding: 1,
                        cursor: 'pointer',
                        border: '1px solid transparent',
                        borderColor: theme.palette.primary.main,
                        marginBottom: 0.5
                      }} onClick={() => {
                        if (!!stockTransferDetail && !!user.id && user.id !== stockTransferDetail.user_id) setStockTransferDetail({...stockTransferDetail, existing_user_id: user.id});
                        if (!!stockTransferDetail && !!user.id && user.id !== stockTransferDetail.user_id) setLinkedNewUser(user);
                      }}>
                        <Typography sx={{fontWeight: 'bold'}}>{user.first_name} {user.last_name}</Typography>
                        <Typography>{user.street} {user.number}, {user.zip} {user.city}</Typography>
                      </Box>}
                    />
                  </Grid>
                </Grid>
              </UserDetailBase>}

              {!!stockTransferDetail.existing_user_id && !stockTransferDetail.new_user_id && <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.internal
                }}
                title="Receiving party - switch to new user"
                wide
              >
                <Grid item container xs={12} sx={{alignContent: 'baseline', flexDirection: 'row'}} spacing="4">
                  <Grid item xs={12} sx={{paddingRight: 0.5, flexDirection: 'column'}}>
                    {!!linkedNewUser && <Typography>{linkedNewUser.first_name} {linkedNewUser.last_name} ({linkedNewUser.vennoot_number})</Typography>}
                    {!!linkedNewUser && <Typography>{linkedNewUser.street} {linkedNewUser.number}, {linkedNewUser.zip} {linkedNewUser.city}</Typography>}
                    {!!linkedNewUser && <Typography>{linkedNewUser.stock_number} ({linkedNewUser.transactions_first_stock_saldo_value} EUR)</Typography>}
                  </Grid>
                  <Grid item xs={12} sx={{paddingRight: 0.5, backgroundColor: '#98B6B4', marginLeft: 0.5}}>
                    <StyledButton
                      label="Unlink!"
                      id='unlink_existing'
                      contained
                      onClick={(v) => {
                        if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, existing_user_id: undefined});
                      }}
                      startIcon={<LaunchIcon />}
                    />
                  </Grid>
                </Grid>
              </UserDetailBase>}

              {!!stockTransferDetail.has_guardian && <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.exit
                }}
                title="Receving party - guardian"
                wide
              >
                <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                  <Grid item xs={12}>
                    <StyledTextField
                      label="First name"
                      value={stockTransferDetail?.guardian_info?.first_name || ''}
                      id='guardian_first_name'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, guardian_info: {...(stockTransferDetail?.guardian_info || {}), first_name: v}});}}
                      fullWidth
                      capitalaction
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTextField
                      label="Last name"
                      value={stockTransferDetail?.guardian_info?.last_name || ''}
                      id='guardian_last_name'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, guardian_info: {...(stockTransferDetail?.guardian_info || {}), last_name: v}});}}
                      fullWidth
                      capitalaction
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTextField
                      label="Email"
                      value={stockTransferDetail?.guardian_info?.email || ''}
                      id='guardian_email'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, guardian_info: {...(stockTransferDetail?.guardian_info || {}), email: v}});}}
                      fullWidth
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <StyledTextField
                      label="Street"
                      value={stockTransferDetail?.guardian_info?.street || ''}
                      id='guardian_street'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, guardian_info: {...(stockTransferDetail?.guardian_info || {}), street: v}});}}
                      fullWidth
                      capitalaction
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <StyledTextField
                      label="Number"
                      value={stockTransferDetail?.guardian_info?.number || ''}
                      id='guardian_number'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, guardian_info: {...(stockTransferDetail?.guardian_info || {}), number: v}});}}
                      fullWidth
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <StyledTextField
                      label="Zip"
                      value={stockTransferDetail?.guardian_info?.zip || ''}
                      id='guardian_zip'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, guardian_info: {...(stockTransferDetail?.guardian_info || {}), zip: v}});}}
                      fullWidth
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <StyledTextField
                      label="City"
                      value={stockTransferDetail?.guardian_info?.city || ''}
                      id='guardian_city'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, guardian_info: {...(stockTransferDetail?.guardian_info || {}), city: v}});}}
                      fullWidth
                      capitalaction
                      errorArray={errors}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                  <Grid item xs={12}>
                    <StyledDateField
                      label="Birthdate"
                      value={stockTransferDetail?.guardian_info?.birth_date || ''}
                      id='guardian_birth_date'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, guardian_info: {...(stockTransferDetail?.guardian_info || {}), birth_date: v.toString()}});}}
                      fullWidth
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTextField
                      label="Phone"
                      value={stockTransferDetail?.guardian_info?.phone || ''}
                      id='guardian_phone'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, guardian_info: {...(stockTransferDetail?.guardian_info || {}), phone: v}});}}
                      fullWidth
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTextField
                      label="Comment"
                      value={stockTransferDetail?.guardian_info?.comment || ''}
                      id='guardian_comment'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, guardian_info: {...(stockTransferDetail?.guardian_info || {}), comment: v}});}}
                      fullWidth
                      multiline
                      errorArray={errors}
                    />
                  </Grid>
                </Grid>
              </UserDetailBase>}

              {(stockTransferDetail?.status !== 'approved' && stockTransferDetail?.status !== 'disapproved') && <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.register
                }}
                title="Stock handling"
                wide
              >
                <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                  <Grid item xs={12}>
                    <StyledTextField
                      label="Stock number to transfer"
                      value={stockTransferDetail?.stock_number?.toString() || ''}
                      id='stock_number'
                      error={!((stockTransferDetail?.stock_number || 0) <= current_user_stock_number && (stockTransferDetail?.stock_number || 0) <= (200 - new_user_stock_number) && (stockTransferDetail?.stock_number || 0) > 0)}
                      onChange={(v) => {
                        let amount = parseInt(v, 10);
                        if (!!stockTransferDetail) {
                          // if (amount <= current_user_stock_number && amount <= (200 - new_user_stock_number) && amount > 0) {
                            setStockTransferDetail({...stockTransferDetail, stock_number: amount});
                          // }
                        }
                      }}
                      fullWidth
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledDateField
                      label="Transaction Date"
                      value={stockTransferDetail?.transaction_date || ''}
                      id='transaction_date'
                      onChange={(v) => {if (!!stockTransferDetail) setStockTransferDetail({...stockTransferDetail, transaction_date: v.toString()});}}
                      fullWidth
                      errorArray={errors}
                    />
                  </Grid>
                  {false && <Grid item xs={12}>
                    <Typography sx={{marginRight: 1, fontSize: '0.8rem', cursor: 'pointer', textDecoration: 'underline', display: 'inline'}} onClick={() => {
                      if (!!stockTransferDetail) setStockTransferDetail({
                        ...stockTransferDetail,
                        stock_number: Math.round(current_user_stock_number / 4)
                      });
                    }}>[1/4]</Typography>|
                    <Typography sx={{marginRight: 1, marginLeft: 1, fontSize: '0.8rem', cursor: 'pointer', textDecoration: 'underline', display: 'inline'}} onClick={() => {
                      if (!!stockTransferDetail) setStockTransferDetail({
                        ...stockTransferDetail,
                        stock_number: Math.round(current_user_stock_number / 3)
                      });
                    }}>[1/3]</Typography>|
                    <Typography sx={{marginRight: 1, marginLeft: 1, fontSize: '0.8rem', cursor: 'pointer', textDecoration: 'underline', display: 'inline'}} onClick={() => {
                      if (!!stockTransferDetail) setStockTransferDetail({
                        ...stockTransferDetail,
                        stock_number: Math.round(current_user_stock_number / 2)
                      });
                    }}>[1/2]</Typography>|
                    <Typography sx={{marginRight: 1, marginLeft: 1, fontSize: '0.8rem', cursor: 'pointer', textDecoration: 'underline', display: 'inline'}} onClick={() => {
                      if (!!stockTransferDetail) setStockTransferDetail({
                        ...stockTransferDetail,
                        stock_number: Math.round((current_user_stock_number / 2) * 2)
                      });
                    }}>[2/3]</Typography>|
                    <Typography sx={{marginRight: 1, marginLeft: 1, fontSize: '0.8rem', cursor: 'pointer', textDecoration: 'underline', display: 'inline'}} onClick={() => {
                      if (!!stockTransferDetail) setStockTransferDetail({
                        ...stockTransferDetail,
                        stock_number: Math.round((current_user_stock_number / 4) * 3)
                      });
                    }}>[3/4]</Typography>|
                    <Typography sx={{marginRight: 1, marginLeft: 1, fontSize: '0.8rem', cursor: 'pointer', textDecoration: 'underline', display: 'inline'}} onClick={() => {
                      if (!!stockTransferDetail) setStockTransferDetail({
                        ...stockTransferDetail,
                        stock_number: current_user_stock_number
                      });
                    }}>[all]</Typography>
                  </Grid>}
                </Grid>
                <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                  <Typography sx={{marginTop: 0.5, width: '100%'}}>Giving party has: {`${current_user_stock_number.toString()} (${!!linkedUser ? linkedUser.transactions_first_stock_saldo_value : stockTransferDetail.user_transactions_first_stock_saldo_value?.toFixed(0)} EUR)`}</Typography><br/>
                  <Typography sx={{marginTop: 0.5, width: '100%'}}>Receiving party has: {`${new_user_stock_number.toString()} (${(new_user_stock_number * share_value).toFixed(0)} EUR)`}</Typography>
                </Grid>
              </UserDetailBase>}

            </Grid>

          </>}

          {!!stockTransferDetail && !createMail && !editing && <Grid container item xs={12} rowSpacing="8" sx={{alignContent: 'baseline'}}>
            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.identity
              }}
              title="Giving party"
              wide
            >
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine name="Name" value={[stockTransferDetail.user_first_name, stockTransferDetail.user_last_name].join(" ")} />
                <DetailLine copyable name="Email" value={stockTransferDetail.user_email} />
                <DetailLine name="Phone" value={stockTransferDetail.user_phone} />
                <DetailLine no_divider name="Address" value={[stockTransferDetail.user_street, stockTransferDetail.user_number].join(" ")} />
                <DetailLine name="" value={[stockTransferDetail.user_zip, stockTransferDetail.user_city].join(" ")} />
                <DetailLine no_divider name="Language" value={stockTransferDetail.user_locale} />
              </Grid>
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine copyable name="Vennoot Number" value={stockTransferDetail.user_vennoot_number} />
                <DetailLine name="Birthdate" date={stockTransferDetail.user_birth_date} />
                <DetailLine name="Mobile" value={stockTransferDetail.user_mobile} />
                <DetailLine no_divider name="COR Address" value={[stockTransferDetail.user_cor_street, stockTransferDetail.user_cor_number || '.'].join(" ")} />
                <DetailLine name="" value={[stockTransferDetail.user_cor_zip, stockTransferDetail.user_cor_city || '.'].join(" ")} />
                <DetailLine no_divider name="Confirmed" bool={!!stockTransferDetail.user_confirmed} />
              </Grid>
            </UserDetailBase>

            {!!stockTransferDetail.new_user_id && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.account
              }}
              title="Receiving party"
              wide
            >
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine name="Name" value={[stockTransferDetail.new_user_first_name, stockTransferDetail.new_user_last_name].join(" ")} />
                <DetailLine copyable name="Email" value={stockTransferDetail.new_user_email} />
                <DetailLine name="Phone" value={stockTransferDetail.new_user_phone} />
                <DetailLine no_divider name="Address" value={[stockTransferDetail.new_user_street, stockTransferDetail.new_user_number].join(" ")} />
                <DetailLine name="" value={[stockTransferDetail.new_user_zip, stockTransferDetail.new_user_city].join(" ")} />
                <DetailLine no_divider name="Language" value={stockTransferDetail.new_user_locale} />
              </Grid>
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine copyable name="Vennoot Number" value={stockTransferDetail.new_user_vennoot_number} />
                <DetailLine name="Birthdate" date={stockTransferDetail.new_user_birth_date} />
                <DetailLine name="Mobile" value={stockTransferDetail.new_user_mobile} />
                <DetailLine no_divider name="COR Address" value={[stockTransferDetail.new_user_cor_street, stockTransferDetail.new_user_cor_number || '.'].join(" ")} />
                <DetailLine name="" value={[stockTransferDetail.new_user_cor_zip, stockTransferDetail.new_user_cor_city || '.'].join(" ")} />
                <DetailLine no_divider name="Confirmed" bool={!!stockTransferDetail.new_user_confirmed} />
              </Grid>
            </UserDetailBase>}

            {!!stockTransferDetail.existing_user_id && !stockTransferDetail.new_user_id && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.contact
              }}
              title="Receiving party - existing user"
              wide
            >
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine name="Name" value={[stockTransferDetail.existing_user_first_name, stockTransferDetail.existing_user_last_name].join(" ")} />
                <DetailLine copyable name="Email" value={stockTransferDetail.existing_user_email} />
                <DetailLine name="Phone" value={stockTransferDetail.existing_user_phone} />
                <DetailLine no_divider name="Address" value={[stockTransferDetail.existing_user_street, stockTransferDetail.existing_user_number].join(" ")} />
                <DetailLine name="" value={[stockTransferDetail.existing_user_zip, stockTransferDetail.existing_user_city].join(" ")} />
                <DetailLine no_divider name="Language" value={stockTransferDetail.existing_user_locale} />
              </Grid>
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine copyable name="Vennoot Number" value={stockTransferDetail.existing_user_vennoot_number} />
                <DetailLine name="Birthdate" date={stockTransferDetail.existing_user_birth_date} />
                <DetailLine name="Mobile" value={stockTransferDetail.existing_user_mobile} />
                <DetailLine no_divider name="COR Address" value={[stockTransferDetail.existing_user_cor_street, stockTransferDetail.existing_user_cor_number || '.'].join(" ")} />
                <DetailLine name="" value={[stockTransferDetail.existing_user_cor_zip, stockTransferDetail.existing_user_cor_city || '.'].join(" ")} />
                <DetailLine no_divider name="Confirmed" bool={!!stockTransferDetail.existing_user_confirmed} />
              </Grid>
            </UserDetailBase>}

            {!stockTransferDetail.existing_user_id && !stockTransferDetail.new_user_id && !!stockTransferDetail.birth_date && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.internal
              }}
              title="Receiving party - new user"
              wide
            >
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine name="Name" value={[stockTransferDetail.first_name, stockTransferDetail.last_name].join(" ")} />
                <DetailLine copyable name="Email" value={stockTransferDetail.email} />
                <DetailLine no_divider name="Address" value={[stockTransferDetail.street, stockTransferDetail.number].join(" ")} />
                <DetailLine name="" value={[stockTransferDetail.zip, stockTransferDetail.city].join(" ")} />
                <DetailLine no_divider name="Zicht account" value={stockTransferDetail.zicht_account} />
              </Grid>
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine name="Birthdate" date={stockTransferDetail.birth_date} />
                <DetailLine name="Phone" value={stockTransferDetail.phone} />
                <DetailLine name="Mobile" value={stockTransferDetail.mobile} />
                <DetailLine no_divider name="Gender" gender_icons value={stockTransferDetail.gender} />
              </Grid>
              {((stockTransferDetail.possible_frauds?.account_num || []).length > 0 || (stockTransferDetail.possible_frauds?.email || []).length > 0 || (stockTransferDetail.possible_frauds?.name_bday || []).length > 0) && <>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={12}>
                  <Typography sx={{fontSize: '0.9rem', color: 'red', display: 'inline', width: 'auto', marginRight: 2}}>Possible frauds found!</Typography>
                  {!fraudsHidden && <Typography sx={{fontSize: '0.8rem', cursor: 'pointer', display: 'inline', width: 'auto'}} onClick={() => {setFraudsHidden(true);}}>(hide)</Typography>}
                  {!!fraudsHidden && <Typography sx={{fontSize: '0.8rem', cursor: 'pointer', display: 'inline', width: 'auto'}} onClick={() => {setFraudsHidden(false);}}>(show)</Typography>}
                </Grid>
                {!fraudsHidden && <Grid item container xs={12} sx={{alignContent: 'baseline'}} rowSpacing="4">
                  {stockTransferDetail.possible_frauds?.account_num?.map(line => <DetailLine no_divider launchable={`/user/${line.id}`} name="Same account number" value={`${line.full_name} (${line.vennoot_number})`} /> )}
                  {stockTransferDetail.possible_frauds?.name_bday?.map(line => <DetailLine no_divider launchable={`/user/${line.id}`} name="Same name and birth date" value={`${line.full_name} (${line.vennoot_number})`} /> )}
                  {stockTransferDetail.possible_frauds?.email?.map(line => <DetailLine no_divider launchable={`/user/${line.id}`} name="Same email" value={`${line.full_name} (${line.vennoot_number})`} /> )}
                </Grid>}
              </>}
            </UserDetailBase>}

            {!!stockTransferDetail.existing_user_has_guardian && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.exit
              }}
              title="Receving party - guardian"
              wide
            >
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine name="Name" value={[stockTransferDetail.existing_user_guardian?.first_name, stockTransferDetail.existing_user_guardian?.last_name].join(" ")} />
                <DetailLine copyable name="Email" value={stockTransferDetail.existing_user_guardian?.email} />
                <DetailLine no_divider name="Address" value={[stockTransferDetail.existing_user_guardian?.street, stockTransferDetail.existing_user_guardian?.number].join(" ")} />
                <DetailLine no_divider name="" value={[stockTransferDetail.existing_user_guardian?.zip, stockTransferDetail.existing_user_guardian?.city].join(" ")} />
              </Grid>
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine name="Birthdate" date={stockTransferDetail.existing_user_guardian?.birth_date?.split("/").reverse().join("-")} />
                <DetailLine name="Phone" value={stockTransferDetail.existing_user_guardian?.phone} />
                {/* @ts-ignore */}
                <DetailLine no_divider name="Comment" value={stockTransferDetail.existing_user_guardian?.comment} />
              </Grid>
            </UserDetailBase>}

            {!!stockTransferDetail.has_guardian && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.exit
              }}
              title="Receving party - guardian"
              wide
            >
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine name="Name" value={[stockTransferDetail.guardian_info?.first_name, stockTransferDetail.guardian_info?.last_name].join(" ")} />
                <DetailLine copyable name="Email" value={stockTransferDetail.guardian_info?.email} />
                <DetailLine no_divider name="Address" value={[stockTransferDetail.guardian_info?.street, stockTransferDetail.guardian_info?.number].join(" ")} />
                <DetailLine no_divider name="" value={[stockTransferDetail.guardian_info?.zip, stockTransferDetail.guardian_info?.city].join(" ")} />
              </Grid>
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine name="Birthdate" date={stockTransferDetail.guardian_info?.birth_date?.split("/").reverse().join("-")} />
                <DetailLine name="Phone" value={stockTransferDetail.guardian_info?.phone} />
                <DetailLine no_divider name="Comment" value={stockTransferDetail.guardian_info?.comment} />
              </Grid>
            </UserDetailBase>}

            {(stockTransferDetail?.status !== 'approved' && stockTransferDetail?.status !== 'disapproved') && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.register
              }}
              title="Stock handling"
              wide
            >
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine name="Giving party has" value={`${current_user_stock_number.toString()} (${stockTransferDetail.user_transactions_first_stock_saldo_value?.toFixed(0)} EUR)`} />
                <DetailLine name="Giving party will cede" value={`${stockTransferDetail.stock_number?.toString()} (${((stockTransferDetail.stock_number || 0) * share_value)} EUR)`} />
                <DetailLine show_warning={(current_user_stock_number - (stockTransferDetail.stock_number || 0)) < 0} name="Giving party will have" value={`${(current_user_stock_number - (stockTransferDetail.stock_number || 0)).toString()} (${((current_user_stock_number - (stockTransferDetail.stock_number || 0)) * share_value)} EUR)`} />
              </Grid>
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine name="Receiving party has" value={`${new_user_stock_number.toString()} (${(new_user_stock_number * share_value).toFixed(0)} EUR)`} />
                <DetailLine name="Receiving will receive" value={`${stockTransferDetail.stock_number?.toString()} (${((stockTransferDetail.stock_number || 0) * share_value)} EUR)`} />
                <DetailLine show_warning={(new_user_stock_number + (stockTransferDetail.stock_number || 0)) > 200} name="Receiving party will have" value={`${(new_user_stock_number + (stockTransferDetail.stock_number || 0)).toString()} (${((new_user_stock_number + (stockTransferDetail.stock_number || 0)) * share_value)} EUR)`} />
              </Grid>
              <Grid item container xs={12} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine name="Transaction Date" date={stockTransferDetail.transaction_date} />
              </Grid>
              {(stockTransferDetail?.other_open_amount || 0) > 0 && <Grid item container xs={12} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine no_divider name="Giving party has other open transfers" value={`${(stockTransferDetail?.other_open_amount || 0)} (${(stockTransferDetail?.other_open_amount || 0) * share_value} EUR)`} />
              </Grid>}
            </UserDetailBase>}

          </Grid>}








          {!createMail && <Grid container item xs={12} spacing="8" sx={{alignContent: 'baseline'}}>
            {!editing && !!stockTransferDetail?.get_item && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.exit
              }}
              title="Signed Doc"
              confirmable={(confirmed) => {setItemConfirmed(confirmed ? 1 : 0); setTimeout(() => {setSaveConfirm(true);}, 200);}}
              deleteable={() => {setPurgeItem(true); setTimeout(() => {setSaveConfirm(true);}, 200);}}
              confirmed={stockTransferDetail.extra_data?.item_confirmed?.toString() === "1"}
              adornment={!!stockTransferDetail && !!stockTransferDetail.get_item && <>
                <StyledIconButton
                  path={stockTransferDetail.get_item}
                  newTab
                  sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
                ><LaunchIcon /></StyledIconButton>
              </>}
              turnable={!!stockTransferDetail.item_blob_content_type && stockTransferDetail.item_blob_content_type.indexOf("image") > -1}
            >
              <Grid item xs={12}>
                {!!stockTransferDetail.item_blob_content_type && stockTransferDetail.item_blob_content_type.indexOf("image") > -1 && <img alt="preview_img" style={{maxWidth: '100%'}} src={stockTransferDetail.get_item} />}
                {!!stockTransferDetail.item_blob_content_type && stockTransferDetail.item_blob_content_type.indexOf("pdf") > -1 && <iframe title="preview_pdf1" style={{width: '100%', height: !!editing ? '500px' : '300px'}} src={stockTransferDetail.get_item} />}
              </Grid>
              <Grid item xs={12}>
                {!!stockTransferDetail?.item_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {stockTransferDetail?.item_filename} ({(parseInt(stockTransferDetail?.item_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>}

            {!editing && !!stockTransferDetail?.get_id_user && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.identity
              }}
              title="ID Giving Party"
              confirmable={(confirmed) => {setIdentityConfirmed(confirmed ? 1 : 0); setTimeout(() => {setSaveConfirm(true);}, 200);}}
              deleteable={() => {setPurgeIdentity(true); setTimeout(() => {setSaveConfirm(true);}, 200);}}
              confirmed={stockTransferDetail.extra_data?.id_user_confirmed?.toString() === "1"}
              adornment={!!stockTransferDetail && !!stockTransferDetail.get_id_user && <>
                <StyledIconButton
                  path={stockTransferDetail.get_id_user}
                  newTab
                  sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
                ><LaunchIcon /></StyledIconButton>
                {/* <LaunchIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                  e.preventDefault();
                  setTimeout(function() {
                    window.open(stockTransferDetail.get_id_user);
                  }, 500);
                }} /> */}
              </>}
              turnable={!!stockTransferDetail.id_user_blob_content_type && stockTransferDetail.id_user_blob_content_type.indexOf("image") > -1}
            >
              <Grid item xs={12}>
                {!!stockTransferDetail.id_user_blob_content_type && stockTransferDetail.id_user_blob_content_type.indexOf("image") > -1 && <img alt="preview_img" style={{maxWidth: '100%'}} src={stockTransferDetail.get_id_user} />}
                {!!stockTransferDetail.id_user_blob_content_type && stockTransferDetail.id_user_blob_content_type.indexOf("pdf") > -1 && <iframe title="preview_pdf2" style={{width: '100%', height: '300px'}} src={stockTransferDetail.get_id_user} />}
              </Grid>
              <Grid item xs={12}>
                {!!stockTransferDetail?.id_user_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {stockTransferDetail?.id_user_filename} ({(parseInt(stockTransferDetail?.id_user_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>}

            {!editing && !!stockTransferDetail?.get_id_new_user && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.identity
              }}
              title="ID Receiving Party"
              confirmable={(confirmed) => {setIdentityNewConfirmed(confirmed ? 1 : 0); if (!confirmed) setTimeout(() => {setSaveConfirm(true);}, 200);}}
              deleteable={() => {setPurgeIdentityNew(true); setTimeout(() => {setSaveConfirm(true);}, 200);}}
              confirmed={stockTransferDetail.extra_data?.id_new_user_confirmed?.toString() === "1"}
              adornment={!!stockTransferDetail && !!stockTransferDetail.get_id_new_user && <>
                <StyledIconButton
                  path={stockTransferDetail.get_id_new_user}
                  newTab
                  sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
                ><LaunchIcon /></StyledIconButton>
                {/* <LaunchIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                  e.preventDefault();
                  setTimeout(function() {
                    window.open(stockTransferDetail.get_id_user);
                  }, 500);
                }} /> */}
              </>}
              turnable={!!stockTransferDetail.id_new_user_blob_content_type && stockTransferDetail.id_new_user_blob_content_type.indexOf("image") > -1}
            >
              <Grid item xs={12}>
                {!!stockTransferDetail.id_new_user_blob_content_type && stockTransferDetail.id_new_user_blob_content_type.indexOf("image") > -1 && <img alt="preview_img" style={{maxWidth: '100%'}} src={stockTransferDetail.get_id_new_user} />}
                {!!stockTransferDetail.id_new_user_blob_content_type && stockTransferDetail.id_new_user_blob_content_type.indexOf("pdf") > -1 && <iframe title="preview_pdf3" style={{width: '100%', height: '300px'}} src={stockTransferDetail.get_id_new_user} />}
              </Grid>
              <Grid item xs={12}>
                {!!stockTransferDetail?.id_new_user_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {stockTransferDetail?.id_new_user_filename} ({(parseInt(stockTransferDetail?.id_new_user_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>}

            {(!!stockTransferDetail?.has_guardian || !!stockTransferDetail?.existing_user_has_guardian) && <>
              {!editing && !!stockTransferDetail?.get_id_voogd && <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.identity
                }}
                title="ID Guardian"
                confirmable={(confirmed) => {setIdentityVoogdConfirmed(confirmed ? 1 : 0); if (!confirmed) setTimeout(() => {setSaveConfirm(true);}, 200);}}
                deleteable={() => {setPurgeIdentityVoogd(true); setTimeout(() => {setSaveConfirm(true);}, 200);}}
                confirmed={stockTransferDetail.extra_data?.id_voogd_confirmed?.toString() === "1"}
                adornment={!!stockTransferDetail && !!stockTransferDetail.get_id_voogd && <>
                  <StyledIconButton
                    path={stockTransferDetail.get_id_voogd}
                    newTab
                    sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
                  ><LaunchIcon /></StyledIconButton>
                  {/* <LaunchIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                    e.preventDefault();
                    setTimeout(function() {
                      window.open(stockTransferDetail.get_id_user);
                    }, 500);
                  }} /> */}
                </>}
                turnable={!!stockTransferDetail.id_voogd_blob_content_type && stockTransferDetail.id_voogd_blob_content_type.indexOf("image") > -1}
              >
                <Grid item xs={12}>
                  {!!stockTransferDetail.id_voogd_blob_content_type && stockTransferDetail.id_voogd_blob_content_type.indexOf("image") > -1 && <img alt="preview_img" style={{maxWidth: '100%'}} src={stockTransferDetail.get_id_voogd} />}
                  {!!stockTransferDetail.id_voogd_blob_content_type && stockTransferDetail.id_voogd_blob_content_type.indexOf("pdf") > -1 && <iframe title="preview_pdf4" style={{width: '100%', height: '300px'}} src={stockTransferDetail.get_id_voogd} />}
                </Grid>
                <Grid item xs={12}>
                  {!!stockTransferDetail?.id_voogd_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {stockTransferDetail?.id_voogd_filename} ({(parseInt(stockTransferDetail?.id_voogd_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
                </Grid>
              </UserDetailBase>}
            </>}

            {!editing && !!stockTransferDetail?.get_blank && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.exit
              }}
              title="Unsigned StockTransfer Document"
              adornment={!!stockTransferDetail && !!stockTransferDetail.get_blank && <>
                <StyledIconButton
                  path={stockTransferDetail.get_blank}
                  newTab
                  sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
                ><LaunchIcon /></StyledIconButton>

                {/* <LaunchIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                  e.preventDefault();
                  setTimeout(function() {
                    window.open(stockTransferDetail.get_blank);
                  }, 500);
                }} /> */}
              </>}
              turnable={!!stockTransferDetail.blank_blob_content_type && stockTransferDetail.blank_blob_content_type.indexOf("image") > -1}
            >
              <Grid item xs={12}>
                {!!stockTransferDetail.blank_blob_content_type && stockTransferDetail.blank_blob_content_type.indexOf("image") > -1 && <img alt="preview_img" style={{maxWidth: '100%'}} src={stockTransferDetail.get_blank} />}
                {!!stockTransferDetail.blank_blob_content_type && stockTransferDetail.blank_blob_content_type.indexOf("pdf") > -1 && <iframe title="preview_pdf5" style={{width: '100%', height: '300px'}} src={stockTransferDetail.get_blank} />}
              </Grid>
              <Grid item xs={12}>
                {!!stockTransferDetail?.blank_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {stockTransferDetail?.blank_filename} ({(parseInt(stockTransferDetail?.blank_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>}

            {!editing && stockTransferDetail?.extra_data?.item_confirmed?.toString() !== "1" && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.exit
              }}
              title="Signed Doc"
            >
              <Grid item xs={12}>
                <Typography sx={{fontSize: '0.75rem'}}>Bijlage</Typography>
                <StyledDropzone
                  acceptedFiles={{
                    'image/*': [],
                    'application/pdf': []
                  }}
                  onDrop={acceptedFiles => {setItem(acceptedFiles[0]);}}
                  multiple={false}
                  maxFiles={1}
                  maxSize={5 * 1000 * 1000}
                  items={!!item ? [item] : []}
                  dropText="Click here, or drop some files."
                  dropHint="Allowed: 1 image (.png, .jpeg, .jpg, .gif) or PDF (.pdf), < 5mb"
                />
                {!!stockTransferDetail?.item_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {stockTransferDetail?.item_filename} ({(parseInt(stockTransferDetail?.item_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>}

            {!editing && stockTransferDetail?.extra_data?.id_user_confirmed?.toString() !== "1" && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.identity
              }}
              title="ID Giving Party"
            >
              <Grid item xs={12}>
                <Typography sx={{fontSize: '0.75rem'}}>Bijlage</Typography>
                <StyledDropzone
                  acceptedFiles={{
                    'image/*': [],
                    'application/pdf': []
                  }}
                  onDrop={acceptedFiles => {setUserId(acceptedFiles[0]);}}
                  multiple={false}
                  maxFiles={1}
                  maxSize={5 * 1000 * 1000}
                  items={!!userId ? [userId] : []}
                  dropText="Click here, or drop some files."
                  dropHint="Allowed: 1 image (.png, .jpeg, .jpg, .gif) or PDF (.pdf), < 5mb"
                />
                {!!stockTransferDetail?.id_user_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {stockTransferDetail?.id_user_filename} ({(parseInt(stockTransferDetail?.id_user_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>}

            {!editing && stockTransferDetail?.extra_data?.id_new_user_confirmed?.toString() !== "1" && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.identity
              }}
              title="ID Receiving Party"
            >
              <Grid item xs={12}>
                <Typography sx={{fontSize: '0.75rem'}}>Bijlage</Typography>
                <StyledDropzone
                  acceptedFiles={{
                    'image/*': [],
                    'application/pdf': []
                  }}
                  onDrop={acceptedFiles => {setNewUserId(acceptedFiles[0]);}}
                  multiple={false}
                  maxFiles={1}
                  maxSize={5 * 1000 * 1000}
                  items={!!newUserId ? [newUserId] : []}
                  dropText="Click here, or drop some files."
                  dropHint="Allowed: 1 image (.png, .jpeg, .jpg, .gif) or PDF (.pdf), < 5mb"
                />
                {!!stockTransferDetail?.id_new_user_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {stockTransferDetail?.id_new_user_filename} ({(parseInt(stockTransferDetail?.id_new_user_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>}

            {(!!stockTransferDetail?.has_guardian || !!stockTransferDetail?.existing_user_has_guardian) && <>
              {!editing && stockTransferDetail?.extra_data?.id_voogd_confirmed?.toString() !== "1" && <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.identity
                }}
                title="ID Guardian"
              >
                <Grid item xs={12}>
                  <Typography sx={{fontSize: '0.75rem'}}>Bijlage</Typography>
                  <StyledDropzone
                    acceptedFiles={{
                      'image/*': [],
                      'application/pdf': []
                    }}
                    onDrop={acceptedFiles => {setVoogdUserId(acceptedFiles[0]);}}
                    multiple={false}
                    maxFiles={1}
                    maxSize={5 * 1000 * 1000}
                    items={!!voogdUserId ? [voogdUserId] : []}
                    dropText="Click here, or drop some files."
                    dropHint="Allowed: 1 image (.png, .jpeg, .jpg, .gif) or PDF (.pdf), < 5mb"
                  />
                  {!!stockTransferDetail?.id_voogd_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {stockTransferDetail?.id_voogd_filename} ({(parseInt(stockTransferDetail?.id_voogd_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
                </Grid>
              </UserDetailBase>}
            </>}
          </Grid>}

          {!!createMail && <Grid container item xs={12} rowSpacing="8">
            <StyledEditorNoTransl
              label="Email content"
              value={emailContent}
              getSunEditorInstance={getSunEditorInstance}
              onChange={(ob) => {
                setEmailContent(ob);
              }}
            />
          </Grid>}

        </Grid>
        <Grid container item xs={12} md={4} sx={{alignContent: 'baseline'}} rowSpacing="4">
          {!createMail && <Grid item xs={12}>
            <Box sx={{
              border: '1px solid transparent',
              // borderColor: theme.palette.primary.main,
              borderRadius: 1,
              padding: 1
            }}>
              <Grid container spacing="8">
                <Grid item xs={12}>
                  <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Actions</Typography>
                </Grid>

                {editing && <>
                  <Grid item xs={6}>
                    <StyledButton
                      label="Update"
                      id='update'
                      contained
                      saveStartIcon
                      onClick={(v) => {
                        saveUser();
                        saveStockTransfer();
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledButton
                      label="Cancel"
                      id='cancel'
                      onClick={(v) => {
                        setStockTransferDetail(oldStockTransferDetail);
                        setOldStockTransferDetail(null);
                        setUserId(undefined);
                        setItem(undefined);
                        setItem(undefined);
                        setErrors([]);
                        setEditing(false);
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                </>}

                {!editing && <>
                  {!!stockTransferDetail?.user_id && <Grid item xs={6}>
                    <StyledButton
                      label="UserDetail"
                      id='user_detail'
                      contained
                      onClick={(v) => {
                        window.open(`/user/${stockTransferDetail.user_id}`, "_BLANK");
                      }}
                      sx={{width: '100%'}}
                      startIcon={<LaunchIcon />}
                    />
                  </Grid>}
                  {!!stockTransferDetail?.existing_user_id && <Grid item xs={6}>
                    <StyledButton
                      label="ExistingUserDetail"
                      id='existing_user_detail'
                      contained
                      onClick={(v) => {
                        window.open(`/user/${stockTransferDetail.existing_user_id}`, "_BLANK");
                      }}
                      sx={{width: '100%'}}
                      startIcon={<LaunchIcon />}
                    />
                  </Grid>}

                  {stockTransferDetail?.extra_data?.checked?.toString() !== '1' && <Grid item xs={6}>
                    <StyledButton
                      label="Edit"
                      id='edit'
                      onClick={(v) => {
                        setOldStockTransferDetail(stockTransferDetail);
                        setEditing(true);
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>}

                  {stockTransferId !== 'new' && stockTransferDetail?.extra_data?.checked?.toString() !== '1' && <Grid item xs={6}>
                    <StyledButton
                      label="Destroy"
                      id='destroy'
                      secondary
                      destroyBtn
                      onClick={(v) => {
                        onRemove();
                      }}
                      sx={{width: '100%'}}
                      startIcon={<DeleteForeverIcon />}
                    />
                  </Grid>}
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  {stockTransferId !== 'new' && stockTransferDetail?.extra_data?.checked?.toString() !== '1' && <Grid item xs={12}>
                    <StyledButton
                      label="Regenerate blank"
                      id='regen_blank'
                      onClick={(v) => {
                        regenBlank();
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>}

                  {stockTransferDetail?.extra_data?.checked?.toString() !== '1' && !!stockTransferDetail && (!stockTransferDetail.new_user_id && (!!stockTransferDetail.birth_date || !!stockTransferDetail.existing_user_id)) && <>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    {(stockTransferDetail?.extra_data?.item_confirmed?.toString() !== "1" || stockTransferDetail?.extra_data?.id_user_confirmed?.toString() !== "1" || stockTransferDetail?.extra_data?.id_new_user_confirmed?.toString() !== "1") && <Grid item xs={12}>
                      <Typography sx={{textAlign: 'center', fontSize: '0.8rem', fontStyle: 'italic', color: theme.palette.secondary.main}} variant="body1">This will set both the IDs and the signed document as confirmed as well!</Typography>
                    </Grid>}
                    <Grid item xs={6}>
                      <StyledButton
                        label="Confirm"
                        id='set_checked'
                        contained
                        startIcon={<CheckCircleOutlineIcon />}
                        onClick={(v) => {
                          if (!itemConfirmed) setItemConfirmed(1);
                          if (!identityConfirmed) setIdentityConfirmed(1);
                          if (!identityNewConfirmed) setIdentityNewConfirmed(1);
                          if (!identityVoogdConfirmed) setIdentityVoogdConfirmed(1);
                          setTimeout(() => {setSaveConfirm(true);}, 200);

                          //  can_confirm: 1
                        }}
                        sx={{width: '100%'}}
                      />
                    </Grid>
                  </>}

                  {false && stockTransferDetail?.extra_data?.checked?.toString() === '1' && <>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      <StyledButton
                        label="Unconfirm"
                        id='set_unchecked'
                        onClick={(v) => {
                          setChecked(false);
                          setTimeout(() => {setSaveConfirm(true);}, 200);
                        }}
                        sx={{width: '100%'}}
                      />
                    </Grid>
                  </>}

                  {(!!item || !!userId || !!newUserId || !!voogdUserId) && <>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      <StyledButton
                        label="Save files"
                        id='save_doc'
                        contained
                        onClick={(v) => {
                          saveStockTransfer();
                        }}
                        sx={{width: '100%'}}
                      />
                    </Grid>
                  </>}

                </>}
              </Grid>
            </Box>
          </Grid>}

          {stockTransferId !== 'new' && !editing && stockTransferDetail?.extra_data?.item_confirmed?.toString() !== "1" && <Grid item xs={12} sx={{marginTop: (createMail ? 0 : 4)}}>
            <Box sx={{
              border: '1px solid transparent',
              // borderColor: theme.palette.primary.main,
              borderRadius: 1,
              padding: 1
            }}>
              <Grid container spacing="8">
                <Grid item xs={12}>
                  <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Errors</Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={errorItemUnclear} onChange={(v) => {setErrorItemUnclear(v.target.checked)}} name="errorItemUnclear" />
                    }
                    label="Signed StockTransfer Document unclear"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={errorIdUnclear} onChange={(v) => {setErrorIdUnclear(v.target.checked)}} name="errorIdUnclear" />
                    }
                    label="ID giving party unclear"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={errorIdNewUnclear} onChange={(v) => {setErrorIdNewUnclear(v.target.checked)}} name="errorIdNewUnclear" />
                    }
                    label="ID receiving party unclear"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={errorDocMissing} onChange={(v) => {setErrorDocMissing(v.target.checked)}} name="errorDocMissing" />
                    }
                    label="Document/ID missing (clarify)"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={errorDocMismatch} onChange={(v) => {setErrorDocMismatch(v.target.checked)}} name="errorDocMismatch" />
                    }
                    label="Document/ID mismatch (clarify)"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={errorCancel} onChange={(v) => {setErrorCancel(v.target.checked)}} name="errorCancel" />
                    }
                    label="Request'll be cancelled (give reason)"
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledButton
                    label={createMail ? "Hide mail" : "Create mail"}
                    id='create_mail'
                    contained={!createMail}
                    onClick={(v) => {
                      setCreateMail(!createMail);
                      window.scrollTo(0, 0);
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledButton
                    label="Update content"
                    id='update_content'
                    onClick={(v) => {
                      fetchEmailContent();
                    }}
                    saveStartIcon
                    sx={{width: '100%'}}
                  />
                </Grid>

                {!!createMail && <Grid item xs={12}>
                  <StyledButton
                    label="Send email"
                    id='send_email'
                    contained
                    onClick={(v) => {
                      sendEmail();
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>}
                {!!createMail && <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={sendToSender} onChange={(v) => {setSendToSender(v.target.checked)}} name="sendtosender" />
                      }
                      label={`Send to sender (${stockTransferDetail?.user_email})`}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={sendToReceiver} onChange={(v) => {setSendToReceiver(v.target.checked)}} name="sendtoreceiver" />
                      }
                      label={`Send to receiver (${stockTransferDetail?.existing_user_email || stockTransferDetail?.email})`}
                    />
                  </Grid>
                </Grid>}

              </Grid>
            </Box>
          </Grid>}

          {!!stockTransferId && !!stockTransferDetail && <NoteModule
            hidden={!(!editing && !createMail)}
            handled_id={stockTransferId}
            user_id={stockTransferDetail.user_id?.toString() || ''}
            handled_type="StockTransfer"
            refreshNotes={refreshNotes}
            setRefreshNotes={(b) => {setRefreshNotes(b);}}
          />}

          <Grid item xs={12}>
            <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Handlers</Typography>
          </Grid>
          {(stockTransferDetail?.extra_data?.handlers || []).length < 1 && <Grid item xs={12}>
            <Typography sx={{color: theme.palette.secondary.main, textAlign: 'center', fontStyle: 'italic'}}>No handlers found...</Typography>
          </Grid>}
          {(stockTransferDetail?.extra_data?.handlers || []).map(handler => <Grid item xs={12}>
            <Typography>{handler[1]} <span style={{fontSize: '0.8rem'}}>({DateTime.fromISO(handler[0]).toFormat("DDD - t")})</span></Typography>
          </Grid>)}

        </Grid>
      </Grid>

      {!!userSavedSnackOpen && <Snackbar
        open={userSavedSnackOpen}
        onClose={() => {setUserSavedSnackOpen(false);}}
        autoHideDuration={6000}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">
          Saved! Do not forget to regenerate the unsigned document, if needed. <StyledButton label="Regenerate now" id='regen_blank' contained onClick={(v) => {
            v.preventDefault();
            v.stopPropagation();

            setUserSavedSnackOpen(false);
            regenBlank();
          }} sx={{marginLeft: 1, paddingTop: 0, paddingBottom: 0, scale: '0.8'}} />
        </MuiAlert>
      </Snackbar>}

      {!!regenBlankSnackOpen && <Snackbar
        open={regenBlankSnackOpen}
        onClose={() => {setRegenBlankSnackOpen(false);}}
        autoHideDuration={2000}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">
          Unsigned document was regenerated!
        </MuiAlert>
      </Snackbar>}

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!stockTransferSavedSnackOpen && <AlertSaved open={stockTransferSavedSnackOpen} setOpen={(b) => {setStockTransferSavedSnackOpen(b);}} />}
      {!!errorMailSentOpen && <AlertEmailSent open={errorMailSentOpen} setOpen={(b) => {setErrorMailSentOpen(b);}} />}
      {!!errorMailErrorOpen && <AlertEmailNotSent open={errorMailErrorOpen} setOpen={(b) => {setErrorMailErrorOpen(b);}} />}
    </Page>
  );
}
