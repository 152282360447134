import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one, fetch_all } from '../../lib/server_helper';
import { loginAtom } from '../../lib/auth';

import {
  Box,
  Divider,
  Grid
} from '@mui/material';

import LaunchIcon from '@mui/icons-material/Launch';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

// import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
// import { AlertSaved } from '../../components/alerts/Saved';
// import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  Page,
  StyledButton,
  StyledIconButton,
  StyledSelect,
  StyledTextField,
  Typography,
  UserSearch
} from '../../components/interface';
import { NoteModule } from '../../components/interface/NoteModule';
import { PaymentCard } from '../../components/interface/PaymentCard';

import { EmissionKey } from '../../models/EmissionKey';
import { Payment } from '../../models/Payment';
import { User } from '../../models/User';

const { DateTime } = require("luxon");

type Props = {};

export const EmissionPaymentDetail: FunctionComponent<Props> = () => {
  // const navigate = useNavigate();
  const theme = useTheme();
  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [paymentId, setPaymentId] = useState<string | undefined>(params.id);
  const [payment, setPayment] = useState<Payment>();
  const [linkedUser, setLinkedUser] = useState<User>();
  const [newUser, setNewUser] = useState<User>();
  const [newUserKeys, setNewUserKeys] = useState<EmissionKey[]>([]);
  const [newUserKey, setNewUserKey] = useState<EmissionKey>();
  const [loader, setLoader] = useState<boolean>(true);
  const [showNewUserPicker, setShowNewUserPicker] = useState<boolean>(false);
  const [showNewUserKeyPicker, setShowNewUserKeyPicker] = useState<boolean>(false);
  const [switcherooCurrent, setSwitcherooCurrent] = useState<number>(0);
  const [switcherooNew, setSwitcherooNew] = useState<number>(0);
  const [switcherooRefund, setSwitcherooRefund] = useState<number>(0);
  const [switcherooRefundReason, setSwitcherooRefundReason] = useState<string>('');

  const [initValues, setInitValues] = useState<{name: string, vennoot_number: string, zicht_account: string, street: string, number: string, zip: string}>({name: '', vennoot_number: '', zicht_account: '', street: '', number: '', zip: ''});
  
  const [refreshNotes, setRefreshNotes] = useState<boolean>(true);

  // const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  // const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  // const [exitSavedSnackOpen, setExitSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'created_at', 'user|id/vennoot_number/first_name/last_name/birth_date/address/phone/mobile/email/stock_number/zicht_account',
    'user|share_type|max_per_user/amount',
    'user|max_shares_left/open_orders',
    'emission_key|id/modded_asn_key/proposed_value/modded_asn_key_of_user/unpaid_value/emission_id',
    'name', 'description', 'amount', 'confirmed', 'repay', 'reference', 'account', 'confirmed_at',
    'root_parent|to_tree'
  ];

  const fetchKey = () => {
    if (!!paymentId) {
      setLoader(true);
      fetch_one<Payment>('/nl/v3/objects/fetch_all', {
        object: 'payment',
        fields: fields,
        id: paymentId
      }, (ob, complete_data) => {
        setPayment(ob);
        setLoader(false);
      }, (z) => {
  
      }, login);
    }
  }

  const fetchKeys = () => {
    if (!!newUser && !!payment) {
      setLoader(true);
      fetch_all<EmissionKey>('/nl/v3/objects/fetch_all', {
        object: 'emission_key',
        fields: ['id', 'modded_asn_key', 'proposed_value', 'activated'],
        filter: {
          // search: '',
          advanced: {
            user_id: newUser?.id,
            emission_id: payment?.emission_key_emission_id,
            filter_profile: 99,
            filter_borderel: 99,
            filter_payment: 99,
            filter_deleted: 0,
            filter_email: 99,
            filter_verified: 0,
            handler_id: 99
          }
        }
      }, (obs, complete_data) => {
        setNewUserKeys(obs);
        setLoader(false);
      }, (z) => {
  
      }, login);
    } else {
      setNewUserKeys([]);
    }
  }

  const handlePayment = () => {
    fetch_one<{success: boolean}>('/nl/v3/objects/custom_action', {
      object: 'payment',
      class_action: 'custom_api_handle_payment',
      id: payment?.id,
      handler_id: login?.id,
      switcheroo_new: switcherooNew,
      switcheroo_new_user_id: newUser?.id,
      switcheroo_new_key: newUserKey,
      switcheroo_current: switcherooCurrent,
      switcheroo_refund: switcherooRefund,
      switcheroo_refund_reason: switcherooRefundReason
    }, (ob, complete_data) => {
      fetchKey();
    }, (z, y) => {
      
    }, login);
  };

  const unconfirm = () => {
    fetch_one<{success: boolean}>('/nl/v3/objects/custom_action', {
      object: 'payment',
      class_action: 'custom_api_unconfirm',
      id: payment?.id,
      handler_id: login?.id
    }, (ob, complete_data) => {
      fetchKey();
    }, (z, y) => {
      
    }, login);
  };

  useEffect(() => {
    fetchKey();
  }, []);

  useEffect(() => {
    fetchKeys();
  }, [newUser]);

  return (
    <Page
      // startEndCrumb={<StyledButton
      //   label="Back to checklist"
      //   id='back'
      //   text
      //   startIcon={<KeyboardBackspaceIcon />}
      //   path={`/emission/checklist`}
      // />}
    >
      <Grid container spacing={1}>
        <Grid item xs={4}>
          
          <PaymentCard payment={payment?.root_parent_to_tree} />

        </Grid>
        {!!payment && <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography variant="h3" sx={{
                color: theme.palette.primary.main,
                borderBottom: '1px solid transparent',
                borderColor: theme.palette.primary.main,
                display: 'inline'
              }}>{payment.reference}</Typography>
            </Grid>
            <Grid item xs={8} sx={{
              textAlign: 'right'
            }}>
              <Typography variant="h3" sx={{
                color: theme.palette.primary.main
              }}>{parseFloat(payment.amount.toString()).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography><span style={{fontWeight: 'bold'}}>Account: </span>{payment.account}</Typography>
            </Grid>
            <Grid item xs={8} sx={{
              textAlign: 'right'
            }}>
              <Typography>{DateTime.fromISO(payment.created_at).toFormat("D")}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography><span style={{fontWeight: 'bold'}}>Name: </span>{payment.name}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography><span style={{fontWeight: 'bold'}}>Description: </span>{payment.description || '<empty>'}</Typography>
            </Grid>

            {!!payment.user_id && <>
              <Grid item xs={12}>
                <Divider sx={{marginBottom: 3}} />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h3" sx={{
                  color: theme.palette.primary.main,
                  borderBottom: '1px solid transparent',
                  borderColor: theme.palette.primary.main,
                  display: 'inline'
                }}>Linked User Information</Typography>
                <StyledIconButton color="primary" newTab path={`/user/${payment.user_id}`}>
                  <LaunchIcon />
                </StyledIconButton>
              </Grid>
              <Grid item xs={6}>
                <Typography><span style={{fontWeight: 'bold'}}>First name: </span>{payment.user_first_name || linkedUser?.first_name || '<empty>'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><span style={{fontWeight: 'bold'}}>Last name: </span>{payment.user_last_name || linkedUser?.last_name || '<empty>'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><span style={{fontWeight: 'bold'}}>VennootNR: </span>{payment.user_vennoot_number || linkedUser?.vennoot_number || '<empty>'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><span style={{fontWeight: 'bold'}}>Birth Date: </span>{DateTime.fromISO(payment.user_birth_date || linkedUser?.birth_date || '<empty>').toFormat("D")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography><span style={{fontWeight: 'bold'}}>Address: </span>{payment.user_address || linkedUser?.address || '<empty>'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><span style={{fontWeight: 'bold'}}>Phone: </span>{payment.user_phone || linkedUser?.phone || '<empty>'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><span style={{fontWeight: 'bold'}}>Mobile: </span>{payment.user_mobile || linkedUser?.mobile || '<empty>'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><span style={{fontWeight: 'bold'}}>Email: </span>{payment.user_email || linkedUser?.email || '<empty>'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{
                  ...((payment.user_stock_number || linkedUser?.stock_number || 0) < 1 || (payment.user_stock_number || linkedUser?.stock_number || 0) > (payment.user_share_type_max_per_user || 200) ? {
                    color: 'red'
                  } : {})
                }}><span style={{fontWeight: 'bold'}}>Current Stock: </span>{payment.user_stock_number || linkedUser?.stock_number || '<empty>'}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography><span style={{fontWeight: 'bold'}}>Zicht Account: </span>{payment.user_zicht_account || linkedUser?.zicht_account || '<empty>'}</Typography>
              </Grid>
            </>}
            {!payment.user_id && !(!payment.confirmed) && <>
              <Grid item xs={12} sx={{paddingRight: 0.5, backgroundColor: '#98B6B4', marginLeft: 0.5}}>
                <UserSearch
                  initialValues={{name: payment.name}}
                  customUserLayout={["padding", "name", "vennr", "zicht_account"]}
                  fields={[
                    'id', 'first_name', 'stock_number', 'last_name', 'vennoot_number', 'birth_date', 'zicht_account', 
                    'transactions|last|transaction_date', 'transactions|first|stock_saldo_value', 'locale', 'share_type|amount', 
                    'address', 'phone', 'mobile', 'email', 'stock_number', 'max_shares_left', 'street', 'zip', 'city'
                  ]}
                  resultLayout={(user) => <Box sx={{
                    padding: 1,
                    cursor: 'pointer',
                    border: '1px solid transparent',
                    borderColor: theme.palette.primary.main,
                    marginBottom: 0.5
                  }} onClick={() => {
                    setPayment({...payment, user_id: user.id});
                    setLinkedUser(user);
                  }}>
                    <Typography sx={{fontWeight: 'bold'}}>
                      {user.first_name} {user.last_name}
                      <span style={{fontWeight: 'normal', float: 'right'}}>{user.zicht_account}</span>
                    </Typography>
                    <Typography>{user.street} {user.number}, {user.zip} {user.city}</Typography>
                    <StyledIconButton sx={{
                      position: 'absolute',
                      right: 1,
                      bottom: 1
                    }} title="Find by address" color="primary" onClick={(e) => {
                      setInitValues({name: '', vennoot_number: '', zicht_account: '', street: user.street || '', number: user.number || '', zip: user.zip || ''});
                    }}>
                      <TravelExploreIcon />
                    </StyledIconButton>
                  </Box>}
                />
              </Grid>
            </>}

            {!!payment.emission_key_id && <>
              <Grid item xs={12}>
                <Divider sx={{marginBottom: 3}} />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h3" sx={{
                  color: theme.palette.primary.main,
                  borderBottom: '1px solid transparent',
                  borderColor: theme.palette.primary.main,
                  display: 'inline'
                }}>ASN Information</Typography>
                <StyledIconButton color="primary" newTab path={`/emission/checklist/${payment.emission_key_id}`}>
                  <LaunchIcon />
                </StyledIconButton>
              </Grid>
              <Grid item xs={6}>
                <Typography><span style={{fontWeight: 'bold'}}>ASN: </span>{payment.emission_key_modded_asn_key || '<empty>'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><span style={{fontWeight: 'bold'}}>ASN (user): </span>{payment.emission_key_modded_asn_key_of_user || '<empty>'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><span style={{fontWeight: 'bold'}}>Amount: </span>{parseFloat(payment.emission_key_proposed_value?.toString() || '0').toFixed(0).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><span style={{fontWeight: 'bold'}}>Open amount: </span>{parseFloat(payment.emission_key_unpaid_value?.toString() || '0').toFixed(0).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR</Typography>
              </Grid>
            </>}

            <Grid item xs={12}>
              <Divider sx={{marginBottom: 3}} />
            </Grid>

            {/* <Grid item xs={12}>
              {JSON.stringify(payment.user_open_orders)}
            </Grid> */}

            <Grid item xs={12}>
              <Typography variant="h3" sx={{
                color: theme.palette.primary.main,
                borderBottom: '1px solid transparent',
                borderColor: theme.palette.primary.main,
                display: 'inline'
              }}>Payment Information</Typography>
              {!payment.confirmed && <Typography sx={{
                float: 'right',
                color: (parseFloat((switcherooCurrent + switcherooNew + switcherooRefund).toFixed(2)) === (parseFloat(parseFloat(payment.amount.toString()).toFixed(2))) ? theme.palette.primary.main : theme.palette.secondary.main)
              }}>{(switcherooCurrent + switcherooNew + switcherooRefund).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}/{parseFloat(payment.amount.toString()).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR</Typography>}
            </Grid>

            {!!payment.confirmed && <Grid item xs={12}>
              <Typography>Payment has been confirmed on {DateTime.fromISO(payment.confirmed_at).toFormat("D T")}</Typography>
              {/* unconfirm? */}
              <StyledButton
                label="Unconfirm (please don't do this)"
                id='unconfirm'
                contained
                onClick={(v) => {
                  unconfirm();
                }}
              />
            </Grid>}
            {!payment.confirmed && <Grid container item xs={12} spacing={1}>
              <Grid item xs={4}>
                <Typography sx={{
                  marginTop: 1
                }}>Add to current ASN</Typography>
                <Typography sx={{
                  fontSize: '0.7rem'
                }}>Amount available: {((payment.user_max_shares_left || 200) * (payment.user_share_type_amount || 50)).toFixed(0).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR</Typography>
                {/* <Typography sx={{
                  fontSize: '0.7rem'
                }}>Order of: {parseInt((payment.emission_key_proposed_value || 10000).toString(), 10).toFixed(0).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR</Typography> */}
              </Grid>
              <Grid item xs={8}>
                <StyledTextField
                  label="Current ASN"
                  type="number"
                  value={switcherooCurrent.toString() || ''}
                  id='switch_current'
                  onChange={(v) => {
                    setSwitcherooCurrent(parseInt(v, 10));
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{
                  marginTop: 0 + (!!newUser ? 0 : 1) + (!!newUserKey ? 0 : 1)
                }}>Add to other ASN</Typography>
                {!!newUser && <Typography sx={{
                  fontSize: '0.7rem'
                }}>User can have: {((newUser.max_shares_left || 200) * (newUser.share_type_amount || 50)).toFixed(0).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR</Typography>}
                {!!newUserKey && <Typography sx={{
                  fontSize: '0.7rem'
                }}>Order of: {parseInt((newUserKey?.proposed_value || 10000).toString(), 10).toFixed(0).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR</Typography>}
              </Grid>
              <Grid item xs={2}>
                <StyledTextField
                  label="Other ASN"
                  type="number"
                  value={switcherooNew.toString() || ''}
                  id='switch_new'
                  onChange={(v) => {
                    setSwitcherooNew(parseInt(v, 10));
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledButton
                  label={!!newUser ? `${newUser.first_name} ${newUser.last_name} (${newUser.vennoot_number})` : "Pick user"}
                  contained={!!newUser}
                  id='pick_user'
                  onClick={(v) => {
                    setShowNewUserPicker(!showNewUserPicker);
                    setShowNewUserKeyPicker(false);
                  }}
                  sx={{
                    width: '100%',
                    marginTop: 1
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledButton
                  label={!!newUserKey ? (newUserKey.new_key ? 'New key (+++//+++)' : `${newUserKey.modded_asn_key}`) : "Pick key"}
                  disabled={!newUser}
                  contained={!!newUserKey}
                  id='pick_user_key'
                  onClick={(v) => {
                    setShowNewUserKeyPicker(!showNewUserKeyPicker);
                    setShowNewUserPicker(false);
                  }}
                  sx={{
                    width: '100%',
                    marginTop: 1
                  }}
                />
              </Grid>
              {showNewUserPicker && <Grid item xs={12} sx={{paddingRight: 0.5, backgroundColor: '#98B6B4', marginLeft: 0.5}}>
                <UserSearch
                  initialValues={initValues}
                  customUserLayout={["padding", "name", "vennr", "zicht_account", "padding", "address"]}
                  fields={[
                    'id', 'first_name', 'stock_number', 'last_name', 'vennoot_number', 'birth_date', 'zicht_account', 
                    'transactions|last|transaction_date', 'transactions|first|stock_saldo_value', 'locale', 'share_type|amount', 
                    'address', 'phone', 'mobile', 'email', 'stock_number', 'max_shares_left', 'street', 'zip', 'city'
                  ]}
                  resultLayout={(user) => <Box sx={{
                    padding: 1,
                    cursor: 'pointer',
                    border: '1px solid transparent',
                    borderColor: theme.palette.primary.main,
                    marginBottom: 0.5,
                    position: 'relative'
                  }} onClick={() => {
                    setNewUser(user);
                    setShowNewUserPicker(false);
                  }}>
                    <Typography sx={{fontWeight: 'bold'}}>
                      {user.first_name} {user.last_name}
                      <span style={{fontWeight: 'normal', float: 'right'}}>{user.zicht_account}</span>
                    </Typography>
                    <Typography>{user.street} {user.number}, {user.zip} {user.city}</Typography>
                    <StyledIconButton sx={{
                      position: 'absolute',
                      right: 1,
                      bottom: 1
                    }} title="Find by address" color="primary" onClick={(e) => {
                      setInitValues({name: '', vennoot_number: '', zicht_account: '', street: user.street || '', number: user.number || '', zip: user.zip || ''});
                    }}>
                      <TravelExploreIcon />
                    </StyledIconButton>
                  </Box>}
                />
              </Grid>}
              {showNewUserKeyPicker && <Grid item xs={12}>
                <Typography sx={{textAlign: 'center'}}>ASN keys</Typography>
                <Divider sx={{marginTop: 1, marginBottom: 1}} />
                <Box sx={{
                  cursor: 'pointer',
                  padding: 1,
                  border: '1px solid transparent',
                  borderColor: theme.palette.primary.main,
                  margin: 0.5,
                  display: 'inline-block',
                  "&:hover": {
                    borderColor: theme.palette.tertiary?.main,
                    backgroundColor: theme.palette.opacitated?.main_01
                  }
                }} onClick={() => {
                  setNewUserKey({new_key: true, email_list: [], notes_list: [], system_notes_list: [], asn_key: ''});
                  setShowNewUserKeyPicker(false);
                }}>
                  <Typography>+++//+++</Typography>
                  <Typography sx={{
                    fontSize: '0.7rem'
                  }}>New ASN with amount</Typography>
                  <Typography sx={{
                    fontSize: '0.7rem'
                  }}>Activated now</Typography>
                </Box>
                {newUserKeys.map(kk => <Box sx={{
                  cursor: 'pointer',
                  padding: 1,
                  border: '1px solid transparent',
                  borderColor: theme.palette.primary.main,
                  margin: 0.5,
                  display: 'inline-block',
                  "&:hover": {
                    borderColor: theme.palette.tertiary?.main,
                    backgroundColor: theme.palette.opacitated?.main_01
                  }
                }} onClick={() => {
                  setNewUserKey(kk);
                  setShowNewUserKeyPicker(false);
                }}>
                  <Typography>{kk.modded_asn_key}</Typography>
                  <Typography sx={{
                    fontSize: '0.7rem'
                  }}>Order of: {parseInt((kk.proposed_value || 10000).toString(), 10).toFixed(0).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR</Typography>
                  <Typography sx={{
                    fontSize: '0.7rem'
                  }}>Activated: {kk.activated}</Typography>
                </Box>)}
              </Grid>}
              <Grid item xs={4}>
                <Typography sx={{
                  marginTop: 2
                }}>Payback</Typography>
              </Grid>
              <Grid item xs={4}>
                <StyledTextField
                  label="Payback"
                  type="number"
                  value={switcherooRefund.toString() || ''}
                  id='switch_refund'
                  onChange={(v) => {
                    setSwitcherooRefund(parseInt(v, 10));
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <StyledSelect
                  label="Payback reason"
                  value={switcherooRefundReason || ''}
                  id='refund_reason'
                  onChange={(v) => {
                    setSwitcherooRefundReason(v);
                  }}
                  list={[
                    {id: '', name: "--- no reason ---"},
                    {id: 'pay_double', name: "Double payment"},
                    {id: 'pay_too_much', name: "Paid too much"},
                    {id: 'dead', name: "Died during process"},
                    {id: 'unknown', name: "Unkown sender"},
                    {id: 'pay_wrong', name: "Paid wrongly"},
                    {id: 'no_adult', name: "Not an adult"},
                    {id: 'too_late', name: "Paid too late"}
                  ]}
                />
              </Grid>
              <Grid item xs={12} sx={{textAlign: 'center'}}>
                <StyledButton
                  label="Handle"
                  id='handle'
                  disabled={
                    parseFloat((switcherooCurrent + switcherooNew + switcherooRefund).toFixed(2)) !== (parseFloat(parseFloat(payment.amount.toString()).toFixed(2))) ||
                    (switcherooNew > 0 && (!newUser || !newUserKey)) ||
                    (switcherooRefund > 0 && !switcherooRefundReason)
                  }
                  contained
                  onClick={(v) => {
                    handlePayment();
                  }}
                />
              </Grid>
            </Grid>}

            <Grid item xs={12}>
              <Divider sx={{marginBottom: 3}} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3" sx={{
                color: theme.palette.primary.main,
                borderBottom: '1px solid transparent',
                borderColor: theme.palette.primary.main,
                display: 'inline'
              }}>Notes</Typography>
            </Grid>
            <Grid item xs={12} sx={{"& > div": {marginTop: 0}}}>
              <NoteModule
                handled_id={payment.id || ''}
                user_id={payment.user_id || ''}
                hideTitle
                handled_type="Payment"
                refreshNotes={refreshNotes}
                setRefreshNotes={(b) => {setRefreshNotes(b);}}
              />
            </Grid>
          </Grid>
        </Grid>}
      </Grid>
    </Page>
  );
}
