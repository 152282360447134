import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useAtom } from 'jotai';

import {
  Fab
} from '@mui/material';

// import { Typography } from './Typography';

type LanguageFabProps = {
  setActiveLocale: (a:'nl' | 'fr') => void;
  activeLocale: 'nl' | 'fr';
};

export const LanguageFab: FunctionComponent<LanguageFabProps> = ({setActiveLocale, activeLocale}) => {
  const theme = useTheme();

  return (<React.Fragment>
    <Fab aria-label="language" sx={{ zIndex: 1202, position: 'fixed', bottom: 16, right: 16, backgroundColor: theme.palette.tertiary?.main, color: theme.palette.primary.main }} onClick={() => {
      setActiveLocale(activeLocale === 'nl' ? 'fr' : 'nl');
    }}>
      {activeLocale === 'nl' ? <>NL</> : <>FR</>}
    </Fab>
  </React.Fragment>);
}