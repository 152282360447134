import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';

import { fetch_all, fetch_one } from '../lib/server_helper';

import {
  Box,
  CircularProgress,
  Grid
} from '@mui/material';

import { Page } from '../components/interface/Page';
import { StyledButton } from '../components/interface/StyledButton';
import { StyledDropzone } from '../components/interface/StyledDropzone';
import { StyledTextField } from '../components/interface/StyledTextField';
import { TimelineBase } from '../components/timeline/Base';
import { Typography } from '../components/interface/Typography';

import { UserNote } from '../models/UserNote';
import { loginAtom } from '../lib/auth';
import { useParams } from 'react-router-dom';

type UserDetailNotesProps = {};

export const UserDetailNotes: FunctionComponent<UserDetailNotesProps> = () => {
  const theme = useTheme();
  
  const params = useParams();
  const userId: string | undefined = params.id;

  const [login, setLogin] = useAtom(loginAtom);
  const [notes, setNotes] = useState<UserNote[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);

  const [item, setItem] = useState<File>();
  const [comment, setComment] = useState<string>('');

  const saveNote = () => {
    setSaveLoader(true);

    let formData = new FormData();
    formData.append('object', 'user_note');
    formData.append('ob[comment]', comment);
    if (!!userId) formData.append('ob[user_id]', userId.toString());
    if (!!login?.id) formData.append('ob[admin_id]', login.id.toString());
    if (!!item) formData.append('files[item]', item);
    
    fetch_one<UserNote>('/nl/v3/objects/save', formData, (ob, complete_data) => {
      fetchNotes();
      setSaveLoader(false);

      setComment('');
      setItem(undefined);
    }, (z) => {

    }, login)
  };

  const fetchNotes = () => {
    setLoader(true);
    
    fetch_all<UserNote>('/nl/v3/objects/fetch_all', {
      object: 'user_note',
      fields: [
        'id', 'type', 'class|name', 'comment', 'created_at', 'admin_name', 'get_item', 'get_blank',
        'get_identity_front', 'get_lijst201', 'handled_id', 'handled_type', 'enabled', 'get_fiscal_item',
        'user|first_name/last_name/vennoot_number/created_at', 'dividend|book_year|start_year'
      ],
      filter: {
        advanced: {
          user_id: userId
        }
      }
    }, (ob, complete_data) => {
      setNotes(ob);
      setLoader(false);
    }, (z) => {

    }, login)
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  let user = '';
  let user_created = '';
  notes.map(note => {
    if (!user) {
      if (!!note.user_first_name) {
        user = [note.user_first_name, note.user_last_name, `(${note.user_vennoot_number})`].join(" ");
        if (!!note.user_created_at) user_created = note.user_created_at;
      }
    }
  });

  return (
    <Page sx={{}} title={`${!!user && user}`}>
      <Grid container spacing="8">
        {!!loader && <Grid item xs={12}>
          <CircularProgress />
        </Grid>}

        <Grid item xs={12} sx={{paddingTop: '0px !important'}}>
          <Box
            sx={{
              padding: 1,
              paddingLeft: 8,
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            <Box sx={{
              width: '2px',
              backgroundColor: theme.palette.primary.main,
              height: '100%',
              position: 'absolute',
              top: 0,
              left: '8px',
              zIndex: 998
            }} />
            <Box sx={{
              width: '9px',
              border: '2px solid transparent',
              borderColor: theme.palette.primary.main,
              backgroundColor: 'white',
              height: '9px',
              position: 'absolute',
              top: 'calc(50% - 5px)',
              left: '3px',
              borderRadius: '8px',
              zIndex: 999
            }} />
            <Box sx={{
              width: '64px',
              backgroundColor: theme.palette.primary.main,
              height: '1px',
              position: 'absolute',
              top: '50%',
              left: 0,
              zIndex: 997
            }} />
            <Box
              sx={{
                padding: 1,
                border: '1px solid transparent',
                borderRadius: '10px 0px 0px 10px',
                borderColor: theme.palette.primary.main
              }}
            >
              <Grid container spacing="8">
                <Grid item xs={12}>
                  <Typography>New note</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    label="Comment"
                    value={comment}
                    id='comment'
                    onChange={(v) => {setComment(v);}}
                    sx={{width: '98%'}}
                    multiline
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography sx={{fontSize: '0.75rem'}}>Bijlage</Typography>
                  <StyledDropzone
                    acceptedFiles={{
                      'image/*': [],
                      'application/pdf': []
                    }}
                    onDrop={acceptedFiles => {setItem(acceptedFiles[0]);}}
                    multiple={false}
                    maxFiles={1}
                    maxSize={5 * 1000 * 1000}
                    items={!!item ? [item] : []}
                    dropText="Click here, or drop some files."
                    dropHint="Allowed: 1 image (.png, .jpeg, .jpg, .gif) or PDF (.pdf), < 5mb"
                  />
                </Grid>
                {!saveLoader && <Grid item xs={12}>
                  <StyledButton
                    label="save"
                    id='save'
                    contained
                    onClick={(v) => {
                      saveNote();
                    }}
                    sx={{alignSelf: 'center'}}
                  />
                </Grid>}
              </Grid>
            </Box>
          </Box>
        </Grid>

        {notes.map(note => <TimelineBase note={note} />)}
        {!!notes && !!notes[0] && <TimelineBase note={notes[0]} creation={user_created} />}

      </Grid>
    </Page>
  );
}
