import { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

import {
  Avatar,
  Box
} from '@mui/material';

import TreeLogo from '../images/favicon.png';

import { Link } from '../components/interface/Link';
import { Typography } from '../components/interface/Typography';

type GenericErrorProps = {
  detail?: string;
  hide_detail?: boolean;
  cta_label?: string;
  cta_link?: string;
};

export const GenericError: FunctionComponent<GenericErrorProps> = ({detail, hide_detail, cta_label, cta_link}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  let detailed_explanation = '';
  if (detail === 'invalid_rights') detailed_explanation = 'You are either not logged in, or you have invalid rights to access this page.';
  if (detail === 'not_signed_in') detailed_explanation = 'You are not signed in.';

  return (
    <Box sx={{
      width: '600px',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Avatar sx={{alignSelf: 'center', width: '200px', height: '200px', marginBottom: 4, marginTop: 4}} src={TreeLogo} />

      {!hide_detail && <Typography variant="h3" sx={{color: theme.palette.secondary.main, textAlign: 'center', marginBottom: 2}}>An error occurred. You have arrived at this errorpage.</Typography>}
      {!hide_detail && <Typography sx={{textAlign: 'center'}}>Following details were found while throwing this error:<br />{detailed_explanation}</Typography>}

      {!!hide_detail && <Typography sx={{color: theme.palette.secondary.main, textAlign: 'center'}}>{detailed_explanation}</Typography>}

      <Link sx={{textAlign: 'center', marginTop: 4}} label={!!cta_label ? cta_label : 'Take me back'} onClick={() => {navigate(!!cta_link ? cta_link : "/");}} />
    </Box>
  );
}
