import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all } from '../../lib/server_helper';

// import {
//   Avatar,
//   Box
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton
} from '../../components/interface';

import { Custom201 } from '../../models/Custom201';
import { loginAtom } from '../../lib/auth';
import { CircularProgress } from '@mui/material';

const { DateTime } = require("luxon");

type ExitCustom201Props = {};

export const ExitCustom201: FunctionComponent<ExitCustom201Props> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);

  const [custom201s, setCustom201s] = useState<Custom201[]>([]);
  const [custom201sCount, setCustom201sCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("updated_at DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onSearch = (s: string) => {
    setLoader(true);
    fetch_all<Custom201>('/nl/v3/objects/fetch_all', {
      object: 'custom201',
      fields: ['id', 'created_at', 'updated_at', 'first_name', 'last_name', 'extra_data', 'get_item', 'user|vennoot_number'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s
      }
    }, (obs, complete_data) => {
      setCustom201s(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setCustom201sCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, sortable: true },
    { field: 'created_at', headerName: 'Created', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'updated_at', headerName: 'Updated', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'vennoot', headerName: 'Deceased', width: 200, sortable: false },
    { field: 'spouse', headerName: 'Spouse', width: 200, sortable: false }
  ];

  return (
    <Page sx={{}} title="Custom201s" helper="custom201s" actions={<>
      <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={'/custom201/new'}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}>
      <SearchBox
        simple
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
        memo="custom201s"
      />
      <DataGrid
        action_count={1}
        actions={(params) => {
          return (<>
            <StyledIconButton color="primary" path={`/custom201/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={custom201sCount}
        objects={(custom201s || []).map(u => ({
          ...u,
          vennoot: u.extra_data?.custom201?.name,
          spouse: u.extra_data?.custom201?.spouse_name
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
