import React, { FunctionComponent } from 'react';

import ArgenCoLogo from '../../../images/Argenco_logo_green_rgb.png';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import {
  Grid,
  IconButton
} from '@mui/material';

import {
  Typography
} from '../../interface';

import { BenefitBlock } from '../../../models/BenefitBlock';
import { EmailBlock } from '../../../models/EmailBlock';

type BlockPublicTextCustomFooterProps = {
  block: EmailBlock | BenefitBlock;
  marked: boolean;
  activeLocale: 'nl' | 'fr';
};

export const BlockPublicTextCustomFooter: FunctionComponent<BlockPublicTextCustomFooterProps> = ({block, marked}) => {
  return ( <Grid xs={12} sx={{
    marginTop: block.settings?.mtop || 0,
    marginBottom: block.settings?.mbot || 0,
    // border: `1px solid ${block.settings?.border || 'transparent'}`,
    padding: '12px',
    // ...(!!marked ? {backgroundColor: 'rgba(255, 255, 0, 0.3)'} : {}),
    backgroundColor: '#e1e8df !important',
    borderRadius: '10px'
  }}>
    <Typography html nw_text sx={{
      textAlign: 'left',
      position: 'relative',
      "& p > a": {
        color: '#185459 !important', textDecoration: 'underline !important'
      }
    }}>
      {/* <img src="https://old.argenco.be/cdn/email_logo_new.png" style={{height: "50px"}} /> */}
      <img src={ArgenCoLogo} style={{height: "30px", marginBottom: 16}} />
      <p><span style={{fontWeight: 'bold'}}>Argen-Co erkende CV</span><br/>Belgiëlei 49-53<br/>2018 Antwerpen</p>
      <p style={{marginTop: 16}}><a href="https://www.argenco.be/" style={{color: '#185459 !important', textDecoration: 'underline !important'}} target="_BLANK">www.argenco.be</a></p>
      {/* <p style={{marginTop: '3px', marginBottom: '3px'}}><a href="https://www.facebook.com/argencocv" target="_BLANK">
        <img width="12" height="12" src="https://old.argenco.be/cdn/email_facebook.png" style={{maxHeight: '12px', height: '12px', marginTop: '1px'}} /><span style={{paddingLeft: '6px'}}>@argencocv</span></a>
      </p>
      <p style={{marginTop: '3px', marginBottom: '3px'}}><a href="https://www.facebook.com/argencocv" target="_BLANK">
        <img width="12" height="12" src="https://old.argenco.be/cdn/email_instagram.png" style={{maxHeight: '12px', height: '12px', marginTop: '1px'}} /><span style={{paddingLeft: '6px'}}>@argencocv</span></a>
      </p>
      <p style={{marginTop: '3px', marginBottom: '3px'}}><a href="https://www.linkedin.com/company/argen-co/" target="_BLANK">
        <img width="12" height="12" src="https://old.argenco.be/cdn/email_linkedin.png" style={{maxHeight: '12px', height: '12px', marginTop: '1px'}} /><span style={{paddingLeft: '6px'}}>Argen-Co</span></a>
      </p> */}
      {/* <p><a href="" target="_BLANK">Klik hier om u af te melden</a></p> */}
      <IconButton onClick={() => {
        window.open("https://www.facebook.com/argencocv", "_BLANK");
      }} sx={{
        position: 'absolute',
        top: 0,
        right: 72
      }}>
        <FacebookIcon sx={{color: '#185459'}} />
      </IconButton>
      <IconButton onClick={() => {
        window.open("https://www.linkedin.com/company/argen-co/", "_BLANK");
      }} sx={{
        position: 'absolute',
        top: 0,
        right: 36
      }}>
        <LinkedInIcon sx={{color: '#185459'}} />
      </IconButton>
      <IconButton onClick={() => {
        window.open("https://www.instagram.com/argencocv/", "_BLANK");
      }} sx={{
        position: 'absolute',
        top: 0,
        right: 0
      }}>
        <InstagramIcon sx={{color: '#185459'}} />
      </IconButton>
    </Typography>
  </Grid>);
};
