import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';

import { fetch_all } from '../../lib/server_helper';

import {
  Box,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import { SearchBox } from './SearchBox';
import { Typography } from './Typography';

import { Notary } from '../../models/Notary';
import { loginAtom } from '../../lib/auth';

type NotarySearchProps = {
  fields: string[];
  resultLayout: (u:Notary) => React.ReactNode;
  initialValues?: {catch_all?: string};
};

export const NotarySearch: FunctionComponent<NotarySearchProps> = ({initialValues, fields, resultLayout}) => {
  const theme = useTheme();

  const [login, setLogin] = useAtom(loginAtom);

  const [loader, setLoader] = useState<boolean>(true);
  const [searchOptions, setSearchOptions] = useState<{catch_all?: string} | undefined>(initialValues);
  const [notaries, setNotaries] = useState<Notary[]>([]);
  const [notariesCount, setNotariesCount] = useState<number>(0);

  const onSearch = () => {
    setLoader(true);
    fetch_all<Notary>('/nl/v3/objects/fetch_all', {
      object: 'notary',
      fields: ['id', 'name', 'email', 'replyto', 'number', 'street', 'zip', 'city'].concat(fields),
      page: 0,
      per_page: 10,
      filter: {
        search: '',
        advanced: searchOptions
      }
    }, (obs, complete_data) => {
      setNotaries(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setNotariesCount(count);

      setLoader(false);
    }, (z) => {

    }, login);
  };

  useEffect(() => {
    onSearch();
  }, [searchOptions]);

  return (<React.Fragment>
    <Grid container item xs={12}>
      <SearchBox
        sx={{width: '100%'}}
        initialValues={initialValues}
        onSearch={(s) => {
          setSearchOptions({catch_all: s});
        }}
        simple
        hide_reset
      />
    </Grid>
    <Grid item xs={12}>
      <Box sx={{marginTop: 0}}>
        <Typography>{notariesCount} objects found...</Typography>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Divider sx={{width: '100%', marginTop: 1, marginBottom: 1}} />
    </Grid>
    {!!loader && <Grid item xs={12}><CircularProgress /></Grid>}
    <Grid item xs={12}>
      {notariesCount > 0 && <React.Fragment>
        {notaries.map(resultLayout)}
      </React.Fragment>}
    </Grid>
  </React.Fragment>);
}