import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import {
  Box
} from '@mui/material';

import {Sidebar} from '../components/Sidebar';

type LayoutProps = {
  sx?: object;
  children?: React.ReactNode;
  version?: string;
  loginId?: number;
}

export const Layout: FunctionComponent<LayoutProps> = ({sx, children, version}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // check queues

  return (<Box sx={{
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%'
  }}>
    <Box sx={{
      flexBasis: {xs: 40, md: 200},
      flexGrow: 1,
      position: 'relative',
      paddingBottom: 8,
      backgroundColor: theme?.palette?.primary_grey?.light
    }}>
      <Box sx={{
        width: 'auto',
        padding: 1
      }}>
        <Sidebar />
      </Box>

      <Box sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        padding: 1,
        cursor: 'pointer'
      }} onClick={() => {
        navigate(`/help/versioning`);
      }}>
        v{version}
      </Box>
    </Box>
    <Box sx={{
      flexBasis: 0,
      flexGrow: 999,
      maxWidth: "calc(100% - 200px)"
    }}>
      <Box sx={{
        width: 'auto',
        padding: 4
      }}>
        {children}
      </Box>
    </Box>
  </Box>);
}

