import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';
import { loginAtom } from '../../lib/auth';

import {
  Box,
  Grid,
  Tooltip
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CommentIcon from '@mui/icons-material/Comment';
import ReplayIcon from '@mui/icons-material/Replay';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  DataGrid,
  Page,
  StyledIconButton,
  StyledSelect,
  StyledTextField,
  Typography
} from '../../components/interface';

import { Emission } from '../../models/Emission';
import { Payment } from '../../models/Payment';

const { DateTime } = require("luxon");

type Props = {};

export const EmissionPayments: FunctionComponent<Props> = () => {
  // const navigate = useNavigate();
  const theme = useTheme();

  const [login, setLogin] = useAtom(loginAtom);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [emissions, setEmissions] = useState<Emission[]>([]);
  const [paymentsCount, setPaymentsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("created_at DESC");

  const [search, setSearch] = useState<string>("");
  const [searchEmission, setSearchEmission] = useState<number>();
  const [enterPressed, setEnterPressed] = useState(false);
  const [filterVerified, setFilterVerified] = useState(0);
  const [filterRepay, setFilterRepay] = useState(99);

  useEffect(() => {
    onSearch('');
  }, [page, order, filterVerified, filterRepay, search]);

  const getEmissions = () => {
    fetch_all<Emission>('/nl/v3/objects/fetch_all', {
      object: 'emission',
      fields: ['id', 'start_date', 'end_date'],
      per_page: 1000,
      page: 0,
      order: "start_date DESC"
    }, (obs, complete_data) => {
      setEmissions(obs);
      setSearchEmission(obs[0].id)
    }, (z) => {
      // setServerErrorSnackOpen(true);
    }, login);
  };

  const onSearch = (s: string) => {
    setLoader(true);
    fetch_all<Payment>('/nl/v3/objects/fetch_all', {
      object: 'payment',
      fields: [
        'id', 'created_at', 'user|vennoot_number', 'name', 'description', 'amount', 'confirmed', 'repay'
      ],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: '',
        advanced: {
          emission_id: searchEmission,
          filter_verified: filterVerified,
          filter_repay: filterRepay,
          catch_all: search
        }
      }
    }, (obs, complete_data) => {
      setPayments(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setPaymentsCount(count);

      setLoader(false);
    }, (z) => {

    }, login)
  };

  useEffect(() => {
    getEmissions();
  }, []);

  const columns: GridColDef[] = [
    { field: 'user_vennoot_number', headerName: 'VenNR', width: 150 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'description', headerName: 'Description', width: 100, renderCell: params => {
      return (<>
        <Tooltip title={params.row.description}>
          <CommentIcon />
        </Tooltip>
      </>);
    } },
    { field: 'amount', headerName: 'Amount', width: 150, align: 'right', renderCell: params => <>
      <Typography sx={{fontSize: '0.9rem'}}>
        {`${parseFloat(params.row.amount).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR`}
      </Typography>
    </> },
    { field: 'icons', headerName: 'States', width: 140, renderCell: params => <>
      <CheckCircleIcon sx={{
        color: (params.row.confirmed ? 'green' : 'red')
      }} />
      <ReplayIcon sx={{
        color: (params.row.repay ? 'green' : 'red')
      }} />
    </> },
  ];

  const renderFilter = (Icon: React.ComponentType<{
    sx?: any;
    onClick?: () => void
  }>, name: string, active: number, setActive: (n:number) => void, include_red: boolean, include_gold: boolean) => {
    return (<Box sx={{
      border: '2px solid transparent',
      borderColor: theme.palette.primary.main,
      textAlign: 'center'
    }}>
      <Box sx={{
        borderBottom: '2px solid transparent',
        borderColor: theme.palette.primary.main,
        paddingTop: 0.5,
        paddingBottom: 0.5
      }}>
        <Typography>{name}</Typography>
      </Box>
      <Box sx={{
        paddingTop: 0.5,
        paddingBottom: 0.5
      }}>
        <Icon
          sx={{height: '32px', width: 'auto', color: 'black', cursor: 'pointer', opacity: active === 99 ? 1 : 0.3}}
          onClick={() => {
            setActive(99);
          }}
        />
        {!!include_red && <Icon
          sx={{height: '32px', width: 'auto', color: 'red', cursor: 'pointer', opacity: active === 0 ? 1 : 0.3}}
          onClick={() => {
            setActive(0);
          }}
        />}
        {!!include_gold && <Icon
          sx={{height: '32px', width: 'auto', color: 'gold', cursor: 'pointer', opacity: active === 2 ? 1 : 0.3}}
          onClick={() => {
            setActive(2);
          }}
        />}
        <Icon
          sx={{height: '32px', width: 'auto', color: 'green', cursor: 'pointer', opacity: active === 1 ? 1 : 0.3}}
          onClick={() => {
            setActive(1);
          }}
        />
      </Box>
    </Box>);
  }

  return (
    <Page sx={{}} title="Payments" helper="payments">

      <Box sx={{
        backgroundColor: theme.palette.quaternary?.main,
        padding: 1,
        position: 'relative',
        overflow: 'hidden',
        marginBottom: 2
      }}>
        <SearchIcon sx={{position: 'absolute', top: -8, left: -8, height: 100, width: 100, opacity: 0.2}} />
        <Box>
          <Grid container sx={{paddingRight: 1}} spacing={2}>

            <Grid item xs={4}>
            </Grid>
            <Grid item xs={4}>
              <StyledSelect
                label="Emission"
                value={searchEmission?.toString() || ''}
                id='emission_filter'
                onChange={(v) => {
                  setSearchEmission(parseInt(v, 10));
                }}
                list={emissions.map(emission => ({id: (emission.id || ''), name: [DateTime.fromISO(emission.start_date).toFormat("D"), DateTime.fromISO(emission.end_date).toFormat("D")].join(" - ")}))}
                sx={{backgroundColor: "#a9c2c0"}}
              />
            </Grid>
            <Grid item xs={4}>
              <StyledTextField
                label="Search..."
                sx={{width: '100%', "input": {backgroundColor: '#a9c2c0', padding: 1}}}
                value={search}
                id='search'
                onChange={(v) => {
                  setSearch(v);
                }}
                setEnterPressed={setEnterPressed}
              />
            </Grid>

            <Grid item container xs={12}>
              <Grid item container xs={4}>
              </Grid>
              <Grid item container xs={4}>
                <Grid item xs={6} sx={{padding: 0.5}}>
                  {renderFilter(CheckCircleIcon, 'Verified', filterVerified, setFilterVerified, true, false)}
                </Grid>
                <Grid item xs={6} sx={{padding: 0.5}}>
                  {renderFilter(ReplayIcon, 'Repay', filterRepay, setFilterRepay, true, false)}
                </Grid>
              </Grid>
              <Grid item container xs={4}>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      
      <DataGrid
        action_count={1}
        actions={(params) => {
          return (<>
            <StyledIconButton title="Open Detail" color="primary"  onClick={() => {
              window.open(`/emission/payment/${params.row.id}`, "_BLANK");
            }}>
              <VisibilityIcon />
            </StyledIconButton>
          </>);
        }}
        objectCount={paymentsCount}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objects={(payments || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />
    </Page>
  );
}
