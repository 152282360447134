import { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate } from "react-router-dom";

import do_login, { loginAtom } from '../lib/auth';

import {
  Avatar,
  Box
} from '@mui/material';

import TreeLogo from '../images/favicon.png';

import { Link } from '../components/interface/Link';
import { StyledButton } from '../components/interface/StyledButton';
import { StyledTextField } from '../components/interface/StyledTextField';
import { Typography } from '../components/interface/Typography';

type LoginProps = {};

export const Login: FunctionComponent<LoginProps> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  return (
    <Box sx={{
      width: '400px',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Avatar sx={{alignSelf: 'center', width: '200px', height: '200px', marginBottom: 4, marginTop: 4}} src={TreeLogo} />

      {!login && <StyledTextField
        label="Username"
        value={username}
        id='username'
        onChange={(v) => {setUsername(v);}}
      />}

     {!login &&  <StyledTextField
        label="Password"
        type="password"
        value={password}
        id='password'
        onChange={(v) => {setPassword(v);}}
      />}

      {!login && <StyledButton
        label="login"
        id='login'
        onClick={(v) => {
          do_login(username, password, (result) => {
            setLogin(result);
            navigate("/");
          });
        }}
        sx={{alignSelf: 'center'}}
      />}

      {!!login && <Typography sx={{color: theme.palette.secondary.main, textAlign: 'center'}}>You are currently logged in</Typography>}
      {!!login && <Link sx={{textAlign: 'center', marginTop: 2}} label='Take me back' onClick={() => {navigate("/");}} />}
    </Box>
  );
}
