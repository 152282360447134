import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../lib/server_helper';

import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import LaunchIcon from '@mui/icons-material/Launch';

import { DashboardBase } from '../components/dashboard/Base';
import { Page } from '../components/interface/Page';
import { GenericError } from './GenericError';
import { StyledButton } from '../components/interface/StyledButton';
import { Typography } from '../components/interface/Typography';

import { Transactional } from '../models/Transactional';
import { loginAtom } from '../lib/auth';
import { StyledSelect } from '../components/interface';
import { BookYear } from '../models';

const { DateTime } = require("luxon");

type DashboardProps = {};

export const Dashboard: FunctionComponent<DashboardProps> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  
  const [login, setLogin] = useAtom(loginAtom);
  const [transactionals, setTransactionals] = useState<Transactional[]>([]);
  const [bookYears, setBookYears] = useState<BookYear[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [statLoader, setStatLoader] = useState<boolean>(false);
  const [statDone, setStatDone] = useState<boolean>(false);
  const [statErrorDone, setStatErrorDone] = useState<boolean>(false);
  const [hoverTransactional, setHoverTransactional] = useState<Transactional|undefined>();
  const [selectedYear, setSelectedYear] = useState<number|string>('');

  let show_marc_stats = true;
  if (!!login && login.extra_data?.settings?.hide_dashboard === '1') show_marc_stats = false;
  let tile_statics = false;
  if (!!login && login.extra_data?.settings?.tile_statics === '1') tile_statics = true;
  let tile_exits = false;
  if (!!login && login.extra_data?.settings?.tile_exits === '1') tile_exits = true;
  let tile_deaths = false;
  if (!!login && login.extra_data?.settings?.tile_deaths === '1') tile_deaths = true;

  useEffect(() => {
    getBookYears();
    if (!!show_marc_stats) onSearch();
  }, [login]);

  const getBookYears = () => {
    fetch_all<BookYear>('/nl/v3/objects/fetch_all', {
      object: 'book_year',
      fields: ['id', 'short_visual'],
      per_page: 1000,
      page: 0,
      order: "id DESC"
    }, (obs, complete_data) => {
      setBookYears(obs);
    }, (z) => {
      // setServerErrorSnackOpen(true);
    }, login);
  };

  const onSearch = () => {
    if (!!login && show_marc_stats) {
      setLoader(true);
      setStatDone(false);
      setStatErrorDone(false);

      fetch_all<Transactional>('/nl/v3/objects/fetch_all', {
        object: 'transactional',
        fields: ['id', 'year', 'month', 'extra_data'],
        per_page: 14,
        page: 0,
        order: "year DESC, month DESC",
        filter: {
          advanced: {
            book_year_id: selectedYear,
            test: 1
          }
        }
      }, (obs, complete_data) => {
        setTransactionals(obs.reverse());
        setLoader(false);
      }, (z) => {
        
      }, login);
    }
  };
  const clearCache = () => {
    fetch_one<Transactional>('/nl/v3/objects/custom_action', {
      object: 'user',
      class_action: 'custom_api_cache_clear'
    }, (obs, complete_data) => {
      
    }, (z, y) => {
      
    }, login)
  };
  const recalculateStats = () => {
    setStatLoader(true);
    fetch_one<Transactional>('/nl/v3/objects/custom_action', {
      object: 'transactional',
      class_action: 'custom_api_calculate_all'
    }, (obs, complete_data) => {
      setStatDone(true);
      setStatLoader(false);
    }, (z, y) => {
      if (!y.response) setStatErrorDone(true);
      setStatLoader(false);
    }, login)
  };










  const calculationTypo = (current_id:number, hovered_id:number, current_amount:number, hovered_amount:number, addition:React.ReactNode) => {
    return (<Typography sx={{
      fontSize: '0.6rem',
      fontFamily: 'Roboto Mono',
      position: 'absolute',
      right: 8,
      bottom: -2,
      opacity: (!!hovered_id && hovered_id !== current_id ? 1: 0),
      color: (!!hovered_id ? (hovered_amount > current_amount ? theme.palette.secondary.main : (hovered_amount < current_amount ? theme.palette.primary.main : 'grey')) : 'grey')
    }}>
      {(!!hovered_id ? (hovered_amount < current_amount ? "+" : (hovered_amount > current_amount ? "" : '')) : '')}
      {(current_amount - hovered_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{addition}
    </Typography>);
  };

  let previous_transactional:Transactional|undefined = undefined;

  useEffect(() => {
    onSearch();
  }, [selectedYear]);

  return (!!login ? <Page sx={{}} title="Dashboard" helper="dashboard" old_url="https://old.argenco.be/">
    <Box
      sx={{
        backgroundColor: theme.palette.quaternary?.main,
        padding: 1,
        position: 'relative',
        overflow: 'hidden',
        marginBottom: 2
      }}
    >
      <StyledButton
        label="Clear cache"
        id='clear_cache'
        contained
        onClick={(v) => {
          window.open(`https://old.argenco.be/administration/clear_cache`, "_BLANK");
          clearCache();
        }}
        startIcon={<LaunchIcon />}
        sx={{marginRight: 1}}
      />
      <StyledButton
        label="Stock Stats"
        id='stock_stats'
        contained
        onClick={(v) => {
          navigate("/stock_stats");
        }}
        sx={{marginRight: 1}}
      />
      <StyledButton
        label="Exit Stats"
        id='exit_stats'
        contained
        onClick={(v) => {
          navigate("/exit_stats");
        }}
        sx={{marginRight: 1}}
      />

      {!!statLoader && <CircularProgress sx={{marginBottom: -2, width: '36px !important', height: '36px !important', marginRight: 1}} />}

      {!!show_marc_stats && !loader && !statLoader && !statDone && <StyledButton
        label="Recalculate stats"
        id='recalc_stats'
        // contained
        onClick={(v) => {
          recalculateStats();
        }}
        sx={{marginRight: 1}}
      />}

      {!!show_marc_stats && !loader && !statLoader && !statDone && <StyledSelect
        label="BookYear"
        value={selectedYear?.toString() || ''}
        id='selectedYear'
        onChange={(v) => {
          setSelectedYear(v);
        }}
        list={[
          {id: '', name: "--- None ---"},
          ...bookYears.map(m => {return {id: m.id?.toString() || '', name: m.short_visual};})
        ]}
        mainSx={{
          width: '200px',
          marginTop: 0,
          marginBottom: 0
        }}
      />}

      {!!statErrorDone && <Box sx={{display: 'inline-block'}} onClick={() => {
        onSearch();
      }}>
        <Typography sx={{color: theme.palette.secondary.main}}>Stats recalculation has timed out. Refresh in a few minutes.</Typography>
      </Box>}

      {!!statDone && <Box sx={{display: 'inline-block'}} onClick={() => {
        onSearch();
      }}>
        <Typography>Stats have been recalculated. Reload or click this notification to refresh the table.</Typography>
      </Box>}
    </Box>

    {(!!tile_statics || !!tile_exits || !!tile_deaths) && <>
      <Grid container spacing="8">
        {!!tile_statics && <Grid item xs={12} md={4} lg={3}>
          <DashboardBase tile_type="statics" />
        </Grid>}

        {!!tile_exits && <>
          <Grid item xs={12} md={4} lg={3}>
            <DashboardBase tile_type="exits" />
          </Grid>
        </>}

        {!!tile_deaths && <>
          <Grid item xs={12} md={4} lg={3}>
            <DashboardBase tile_type="deaths" />
          </Grid>
        </>}
      </Grid>
    </>}

    {!!show_marc_stats && <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{'& th': {padding: 1} }}
          >
            <TableCell align="right" sx={{borderRight: '1px solid transparent', borderColor: theme.palette.primary.main}}>Month</TableCell>
            <TableCell align="right" sx={{borderColor: theme.palette.primary.main}}>A&nbsp;Ass.</TableCell>
            <TableCell align="right" sx={{borderColor: theme.palette.primary.main}}>A&nbsp;Amount</TableCell>
            <TableCell align="right" sx={{borderRight: '1px solid transparent', borderColor: theme.palette.primary.main}}>A&nbsp;Capital</TableCell>
            <TableCell align="right" sx={{borderColor: theme.palette.primary.main}}>B&nbsp;Ass.</TableCell>
            <TableCell align="right" sx={{borderColor: theme.palette.primary.main}}>B&nbsp;Amount</TableCell>
            <TableCell align="right" sx={{borderRight: '1px solid transparent', borderColor: theme.palette.primary.main}}>B&nbsp;Capital</TableCell>
            <TableCell align="right" sx={{borderColor: theme.palette.primary.main}}>Total&nbsp;Ass.</TableCell>
            <TableCell align="right" sx={{borderColor: theme.palette.primary.main}}>Total&nbsp;Amount</TableCell>
            <TableCell align="right" sx={{borderRight: '1px solid transparent', borderColor: theme.palette.primary.main}}>Total&nbsp;Capital</TableCell>
            <TableCell align="right" sx={{borderColor: theme.palette.primary.main}}>Delta</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loader && transactionals.map(transactional => {
            let delta:number|string = '';
            if (!!previous_transactional) delta = ((transactional?.extra_data?.A.amount || 0) + (transactional?.extra_data?.B.amount || 0)) - ((previous_transactional?.extra_data?.A.amount || 0) + (previous_transactional?.extra_data?.B.amount || 0));

            previous_transactional = transactional;
            return (<>
              <TableRow
                key={transactional.id}
                sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 }, '& td': {padding: 1} }}
                onMouseEnter={() => {setHoverTransactional(transactional);}}
                onMouseLeave={() => {setHoverTransactional(undefined);}}
              >
            {/* Month */}
                <TableCell align="right" sx={{borderRight: '1px solid transparent', borderRightColor: theme.palette.primary.main}}>
                  <Typography sx={{fontSize: '0.8rem'}}>{DateTime.local(transactional.year, transactional.month, 1, 12, 0).toFormat("LLLL yyyy").split(" ").join(String.fromCharCode(160))}</Typography>
                </TableCell>
            {/* A */}
                <TableCell align="right" sx={{position: 'relative'}}>
                  <Typography sx={{fontSize: '0.8rem', color: 'grey', fontFamily: 'Roboto Mono'}}>{transactional?.extra_data?.A.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</Typography>
                  {calculationTypo(transactional.id, (hoverTransactional?.id || 0), (transactional?.extra_data?.A.users || 0), (hoverTransactional?.extra_data?.A.users || 0), <></>)}
                </TableCell>
                <TableCell align="right" sx={{position: 'relative'}}>
                < Typography sx={{fontSize: '0.8rem', color: 'grey', fontFamily: 'Roboto Mono'}}>{transactional?.extra_data?.A.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</Typography>
                  {calculationTypo(transactional.id, (hoverTransactional?.id || 0), (transactional?.extra_data?.A.total || 0), (hoverTransactional?.extra_data?.A.total || 0), <></>)}
                </TableCell>
                <TableCell align="right" sx={{position: 'relative', borderRight: '1px solid transparent', borderRightColor: theme.palette.primary.main}}>
                  <Typography sx={{fontSize: '0.8rem', color: 'grey', fontFamily: 'Roboto Mono'}}>{transactional?.extra_data?.A.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}&nbsp;&euro;</Typography>
                  {calculationTypo(transactional.id, (hoverTransactional?.id || 0), (transactional?.extra_data?.A.amount || 0), (hoverTransactional?.extra_data?.A.amount || 0), <>&nbsp;&euro;</>)}
                </TableCell>
            {/* B */}
                <TableCell align="right" sx={{position: 'relative'}}>
                  <Typography sx={{fontSize: '0.8rem', color: 'grey', fontFamily: 'Roboto Mono'}}>{transactional?.extra_data?.B.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</Typography>
                  {calculationTypo(transactional.id, (hoverTransactional?.id || 0), (transactional?.extra_data?.B.users || 0), (hoverTransactional?.extra_data?.B.users || 0), <></>)}
                </TableCell>
                <TableCell align="right" sx={{position: 'relative'}}>
                  <Typography sx={{fontSize: '0.8rem', color: 'grey', fontFamily: 'Roboto Mono'}}>{transactional?.extra_data?.B.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</Typography>
                  {calculationTypo(transactional.id, (hoverTransactional?.id || 0), (transactional?.extra_data?.B.total || 0), (hoverTransactional?.extra_data?.B.total || 0), <></>)}
                </TableCell>
                <TableCell align="right" sx={{position: 'relative', borderRight: '1px solid transparent', borderRightColor: theme.palette.primary.main}}>
                  <Typography sx={{fontSize: '0.8rem', color: 'grey', fontFamily: 'Roboto Mono'}}>{transactional?.extra_data?.B.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}&nbsp;&euro;</Typography>
                  {calculationTypo(transactional.id, (hoverTransactional?.id || 0), (transactional?.extra_data?.B.amount || 0), (hoverTransactional?.extra_data?.B.amount || 0), <>&nbsp;&euro;</>)}
                </TableCell>
            {/* Total */}
                <TableCell align="right" sx={{position: 'relative'}}>
                  <Typography sx={{fontSize: '0.8rem', color: 'grey', fontFamily: 'Roboto Mono'}}>{((transactional?.extra_data?.A.users || 0) + (transactional?.extra_data?.B.users || 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</Typography>
                  {/* {calculationTypo(transactional.id, (hoverTransactional?.id || 0), ((transactional?.extra_data?.A.users || 0) + (transactional?.extra_data?.B.users || 0)), ((hoverTransactional?.extra_data?.A.users || 0) + (hoverTransactional?.extra_data?.B.users || 0)), <></>)} */}
                </TableCell>
                <TableCell align="right" sx={{position: 'relative'}}>
                  <Typography sx={{fontSize: '0.8rem', color: 'grey', fontFamily: 'Roboto Mono'}}>{((transactional?.extra_data?.A.total || 0) + (transactional?.extra_data?.B.total || 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</Typography>
                  {/* {calculationTypo(transactional.id, (hoverTransactional?.id || 0), ((transactional?.extra_data?.A.total || 0) + (transactional?.extra_data?.B.total || 0)), ((hoverTransactional?.extra_data?.A.total || 0) + (hoverTransactional?.extra_data?.B.total || 0)), <></>)} */}
                </TableCell>
                <TableCell align="right" sx={{position: 'relative', borderRight: '1px solid transparent', borderRightColor: theme.palette.primary.main}}>
                  <Typography sx={{fontSize: '0.8rem', color: 'grey', fontFamily: 'Roboto Mono'}}>{((transactional?.extra_data?.A.amount || 0) + (transactional?.extra_data?.B.amount || 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}&nbsp;&euro;</Typography>
                  {/* {calculationTypo(transactional.id, (hoverTransactional?.id || 0), ((transactional?.extra_data?.A.amount || 0) + (transactional?.extra_data?.B.amount || 0)), ((hoverTransactional?.extra_data?.A.amount || 0) + (hoverTransactional?.extra_data?.B.amount || 0)), <>&nbsp;&euro;</>)} */}
                </TableCell>
            {/* Delta */}
                <TableCell align="right" sx={{position: 'relative'}}>
                  <Typography sx={{fontSize: '0.8rem', color: 'grey', fontFamily: 'Roboto Mono'}}>{!!delta && <>{delta.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}&nbsp;&euro;</>}</Typography>
                </TableCell>
              </TableRow>
            </>);
          })}
        </TableBody>
      </Table>
    </TableContainer>}

  </Page> : <Page sx={{}} title="Dashboard">
    <GenericError hide_detail detail="not_signed_in" cta_label="Sign in now" cta_link="/login" />
  </Page>);
}
