import React, { FunctionComponent } from 'react';

import {
  Grid
} from '@mui/material';

import {
  Typography
} from '../../interface';

import { BenefitBlock } from '../../../models/BenefitBlock';
import { EmailBlock } from '../../../models/EmailBlock';

type BlockPublicTitleTextProps = {
  block: EmailBlock | BenefitBlock;
  marked: boolean;
  activeLocale: 'nl' | 'fr';
};

export const BlockPublicTitleText: FunctionComponent<BlockPublicTitleTextProps> = ({block, marked, activeLocale}) => {
  let string_value = block.string_value;
  if (typeof(string_value) !== 'string') string_value = (activeLocale === 'nl' ? string_value?.nl : string_value?.fr);
  let title_value = block.title_value;
  if (typeof(title_value) !== 'string') title_value = (activeLocale === 'nl' ? title_value?.nl : title_value?.fr);

  return ( <Grid xs={12} id={block.id?.toString()} sx={{
    marginTop: block.settings?.mtop || 0,
    marginBottom: block.settings?.mbot || 0,
    border: `1px solid ${block.settings?.border || 'transparent'}`,
    padding: '12px',
    paddingTop: 0,
    paddingBottom: 0,
    ...(!!marked ? {backgroundColor: 'rgba(255, 255, 0, 0.3)'} : {})
  }}>
    {!!title_value && <Typography variant="h3" nw_title html>{title_value}</Typography>}
    {!title_value && <Typography variant="h3" sx={{fontStyle: 'italic', color: 'lightgrey !important'}}>Title goes here...</Typography>}
    
    {!!string_value && <Typography nw_text html>{string_value}</Typography>}
    {!string_value && <Typography sx={{fontStyle: 'italic', color: 'lightgrey !important'}}>Content goes here...</Typography>}
  </Grid>);
};
