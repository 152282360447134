import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';
import { loginAtom } from '../../lib/auth';

import {
  Box,
  Grid
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  DataGrid,
  Page,
  StyledButton,
  StyledIconButton,
  StyledSelect,
  StyledTextField,
  Typography
} from '../../components/interface';

import { Emission } from '../../models/Emission';
import { Payment } from '../../models/Payment';

const { DateTime } = require("luxon");

type Props = {};

export const EmissionRepayments: FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [login, setLogin] = useAtom(loginAtom);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [emissions, setEmissions] = useState<Emission[]>([]);
  const [paymentsCount, setPaymentsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("created_at DESC");

  const [search, setSearch] = useState<string>("");
  const [searchEmission, setSearchEmission] = useState<number>();
  const [enterPressed, setEnterPressed] = useState(false);

  const [repayInfo, setRepayInfo] = useState<{count: number, amount: number}>({count: 0, amount: 0});

  useEffect(() => {
    onSearch('');
    onFindToRepay();
  }, [page, order, searchEmission, search]);

  const getEmissions = () => {
    fetch_all<Emission>('/nl/v3/objects/fetch_all', {
      object: 'emission',
      fields: ['id', 'start_date', 'end_date'],
      per_page: 1000,
      page: 0,
      order: "start_date DESC"
    }, (obs, complete_data) => {
      setEmissions(obs);
      setSearchEmission(obs[0].id)
    }, (z) => {
      // setServerErrorSnackOpen(true);
    }, login);
  };

  const initRepay = () => {
    fetch_one<{
      custom_result: {
        success: boolean
      }
    }>('/nl/v3/objects/custom_action', {
      object: 'payment',
      class_action: "custom_api_init_repay",
      emission_id: searchEmission,
      handler_id: login?.id
    }, (ob, complete_data) => {
      navigate("/exports");
    }, (z) => {

    }, login)
  };

  const onFindToRepay = () => {
    fetch_one<{
      custom_result: {
        count: number,
        amount: number
      }
    }>('/nl/v3/objects/custom_action', {
      object: 'payment',
      class_action: "custom_api_get_repay_overview",
      emission_id: searchEmission
    }, (ob, complete_data) => {
      setRepayInfo(ob.custom_result);
    }, (z) => {

    }, login)
  };

  const onSearch = (s: string) => {
    setLoader(true);
    fetch_all<Payment>('/nl/v3/objects/fetch_all', {
      object: 'payment',
      fields: [
        'id', 'created_at', 'repay_reason', 'amount', 'repay', 'repay_at', 'account', 'reference'
      ],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: '',
        advanced: {
          emission_id: searchEmission,
          catch_all: search,
          filter_verified: 99,
          filter_repay: 1
        }
      }
    }, (obs, complete_data) => {
      setPayments(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setPaymentsCount(count);

      setLoader(false);
    }, (z) => {

    }, login)
  };

  useEffect(() => {
    getEmissions();
  }, []);

  const columns: GridColDef[] = [
    { field: 'reference', headerName: 'Reference', width: 200 },
    { field: 'account', headerName: 'Account', width: 200 },
    { field: 'amount', headerName: 'Amount', width: 150, align: 'right', renderCell: params => <>
      <Typography sx={{fontSize: '0.9rem'}}>
        {`${parseFloat(params.row.amount).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR`}
      </Typography>
    </> },
    { field: 'repay_at', headerName: 'Repaid at', width: 150, valueFormatter: params => !!params?.value ? DateTime.fromISO(params?.value).toFormat("D t") : '' },
    { field: 'repay_reason', headerName: 'Reason', width: 200, renderCell: params => <>
      <Typography sx={{fontSize: '0.9rem'}}>
        {params.row.repay_reason}
      </Typography>
    </> },
  ];

  return (
    <Page sx={{}} title="Repayments" helper="repayments" actions={<>
      {repayInfo.count > 0 && <StyledButton
        label={`Export ${repayInfo.count}`}
        id='export_repays'
        contained
        onClick={() => {
          initRepay();
        }}
      />}
    </>}>

      <Box sx={{
        backgroundColor: theme.palette.quaternary?.main,
        padding: 1,
        position: 'relative',
        overflow: 'hidden',
        marginBottom: 2
      }}>
        <SearchIcon sx={{position: 'absolute', top: -8, left: -8, height: 100, width: 100, opacity: 0.2}} />
        <Box>
          <Grid container sx={{paddingRight: 1}} spacing={2}>

            <Grid item xs={4}>
            </Grid>
            <Grid item xs={4}>
              <StyledSelect
                label="Emission"
                value={searchEmission?.toString() || ''}
                id='emission_filter'
                onChange={(v) => {
                  setSearchEmission(parseInt(v, 10));
                }}
                list={emissions.map(emission => ({id: (emission.id || ''), name: [DateTime.fromISO(emission.start_date).toFormat("D"), DateTime.fromISO(emission.end_date).toFormat("D")].join(" - ")}))}
                sx={{backgroundColor: "#a9c2c0"}}
              />
            </Grid>
            <Grid item xs={4}>
              <StyledTextField
                label="Search..."
                sx={{width: '100%', "input": {backgroundColor: '#a9c2c0', padding: 1}}}
                value={search}
                id='search'
                onChange={(v) => {
                  setSearch(v);
                }}
                setEnterPressed={setEnterPressed}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      
      <DataGrid
        action_count={1}
        actions={(params) => {
          return (<>
            <StyledIconButton title="Open Detail" color="primary"  onClick={() => {
              window.open(`/emission/payment/${params.row.id}`, "_BLANK");
            }}>
              <VisibilityIcon />
            </StyledIconButton>
          </>);
        }}
        objectCount={paymentsCount}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objects={(payments || []).map(u => {console.log(u); return ({
          ...u
        })})}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          // if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />
    </Page>
  );
}
