import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';

import { fetch_all } from '../lib/server_helper';

import {
  CircularProgress,
  Grid
} from '@mui/material';

import { Page } from '../components/interface/Page';
import { AuditBase } from '../components/audits/Base';

import { Audit } from '../models/Audit';
import { loginAtom } from '../lib/auth';
import { useParams } from 'react-router-dom';

type UserDetailAuditsProps = {};

export const UserDetailAudits: FunctionComponent<UserDetailAuditsProps> = () => {
  // const theme = useTheme();
  
  const params = useParams();
  const userId: string | undefined = params.id;

  const [login, setLogin] = useAtom(loginAtom);
  const [audits, setAudits] = useState<Audit[]>([]);
  const [loader, setLoader] = useState<boolean>(true);

  const fetchAudits = () => {
    setLoader(true);
    
    fetch_all<Audit>('/nl/v3/objects/fetch_all', {
      object: 'audit',
      fields: [
        'id', 'created_at', 'auditable|first_name/last_name', 'action', 'version', 'audited_changes'
      ],
      filter: {
        advanced: {
          auditable_type: "User",
          auditable_id: userId
        }
      },
      order: "id DESC"
    }, (ob, complete_data) => {
      setAudits(ob);
      setLoader(false);
    }, (z) => {

    }, login)
  };

  useEffect(() => {
    fetchAudits();
  }, []);

  return (
    <Page sx={{}} title={`Audits of ${audits[0]?.auditable_first_name} ${audits[0]?.auditable_last_name}`}>
      <Grid container spacing="8">
        {!!loader && <Grid item xs={12}>
          <CircularProgress />
        </Grid>}

        {audits.map(audit => <AuditBase audit={audit} />)}

      </Grid>
    </Page>
  );
}
