import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

// import {
//   IconButton
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LaunchIcon from '@mui/icons-material/Launch';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { DataGrid } from '../../components/interface/DataGrid';
import { Page } from '../../components/interface/Page';
import { SearchBox } from '../../components/interface/SearchBox';
import { StyledButton } from '../../components/interface/StyledButton';
import { StyledIconButton } from '../../components/interface/StyledIconButton';
import { Typography } from '../../components/interface/Typography';

import { Raffle } from '../../models/Raffle';
import { loginAtom } from '../../lib/auth';

const { DateTime } = require("luxon");

type ContentRafflesProps = {};

export const ContentRaffles: FunctionComponent<ContentRafflesProps> = () => {
  // const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);
  const [raffles, setRaffles] = useState<Raffle[]>([]);
  const [rafflesCount, setRafflesCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [exportLoader, setExportLoader] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");

  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onExport = (id: number) => {
    setExportLoader(id);
    fetch_one<{custom_result: {url: string}}>('/nl/v3/objects/custom_action', {
      object: 'raffle',
      class_action: "custom_api_export_subscriptions",
      id: id,
      handler_id: login?.id
    }, (obs, complete_data) => {
      window.open(obs.custom_result.url, "_BLANK");
      setExportLoader(0);
    }, (z) => {

    }, login);
  };

  const onRemove = (id: number) => {
    setRemoveLoader(true);
    fetch_one<Raffle>('/nl/v3/objects/remove', {
      object: 'raffle',
      ob: {
        id: id
      }
    }, (obs, complete_data) => {
      onSearch('');
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onSearch = (s: string, filters?:{enabled:string[]}) => {
    setLoader(true);
    fetch_all<Raffle>('/nl/v3/objects/fetch_all', {
      object: 'raffle',
      fields: ['id', 'name', 'raffle_subscriptions|count', 'created_at'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          enabled: (filters?.enabled || ['1'])
        }
      }
    }, (obs, complete_data) => {
      setRaffles(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setRafflesCount(count);

      setLoader(false);
    }, (z) => {

    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'title_nl', headerName: 'Name', width: 400, sortable: false },
    { field: 'raffle_subscriptions_count', headerName: 'Subs', width: 100, sortable: false, renderCell: params => <>
      <Typography sx={{
        fontSize: '0.9rem',
        cursor: 'pointer',
        textDecoration: 'underline'
      }} onClick={() => {
        onExport(params.row.id);
        // navigate(`/benefit_codes/${params.row.id}`);
      }}>{params.row.raffle_subscriptions_count}</Typography>
    </> },
    { field: 'created_at', headerName: 'Created', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
  ];

  return (
    <Page sx={{}} title="Raffles" helper="raffles" old_url="https://old.argenco.be/raffles" actions={<>
      <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={'/raffle/new'}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}>
      <SearchBox
        simple={true}
        onSearch={(s, x) => {
          setPage(0);
          onSearch(s, x);
        }}
        // filter_enabled
        // memo="raffles"
      />
      <DataGrid
        action_count={3}
        actions={(params) => {
          return (<>
            <StyledIconButton color="primary" path={`/raffle/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
            {!removeLoader && <StyledIconButton destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
            <StyledIconButton color="primary" onClick={(e) => {
              e.preventDefault();
              window.open(`https://www.argenco.be/raffle/${params.row.id}`, "_BLANK");
            }}>
              <LaunchIcon />
            </StyledIconButton>
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={rafflesCount}
        objects={(raffles || []).map(u => ({
          ...u,
          title_nl: u.name?.nl
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />
    </Page>
  );
}
