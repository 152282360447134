import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one } from '../lib/server_helper';
import { loginAtom } from '../lib/auth';

import {
  Box,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {
  Page,
  StyledButton,
  StyledEditorNoTransl,
  StyledTextField,
  Typography
} from '../components/interface';

import { AddressEmail } from '../models/AddressEmail';
import { User } from '../models/User';

const { DateTime } = require("luxon");

type Props = {};

export const UserInvalidAddress: FunctionComponent<Props> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  
  const params = useParams();
  const userId: string | undefined = params.id;

  const [login, setLogin] = useAtom(loginAtom);
  const [user, setUser] = useState<User>();
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);

  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [officeEmail, setOfficeEmail] = useState<string>('');

  const fetchUser = () => {
    setLoader(true);
    
    fetch_one<User>('/nl/v3/objects/fetch_all', {
      object: 'user',
      fields: [
        'id', 'first_name', 'last_name', 'vennoot_number', 'locale', 'address', 'address_emails|count',
        'death_linked_office|office_number', 'address_emails'
      ],
      id: userId
    }, (ob, complete_data) => {
      setUser(ob);
      setLoader(false);
    }, (z) => {
      
    }, login)
  };
  
  const doSave = () => {
    if (!!user) {
      fetch_one<AddressEmail>('/nl/v3/objects/custom_action', {
        object: 'address_email',
        class_action: 'custom_api_save',
        handler_id: login?.id,
        user_id: userId,
        office_email: officeEmail,
        body: body,
        subject: subject
      }, (ob, complete_data) => {
        if (!!ob?.custom_result?.success) {
          navigate('/users');
        } else {
          // error?
        }
      }, (z) => {

      }, login);
    }
  };
  
  const fetchBlank = (locale: string) => {
    if (!!user) {
      fetch_one<AddressEmail>('/nl/v3/objects/custom_action', {
        object: 'address_email',
        class_action: 'custom_api_get_content',
        handler_id: login?.id,
        user_id: userId,
        loc: locale
      }, (ob, complete_data) => {
        setSubject(ob.custom_result?.subject || '');
        setBody('');
        setTimeout(() => {setBody(ob.custom_result?.body || '');}, 500);
      }, (z) => {

      }, login);
    }
  };

  useEffect(() => {
    fetchBlank(user?.locale || 'nl');
  }, [user]);

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <Page sx={{}} title={`${!!user && [user.first_name, user.last_name].join(' ')}`}>
      <Grid container spacing="8">
        {!!loader && <Grid item xs={12}>
          <CircularProgress />
        </Grid>}

        <Grid item xs={12}>
          <Box sx={{
            // width: '100%',
            padding: 1,
            border: '1px solid transparent',
            borderColor: theme.palette.primary.main,
            backgroundColor: '#98B6B4',
            marginBottom: 0.5,
            position: 'relative'
          }}>
            <Typography sx={{}}>This user was reminded {user?.address_emails_count} times already!</Typography>
            {(user?.address_emails || []).length > 0 && <>&nbsp;</>}
            {(user?.address_emails || []).length > 0 && (user?.address_emails || []).map(email => <>
              <Typography>{email.office_email} ({DateTime.fromISO(email.created_at).toFormat("DD - T")})</Typography>
            </>)}
          </Box>
          <Box sx={{
            // width: '100%',
            padding: 1,
            border: '1px solid transparent',
            borderColor: theme.palette.primary.main,
            backgroundColor: '#98B6B4',
            marginBottom: 0.5,
            position: 'relative'
          }}>
            <StyledButton label="Reload NL" id="reloadnl" onClick={() => {
              fetchBlank('nl');
            }} />
            <StyledButton label="Reload FR" id="reloadfr" sx={{marginLeft: 1}} onClick={() => {
              fetchBlank('fr');
            }} />
          </Box>
        </Grid>
        {/* death_linked_office_office_number */}

        <Grid item xs={12}>
          <Box sx={{
            // width: '100%',
            padding: 1,
            border: '1px solid transparent',
            borderColor: theme.palette.primary.main,
            marginBottom: 0.5,
            position: 'relative'
          }}>
            <Typography>{user?.first_name} {user?.last_name} ({user?.vennoot_number})</Typography>
            <Typography>{user?.address}</Typography>
            {!!user?.death_linked_office_office_number && <Typography>Kantoor: {user?.death_linked_office_office_number}</Typography>}
          </Box>
        </Grid>

        <Grid item xs={12} sx={{marginTop: 1, marginBottom: 1}}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <StyledTextField
            label="Office email"
            value={officeEmail}
            id='office_email'
            onChange={(v) => {
              setOfficeEmail(v);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            label="Subject"
            value={subject}
            id='subject'
            onChange={(v) => {
              setSubject(v);
            }}
            fullWidth
          />
        </Grid>
        {!!body && <Grid item xs={12}>
          <StyledEditorNoTransl
            label="Body"
            value={body}
            onChange={(v) => {
              setBody(v);
            }}
          />
        </Grid>}
        <Grid item xs={12}>
          <StyledButton
            label="Save & Send"
            id='save'
            disabled={!officeEmail || !subject || !body}
            contained
            onClick={() => {
              doSave();
            }}
            startIcon={<AddCircleOutlineIcon />}
          />
        </Grid>

      </Grid>
    </Page>
  );
}
