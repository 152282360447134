import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useMutation, useQuery } from '@tanstack/react-query';

// import { fetch_all, fetch_one } from '../lib/server_helper';
import { loginAtom } from '../lib/auth';
import { fetchAll, fetchOne } from '../lib/v31lib';

import {
  CircularProgress,
  Grid
} from '@mui/material';

import {
  Page,
  StyledButton,
  StyledSelect,
  Typography
} from '../components/interface';

import { BookYear } from '../models/BookYear';

// const { DateTime } = require("luxon");

type Props = {};
type RawExportStat = {
  custom_result: string
};

export const ExitStats: FunctionComponent<Props> = () => {
  // const theme = useTheme();
  
  const [login, setLogin] = useAtom(loginAtom);
  const [bookYear, setBookYear] = useState<number>();
  const [bookYearMinus1, setBookYearMinus1] = useState<number>();
  const [bookYearMinus2, setBookYearMinus2] = useState<number>();
  const [exportUrl, setExportUrl] = useState<string>();

  const {
    data: bookYears,
    // isLoading: bookYearsLoading,
    // isError: bookYearsError,
    // isSuccess: bookYearsSuccess,
    // refetch: bookYearsRefetch,
  } = useQuery({
    queryKey: ["book_years"],
    queryFn: () =>
      fetchAll<BookYear>(
        "/nl/v3/objects/fetch_all",
        {
          object: "book_year",
          fields: ["id", "short_visual"],
          order: "id DESC"
        },
        login
      ),
    enabled: !!login
  });

  







  const mutationCalculate = useMutation({
    mutationFn: () => {
      setExportUrl(undefined);
      return fetchOne<RawExportStat>(
        "/nl/v3/objects/custom_action",
        {
          object: 'export',
          class_action: 'custom_api_export_exit_stats',
          handler_id: login?.id,
          book_year: bookYear,
          book_year_m1: bookYearMinus1,
          book_year_m2: bookYearMinus2
        },
        login
      );
    },
    onSuccess: (data, variables, context) => {
      setExportUrl(data.custom_result);
    },
  });








  return (<Page sx={{}} title="Exit Stats" helper="exit_stats">
    <Grid container spacing="8">
      <Grid item xs={12} md={4}>
        <StyledSelect
          label="Book Year Base (current)"
          value={bookYear?.toString() || ''}
          id='book_year'
          onChange={(v) => {setBookYear(parseInt(v, 10));}}
          list={[
            {id: '', name: "Unselected"},
            ...((bookYears || []).map(by => {return {id: by.id?.toString() || '', name: by.short_visual};}))
          ]}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <StyledSelect
          label="Book Year Previous"
          value={bookYearMinus1?.toString() || ''}
          id='book_year'
          onChange={(v) => {setBookYearMinus1(parseInt(v, 10));}}
          list={[
            {id: '', name: "Unselected"},
            ...((bookYears || []).map(by => {return {id: by.id?.toString() || '', name: by.short_visual};}))
          ]}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <StyledSelect
          label="Book Year Two Ago"
          value={bookYearMinus2?.toString() || ''}
          id='book_year'
          onChange={(v) => {setBookYearMinus2(parseInt(v, 10));}}
          list={[
            {id: '', name: "Unselected"},
            ...((bookYears || []).map(by => {return {id: by.id?.toString() || '', name: by.short_visual};}))
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledButton
          label="Spin the wheel"
          disabled={!bookYear}
          id='spin'
          contained
          onClick={(v) => {
            mutationCalculate.mutate();
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {!!mutationCalculate.isPending && <CircularProgress />}
        {!!exportUrl && <Typography>Download your export here: <a href={exportUrl} target="_BLANK">{exportUrl}</a></Typography>}
      </Grid>
    </Grid>
  </Page>);
}
