import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all } from '../../lib/server_helper';

// import {
//   Box
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import VisibilityIcon from '@mui/icons-material/Visibility';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledIconButton
} from '../../components/interface';

import { UserChange } from '../../models/UserChange';
// import refresh_queues, { queueAtom } from '../../lib/queues';
import { loginAtom } from '../../lib/auth';

const { DateTime } = require("luxon");

type OfficeUserChangesProps = {};

export const OfficeUserChanges: FunctionComponent<OfficeUserChangesProps> = () => {
  // const theme = useTheme();
  // const navigate = useNavigate();

  const login = useAtomValue(loginAtom);
  // const [login, setLogin] = useAtom(loginAtom);
  // const [myQueues, setMyQueues] = useAtom(queueAtom);

  const [userChanges, setUserChanges] = useState<UserChange[]>([]);
  const [userChangesCount, setUserChangesCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [filters, setFilters] = useState<{checked?: string[]}>();
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("user_id ASC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch('', filters);
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order]);

  const onSearch = (s: string, filters?:{checked?: string[]}) => {
    // console.log(filters);
    setLoader(true);
    fetch_all<UserChange>('/nl/v3/objects/fetch_all', {
      object: 'user_change',
      fields: ['id', 'extra_data', 'created_at', 'updated_at', 'status', 'user|first_name/last_name/vennoot_number'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          ...(!filters?.checked ? {unchecked: 1} : (filters?.checked?.length === 1 ? (filters?.checked[0] === '0' ? {unchecked: 1} : {checked: 1}) : {})),
        }
      }
    }, (obs, complete_data) => {
      setUserChanges(obs);
      setFilters(filters);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setUserChangesCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, sortable: true },
    { field: 'vennoot_number', headerName: 'VennNr', width: 150, sortable: false },
    { field: 'vennoot_name', headerName: 'Name', width: 200, sortable: false },
    { field: 'status_engrish', headerName: 'Status', width: 200, sortable: false },
    { field: 'created_at', headerName: 'Created', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") }
  ];

  return (
    <Page sx={{}} title="UserChanges" helper="user_changes">
      <SearchBox
        deathSearch={(s, x) => {
          setPage(0);
          onSearch(s, x);
        }}
        collapsable
        memo="user_changes"
      />
      <DataGrid
        action_count={1}
        actions={(params) => {
          return (<>
            <StyledIconButton color="primary" path={`/user_change/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={userChangesCount}
        objects={(userChanges || []).map(u => ({
          ...u,
          vennoot_number: u.user_vennoot_number,
          vennoot_name: [u.user_first_name, u.user_last_name].join(' '),
          status_engrish: (u.status === 0 ? "Unchecked" : (u.status === 1 ? "Checked" : "Overridden"))
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
