import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

import {
  Avatar,
  CircularProgress
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InventoryIcon from '@mui/icons-material/Inventory';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton
} from '../../components/interface';

import { News } from '../../models/News';
import { loginAtom } from '../../lib/auth';

const { DateTime } = require("luxon");

type Props = {};

export const ContentInvestments: FunctionComponent<Props> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);

  const [templates, setTemplates] = useState<News[]>([]);
  const [templatesCount, setTemplatesCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onRemove = (id: number) => {
    setRemoveLoader(true);
    fetch_one<News>('/nl/v3/objects/remove', {
      object: 'v2_news',
      ob: {
        id: id
      }
    }, (obs, complete_data) => {
      onSearch('');
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onSearch = (s: string) => {
    // console.log(filters);
    setLoader(true);
    fetch_all<News>('/nl/v3/objects/fetch_all', {
      object: 'v2_news',
      fields: ['id', 'created_at', 'updated_at', 'name', 'extra_data', 'enabled', 'weight', 'is_hidden', 'views|count'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          investment: 1,
          fallback: s
        }
      }
    }, (obs, complete_data) => {
      setTemplates(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setTemplatesCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, sortable: true },
    { field: 'weight', headerName: 'Weight', width: 100, sortable: true },
    { field: 'name', headerName: 'Internal name', width: 200, sortable: true },
    { field: 'created_at', headerName: 'Created', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'updated_at', headerName: 'Updated', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'enabled', headerName: 'Is Active', width: 120, sortable: false, renderCell: params => <>
      {!!params.row.enabled && <CheckIcon sx={{color: theme.palette.tertiary?.main, marginRight: 0.5}} />}
      {!params.row.enabled && <CloseIcon sx={{color: theme.palette.secondary.main, marginRight: 0.5}} />}
      {params.row.extra_data.languages?.indexOf('0') > -1 && <Avatar sx={{backgroundColor: theme.palette.tertiary?.main, width: '24px', height: '24px', fontSize: '0.7rem', marginRight: 0.5}}>NL</Avatar>}
      {params.row.extra_data.languages?.indexOf('0') < 0 && <Avatar sx={{backgroundColor: theme.palette.secondary.main, width: '24px', height: '24px', fontSize: '0.7rem', marginRight: 0.5}}>NL</Avatar>}
      {params.row.extra_data.languages?.indexOf('1') > -1 && <Avatar sx={{backgroundColor: theme.palette.tertiary?.main, width: '24px', height: '24px', fontSize: '0.7rem', marginRight: 0.5}}>FR</Avatar>}
      {params.row.extra_data.languages?.indexOf('1') < 0 && <Avatar sx={{backgroundColor: theme.palette.secondary.main, width: '24px', height: '24px', fontSize: '0.7rem', marginRight: 0.5}}>FR</Avatar>}
      {!!params.row.is_hidden && <InventoryIcon sx={{color: theme.palette.tertiary?.main, marginRight: 0.5}} />}
    </> },
    { field: 'views_count', headerName: 'Views', width: 100, sortable: false },
  ];

  return (
    <Page sx={{}} title="Investments" helper="news" actions={<>
      <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={'/investment/new'}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}>
      <SearchBox
        simple={true}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
        memo="news"
      />
      <DataGrid
        action_count={2}
        actions={(params) => {
          return (<>
            <StyledIconButton color="primary" path={`/investment/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
            {!!removeLoader && <CircularProgress />}
            {!removeLoader && <StyledIconButton destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={templatesCount}
        objects={(templates || []).map(u => ({
          ...u,
          title_nl: u.subject?.nl || ''
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
