import React, { FunctionComponent, ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  Pagination
} from '@mui/material';

import { DataGridPro, GridColDef, GridSortModel } from '@mui/x-data-grid-pro';

import { Typography } from './Typography';

type DataGridProps = {
  action_count?: number;
  actions?: (e:{row:{
    id:number,
    get_item?: string,
    status?: number,
    is_confirmed?: number,
    voting_results_count?: number,
    dividend_pains_count?: number,
    office_accounts_count?: number,
    office_logs_count?: number,
    office_id?: number,
    is_commercial?: boolean,
    street?: string,
    number?: string,
    zip?: string,
    is_sent?: boolean,
    dividend_calculations_count?: number,
    payments_last_row_number?: number,
    is_finished?: boolean,
    get_result?: string;
  }}) => ReactNode;
  columns: GridColDef[];
  getRowClassName?: (e:{row:{is_error?: boolean, enabled:boolean, cancelled?: boolean, is_stock?: boolean, is_who?: boolean, user_id?: number, v2_enabled?: boolean; enabled_offices?: boolean}}) => string;
  loader: boolean;
  hidePagination?: boolean;
  objectCount: number;
  objects: Array<any>;
  onSortModelChange?: (e:GridSortModel, f:unknown) => void;
  page: number;
  setPage?: (e:number) => void;
  sx?: object;
};

export const DataGrid: FunctionComponent<DataGridProps> = ({
  objectCount, getRowClassName, objects, columns, loader, onSortModelChange, page, setPage, actions, action_count, hidePagination, sx
}) => {
  const theme = useTheme();

  let initialState = {pinnedColumns: {left: ['id', 'vennoot_number'], right: ['actions']}};

  if (!!actions) {
    columns.push({
      field: 'actions',
      headerName: '',
      width: (20 + (action_count || 1) * 40),
      sortable: false,
      hideable: false,
      renderCell:  (params) => {return actions(params);}
    });
  }

  return (<React.Fragment>
    <Box sx={{marginTop: 2}}>
      <Typography>{objectCount} objects found...</Typography>
    </Box>
    <Box sx={{ width: "100%", maxWidth: '100%', overflow: 'hidden' }}>
      <Box sx={{ width: "auto" }}>
        <DataGridPro
          getRowClassName={(params) => {if (!!getRowClassName) {return getRowClassName(params);} else {return '';}}}
          initialState={initialState}
          rows={objects}
          columns={columns}
          loading={loader}
          autoHeight
          hideFooter
          disableColumnFilter
          editMode="row"
          rowCount={objectCount}
          onSortModelChange={(a, b) => {
            if (!!onSortModelChange) onSortModelChange(a, b);
          }}
          sx={{
            "& ::-webkit-scrollbar": {
              width: "6px"
            },
            "& ::-webkit-scrollbar-track": {
              backgroundColor: theme.palette.primary.main
            },
            "& ::-webkit-scrollbar-thumb": {
              borderRadius: "4px",
              boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
              backgroundColor: theme.palette.tertiary?.main
            },
            ".datagrid-row-enabled-false div": {
              color: 'lightgrey'
            },
            "div:not(.MuiDataGrid-pinnedColumns) > .datagrid-row-cancelled-true div": {
              color: 'rgba(255, 0, 0, 0.8)',
              backgroundColor:'lightgrey'
            },
            ".MuiDataGrid-pinnedColumns .datagrid-row-enabled-false div": {
              color: 'grey'
            },
            ".MuiDataGrid-pinnedColumnHeaders": {
              backgroundColor: 'white'
            },
            ".MuiDataGrid-pinnedColumns": {
              backgroundColor: 'rgba(199, 214, 213)'
            },
            ...sx
          }}
        />
      </Box>
    </Box>
    {!hidePagination && <Box sx={{marginTop: 2}}>
      <Pagination
        count={Math.ceil(objectCount / 20)}
        variant="outlined"
        color="primary"
        siblingCount={2}
        boundaryCount={2}
        page={page + 1}
        onChange={(e, p) => {
          if (!!setPage) setPage(p - 1);
        }}
      />
    </Box>}
  </React.Fragment>);
}