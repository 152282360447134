import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { useParams } from 'react-router-dom';

import { fetch_one } from '../../lib/server_helper';

import {
  Box,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LaunchIcon from '@mui/icons-material/Launch';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import { DetailLine } from '../../components/interface/Detail/DetailLine';
import {
  Page,
  StyledButton,
  StyledIconButton,
  Typography
} from '../../components/interface';
import { UserDetailBase } from '../../components/user_detail/Base';

import { MeetingVote } from '../../models/MeetingVote';

type MeetingVoteDetailProps = {};

export const MeetingVoteDetail: FunctionComponent<MeetingVoteDetailProps> = () => {
  // const navigate = useNavigate();
  const theme = useTheme();
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['login'],
  //   queryFn: async () => {return },
  // });

  const params = useParams();
  const meetingId: string | undefined = params.id;

  const login = useAtomValue(loginAtom);
  // const [login, setLogin] = useAtom(loginAtom);
  const [voteDetail, setVoteDetail] = useState<MeetingVote|null>(null);
  const [loader, setLoader] = useState<boolean>(true);

  // const [volmachtNr, setVolmachtNr] = useState<string>('');

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [meetingSavedSnackOpen, setMeetingSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'created_at', 'updated_at', 'extra_data', 'status_level', 'get_item',
    'item_blob|content_type', 'item|filename/byte_size', 'meeting_id', 'result_data', 
    'user|id/first_name/last_name/vennoot_number/birth_date/street/number/zip/city/phone/mobile/gender/cor_street/cor_number/cor_zip/cor_city/email',
    'meeting|voting_data'
  ];

  const fetchVote = () => {
    setLoader(true);
    
    fetch_one<MeetingVote>('/nl/v3/objects/fetch_all', {
      object: 'meeting_vote',
      fields: fields,
      id: meetingId
    }, (ob, complete_data) => {
      openVote(ob);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const saveDetail = (pars:object) => {
    fetch_one<MeetingVote>('/nl/v3/objects/custom_action', {
      object: 'meeting_vote',
      fields: fields,
      class_action: 'custom_api_save_detail',
      id: meetingId,
      handler_id: login?.id,
      ...pars
    }, (ob, complete_data) => {
      fetchVote();
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  useEffect(() => {
    fetchVote();
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openVote = (ob: MeetingVote) => {
    setVoteDetail(ob);
  }

  return (
    <Page
      sx={{}}
      title={`${!!voteDetail && [voteDetail.user_first_name, voteDetail.user_last_name].join(" ")}`}
      startEndCrumb={<StyledButton
        label="Back to Votes"
        id='back'
        text
        startIcon={<KeyboardBackspaceIcon />}
        path={`/meeting_votes/${voteDetail?.meeting_id}`}
        sx={{marginBottom: 1}}
      />}
    >
      <Grid container spacing="8">
        <Grid container item xs={12} spacing="8" sx={{alignContent: 'baseline'}}>
          {!!loader && <Grid item xs={12}>
            <CircularProgress />
          </Grid>}

          <Grid container item xs={8} spacing="8">

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.identity
              }}
              title="Identity"
            >
              <DetailLine name="First name" value={!!voteDetail ? voteDetail.user_first_name : ''} />
              <DetailLine name="Last name" value={!!voteDetail ? voteDetail.user_last_name : ''} />
              <DetailLine name="Birthdate" date={!!voteDetail ? voteDetail?.user_birth_date : ''} />
              <DetailLine no_divider copyable name="Vennoot Number" value={!!voteDetail ? voteDetail.user_vennoot_number : ''} />
            </UserDetailBase>

            {!!voteDetail?.get_item && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.exit
              }}
              title="Confirmation"
              adornment={!!voteDetail && !!voteDetail.get_item && <>
                <StyledIconButton
                  path={voteDetail.get_item}
                  newTab
                  sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
                ><LaunchIcon /></StyledIconButton>
              </>}
              turnable={!!voteDetail.item_blob_content_type && voteDetail.item_blob_content_type.indexOf("image") > -1}
            >
              <Grid item xs={12}>
                {!!voteDetail.item_blob_content_type && voteDetail.item_blob_content_type.indexOf("image") > -1 && <img alt="preview_img" style={{maxWidth: '100%'}} src={voteDetail.get_item} />}
                {!!voteDetail.item_blob_content_type && voteDetail.item_blob_content_type.indexOf("pdf") > -1 && <iframe title="preview_pdf" style={{width: '100%', height: '300px'}} src={voteDetail.get_item} />}
              </Grid>
              <Grid item xs={12}>
                {!!voteDetail?.item_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {voteDetail?.item_filename} ({(parseInt(voteDetail?.item_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>}

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.guardian
              }}
              title={`Votes`}
              wide
            >
              {(voteDetail?.meeting_voting_data || []).map((q, i) => <Grid item xs={12}>
                <Typography>{q.internal || q.question.nl.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 90)}</Typography>
                <Typography sx={{fontWeight: 'bold'}}>{["Niet akkoord", "Akkoord", "Onthouding", "Ongeldig"][(voteDetail?.result_data?.answers[i] || 3)]}</Typography>
                <Divider />
              </Grid>)}
            </UserDetailBase>

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.contact
              }}
              title="Contact"
              wide
            >
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine name="Gender" gender_icons value={!!voteDetail ? voteDetail.user_gender : ''} />
                <DetailLine copyable name="E-mail" value={!!voteDetail ? voteDetail.user_email : ''} />
                <DetailLine no_divider name="Address" value={!!voteDetail ? [voteDetail.user_street, voteDetail.user_number].join(" ") : ''} />
                <DetailLine no_divider name="" value={!!voteDetail ? [voteDetail.user_zip, voteDetail.user_city].join(" ") : ''} />
              </Grid>
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine name="Phone" value={!!voteDetail ? voteDetail.user_phone : ''} />
                <DetailLine  name="Mobile" value={!!voteDetail ? voteDetail.user_mobile : ''} />
                {!!voteDetail?.user_cor_street && !!voteDetail?.user_cor_zip && <DetailLine no_divider name="COR Address" value={!!voteDetail ? [voteDetail.user_cor_street, voteDetail.user_cor_number].join(" ") : ''} />}
                {!!voteDetail?.user_cor_street && !!voteDetail?.user_cor_zip && <DetailLine no_divider name="" value={!!voteDetail ? [voteDetail.user_cor_zip, voteDetail.user_cor_city].join(" ") : ''} />}
              </Grid>
            </UserDetailBase>

          </Grid>
          <Grid container item xs={12} md={4} spacing="8" sx={{alignContent: 'baseline', flexBasis: "calc(33.33% + 8px) !important"}} rowSpacing="4">
            <Grid item xs={12}>
              <Box sx={{
                border: '1px solid transparent',
                // borderColor: theme.palette.primary.main,
                borderRadius: 1,
                padding: 1
              }}>
                <Grid container spacing="8">
                  <Grid item xs={12}>
                    <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Actions</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <StyledButton
                      label="Regen PDF"
                      id='regen_pdf'
                      onClick={(v) => {
                        saveDetail({
                          action_name: 'regen_item'
                        });
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>

                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!meetingSavedSnackOpen && <AlertSaved open={meetingSavedSnackOpen} setOpen={(b) => {setMeetingSavedSnackOpen(b);}} />}
    </Page>
  );
}
