import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

// import {
//   Box
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import EmailIcon from '@mui/icons-material/Email';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton
} from '../../components/interface';

import { MeetingEmail } from '../../models/MeetingEmail';
import refresh_queues, { queueAtom } from '../../lib/queues';
import { loginAtom } from '../../lib/auth';

const { DateTime } = require("luxon");

type MeetingEmailsProps = {};

export const MeetingEmails: FunctionComponent<MeetingEmailsProps> = () => {
  // const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);
  const [myQueues, setMyQueues] = useAtom(queueAtom);

  const params = useParams();
  const meetingId: string | undefined = params.id;

  const [emails, setEmails] = useState<MeetingEmail[]>([]);
  const [emailsCount, setEmailsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [handleLoader, setHandleLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onSearch = (s: string) => {
    // console.log(filters);
    setLoader(true);
    fetch_all<MeetingEmail>('/nl/v3/objects/fetch_all', {
      object: 'meeting_email',
      fields: ['id', 'email', 'user|first_name/last_name/vennoot_number', 'created_at'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          meeting_id: meetingId
        }
      }
    }, (obs, complete_data) => {
      setEmails(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setEmailsCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const sendEmails = () => {
    if (!!login) {
      setHandleLoader(true);
      fetch_one<MeetingEmail>('/nl/v3/objects/custom_action', {
        object: 'meeting_email',
        class_action: 'custom_api_create_email_queue',
        handler_id: login.id
      }, (obs, complete_data) => {
        refresh_queues(login, (res) => {
          if (!!res) setMyQueues(res);
        });
        onSearch('');

        setHandleLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
        setHandleLoader(false);
      }, login);
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, sortable: true },
    { field: 'vennoot_number', headerName: 'VennNr', width: 150, sortable: false },
    { field: 'vennoot_name', headerName: 'Name', width: 200, sortable: false },
    { field: 'created_at', headerName: 'Created', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") }
  ];

  return (
    <Page sx={{}} title="Meeting Emails" helper="emails" old_url="https://old.argenco.be/commercial_meeting_emails" actions={<>
      {!handleLoader && emailsCount > 0 && <StyledButton
        label={`Send ${emailsCount} emails`}
        id='send_emails'
        contained
        sx={{marginRight: 3}}
        startIcon={<EmailIcon />}
        onClick={() => {
          sendEmails();
        }}
      />}
    </>}>
      <SearchBox
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
        simple
        // filter_bookyear
        // memo="meeting_emails"
      />
      <DataGrid
        // action_count={1}
        // actions={(params) => {
        //   return (<>
        //     <StyledIconButton color="primary" path={`/meeting_email/${params.row.id}`}>
        //       <VisibilityIcon />
        //     </StyledIconButton>
        //   </>);
        // }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={emailsCount}
        objects={(emails || []).map(u => ({
          ...u,
          vennoot_number: u.user_vennoot_number,
          vennoot_name: [u.user_first_name, u.user_last_name].join(' ')
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
