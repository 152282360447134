import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../lib/server_helper';

import {
  Avatar,
  CircularProgress
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AltRouteIcon from '@mui/icons-material/AltRoute';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { DataGrid } from '../components/interface/DataGrid';
import { Page } from '../components/interface/Page';
import { SearchBox } from '../components/interface/SearchBox';
import { StyledIconButton } from '../components/interface/StyledIconButton';

import { AlertSomethingWentWrong } from '../components/alerts/SomethingWentWrong';

import { User } from '../models/User';
import { loginAtom } from '../lib/auth';

const { DateTime } = require("luxon");

type UsersProps = {};

export const Users: FunctionComponent<UsersProps> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['login'],
  //   queryFn: async () => {return },
  // });

  const [login, setLogin] = useAtom(loginAtom);
  const [users, setUsers] = useState<User[]>([]);
  const [usersCount, setUsersCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [handleLoader, setHandleLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id ASC");
  const [searchBirthdate, setSearchBirthdate] = useState('');
  const [searchCatchAll, setSearchCatchAll] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [searchName, setSearchName] = useState('');
  const [searchNumber, setSearchNumber] = useState('');
  const [searchStreet, setSearchStreet] = useState('');
  const [searchVennootNumber, setSearchVennootNumber] = useState('');
  const [searchZipCity, setSearchZipCity] = useState('');
  const [initialValues, setInitialValues] = useState({street: '', number: '', zip: '', resettable: true});

  const [filterEnabled, setFilterEnabled] = useState(['0', '1']);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch('');
  }, [searchName, searchEmail, searchBirthdate, searchVennootNumber, searchStreet, searchNumber, searchZipCity, searchCatchAll, filterEnabled]);
  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onSearch = (s: string) => {
    setLoader(true);
    fetch_all<User>('/nl/v3/objects/fetch_all', {
      object: 'user',
      fields: ['id', 'first_name', 'last_name', 'vennoot_number', 'enabled', 'created_at', 'stock_number', 'readable_address', 'birth_date', 'active_exit', 'notes|count', 'street', 'number', 'zip'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          name: searchName,
          email: searchEmail,
          birth_date: searchBirthdate,
          vennoot_number: searchVennootNumber,
          street: searchStreet,
          number: searchNumber,
          zip: searchZipCity,
          catch_all: searchCatchAll,
          enabled: filterEnabled
        }
      }
    }, (obs, complete_data) => {
      setUsers(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setUsersCount(count);

      setLoader(false);
    }, (z) => {

    }, login);
  };

  const generateBlankTransfer = (id: number) => {
    if (!!login) {
      setHandleLoader(true);
      fetch_one<User>('/nl/v3/objects/custom_action', {
        object: 'user',
        class_action: 'custom_api_blank_overdracht',
        handler_id: login.id,
        id: id
      }, (obs, complete_data) => {
        if (!!obs && !!obs?.custom_result && !!obs.custom_result?.downloadable) navigate(obs.custom_result?.downloadable);
        setHandleLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
        setHandleLoader(false);
      }, login);
    }
  };

  const columns: GridColDef[] = [
    { field: 'vennoot_number', headerName: 'VenNR', width: 150 },
    { field: 'id', headerName: 'ID', width: 100, renderCell: params => <>
      {params.row.id}
      {params.row.active_exit > 0 && <>
        <Avatar sx={{marginLeft: 1, width: 24, height: 24, border: '1px solid transparent', borderColor: theme.palette.secondary.main}}>
          <WarningAmberIcon sx={{color: theme.palette.secondary.main, height: 16, width: 16}} />
        </Avatar>
      </>}
    </> },
    { field: 'first_name', headerName: 'First Name', width: 120 },
    { field: 'last_name', headerName: 'Last Name', width: 120 },
    { field: 'birth_date', headerName: 'Birth Date', width: 180, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'notes_count', headerName: 'Notes', width: 80 },
    { field: 'stock_number', headerName: 'Stock', width: 80 },
    { field: 'address', headerName: 'Address', width: 200, sortable: false },
    { field: 'created_at', headerName: 'Created', width: 180, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("f") },
  ];

  return (
    <Page sx={{}} title="Users" helper="users" old_url="https://old.argenco.be/users">
      <SearchBox
        simple={false}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
        initialValues={initialValues}
        userSearch={(advanced: {name:string, email:string, birth_date:string, vennoot_number:string, street:string, number:string, zip:string, catch_all:string, enabled:string[]}) => {
          setSearchName(advanced.name);
          setSearchEmail(advanced.email);
          setSearchBirthdate(advanced.birth_date);
          setSearchVennootNumber(advanced.vennoot_number);
          setSearchStreet(advanced.street);
          setSearchNumber(advanced.number);
          setSearchZipCity(advanced.zip);
          setSearchCatchAll(advanced.catch_all);
          setFilterEnabled(advanced.enabled);
          setPage(0);
        }}
        filter_enabled
        defaultFilterEnabled={['0', '1']}
        memo="users"
      />
      <DataGrid
        action_count={4}
        actions={(params) => {
          return (<>
            <StyledIconButton title="Open Detail" color="primary" path={`/user/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
            <StyledIconButton title="Invalid address email" color="primary" onClick={(e) => {
              navigate(`/user/${params.row.id}/invalid_address`);
            }}>
              <DomainDisabledIcon />
            </StyledIconButton>
            {!!handleLoader && <CircularProgress />}
            {!handleLoader && <StyledIconButton title="Generate blank transfer" color="primary" onClick={(e) => {
              generateBlankTransfer(params.row.id);
            }}>
              <AltRouteIcon sx={{transform: 'rotate(90deg)'}} />
            </StyledIconButton>}
            <StyledIconButton title="Find by address" color="primary" onClick={(e) => {
              setSearchName('');
              setSearchEmail('');
              setSearchVennootNumber('');
              setSearchCatchAll('');
              setFilterEnabled(['0', '1']);
              setSearchBirthdate('');
              setSearchStreet(params.row.street || '');
              setSearchNumber(params.row.number || '');
              setSearchZipCity(params.row.zip || '');
              setPage(0);
              setInitialValues({street: params.row.street || '', number: params.row.number || '', zip: params.row.zip || '', resettable: true});
            }}>
              <TravelExploreIcon />
            </StyledIconButton>
          </>);
        }}
        objectCount={usersCount}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objects={(users || []).map(u => ({
          ...u,
          name: [u.first_name, u.last_name].join(" "),
          address: u.readable_address
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
