import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one } from '../../../lib/server_helper';
import { loginAtom } from '../../../lib/auth';

import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconItsme from "../../../images/itsme.svg";
import LaunchIcon from '@mui/icons-material/Launch';

import { AlertCheckInputs } from '../../../components/alerts/CheckInputs';
import { AlertSaved } from '../../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../../components/alerts/SomethingWentWrong';

import {
  BankAccount,
  StyledButton,
  StyledDateField,
  StyledDropzone,
  StyledIconButton,
  StyledSelect,
  StyledTextField,
  Typography
} from '../../../components/interface';
import { DetailLine } from '../../../components/interface/Detail/DetailLine';
import { UserDetailBase } from '../../../components/user_detail/Base';

import { EmissionKey } from '../../../models/EmissionKey';
import { User } from '../../../models/User';

const { DateTime } = require("luxon");

type Props = {
  emissionKey: EmissionKey;
  isHidden: boolean;
  fields: string[];
  refreshKey: () => void;
};

export const EmissionDetailTabsDetailProfile: FunctionComponent<Props> = ({emissionKey, isHidden, fields, refreshKey}) => {
  // const navigate = useNavigate();
  const theme = useTheme();
  // const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [editing, setEditing] = useState<boolean>(false);
  const [editableEmissionKey, setEditableEmissionKey] = useState<EmissionKey>(emissionKey);
  const [errors, setErrors] = useState<string[]>([]);

  const [item, setItem] = useState<File>();
  const [guardianItem, setGuardianItem] = useState<File>();

  const [loader, setLoader] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [fraudsChecked, setFraudsChecked] = useState<boolean>(false);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [userSavedSnackOpen, setUserSavedSnackOpen] = useState<boolean>(false);

  const saveUser = () => {
    setErrors([]);
    let errors = [];

    if (typeof(editableEmissionKey?.user_first_name) === 'undefined' || editableEmissionKey?.user_first_name === null || editableEmissionKey?.user_first_name.trim() === "") errors.push('first_name');
    if (typeof(editableEmissionKey?.user_last_name) === 'undefined' || editableEmissionKey?.user_last_name === null || editableEmissionKey?.user_last_name.trim() === "") errors.push('last_name');
    if (typeof(editableEmissionKey?.user_birth_date) === 'undefined' || editableEmissionKey?.user_birth_date === null || editableEmissionKey?.user_birth_date.trim() === "") errors.push('birth_date');
    if (typeof(editableEmissionKey?.user_street) === 'undefined' || editableEmissionKey?.user_street === null || editableEmissionKey?.user_street.trim() === "") errors.push('street');
    if (typeof(editableEmissionKey?.user_number) === 'undefined' || editableEmissionKey?.user_number === null || editableEmissionKey?.user_number.trim() === "") errors.push('number');
    if (typeof(editableEmissionKey?.user_zip) === 'undefined' || editableEmissionKey?.user_zip === null || editableEmissionKey?.user_zip.trim() === "") errors.push('zip');
    if (typeof(editableEmissionKey?.user_city) === 'undefined' || editableEmissionKey?.user_city === null || editableEmissionKey?.user_city.trim() === "") errors.push('city');

    if (typeof(editableEmissionKey?.user_zicht_account) === 'undefined' || editableEmissionKey?.user_zicht_account === null || editableEmissionKey?.user_zicht_account.trim() === "") errors.push('zicht_account');
    if (!!editableEmissionKey?.user_has_guardianship) {
      if (typeof(editableEmissionKey?.user_guardian_first_name) === 'undefined' || editableEmissionKey?.user_guardian_first_name === null || editableEmissionKey?.user_guardian_first_name.trim() === "") errors.push('guardian_first_name');
      if (typeof(editableEmissionKey?.user_guardian_last_name) === 'undefined' || editableEmissionKey?.user_guardian_last_name === null || editableEmissionKey?.user_guardian_last_name.trim() === "") errors.push('guardian_last_name');
      if (typeof(editableEmissionKey?.user_guardian_email) === 'undefined' || editableEmissionKey?.user_guardian_email === null || editableEmissionKey?.user_guardian_email.trim() === "") errors.push('guardian_email');
      if (typeof(editableEmissionKey?.user_guardian_birth_date) === 'undefined' || editableEmissionKey?.user_guardian_birth_date === null || editableEmissionKey?.user_guardian_birth_date.trim() === "") errors.push('guardian_birth_date');
      if (typeof(editableEmissionKey?.user_guardian_street) === 'undefined' || editableEmissionKey?.user_guardian_street === null || editableEmissionKey?.user_guardian_street.trim() === "") errors.push('guardian_street');
      if (typeof(editableEmissionKey?.user_guardian_number) === 'undefined' || editableEmissionKey?.user_guardian_number === null || editableEmissionKey?.user_guardian_number.trim() === "") errors.push('guardian_number');
      if (typeof(editableEmissionKey?.user_guardian_zip) === 'undefined' || editableEmissionKey?.user_guardian_zip === null || editableEmissionKey?.user_guardian_zip.trim() === "") errors.push('guardian_zip');
      if (typeof(editableEmissionKey?.user_guardian_city) === 'undefined' || editableEmissionKey?.user_guardian_city === null || editableEmissionKey?.user_guardian_city.trim() === "") errors.push('guardian_city');
      if (typeof(editableEmissionKey?.user_guardian_phone) === 'undefined' || editableEmissionKey?.user_guardian_phone === null || editableEmissionKey?.user_guardian_phone.trim() === "") errors.push('guardian_phone');
    }
    // console.log(errors);
    if (errors.length < 1) {
      setLoader(true);
  
      let formData = new FormData();
      formData.append('add_key_handler', editableEmissionKey?.id?.toString() || '');
      formData.append('handler_id', login?.id?.toString() || '');
      formData.append('object', 'user');
      fields.map(field => {
        formData.append('fields[]', field);
      });
      if (!!editableEmissionKey?.user_id) formData.append('ob[id]', editableEmissionKey.user_id.toString());
      formData.append('ob[first_name]', editableEmissionKey?.user_first_name || '');
      formData.append('ob[last_name]', editableEmissionKey?.user_last_name || '');
      formData.append('ob[birth_date]', editableEmissionKey?.user_birth_date || '');
      
      formData.append('ob[gender]', editableEmissionKey?.user_gender === 'F' ? 'F' : 'M');
      formData.append('ob[email]', editableEmissionKey?.user_email || '');
      formData.append('ob[street]', editableEmissionKey?.user_street || '');
      formData.append('ob[number]', editableEmissionKey?.user_number || '');
      formData.append('ob[zip]', editableEmissionKey?.user_zip || '');
      formData.append('ob[city]', editableEmissionKey?.user_city || '');
      formData.append('ob[phone]', editableEmissionKey?.user_phone || '');
      formData.append('ob[mobile]', editableEmissionKey?.user_mobile || '');
      formData.append('ob[cor_street]', editableEmissionKey?.user_cor_street || '');
      formData.append('ob[cor_street_2]', editableEmissionKey?.user_cor_street_2 || '');
      formData.append('ob[cor_number]', editableEmissionKey?.user_cor_number || '');
      formData.append('ob[cor_zip]', editableEmissionKey?.user_cor_zip || '');
      formData.append('ob[cor_city]', editableEmissionKey?.user_cor_city || '');

      if (!!editableEmissionKey?.user_has_guardianship && !!guardianItem) formData.append('guardian[identity_front]', guardianItem);

      formData.append('ob[locale]', editableEmissionKey?.user_locale || 'nl');
      formData.append('ob[zicht_account]', editableEmissionKey?.user_zicht_account || '');

      if (!!item) formData.append('ob[identity_front]', item);

      if (!!editableEmissionKey?.user_has_guardianship) {
        formData.append('guardian[first_name]', editableEmissionKey?.user_guardian_first_name || '');
        formData.append('guardian[last_name]', editableEmissionKey?.user_guardian_last_name || '');
        formData.append('guardian[email]', editableEmissionKey?.user_guardian_email || '');
        formData.append('guardian[birth_date]', editableEmissionKey?.user_guardian_birth_date || '');
        formData.append('guardian[street]', editableEmissionKey?.user_guardian_street || '');
        formData.append('guardian[number]', editableEmissionKey?.user_guardian_number || '');
        formData.append('guardian[zip]', editableEmissionKey?.user_guardian_zip || '');
        formData.append('guardian[city]', editableEmissionKey?.user_guardian_city || '');
        formData.append('guardian[phone]', editableEmissionKey?.user_guardian_phone || '');
        formData.append('guardian[comment]', editableEmissionKey?.user_guardian_comment || '');
      }
      
      fetch_one<User>('/nl/v3/objects/save', formData, (ob, complete_data) => {
        refreshKey();
        setEditing(false);
        setLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, login);
    } else {
      setErrorSnackOpen(true);
      setErrors(errors);
    }
  };

  const setSaveConfirm = () => {
    fetch_one<EmissionKey>('/nl/v3/objects/custom_action', {
      object: 'emission_key',
      class_action: 'custom_api_confirm_step',
      id: emissionKey.id,
      step: 'profile'
    }, (ob, complete_data) => {
      refreshKey();
    }, (z, y) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const fraudListItem = (fraud: {
    id?: number;
    vennoot_number?: string;
    full_name?: string;
  }, type: string) => {
    return <ListItem dense sx={{
      cursor: 'pointer',
      borderRadius: 4,
      "&:hover": {
        backgroundColor: theme.palette.opacitated?.secondary_02
      }
    }} onClick={() => {
      window.open(`https://backend.argenco.be/user/${fraud.id}`, "_BLANK")
    }}>
      <ListItemIcon>
        <LaunchIcon />
      </ListItemIcon>
      <ListItemText primary={<>{fraud.vennoot_number}<span style={{marginLeft: 16, fontStyle: 'italic'}}>({type})</span></>} secondary={fraud.full_name} />
    </ListItem>
  }

  return (
    <Box hidden={isHidden} sx={{paddingTop: 2}}>
      <Grid container spacing="8">
        {!emissionKey.check_profile && Object.values(emissionKey.user_possible_frauds || {}).filter(v => v.length > 0).length > 0 && <Grid item xs={12} sx={{alignContent: 'baseline'}}>
          <Box sx={{
            backgroundColor: theme.palette.opacitated?.secondary_02,
            border: "1px solid transparent",
            borderColor: theme.palette.secondary.dark,
            padding: 2,
            position: 'relative'
          }}>
            <Typography>Some possible frauds were detected:</Typography>
            <Typography>{(emissionKey.user_possible_frauds?.account_num || []).map(fraud => fraudListItem(fraud, "AccountNumber"))}</Typography>
            <Typography>{(emissionKey.user_possible_frauds?.name_bday || []).map(fraud => fraudListItem(fraud, "Birthday/Name"))}</Typography>
            <Typography>{(emissionKey.user_possible_frauds?.email || []).map(fraud => fraudListItem(fraud, "Email"))}</Typography>

            <FormControlLabel
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                padding: 0.5,
                paddingRight: 2,
                borderRadius: 4,
                position: 'absolute',
                top: 16,
                right: 2
              }}
              control={
                <Checkbox checked={fraudsChecked} onChange={(v) => {
                  setFraudsChecked(v.target.checked);
                }} name="frauds_checked" />
              }
              label="Frauds are okay"
            />
          </Box>
        </Grid>}

        {!editing && <Grid item xs={12} md={4} sx={{alignContent: 'baseline'}}>
          <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.identity
            }}
            title="Identity"
            wide
            adornment={!!emissionKey && !!emissionKey.user_itsme_sub ? <Avatar src={IconItsme} sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} /> : <></>}
          >
            <DetailLine name="First name" value={!!emissionKey ? emissionKey.user_first_name : ''} />
            <DetailLine name="Last name" value={!!emissionKey ? emissionKey.user_last_name : ''} />
            <DetailLine name="Birthdate" date={!!emissionKey ? emissionKey.user_birth_date : ''} />
            <DetailLine no_divider copyable name="Vennoot Number" value={!!emissionKey ? emissionKey.user_vennoot_number : ''} />
          </UserDetailBase>

          <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.account,
              marginTop: 1
            }}
            title="Account"
            wide
          >
            <DetailLine name="Language" value={!!emissionKey ? emissionKey.user_locale : ''} />
          </UserDetailBase>

          {!!emissionKey?.user_get_identity_front && <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.identity,
              marginTop: 1
            }}
            wide
            title="ID"
            // confirmable={(confirmed) => {setIdentityConfirmed(confirmed ? 1 : 0); if (!confirmed) setPurgeIdentity(true); setTimeout(() => {setSaveConfirm(true);}, 200);}}
            // deleteable={() => {setPurgeIdentity(true); setTimeout(() => {setSaveConfirm(true);}, 200);}}
            // confirmed={emissionKey.extra_data?.identity_front_confirmed?.toString() === "1"}
            turnable={!!emissionKey.user_identity_front_blob_content_type && emissionKey.user_identity_front_blob_content_type.indexOf("image") > -1}
            adornment={!!emissionKey && !!emissionKey.user_get_identity_front && <>
              <StyledIconButton
                path={emissionKey.user_get_identity_front}
                newTab
                sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
              ><LaunchIcon /></StyledIconButton>
            </>}
          >
            <Grid item xs={12}>
              {!!emissionKey.user_identity_front_blob_content_type && emissionKey.user_identity_front_blob_content_type.indexOf("image") > -1 && <img style={{maxWidth: '100%'}} src={emissionKey.user_get_identity_front} />}
              {!!emissionKey.user_identity_front_blob_content_type && emissionKey.user_identity_front_blob_content_type.indexOf("pdf") > -1 && <iframe style={{width: '100%', height: '300px'}} src={emissionKey.user_get_identity_front} />}
            </Grid>
            <Grid item xs={12}>
              {!!emissionKey?.user_identity_front_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {emissionKey?.user_identity_front_filename} ({(parseInt(emissionKey?.user_identity_front_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
            </Grid>
          </UserDetailBase>}

          {!!emissionKey && emissionKey.user_has_guardianship && emissionKey.user_guardian_get_identity_front && <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.guardian,
              marginTop: 1
            }}
            title="Guardian ID"
            wide
            adornment={!!emissionKey && !!emissionKey.user_guardian_get_identity_front && <>
              <StyledIconButton
                path={emissionKey.user_guardian_get_identity_front}
                newTab
                sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
              ><LaunchIcon /></StyledIconButton>
            </>}
          >
            <Grid item xs={12}>
              {!!emissionKey.user_guardian_identity_front_blob_content_type && emissionKey.user_guardian_identity_front_blob_content_type.indexOf("image") > -1 && <img style={{maxWidth: '100%'}} src={emissionKey.user_guardian_get_identity_front} />}
              {!!emissionKey.user_guardian_identity_front_blob_content_type && emissionKey.user_guardian_identity_front_blob_content_type.indexOf("pdf") > -1 && <iframe style={{width: '100%', height: '300px'}} src={emissionKey.user_guardian_get_identity_front} />}
            </Grid>
            <Grid item xs={12}>
              {!!emissionKey?.user_guardian_identity_front_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {emissionKey?.user_guardian_identity_front_filename} ({(parseInt(emissionKey?.user_guardian_identity_front_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
            </Grid>
          </UserDetailBase>}
        </Grid>}
        {!editing && <Grid item xs={12} md={4} sx={{alignContent: 'baseline'}}>
          <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.register
            }}
            title="Register"
            wide
          >
            <DetailLine name="Stock Number" value={!!emissionKey ? emissionKey.user_stock_number?.toString() : ''} />
            <DetailLine name="Type" value={!!emissionKey ? (emissionKey.user_vennoot_number ? emissionKey.user_vennoot_number[0] : '') : ''} />
            <DetailLine name="Value" value={!!emissionKey ? `${emissionKey.user_transactions_first_stock_saldo_value?.toFixed(0)} EUR` : ''} />
            <DetailLine no_divider name="Zicht account" value={!!emissionKey ? emissionKey.user_zicht_account : ''} />
          </UserDetailBase>

          <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.contact,
              marginTop: 1
            }}
            title="Contact"
            wide
          >
            <Grid item container xs={12} sx={{alignContent: 'baseline'}} rowSpacing="4">
              <DetailLine name="Gender" gender_icons value={!!emissionKey ? emissionKey.user_gender : ''} />
              <DetailLine copyable name="E-mail" value={!!emissionKey ? emissionKey.user_email : ''} />
              <DetailLine name="Phone" value={!!emissionKey ? emissionKey.user_phone : ''} />
              <DetailLine name="Mobile" value={!!emissionKey ? emissionKey.user_mobile : ''} />
              <DetailLine no_divider name="Address" value={!!emissionKey ? [emissionKey.user_street, emissionKey.user_number].join(" ") : ''} />
              <DetailLine no_divider={!(!!emissionKey?.cor_user_street && !!emissionKey?.user_cor_zip)} name="" value={!!emissionKey ? [emissionKey.user_zip, emissionKey.user_city].join(" ") : ''} />
              {!!emissionKey?.cor_user_street && !!emissionKey?.user_cor_zip && <DetailLine no_divider name="COR Address" value={!!emissionKey ? [emissionKey.user_cor_street_2].join(" ") : ''} />}
              {!!emissionKey?.cor_user_street && !!emissionKey?.user_cor_zip && <DetailLine no_divider name="" value={!!emissionKey ? [emissionKey.user_cor_street, emissionKey.user_cor_number].join(" ") : ''} />}
              {!!emissionKey?.cor_user_street && !!emissionKey?.user_cor_zip && <DetailLine no_divider name="" value={!!emissionKey ? [emissionKey.user_cor_zip, emissionKey.user_cor_city].join(" ") : ''} />}
            </Grid>
          </UserDetailBase>

          {!!emissionKey && emissionKey.user_has_guardianship && <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.guardian,
              marginTop: 1
            }}
            wide
            title="Guardian"
          >
            <DetailLine name="First name" value={!!emissionKey ? emissionKey.user_guardian_first_name : ''} />
            <DetailLine name="Last name" value={!!emissionKey ? emissionKey.user_guardian_last_name : ''} />
            <DetailLine copyable name="E-mail" value={!!emissionKey ? emissionKey.user_guardian_email : ''} />
            <DetailLine name="Birthdate" date={!!emissionKey ? emissionKey?.user_guardian_birth_date : ''} />
            <DetailLine no_divider name="Address" value={!!emissionKey ? [emissionKey.user_guardian_street, emissionKey.user_guardian_number].join(" ") : ''} />
            <DetailLine name="" value={!!emissionKey ? [emissionKey.user_guardian_zip, emissionKey.user_guardian_city].join(" ") : ''} />
            <DetailLine name="Phone" value={!!emissionKey ? emissionKey.user_guardian_phone : ''} />
            <DetailLine no_divider name="Comment" value={!!emissionKey ? emissionKey.user_guardian_comment : ''} />
          </UserDetailBase>}
        </Grid>}

        {!!editing && <Grid container item xs={12} md={4} spacing="8" sx={{alignContent: 'baseline'}}>
          <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.identity
            }}
            title="Identity"
            adornment={!!editableEmissionKey && !!editableEmissionKey.user_itsme_sub ? <Avatar src={IconItsme} sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} /> : <></>}
            wide
          >
            <Grid item xs={12}>
              <StyledTextField
                label="First Name"
                value={editableEmissionKey?.user_first_name || ''}
                id='first_name'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_first_name: v});}}
                fullWidth
                capitalaction
                errorArray={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Last Name"
                value={editableEmissionKey?.user_last_name || ''}
                id='last_name'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_last_name: v});}}
                fullWidth
                capitalaction
                errorArray={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledDateField
                label="Birthdate"
                value={editableEmissionKey?.user_birth_date || ''}
                id='birth_date'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_birth_date: v.toString()});}}
                fullWidth
                errorArray={errors}
              />
            </Grid>
          </UserDetailBase>

          <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.account
            }}
            title="Account"
            wide
          >
            <Grid item xs={12}>
              <StyledSelect
                label="Language"
                value={editableEmissionKey?.user_locale || 'nl'}
                id='locale'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_locale: v});}}
                list={[
                  {id: 'nl', name: "Dutch"},
                  {id: 'fr', name: "French"}
                ]}
              />
            </Grid>
          </UserDetailBase>

          <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.identity
            }}
            title="ID"
            wide
          >
            <Grid item xs={12}>
              <Typography sx={{fontSize: '0.75rem'}}>Bijlage</Typography>
              <StyledDropzone
                acceptedFiles={{
                  'image/*': [],
                  'application/pdf': []
                }}
                onDrop={acceptedFiles => {setItem(acceptedFiles[0]);}}
                multiple={false}
                maxFiles={1}
                maxSize={3 * 1000 * 1000}
                items={!!item ? [item] : []}
                dropText="Click here, or drop some files."
                dropHint="Allowed: 1 image (.png, .jpeg, .jpg, .gif) or PDF (.pdf), < 3mb"
              />
              {!!editableEmissionKey?.user_identity_front_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {editableEmissionKey?.user_identity_front_filename} ({(parseInt(editableEmissionKey?.user_identity_front_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
            </Grid>
          </UserDetailBase>

          {!!editableEmissionKey?.user_has_guardianship && <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.guardian
            }}
            title="Guardian ID"
            wide
          >
            <Grid item xs={12}>
              <Typography sx={{fontSize: '0.75rem'}}>Bijlage</Typography>
              <StyledDropzone
                acceptedFiles={{
                  'image/*': [],
                  'application/pdf': []
                }}
                onDrop={acceptedFiles => {setGuardianItem(acceptedFiles[0]);}}
                multiple={false}
                maxFiles={1}
                maxSize={3 * 1000 * 1000}
                items={!!guardianItem ? [guardianItem] : []}
                dropText="Click here, or drop some files."
                dropHint="Allowed: 1 image (.png, .jpeg, .jpg, .gif) or PDF (.pdf), < 3mb"
              />
              {!!editableEmissionKey?.user_guardian_identity_front_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {editableEmissionKey?.user_guardian_identity_front_filename} ({(parseInt(editableEmissionKey?.user_guardian_identity_front_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
            </Grid>
          </UserDetailBase>}
        </Grid>}

        {!!editing && <Grid container item xs={12} md={4} spacing="8" sx={{alignContent: 'baseline'}}>
          <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.register
            }}
            title="Register"
            wide
          >
            <Grid item xs={12}>
              <BankAccount
                label="Zicht account"
                value={editableEmissionKey?.user_zicht_account || ''}
                id="zicht_account"
                onChange={(v, checked) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_zicht_account: v});}}
                fullWidth
                errorArray={errors}
              />
            </Grid>
          </UserDetailBase>

          <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.contact
            }}
            title="Contact"
            wide
          >
            <Grid item xs={12}>
              <StyledSelect
                label="Gender"
                value={editableEmissionKey?.user_gender === 'F' ? 'F' : 'M'}
                id='gender'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_gender: v});}}
                list={[
                  {id: 'M', name: "Male"},
                  {id: 'F', name: "Female"}
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="E-mail"
                value={editableEmissionKey?.user_email || ''}
                id='email'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_email: v});}}
                fullWidth
                errorArray={errors}
              />
            </Grid>
            <Grid item xs={8}>
              <StyledTextField
                label="Street"
                value={editableEmissionKey?.user_street || ''}
                id='street'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_street: v});}}
                fullWidth
                capitalaction
                errorArray={errors}
              />
            </Grid>
            <Grid item xs={4}>
              <StyledTextField
                label="Number"
                value={editableEmissionKey?.user_number || ''}
                id='number'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_number: v});}}
                fullWidth
                errorArray={errors}
              />
            </Grid>
            <Grid item xs={4}>
              <StyledTextField
                label="Zip"
                value={editableEmissionKey?.user_zip || ''}
                id='zip'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_zip: v});}}
                fullWidth
                errorArray={errors}
              />
            </Grid>
            <Grid item xs={8}>
              <StyledTextField
                label="City"
                value={editableEmissionKey?.user_city || ''}
                id='city'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_city: v});}}
                fullWidth
                capitalaction
                errorArray={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Phone"
                value={editableEmissionKey?.user_phone || ''}
                id='phone'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_phone: v});}}
                fullWidth
                errorArray={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Mobile"
                value={editableEmissionKey?.user_mobile || ''}
                id='mobile'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_mobile: v});}}
                fullWidth
                errorArray={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="COR Street header"
                value={editableEmissionKey?.user_cor_street_2 || ''}
                id='cor_street_2'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_cor_street_2: v});}}
                fullWidth
                capitalaction
              />
            </Grid>
            <Grid item xs={8}>
              <StyledTextField
                label="COR Street"
                value={editableEmissionKey?.user_cor_street || ''}
                id='cor_street'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_cor_street: v});}}
                fullWidth
                capitalaction
              />
            </Grid>
            <Grid item xs={4}>
              <StyledTextField
                label="COR Number"
                value={editableEmissionKey?.user_cor_number || ''}
                id='cor_number'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_cor_number: v});}}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <StyledTextField
                label="COR Zip"
                value={editableEmissionKey?.user_cor_zip || ''}
                id='cor_zip'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_cor_zip: v});}}
                fullWidth
              />
            </Grid>
            <Grid item xs={8}>
              <StyledTextField
                label="COR City"
                value={editableEmissionKey?.user_cor_city || ''}
                id='cor_city'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_cor_city: v});}}
                fullWidth
                capitalaction
              />
            </Grid>
          </UserDetailBase>

          {!!editableEmissionKey?.user_has_guardianship && <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.guardian
            }}
            title="Guardian"
            wide
          >
            <Grid item xs={12}>
              <StyledTextField
                label="Guardian first name"
                value={editableEmissionKey?.user_guardian_first_name || ''}
                id='guardian_first_name'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_guardian_first_name: v});}}
                fullWidth
                capitalaction
                errorArray={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Guardian last name"
                value={editableEmissionKey?.user_guardian_last_name || ''}
                id='guardian_last_name'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_guardian_last_name: v});}}
                fullWidth
                capitalaction
                errorArray={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Guardian e-mail"
                value={editableEmissionKey?.user_guardian_email || ''}
                id='guardian_email'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_guardian_email: v});}}
                fullWidth
                errorArray={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledDateField
                label="Guardian birthdate"
                value={editableEmissionKey?.user_guardian_birth_date || ''}
                id='guardian_birth_date'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_guardian_birth_date: v.toString()});}}
                fullWidth
                errorArray={errors}
              />
            </Grid>
            <Grid item xs={8}>
              <StyledTextField
                label="Guardian street"
                value={editableEmissionKey?.user_guardian_street || ''}
                id='guardian_street'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_guardian_street: v});}}
                fullWidth
                capitalaction
                errorArray={errors}
              />
            </Grid>
            <Grid item xs={4}>
              <StyledTextField
                label="Guardian number"
                value={editableEmissionKey?.user_guardian_number || ''}
                id='guardian_number'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_guardian_number: v});}}
                fullWidth
                errorArray={errors}
              />
            </Grid>
            <Grid item xs={4}>
              <StyledTextField
                label="Guardian zip"
                value={editableEmissionKey?.user_guardian_zip || ''}
                id='guardian_zip'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_guardian_zip: v});}}
                fullWidth
                errorArray={errors}
              />
            </Grid>
            <Grid item xs={8}>
              <StyledTextField
                label="Guardian city"
                value={editableEmissionKey?.user_guardian_city || ''}
                id='guardian_city'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_guardian_city: v});}}
                fullWidth
                capitalaction
                errorArray={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Guardian phone"
                value={editableEmissionKey?.user_guardian_phone || ''}
                id='guardian_phone'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_guardian_phone: v});}}
                fullWidth
                errorArray={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Guardian comment"
                value={editableEmissionKey?.user_guardian_comment || ''}
                id='guardian_comment'
                onChange={(v) => {if (!!editableEmissionKey) setEditableEmissionKey({...editableEmissionKey, user_guardian_comment: v});}}
                fullWidth
                multiline
              />
            </Grid>
          </UserDetailBase>}
        </Grid>}

        <Grid item container xs={12} md={4} sx={{alignContent: 'baseline', flexBasis: "calc(33.33% + 8px) !important"}} spacing="8">
          <Grid item xs={12}>
            <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Actions</Typography>
          </Grid>

          {!!editing && !!loader && <Grid item xs={12} sx={{textAlign: 'center'}}>
            <CircularProgress />
          </Grid>}
          {editing && !loader && <>
            <Grid item xs={6}>
              <StyledButton
                label="Update"
                id='update'
                contained
                saveStartIcon
                onClick={(v) => {
                  saveUser();
                }}
                sx={{width: '100%'}}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledButton
                label="Cancel"
                id='cancel'
                onClick={(v) => {
                  setEditableEmissionKey(emissionKey);
                  setEditing(false);
                }}
                sx={{width: '100%'}}
              />
            </Grid>
          </>}

          {!editing && <>
            <Grid item xs={6}>
              <StyledButton
                label="Edit"
                id='edit'
                contained
                onClick={(v) => {
                  setEditableEmissionKey(emissionKey);
                  setErrors([]);
                  setEditing(true);
                }}
                sx={{width: '100%'}}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={6}>
              <StyledButton
                label="GoTo Order URL"
                id='order_url'
                onClick={(v) => {
                  window.open(`https://www.argenco.be/emissions/stocks/${emissionKey.user_stock_id}`, "_BLANK");
                }}
                sx={{width: '100%'}}
                startIcon={<LaunchIcon />}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledButton
                label="Copy Order URL"
                id='order_url_copy'
                contained
                onClick={(v) => {
                  navigator.clipboard.writeText(`https://www.argenco.be/emissions/stocks/${emissionKey.user_stock_id}`);
                  setOpen(true);
                }}
                sx={{width: '100%'}}
                startIcon={<ContentCopyIcon />}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3" sx={{marginTop: 1, textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Confirmation</Typography>
            </Grid>
            {!!emissionKey.check_profile && <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Typography sx={{
                fontStyle: 'italic',
                fontSize: '0.8rem'
              }}>Confirmed on {DateTime.fromISO(emissionKey.check_profile_confirmed_at).toFormat("DDDD")}</Typography>
            </Grid>}
            {!emissionKey.check_profile && <Grid item xs={6}>
              <StyledButton
                label="Confirm"
                id='set_checked'
                disabled={Object.values(emissionKey.user_possible_frauds || {}).filter(v => v.length > 0).length > 0 && !fraudsChecked}
                contained
                startIcon={<CheckCircleOutlineIcon />}
                onClick={(v) => {
                  // if (!itemConfirmed) setItemConfirmed(1);
                  // if (!identityConfirmed) setIdentityConfirmed(1);
                  // if (!identityNewConfirmed) setIdentityNewConfirmed(1);
                  // if (!identityVoogdConfirmed) setIdentityVoogdConfirmed(1);
                  // setTimeout(() => {setSaveConfirm(true);}, 200);
                  setSaveConfirm();
                }}
                sx={{width: '100%'}}
              />
            </Grid>}
          </>}
        </Grid>

        {!!emissionKey.check_profile && Object.values(emissionKey.user_possible_frauds || {}).filter(v => v.length > 0).length > 0 && <Grid item xs={12} sx={{alignContent: 'baseline'}}>
          <Box sx={{
            backgroundColor: theme.palette.opacitated?.secondary_02,
            border: "1px solid transparent",
            borderColor: theme.palette.secondary.dark,
            padding: 2
          }}>
            <Typography>Some possible frauds were detected (and subsequently deemed 'okay'):</Typography>
            <Typography>{(emissionKey.user_possible_frauds?.account_num || []).map(fraud => fraudListItem(fraud, "AccountNumber"))}</Typography>
            <Typography>{(emissionKey.user_possible_frauds?.name_bday || []).map(fraud => fraudListItem(fraud, "Birthday/Name"))}</Typography>
            <Typography>{(emissionKey.user_possible_frauds?.email || []).map(fraud => fraudListItem(fraud, "Email"))}</Typography>
          </Box>
        </Grid>}
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!userSavedSnackOpen && <AlertSaved open={userSavedSnackOpen} setOpen={(b) => {setUserSavedSnackOpen(b);}} />}

      {!!open && <Snackbar
        open={open}
        onClose={() => {setOpen(false);}}
        autoHideDuration={2000}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">Copied!</MuiAlert>
      </Snackbar>}
    </Box>
  );
}
