import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';

import { fetch_all } from '../../lib/server_helper';

import {
  Box,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import { SearchBox } from './SearchBox';
import { Typography } from './Typography';

import { User } from '../../models/User';
import { loginAtom } from '../../lib/auth';

type UserSearchProps = {
  fields: string[];
  customUserLayout: ("padding" | "name" | "email" | "birthdate" | "vennr" | "address" | "zicht_account")[];
  resultLayout: (u:User) => React.ReactNode;
  initialValues?: {birth_date?: string, street?: string, number?: string, zip?: string, vennoot_number?: string, name?: string, zicht_account?: string};
};

export const UserSearch: FunctionComponent<UserSearchProps> = ({customUserLayout, initialValues, fields, resultLayout}) => {
  const theme = useTheme();

  const [login, setLogin] = useAtom(loginAtom);

  const [loader, setLoader] = useState<boolean>(true);
  const [searchOptions, setSearchOptions] = useState<{birth_date?: string, street?: string, number?: string, zip?: string, vennoot_number?: string, name?: string, zicht_account?: string} | undefined>(initialValues);
  const [users, setUsers] = useState<User[]>([]);
  const [usersCount, setUsersCount] = useState<number>(0);

  const onSearch = () => {
    setLoader(true);
    fetch_all<User>('/nl/v3/objects/fetch_all', {
      object: 'user',
      fields: ['id', 'first_name', 'last_name', 'birth_date', 'street', 'number', 'zip', 'city'].concat(fields),
      page: 0,
      per_page: 20,
      filter: {
        search: '',
        only_active: 1,
        advanced: {
          ...searchOptions,
          ...(!!searchOptions?.vennoot_number ? {vennoot_number: searchOptions.vennoot_number} : {})
        }
      }
    }, (obs, complete_data) => {
      setUsers(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setUsersCount(count);

      setLoader(false);
    }, (z) => {

    }, login);
  };

  useEffect(() => {
    onSearch();
  }, [searchOptions]);

  return (<React.Fragment>
    <Grid container item xs={12}>
      <SearchBox
        sx={{width: '100%'}}
        memo={undefined}
        initialValues={initialValues}
        customUserLayout={customUserLayout}
        customUserSearch={(opts) => {
          console.log(opts);
          setSearchOptions(opts);
        }}
      />
    </Grid>
    <Grid item xs={12}>
      <Box sx={{marginTop: 0}}>
        <Typography>{usersCount} objects found...</Typography>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Divider sx={{width: '100%', marginTop: 1, marginBottom: 1}} />
    </Grid>
    {!!loader && <Grid item xs={12}><CircularProgress /></Grid>}
    <Grid item xs={12}>
      {usersCount > 0 && <React.Fragment>
        {users.map(resultLayout)}
      </React.Fragment>}
    </Grid>
  </React.Fragment>);
}