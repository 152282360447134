import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one, fetch_all } from '../../lib/server_helper';

import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import { loginAtom } from '../../lib/auth';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LaunchIcon from '@mui/icons-material/Launch';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertEmailNotSent } from '../../components/alerts/EmailNotSent';
import { AlertEmailSent } from '../../components/alerts/EmailSent';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import { DetailLine } from '../../components/interface/Detail/DetailLine';
import { Page } from '../../components/interface/Page';
import { StyledButton } from '../../components/interface/StyledButton';
import { Typography } from '../../components/interface/Typography';
import { UserDetailBase } from '../../components/user_detail/Base';

import {
  ExitReason,
  ExitReasonLink
} from '../../models';

// const { DateTime } = require("luxon");

type Props = {};

export const ExitLinkDetail: FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['login'],
  //   queryFn: async () => {return },
  // });

  const params = useParams();
  const exitId: string | undefined = params.id;

  const [login, setLogin] = useAtom(loginAtom);
  const [exitDetail, setExitDetail] = useState<ExitReasonLink|null>(null);
  const [reasons, setReasons] = useState<ExitReason[]>([]);
  const [loader, setLoader] = useState<boolean>(true);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setExitSavedSnackOpen] = useState<boolean>(false);
  const [regenBlankSnackOpen, setRegenBlankSnackOpen] = useState<boolean>(false);
  const [errorMailSentOpen, setErrorMailSentOpen] = useState<boolean>(false);
  const [errorMailErrorOpen, setErrorMailErrorOpen] = useState<boolean>(false);

  const fields = [
    'id', 'created_at', 'updated_at', 'extra_data', 'link_status', 'reasons', 'other_reason', 'comment',
    'user|id/first_name/last_name/vennoot_number/birth_date/stock_number/zicht_account/confirmed/locale',
    'user|email/mobile/phone/street/number/zip/city/cor_street/cor_number/cor_zip/cor_city', 'book_year|visual',
    'emission_doc_id'
  ];

  const fetchExit = () => {
    setLoader(true);
    
    fetch_one<ExitReasonLink>('/nl/v3/objects/fetch_all', {
      object: 'exit_reason_link',
      fields: fields,
      id: exitId
    }, (ob, complete_data) => {
      setExitDetail(ob);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login)
  };
  const fetchReasons = () => {
    setLoader(true);
    
    fetch_all<ExitReason>('/nl/v3/objects/fetch_all', {
      object: 'exit_reason',
      fields: ["id", "name", 'weight'],
      order: "weight ASC"
    }, (obs, complete_data) => {
      setReasons(obs)
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login)
  };

  const saveExit = (force_state:number|undefined) => {
    fetch_one<ExitReasonLink>('/nl/v3/objects/save', {
      object: 'exit_reason_link',
      id: exitDetail?.id,
      ob: {
        id: exitDetail?.id,
        reasons: exitDetail?.reasons,
        link_status: !!force_state ? force_state : exitDetail?.link_status
      }
    }, (ob, complete_data) => {
      fetchExit();
      navigate("/exit_reason/list");
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  useEffect(() => {
    fetchExit();
    fetchReasons();
  }, []);

  return (
    <Page
      sx={{}}
      title={`${!!exitDetail && [exitDetail.user_first_name, exitDetail.user_last_name, `(${exitDetail.user_vennoot_number}) [${["Open", "Pending", "Closed", "Removed"][exitDetail?.link_status || 0]}]`].join(" ")}`}
      startEndCrumb={<StyledButton
        label="Back to ExitReasonList"
        id='back'
        text
        startIcon={<KeyboardBackspaceIcon />}
        path={`/exit_reason/list`}
        sx={{marginBottom: 1}}
      />}
    >
      <Grid container spacing="8">
        <Grid container item xs={12} md={8} spacing="8" sx={{alignContent: 'baseline', position: 'relative'}}>
          {!!loader && <Grid item xs={12}>
            <CircularProgress />
          </Grid>}

          <Grid container item xs={12} md={6} rowSpacing="8" sx={{alignContent: 'baseline'}}>
            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.identity
              }}
              title="Identity"
              wide
            >
              <DetailLine name="First name" value={!!exitDetail ? exitDetail.user_first_name : ''} />
              <DetailLine name="Last name" value={!!exitDetail ? exitDetail.user_last_name : ''} />
              <DetailLine name="Birthdate" date={!!exitDetail ? exitDetail?.user_birth_date : ''} />
              <DetailLine no_divider copyable name="Vennoot Number" value={!!exitDetail ? exitDetail.user_vennoot_number : ''} />
            </UserDetailBase>
            
          </Grid>
          <Grid container item xs={12} md={6} rowSpacing="8" sx={{alignContent: 'baseline'}}>
            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.guardian
              }}
              title="Feedback"
              wide
            >
              <DetailLine no_divider name="User Comment" value='&nbsp;' />
              <DetailLine name="" value={!!exitDetail ? exitDetail.other_reason : ''} />
              <DetailLine no_divider name="Office Comment" value='&nbsp;' />
              <DetailLine no_divider name="" value={!!exitDetail ? exitDetail.comment : ''} />
            </UserDetailBase>
          </Grid>

          <Grid container item xs={12} rowSpacing="8" sx={{alignContent: 'baseline'}}>
            <Grid container item xs={12} md={6} rowSpacing="8" sx={{alignContent: 'baseline', paddingRight: 0.5}}>
              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.register
                }}
                title="Top Reasons"
                wide
              >
                {reasons.filter(fff => parseInt((fff.weight || 0).toString(), 10) < 100).map(reason => <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={(exitDetail?.reasons || '').split(",").map(mm => parseInt(mm, 10)).indexOf(reason.id || 0) > -1} onChange={(v) => {
                        if (v.target.checked) {
                          setExitDetail({
                            ...exitDetail,
                            reasons: `,${[...(exitDetail?.reasons || '').split(",").filter(fff => !!fff).map(mm => parseInt(mm, 10)), reason.id || 0].join(",")},`
                          });
                        } else {
                          setExitDetail({
                            ...exitDetail,
                            reasons: `,${[...(exitDetail?.reasons || '').split(",").filter(fff => !!fff).map(mm => parseInt(mm, 10)).filter(fff => fff !== (reason.id || 0))].join(",")},`
                          });
                        }
                      }} name={reason.name?.nl} />
                    }
                    label={reason.name?.nl}
                  />
                </Grid>)}
              </UserDetailBase>
            </Grid>
            <Grid container item xs={12} md={6} rowSpacing="8" sx={{alignContent: 'baseline', paddingLeft: 0.5}}>
              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.register
                }}
                title="Other Reasons"
                wide
              >
                {reasons.filter(fff => parseInt((fff.weight || 0).toString(), 10) >= 100).map(reason => <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={(exitDetail?.reasons || '').split(",").map(mm => parseInt(mm, 10)).indexOf(reason.id || 0) > -1} onChange={(v) => {
                        if (v.target.checked) {
                          setExitDetail({
                            ...exitDetail,
                            reasons: `,${[...(exitDetail?.reasons || '').split(",").filter(fff => !!fff).map(mm => parseInt(mm, 10)), reason.id || 0].join(",")},`
                          });
                        } else {
                          setExitDetail({
                            ...exitDetail,
                            reasons: `,${[...(exitDetail?.reasons || '').split(",").filter(fff => !!fff).map(mm => parseInt(mm, 10)).filter(fff => fff !== (reason.id || 0))].join(",")},`
                          });
                        }
                      }} name={reason.name?.nl} />
                    }
                    label={reason.name?.nl}
                  />
                </Grid>)}
              </UserDetailBase>
           </Grid>
            
          </Grid>
        </Grid>

        <Grid container item xs={12} md={4} sx={{alignContent: 'baseline'}} rowSpacing="4">
          <Grid item xs={12}>
            <Box sx={{
              border: '1px solid transparent',
              // borderColor: theme.palette.primary.main,
              borderRadius: 1,
              padding: 1
            }}>
              <Grid container spacing="8">
                <Grid item xs={12}>
                  <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Actions</Typography>
                </Grid>

                {!!exitDetail?.user_id && <Grid item xs={6}>
                  <StyledButton
                    label="UserDetail"
                    id='user_detail'
                    contained
                    onClick={(v) => {
                      window.open(`/user/${exitDetail.user_id}`, "_BLANK");
                    }}
                    sx={{width: '100%'}}
                    startIcon={<LaunchIcon />}
                  />
                </Grid>}
                {!!exitDetail?.emission_doc_id && <Grid item xs={6}>
                  <StyledButton
                    label="ExitDetail"
                    id='exit_detail'
                    contained
                    onClick={(v) => {
                      window.open(`/exit/${exitDetail.emission_doc_id}`, "_BLANK");
                    }}
                    sx={{width: '100%'}}
                    startIcon={<LaunchIcon />}
                  />
                </Grid>}

                <Grid item xs={6}>
                  <StyledButton
                    label="Save"
                    id='update'
                    contained
                    saveStartIcon
                    onClick={(v) => {
                      saveExit(undefined);
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={6}></Grid>

                <Grid item xs={6}>
                  <StyledButton
                    label="Save as pending"
                    id='update2'
                    saveStartIcon
                    onClick={(v) => {
                      saveExit(1);
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledButton
                    label="Save as closed"
                    id='update3'
                    saveStartIcon
                    onClick={(v) => {
                      saveExit(2);
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

        </Grid>
      </Grid>

      {!!regenBlankSnackOpen && <Snackbar
        open={regenBlankSnackOpen}
        onClose={() => {setRegenBlankSnackOpen(false);}}
        autoHideDuration={2000}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">
          Unsigned document was regenerated!
        </MuiAlert>
      </Snackbar>}

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setExitSavedSnackOpen(b);}} />}
      {!!errorMailSentOpen && <AlertEmailSent open={errorMailSentOpen} setOpen={(b) => {setErrorMailSentOpen(b);}} />}
      {!!errorMailErrorOpen && <AlertEmailNotSent open={errorMailErrorOpen} setOpen={(b) => {setErrorMailErrorOpen(b);}} />}
    </Page>
  );
}
