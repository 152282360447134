import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';
import { loginAtom } from '../../lib/auth';

import {
  CircularProgress
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownloadIcon from '@mui/icons-material/Download';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  StyledButton,
  StyledIconButton
} from '../../components/interface';

import { Print } from '../../models/Print';

const { DateTime } = require("luxon");

type Props = {};

export const EmailPrints: FunctionComponent<Props> = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);

  const [prints, setPrints] = useState<Print[]>([]);
  const [printsCount, setPrintsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [sendLoader, setSendLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onGenerate = (id: number) => {
    setSendLoader(true);
    fetch_one<Print>('/nl/v3/objects/custom_action', {
      object: 'print',
      class_action: 'custom_api_generate_result',
      id: id
    }, (obs, complete_data) => {
      setSendLoader(false);
      onSearch('');
    }, (z) => {

    }, login);
  };

  const onRemove = (id: number) => {
    setRemoveLoader(true);
    fetch_one<Print>('/nl/v3/objects/remove', {
      object: 'print',
      ob: {
        id: id
      }
    }, (obs, complete_data) => {
      onSearch('');
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onSearch = (s: string) => {
    // console.log(filters);
    setLoader(true);
    fetch_all<Print>('/nl/v3/objects/fetch_all', {
      object: 'print',
      fields: ['id', 'created_at', 'is_finished', 'extra_data', 'get_result'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          
        }
      }
    }, (obs, complete_data) => {
      setPrints(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setPrintsCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, sortable: true },
    { field: 'users_count', headerName: 'Users', width: 200, sortable: false },
    { field: 'created_at', headerName: 'Created', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'is_finished', headerName: 'Finished?', width: 100, sortable: false, renderCell: params => <>
      {!!params.row.is_finished && <CheckIcon sx={{color: theme.palette.tertiary?.main}} />}
      {!params.row.is_finished && <CloseIcon sx={{color: theme.palette.secondary.main}} />}
    </> }
  ];

  return (
    <Page sx={{}} title="Prints" helper="prints" actions={<>
      <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={'/print/new'}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}>
      <DataGrid
        action_count={2}
        actions={(params) => {
          return (<>
            {!!params.row.is_finished && !!params.row.get_result && <StyledIconButton color="primary" onClick={(e) => {
              window.open(params.row.get_result, "_BLANK");
            }}>
              <DownloadIcon />
            </StyledIconButton>}
            {!params.row.is_finished && !!sendLoader && <CircularProgress />}
            {!params.row.is_finished && !sendLoader && <StyledIconButton color="primary" title="Generate file" onClick={(e) => {
              onGenerate(params.row.id);
            }}>
              <DriveFileRenameOutlineIcon />
            </StyledIconButton>}
            {!params.row.is_finished && !!removeLoader && <CircularProgress />}
            {!params.row.is_finished && !removeLoader && <StyledIconButton destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={printsCount}
        objects={(prints || []).map(u => ({
          ...u,
          users_count: (u.extra_data?.users || '-')
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
