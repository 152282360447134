import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box
} from '@mui/material';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import DownloadIcon from '@mui/icons-material/Download';
import LaunchIcon from '@mui/icons-material/Launch';

import { UserNote } from '../../models/UserNote';

import { Typography } from '../interface/Typography';

const { DateTime } = require("luxon");

type TimelineNoteProps = {
  note: UserNote;
};

export const TimelineNote: FunctionComponent<TimelineNoteProps> = ({note}) => {
  const theme = useTheme();

  return (<>
    <Box
      sx={{
        display: 'inline-block',
        marginRight: 1,
        paddingRight: 1,
        borderRight: '1px solid lightgrey'
      }}
    >
      <Typography sx={{
        color: 'grey',
        fontSize: 11
      }}><AttachFileIcon sx={{scale: '0.7', marginBottom: '-7px'}} /> {DateTime.fromISO(note.created_at).toFormat("DD TT")}</Typography>
    </Box>
    <Box
      sx={{
        display: 'inline-block',
        marginRight: 1,
        paddingRight: 1,
        borderRight: '1px solid lightgrey'
      }}
    >
      <Typography sx={{
        fontSize: 11
      }}>{note.admin_name}</Typography>
    </Box>
    {!!note.handled_type && <Box
      sx={{
        display: 'inline-block',
        marginRight: 1,
        paddingRight: 1,
        borderRight: '1px solid lightgrey'
      }}
    >
      {note.handled_type === "EmissionDoc::Uitschrijving" && <Typography sx={{
        fontSize: 14
      }}><LaunchIcon sx={{scale: '0.7', marginBottom: '-7px'}} /> Uitschrijving</Typography>}
      {note.handled_type === "EmissionKey" && <Typography sx={{
        fontSize: 14
      }}><LaunchIcon sx={{scale: '0.7', marginBottom: '-7px'}} /> EmissionKey</Typography>}
      {note.handled_type === "User" && <Typography sx={{
        fontSize: 14
      }}><LaunchIcon sx={{scale: '0.7', marginBottom: '-7px'}} /> User</Typography>}
      {note.handled_type !== "User" && note.handled_type !== "EmissionKey" && note.handled_type !== "EmissionDoc::Uitschrijving" && <Typography sx={{
        fontSize: 14
      }}>{note.handled_type} ({note.handled_id})</Typography>}
{/* <% if item.handled_type == "EmissionDoc::Uitschrijving" %>
  <a href="/uitschrijvings/detail?id=<%= item.handled_id %>" target="_BLANK"><i class="fa fa-external-link fa-fw"></i> Uitschrijving</a>
<% elsif item.handled_type == "EmissionKey" %>
  <a href="/emissions/checklist?open_id=<%= item.handled_id %>" target="_BLANK"><i class="fa fa-external-link fa-fw"></i> EmissionKey</a>
<% elsif item.handled_type == "User" %>
  <a href="/users/<%= item.handled_id %>" target="_BLANK"><i class="fa fa-external-link fa-fw"></i> User</a>
<% else %>
  <%= item.handled_type %> (<%= item.handled_id %>)
<% end %> */}
    </Box>}
    {!!note.get_item && <Box
      sx={{
        display: 'inline-block',
        marginRight: 1,
        paddingRight: 1,
        borderRight: '1px solid lightgrey'
      }}
    >
      <Typography sx={{
        fontSize: 14
      }}><DownloadIcon
        sx={{scale: '0.7', marginBottom: '-7px', cursor: 'pointer'}}
        onClick={(e) => {e.preventDefault(); window.open(note.get_item, "_BLANK");}}
      />{note.handled_type === "EmissionDoc::Uitschrijving" && <> (Waarschijnlijk) attest van overlijden</>}</Typography>
{/* <div class="time_note_download">
  <a href="<%= item.get_item %>" target="_BLANK"><i class="fa fa-download fa-fw"></i></a>
</div>
<% if item.handled_type == "EmissionDoc::Uitschrijving" %>
  (Waarschijnlijk) attest van overlijden
<% end %> */}
    </Box>}
    <Box
      sx={{
        display: 'inline-block'
      }}
    >
      <Typography sx={{
        fontSize: 14
      }}>{note.comment}</Typography>
    </Box>
  </>);
}