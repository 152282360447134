import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

// import {
//   Box
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import VisibilityIcon from '@mui/icons-material/Visibility';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledIconButton
} from '../../components/interface';

import { DividendCalculation } from '../../models/DividendCalculation';
import { loginAtom } from '../../lib/auth';

const { DateTime } = require("luxon");

type DividendCalculationsProps = {};

export const DividendCalculations: FunctionComponent<DividendCalculationsProps> = () => {
  // const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);
  // const [myQueues, setMyQueues] = useAtom(queueAtom);

  const [calculations, setCalculations] = useState<DividendCalculation[]>([]);
  const [calculationsCount, setCalculationsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [filters, setFilters] = useState<{book_year_id:number}>();
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("user_id ASC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch('', filters);
  }, [page, order]);

  // useEffect(() => {
  //   if (!!myQueues?.filter(x => x.type === "FoQueue::Calculation") && myQueues?.filter(x => x.type === "FoQueue::Calculation").length > 0) {
  //     onSearch('', filters);
  //   } else {
  //     if (!!handleLoader) {
  //       setHandleLoader(false);
  //       onSearch('', filters);
  //     }
  //   }
  // }, [myQueues]);

  // const onCalculate = (id: number, is_test: number) => {
  //   fetch_one<DividendCalculation>('/nl/v3/objects/custom_action', {
  //     object: 'dividend_calculation',
  //     class_action: 'custom_api_engage',
  //     handler_id: login?.id,
  //     id: id,
  //     test: is_test
  //   }, (obs, complete_data) => {
  //     onSearch();
  //   }, (z) => {

  //   }, login);
  // };

  const onSearch = (s: string, filters?:{book_year_id:number}) => {
    // console.log(filters);
    setLoader(true);
    fetch_all<DividendCalculation>('/nl/v3/objects/fetch_all', {
      object: 'dividend_calculation',
      fields: ['id', 'extra_data', 'created_at', 'updated_at', 'dividend|book_year|short_visual', 'user|first_name/last_name/vennoot_number'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          book_year_id: (filters?.book_year_id || 0)
        }
      }
    }, (obs, complete_data) => {
      setCalculations(obs);
      setFilters(filters);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setCalculationsCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, sortable: true },
    { field: 'vennoot_number', headerName: 'VennNr', width: 150, sortable: false },
    { field: 'vennoot_name', headerName: 'Name', width: 200, sortable: false },
    { field: 'netto', headerName: 'Div', width: 100, sortable: false, align: 'right' },
    { field: 'created_at', headerName: 'Created', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") }
  ];

  return (
    <Page sx={{}} title="Dividend Calculations" helper="calculations" old_url="https://old.argenco.be/dividends">
      <SearchBox
        onSearch={(s, x) => {
          setPage(0);
          onSearch(s, x);
        }}
        simple
        filter_bookyear
        memo="dividend_calculations"
      />
      <DataGrid
        action_count={1}
        actions={(params) => {
          return (<>
            <StyledIconButton color="primary" path={`/dividend_calculation/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={calculationsCount}
        objects={(calculations || []).map(u => ({
          ...u,
          vennoot_number: u.user_vennoot_number,
          netto: `${u.extra_data?.calculation?.bottomline?.actual_payment?.toFixed(2) || '?'} EUR`,
          vennoot_name: [u.user_first_name, u.user_last_name].join(' ')
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
