import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box
} from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';

import { UserNote } from '../../models/UserNote';

import { Typography } from '../interface/Typography';

const { DateTime } = require("luxon");

type TimelineUserCreatedProps = {
  creation: string;
};

export const TimelineUserCreated: FunctionComponent<TimelineUserCreatedProps> = ({creation}) => {
  const theme = useTheme();

  return (<>
    <Box
      sx={{
        display: 'inline-block',
        marginRight: 1,
        paddingRight: 1,
        borderRight: '1px solid lightgrey'
      }}
    >
      <Typography sx={{
        color: 'grey',
        fontSize: 11
      }}><PersonIcon sx={{scale: '0.7', marginBottom: '-7px'}} /> {DateTime.fromISO(creation).toFormat("DD TT")}</Typography>
    </Box>
    <Box
      sx={{
        display: 'inline-block'
      }}
    >
      <Typography sx={{
        fontSize: 14,
        fontStyle: 'italic'
      }}>User was created</Typography>
    </Box>
  </>);
}