import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import { fetch_all, fetch_one } from '../lib/server_helper';

import {
  Box,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

import { Page } from '../components/interface/Page';
import { StyledButton } from '../components/interface/StyledButton';
import { StyledIconButton } from '../components/interface/StyledIconButton';
import { StyledTextField } from '../components/interface/StyledTextField';
import { Typography } from '../components/interface/Typography';

import { Help as HelpOb } from '../models/Help';
import { loginAtom } from '../lib/auth';
import { useParams } from 'react-router-dom';

const { DateTime } = require("luxon");

type HelpProps = {};

export const Help: FunctionComponent<HelpProps> = () => {
  const theme = useTheme();
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['login'],
  //   queryFn: async () => {return },
  // });

  const params = useParams();
  const slug: string | undefined = params.slug;

  const [login, setLogin] = useAtom(loginAtom);
  const [helps, setHelps] = useState<HelpOb[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [content, setContent] = useState('');
  const [weight, setWeight] = useState('1000');
  const [activeId, setActiveId] = useState<string|null>(null);

  let setting_editor_height = '300px';
  if (!!login && !!login.extra_data?.settings?.editor_height) setting_editor_height = login.extra_data.settings.editor_height;

  const fetchHelp = () => {
    setLoader(true);
    
    fetch_all<HelpOb>('/nl/v3/objects/fetch_all', {
      object: 'help',
      fields: ['id', 'slug', 'user|first_name/last_name', 'content', 'created_at', 'weight'],
      filter:{
        advanced: {
          slug: slug
        }
      },
      order: "weight DESC, id DESC"
    }, (obs, complete_data) => {
      setHelps(obs);
      setLoader(false);
    }, (z) => {

    }, login)
  };

  const saveHelp = (mode:string, id:string|number|null|undefined) => {
    setLoader(true);
    
    fetch_one<HelpOb>(`/nl/v3/objects/${mode}`, {
      object: 'help',
      fields: ['id', 'slug', 'user|first_name/last_name', 'content', 'created_at', 'weight'],
      filter:{
        advanced: {
          slug: slug
        }
      },
      ob: !!login ? {
        id: id,
        content: content,
        weight: weight,
        slug: slug,
        user_id: login.id
      } : {},
      order: "weight DESC, id DESC"
    }, (obs, complete_data) => {
      // success?
      fetchHelp();

      setContent('');
      setWeight('1000');
      setActiveId(null);
      
      setLoader(false);
    }, (z) => {

    }, login)
  };

  useEffect(() => {
    fetchHelp();
  }, [slug]);

  return (
    <Page sx={{}} title="Help">
      <Grid container spacing={8}>
        <Grid container item xs={12}>
          {!!loader && <Grid item xs={12}>
            <CircularProgress />
          </Grid>}
          <Grid item xs={12}>
            <Typography variant="h3" sx={{marginBottom: 2}}>Entries for '{slug}'</Typography>
          </Grid>
          <Grid item xs={12}>
            {!!helps && helps.map(ob => (<Box sx={{
              border: '2px solid transparent',
              borderColor: theme.palette.primary.main,
              padding: 1,
              marginBottom: 1
            }}>
              <Box sx={{display: 'inline-block', width: '49%'}}>
                <Typography variant="body1">{ob.user_first_name} {ob.user_last_name}</Typography>
              </Box>
              <Box sx={{display: 'inline-block', width: '49%', textAlign: 'right', float: 'right'}}>
                <StyledIconButton color="primary" onClick={(e) => {
                  setContent(ob.content);
                  setWeight(ob.weight.toString());
                  if (!!ob.id) setActiveId(ob.id.toString());
                  window.scrollTo(0, document.body.scrollHeight);
                }}>
                  <EditIcon />
                </StyledIconButton>
                <StyledIconButton destroyBtn color="secondary" onClick={(e) => {
                  saveHelp('remove', ob.id);
                }}>
                  <DeleteForeverIcon />
                </StyledIconButton>
              </Box>
              <Typography variant="body2" html>{ob.content}</Typography>
              <Divider sx={{marginTop: 2, marginBottom: 1}} />
              <Box sx={{display: 'inline-block', width: '49%'}}>
                <Typography variant="body2">{DateTime.fromISO(ob.created_at).toFormat("f")}</Typography>
              </Box>
              <Box sx={{display: 'inline-block', width: '49%', textAlign: 'right', float: 'right'}}>
                <Typography variant="body2">Weight {ob.weight}</Typography>
              </Box>
            </Box>))}
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{marginTop: 3, marginBottom: 4}} />
          </Grid>
          <Grid item xs={12}>
            <SunEditor
              height={setting_editor_height}
              setOptions={{
                buttonList: [
                  ['undo', 'redo'],
                  ['font', 'fontSize', 'formatBlock'],
                  ['paragraphStyle', 'blockquote'],
                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                  ['fontColor', 'hiliteColor', 'textStyle'],
                  ['removeFormat'],
                  '/',
                  ['outdent', 'indent'],
                  ['align', 'horizontalRule', 'list', 'lineHeight'],
                  // ['table', 'link', 'image', 'video', 'math'], // You must add the 'katex' library at options to use the 'math' plugin.
                  // ['imageGallery'], // You must add the "imageGalleryUrl".
                  ['fullScreen', 'showBlocks', 'codeView'],
                  ['preview', 'print'],
                  // ['save', 'template'],
                  // ['dir', 'dir_ltr', 'dir_rtl'],
                  // '/', Line break
                ]
              }}
              setContents={content}
              onChange={(v) => {
                setContent(v);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              label="Weight"
              value={weight}
              id='weight'
              onChange={(v) => {
                setWeight(v);
              }}
              sx={{marginTop: 1, marginLeft: -1, "input": {backgroundColor: '#a9c2c0', padding: 1}}}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledButton
              label="Save"
              id='save'
              contained
              onClick={(v) => {
                saveHelp('save', activeId);
              }}
              sx={{marginTop: 2, float: 'right'}}
            />
            <StyledButton
              label="Cancel"
              id='cancel'
              secondary
              onClick={(v) => {
                setContent('');
                setWeight('1000');
                setActiveId(null);
              }}
              sx={{marginTop: 2, float: 'right', marginRight: 2}}
            />
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
}
