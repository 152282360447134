import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

// import {
//   IconButton
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { DataGrid } from '../../components/interface/DataGrid';
import { Page } from '../../components/interface/Page';
import { SearchBox } from '../../components/interface/SearchBox';
import { StyledButton } from '../../components/interface/StyledButton';
import { StyledIconButton } from '../../components/interface/StyledIconButton';

import { OfficeStatic } from '../../models/OfficeStatic';
import { loginAtom } from '../../lib/auth';

type Props = {};

export const OfficeStatics: FunctionComponent<Props> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();

  const login = useAtomValue(loginAtom);
  // const [login, setLogin] = useAtom(loginAtom);
  const [statics, setStatics] = useState<OfficeStatic[]>([]);
  const [staticsCount, setStaticsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");

  useEffect(() => {
    onSearch('');
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order]);

  const onRemove = (id: number) => {
    setRemoveLoader(true);
    fetch_one<OfficeStatic>('/nl/v3/objects/remove', {
      object: 'office_static',
      ob: {
        id: id
      }
    }, (obs, complete_data) => {
      onSearch('');
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onSearch = (s: string) => {
    setLoader(true);
    fetch_all<OfficeStatic>('/nl/v3/objects/fetch_all', {
      object: 'office_static',
      fields: ['id', 'identifier', 'body', 'lang_nl', 'lang_fr'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s
      }
    }, (obs, complete_data) => {
      setStatics(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setStaticsCount(count);

      setLoader(false);
    }, (z) => {

    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'identifier', headerName: 'Identifier', width: 500 },
    { field: 'lang_nl', headerName: 'NL', width: 40, sortable: false, renderCell: (params) => <>
      {params?.value < 1 && <StarOutlineIcon sx={{color: 'red'}} />}
      {params?.value > 99 && <StarIcon sx={{color: theme.palette.tertiary?.main}} />}
      {params?.value <= 99 && params?.value >= 1 && <StarHalfIcon sx={{color: 'orange'}} />}
    </> },
    { field: 'lang_fr', headerName: 'FR', width: 40, sortable: false, renderCell: (params) => <>
      {params?.value < 1 && <StarOutlineIcon sx={{color: 'red'}} />}
      {params?.value > 99 && <StarIcon sx={{color: theme.palette.tertiary?.main}} />}
      {params?.value <= 99 && params?.value >= 1 && <StarHalfIcon sx={{color: 'orange'}} />}
    </> },
  ];

  return (
    <Page sx={{}} title="Office Statics" helper="office_statics" actions={<>
      <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={'/office_static/new'}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}>
      <SearchBox
        simple={true}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
        memo="office_statics"
      />
      <DataGrid
        action_count={2}
        actions={(params) => {
          return (<>
            <StyledIconButton color="primary" path={`/office_static/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
            {!removeLoader && <StyledIconButton destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={staticsCount}
        objects={(statics || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />
    </Page>
  );
}
