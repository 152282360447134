import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

import {
  CircularProgress,
  Grid,
  TextField
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton
} from '../../components/interface';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import { Notary } from '../../models/Notary';
import { loginAtom } from '../../lib/auth';

type ExitNotariesProps = {};

export const ExitNotaries: FunctionComponent<ExitNotariesProps> = () => {
  // const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);
  const [notaries, setNotaries] = useState<Notary[]>([]);
  const [notariesCount, setNotariesCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [handleLoader, setHandleLoader] = useState<boolean>(false);
  const [importActive, setImportActive] = useState<boolean>(false);
  const [importList, setImportList] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id ASC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onSearch = (s: string) => {
    setLoader(true);
    fetch_all<Notary>('/nl/v3/objects/fetch_all', {
      object: 'notary',
      fields: [
        'id', 'created_at', 'first_name', 'last_name', 'email', 'street', 'city', 'zip', 'email',
        'number', 'office_number', 'office_name', 'phone', 'replyto'
      ],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s
      }
    }, (obs, complete_data) => {
      setNotaries(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setNotariesCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const handleImport = () => {
    if (!!login) {
      setHandleLoader(true);
      fetch_one<Notary>('/nl/v3/objects/custom_action', {
        object: 'notary',
        class_action: 'custom_api_import_list',
        handler_id: login.id,
        list: importList
      }, (obs, complete_data) => {
        setImportList('');
        onSearch('');
        setHandleLoader(false);
      }, (z) => {
        setHandleLoader(false);
        setServerErrorSnackOpen(true);
      }, login);
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 60, sortable: false },
    { field: 'number', headerName: 'Number', width: 100, sortable: true },
    { field: 'office_number', headerName: 'Off. Number', width: 100, sortable: true },
    { field: 'office_name', headerName: 'Office Name', width: 250, sortable: true },
    { field: 'first_name', headerName: 'First Name', width: 180, sortable: true },
    { field: 'last_name', headerName: 'Last Name', width: 180, sortable: true },
    { field: 'email', headerName: 'Email', width: 200, sortable: false, renderCell: params => <>
      {!!params.row.replyto && <>
        <span style={{color: 'red'}}>{params.row.replyto}</span>
      </>}
      {!params.row.replyto && <>
        <>{params.row.email}</>
      </>}
    </> },
    { field: 'phone', headerName: 'Phone', width: 140, sortable: true }
  ];

  return (
    <Page sx={{}} title="Notaries" helper="notaries" old_url="https://old.argenco.be/notaries" actions={<>
      <StyledButton
        label="Import list"
        id='import_list'
        contained
        sx={{marginRight: 3}}
        onClick={() => {
          setImportActive(!importActive);
        }}
      />
    </>}>
      {!!importActive && <>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              value={importList}
              id="importlistfield"
              variant="outlined"
              multiline
              label="List"
              onChange={(e) => {setImportList(e.target.value);}}
              sx={{
                width: '100%',
                paddingTop: 1,
                paddingBottom: 1,
                'input': {
                  padding: 1
                },
                'label[data-shrink=true]': {
                  left: 0,
                  top: 8
                },
                'label[data-shrink=false]': {
                  opacity: 0.4
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {!handleLoader && <StyledButton
              label="Import now"
              id='import_list_now'
              contained
              onClick={() => {
                handleImport();
              }}
            />}
            {!!handleLoader && <CircularProgress />}
          </Grid>
        </Grid>
      </>}
      {!importActive && <>
        <SearchBox
          simple={true}
          onSearch={(s) => {
            setPage(0);
            onSearch(s);
          }}
          memo="faqs"
        />
        <DataGrid
          action_count={1}
          actions={(params) => {
            return (<>
              <StyledIconButton color="primary" path={`/notary/${params.row.id}`}>
                <VisibilityIcon />
              </StyledIconButton>
            </>);
          }}
          getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
          objectCount={notariesCount}
          objects={(notaries || []).map(u => ({
            ...u
          }))}
          columns={columns}
          loader={loader}
          onSortModelChange={(a, b) => {
            let a_0_field:string = a[0].field;
            setOrder([a_0_field, a[0].sort].join(' '));
          }}
          page={page}
          setPage={(p) => {setPage(p);}}
        />

        {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      </>}
    </Page>
  );
}
