import React, { FunctionComponent } from 'react';

import {
  Grid
} from '@mui/material';

import { StyledButton } from '../../StyledButton';
import { Typography } from '../../Typography';

type SearchFilterStatusStringsProps = {
  values: [string, string][];
  filterValue: string[];
  setFilterValue: (z:string[]) => void;
  setEnterPressed?: (v:boolean) => void;
};

export const SearchFilterStatusStrings: FunctionComponent<SearchFilterStatusStringsProps> = ({values, filterValue, setFilterValue, setEnterPressed}) => {

  return (<>
    <Grid item xs={4} sx={{textAlign: 'right'}}>
      <Typography sx={{marginTop: 0.5}}>Filter (Status)</Typography>
    </Grid>
    {values.slice(0, 4).map(v => <>
      <Grid item xs={2}>
        <StyledButton
          label={v[1]}
          contained={filterValue.indexOf(v[0]) > -1}
          secondary={filterValue.indexOf(v[0]) < 0}
          id='filter_true_false_no'
          onClick={(vv) => {
            if (filterValue.indexOf(v[0]) > -1) {
              let old_arr = [...filterValue];
              old_arr.splice(old_arr.indexOf(v[0]), 1);
              setFilterValue([...old_arr]);
              if (!!setEnterPressed) setEnterPressed(true);
            } else {
              setFilterValue([
                ...filterValue,
                v[0]
              ]);
              if (!!setEnterPressed) setEnterPressed(true);
            }
          }}
          sx={{width: '100%'}}
        />
      </Grid>
    </>)}
    <Grid item xs={4} sx={{textAlign: 'right'}}></Grid>
    {values.slice(4, 8).map(v => <>
      <Grid item xs={2}>
        <StyledButton
          label={v[1]}
          contained={filterValue.indexOf(v[0]) > -1}
          secondary={filterValue.indexOf(v[0]) < 0}
          id='filter_true_false_no'
          onClick={(vv) => {
            if (filterValue.indexOf(v[0]) > -1) {
              let old_arr = [...filterValue];
              old_arr.splice(old_arr.indexOf(v[0]), 1);
              setFilterValue([...old_arr]);
              if (!!setEnterPressed) setEnterPressed(true);
            } else {
              setFilterValue([
                ...filterValue,
                v[0]
              ]);
              if (!!setEnterPressed) setEnterPressed(true);
            }
          }}
          sx={{width: '100%'}}
        />
      </Grid>
    </>)}
  </>);
}