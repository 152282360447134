import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { fetch_one } from '../../lib/server_helper';

import {
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  Page,
  StyledButton,
  StyledTextField
} from '../../components/interface';
import { UserDetailBase } from '../../components/user_detail/Base';

import { SystemSetting } from '../../models/SystemSetting';

type PlatformSystemSettingsProps = {};

export const PlatformSystemSettings: FunctionComponent<PlatformSystemSettingsProps> = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  // const params = useParams();

  const login = useAtomValue(loginAtom);
  // const [login, setLogin] = useAtom(loginAtom);
  const [settingDetail, setSettingDetail] = useState<SystemSetting|null>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  // const [exitId, setSystemSettingId] = useState<string | undefined>(params.id);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setSystemSettingSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'created_at', 'updated_at', 'extra_data', 'defaults'
  ];

  const fetchSetting = () => {
    setLoader(true);
    
    fetch_one<SystemSetting>('/nl/v3/objects/fetch_all', {
      object: 'system_setting',
      fields: fields,
      id: 1
    }, (ob, complete_data) => {
      openSetting(ob);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const saveSetting = () => {
    setSaveLoader(true);
    fetch_one<SystemSetting>('/nl/v3/objects/save', {
      object: 'system_setting',
      fields: fields,
      id: 1,
      ob: {
        extra_data: settingDetail?.extra_data,
        id: 1
      },
      handler_id: login?.id
    }, (ob, complete_data) => {
      // setSystemSettingId(ob.id?.toString());
      openSetting(ob);
      setSaveLoader(false);
      setSystemSettingSavedSnackOpen(true);
      navigate("/system_settings");
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  useEffect(() => {
    fetchSetting();
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openSetting = (ob: SystemSetting) => {
    setSettingDetail(ob);
  }

  return (
    <Page sx={{}} title="System Settings">
      <Grid container spacing="8">
        <Grid container item xs={12} spacing="8" sx={{alignContent: 'baseline'}}>
          {!!loader && <Grid item xs={12}>
            <CircularProgress />
          </Grid>}

          <Grid container item xs={12} spacing="8">
            
            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.identity
              }}
              title="PAIN - Dividend"
              wide
            >
              <Grid item xs={12}>
                <StyledTextField
                  label="Account"
                  value={settingDetail?.extra_data.pain.dividend.account || ''}
                  id='div_b'
                  onChange={(v) => {if (!!settingDetail) setSettingDetail({
                    ...settingDetail,
                    extra_data: {
                      ...(settingDetail?.extra_data || {}),
                      pain: {
                        ...(settingDetail?.extra_data.pain || {}),
                        dividend: {
                          ...(settingDetail?.extra_data.pain.dividend || {}),
                          account: v
                        }
                      }
                    }
                  });}}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  label="Reference NL"
                  value={settingDetail?.extra_data.pain.dividend.ref_nl || ''}
                  id='div_b'
                  onChange={(v) => {if (!!settingDetail) setSettingDetail({
                    ...settingDetail,
                    extra_data: {
                      ...(settingDetail?.extra_data || {}),
                      pain: {
                        ...(settingDetail?.extra_data.pain || {}),
                        dividend: {
                          ...(settingDetail?.extra_data.pain.dividend || {}),
                          ref_nl: v
                        }
                      }
                    }
                  });}}
                  fullWidth
                  helperText={`Default: ${settingDetail?.defaults.pain.dividend.ref_nl}`}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  label="Reference FR"
                  value={settingDetail?.extra_data.pain.dividend.ref_fr || ''}
                  id='div_b'
                  onChange={(v) => {if (!!settingDetail) setSettingDetail({
                    ...settingDetail,
                    extra_data: {
                      ...(settingDetail?.extra_data || {}),
                      pain: {
                        ...(settingDetail?.extra_data.pain || {}),
                        dividend: {
                          ...(settingDetail?.extra_data.pain.dividend || {}),
                          ref_fr: v
                        }
                      }
                    }
                  });}}
                  fullWidth
                  helperText={`Default: ${settingDetail?.defaults.pain.dividend.ref_fr}`}
                />
              </Grid>
            </UserDetailBase>

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.account
              }}
              title="PAIN - Exit"
              wide
            >
              <Grid item xs={12}>
                <StyledTextField
                  label="Account"
                  value={settingDetail?.extra_data.pain.exit.account || ''}
                  id='div_b'
                  onChange={(v) => {if (!!settingDetail) setSettingDetail({
                    ...settingDetail,
                    extra_data: {
                      ...(settingDetail?.extra_data || {}),
                      pain: {
                        ...(settingDetail?.extra_data.pain || {}),
                        exit: {
                          ...(settingDetail?.extra_data.pain.exit || {}),
                          account: v
                        }
                      }
                    }
                  });}}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  label="Reference NL"
                  value={settingDetail?.extra_data.pain.exit.ref_nl || ''}
                  id='div_b'
                  onChange={(v) => {if (!!settingDetail) setSettingDetail({
                    ...settingDetail,
                    extra_data: {
                      ...(settingDetail?.extra_data || {}),
                      pain: {
                        ...(settingDetail?.extra_data.pain || {}),
                        exit: {
                          ...(settingDetail?.extra_data.pain.exit || {}),
                          ref_nl: v
                        }
                      }
                    }
                  });}}
                  fullWidth
                  helperText={`Default: ${settingDetail?.defaults.pain.exit.ref_nl}`}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  label="Reference FR"
                  value={settingDetail?.extra_data.pain.exit.ref_fr || ''}
                  id='div_b'
                  onChange={(v) => {if (!!settingDetail) setSettingDetail({
                    ...settingDetail,
                    extra_data: {
                      ...(settingDetail?.extra_data || {}),
                      pain: {
                        ...(settingDetail?.extra_data.pain || {}),
                        exit: {
                          ...(settingDetail?.extra_data.pain.exit || {}),
                          ref_fr: v
                        }
                      }
                    }
                  });}}
                  fullWidth
                  helperText={`Default: ${settingDetail?.defaults.pain.exit.ref_fr}`}
                />
              </Grid>
            </UserDetailBase>

          </Grid>
        
          <Grid item xs={12} spacing="8" sx={{alignContent: 'baseline', textAlign: 'center'}}>
            <Divider sx={{marginTop: 1, marginBottom: 2}} />

            {!!loader && <CircularProgress />}
            {!loader && !saveLoader && <StyledButton
              label="Save"
              id='save'
              contained
              saveStartIcon
              onClick={(v) => {
                saveSetting();
              }}
              sx={{marginLeft: 1}}
            />}
            {!loader && <StyledButton
              label="Cancel"
              id='cancel'
              // contained
              onClick={(v) => {
                navigate("/system_settings");
              }}
              sx={{marginLeft: 1}}
            />}
          </Grid>
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setSystemSettingSavedSnackOpen(b);}} />}
    </Page>
  );
}
