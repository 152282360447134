import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one, fetch_all } from '../../lib/server_helper';

import {
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  LanguageFab,
  Page,
  StyledButton,
  StyledDropzone,
  StyledEditor,
  StyledSelect,
  StyledSwitch,
  Typography
} from '../../components/interface';

import { Dividend } from '../../models/Dividend';
import { Print } from '../../models/Print';

type Props = {};

export const EmailPrintDetail: FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  // const theme = useTheme();

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [printDetail, setPrintDetail] = useState<Print|null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [item, setItem] = useState<File>();
  const [dividends, setDividends] = useState<Dividend[]>([]);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setDividendSavedSnackOpen] = useState<boolean>(false);

  const [activeLocale, setActiveLocale] = useState<'nl'|'fr'>('nl');

  const fields = [
    'id', 'extra_data'
  ];

  const getDividends = () => {
    fetch_all<Dividend>('/nl/v3/objects/fetch_all', {
      object: 'dividend',
      fields: ['id', 'book_year|short_visual'],
      per_page: 1000,
      page: 0,
      order: "id DESC"
    }, (obs, complete_data) => {
      setDividends(obs);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const saveSetting = () => {
    let formData = new FormData();
    formData.append('object', 'print');
    formData.append('ob[fake_field]', '');
    if (!!printDetail) {
      formData.append('ob[body][nl]', printDetail.body?.nl || '');
      formData.append('ob[body][fr]', printDetail.body?.fr || '');
      formData.append('ob[extra_data][overdracht]', printDetail.extra_data?.overdracht === '1' ? '1' : '0');
      formData.append('ob[extra_data][register]', printDetail.extra_data?.register === '1' ? '1' : '0');
      formData.append('ob[extra_data][slim_divcalc]', printDetail.extra_data?.slim_divcalc?.toString() || '');
    }

    if (!!item) formData.append('ob[item]', item);

    fetch_one<Print>('/nl/v3/objects/save', formData, (obs, complete_data) => {
      navigate("/prints");
    }, (z) => {

    }, login);
  };

  useEffect(() => {
    getDividends();
  }, []);

  return (
    <Page sx={{}} title={`Print Detail`}>
      <LanguageFab
        activeLocale={activeLocale}
        setActiveLocale={(lc) => {setActiveLocale(lc);}}
      />

      <Grid container spacing="8">

        <Grid item xs={12}>
          <Typography sx={{fontSize: '0.75rem'}}>File with vennotennummers, everything not in column A gets ignored</Typography>
          <StyledDropzone
            acceptedFiles={{
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
            }}
            onDrop={acceptedFiles => {setItem(acceptedFiles[0]);}}
            multiple={true}
            maxSize={250 * 1000 * 1000}
            items={!!item ? [item] : []}
            dropText="Click/Drop"
            dropHint="All.: excel files, < 25mb"
          />
        </Grid>
        <StyledEditor
          label="Body"
          value={printDetail?.body || {nl: '', fr: ''}}
          activeLocale={activeLocale}
          onChange={(v) => {
            setPrintDetail({
              ...(printDetail || {}),
              body: v
            });
          }}
        />
        <Grid item xs={4}>
          <StyledSwitch
            label="Add transfer/question page"
            value={parseInt(printDetail?.extra_data?.overdracht || '0', 10) === 1}
            onChange={(v) => {
              setPrintDetail({
                ...(printDetail || {}),
                extra_data: {
                  ...(printDetail?.extra_data || {}),
                  overdracht: !!v ? '1' : '0'
                }
              });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledSwitch
            label="Add register"
            value={parseInt(printDetail?.extra_data?.register || '0', 10) === 1}
            onChange={(v) => {
              setPrintDetail({
                ...(printDetail || {}),
                extra_data: {
                  ...(printDetail?.extra_data || {}),
                  register: !!v ? '1' : '0'
                }
              });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledSelect
            label="Add DivCalc page"
            value={printDetail?.extra_data?.slim_divcalc?.toString() || '0'}
            id='add_divcalc'
            onChange={(v) => {
              setPrintDetail({
                ...(printDetail || {}),
                extra_data: {
                  ...(printDetail?.extra_data || {}),
                  slim_divcalc: !!v ? (parseInt(v, 10) || undefined) : undefined
                }
              });
            }}
            list={[
              {id: '0', name: 'Do not add DivCalc page'},
              ...dividends.map(m => {return {id: m.id?.toString() || '', name: m.book_year_short_visual};})
            ]}
          />
        </Grid>
        
        <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center'}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!!loader && <CircularProgress />}
          {!loader && <StyledButton
            label="Cancel"
            id='cancel'
            // contained
            onClick={(v) => {
              navigate("/prints");
            }}
            sx={{marginLeft: 1}}
          />}
          {!loader && <StyledButton
            label="Save"
            id='save'
            contained
            onClick={(v) => {
              saveSetting();
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setDividendSavedSnackOpen(b);}} />}
    </Page>
  );
}
