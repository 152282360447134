import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one } from '../../../lib/server_helper';
import { loginAtom } from '../../../lib/auth';

import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { AlertCheckInputs } from '../../../components/alerts/CheckInputs';
import { AlertSaved } from '../../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../../components/alerts/SomethingWentWrong';

import SunEditorCore from "suneditor/src/lib/core";

import {
  StyledButton,
  StyledTextField,
  StyledEditorNoTransl,
  Typography
} from '../../../components/interface';
import { NoteModule } from '../../../components/interface/NoteModule';

import { EmissionKey } from '../../../models/EmissionKey';

type Props = {
  emissionKey: EmissionKey;
  isHidden: boolean;
  fields: string[];
  refreshKey: () => void;
};

export const EmissionDetailTabsDetailCommunication: FunctionComponent<Props> = ({emissionKey, isHidden, fields, refreshKey}) => {
  // const navigate = useNavigate();
  const theme = useTheme();
  // const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [refreshNotes, setRefreshNotes] = useState<boolean>(true);

  const [mailCount, setMailCount] = useState<number>(0);
  const [mails, setMails] = useState<{
    id: number;
    user_name: string;
    created_at: string;
    message: string;
  }[]>([]);
  const [mailOpen, setMailOpen] = useState<number>();
  const [mailLoader, setMailLoader] = useState<boolean>(true);
  const [isMailsHidden, setIsMailsHidden] = useState<boolean>(true);

  const [notesCount, setNotesCount] = useState<number>(0);
  const [noteLoader, setNoteLoader] = useState<boolean>(true);
  const [isNotesHidden, setIsNotesHidden] = useState<boolean>(true);
  const [helpOpen, setHelpOpen] = useState<boolean>(false);

  const [systemNotesCount, setSystemNotesCount] = useState<number>(0);
  const [systemNotes, setSystemNotes] = useState<{
    id: number;
    user_name: string;
    created_at: string;
    message: string;
  }[]>([]);
  const [systemNoteOpen, setSystemNoteOpen] = useState<number>();
  const [systemNoteLoader, setSystemNoteLoader] = useState<boolean>(true);
  const [isSystemNotesHidden, setIsSystemNotesHidden] = useState<boolean>(true);

  const [mailLanguage, setMailLanguage] = useState<string>(emissionKey.user_locale === 'fr' ? 'fr' : 'nl');
  const [mailErrors, setMailErrors] = useState<number[]>((emissionKey.extra_data?.errors || []).map(s => parseInt(s, 10)));
  const [mailSubject, setMailSubject] = useState<string>('');
  const [mailBody, setMailBody] = useState<string>('');

  const editor = useRef<SunEditorCore>();
  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  const [generateLoader, setGenerateLoader] = useState<boolean>(false);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [userSavedSnackOpen, setUserSavedSnackOpen] = useState<boolean>(false);
  const [regenBlankSnackOpen, setRegenBlankSnackOpen] = useState<boolean>(false);

  const fetchMails = () => {
    setMailLoader(true);
    fetch_one<EmissionKey>('/nl/v3/objects/fetch_all', {
      object: 'emission_key',
      fields: ['email_list', 'notes_list', 'system_notes_list'],
      id: emissionKey.id
    }, (ob, complete_data) => {
      setMails(ob.email_list);
      setMailCount(ob.email_list.length);

      setNotesCount(ob.notes_list.length);

      setSystemNotes(ob.system_notes_list);
      setSystemNotesCount(ob.system_notes_list.length);

      setMailLoader(false);
      setNoteLoader(false);
      setSystemNoteLoader(false);
    }, (z) => {

    }, login);
  }

  const onGenerate = () => {
    setGenerateLoader(true);
    fetch_one<{custom_result: {subject: string, body: string}}>('/nl/v3/objects/custom_action', {
      object: 'emission_key',
      class_action: 'custom_api_generate_email',
      id: emissionKey.id,
      mail_locale: mailLanguage,
      errors: mailErrors.join(";")
    }, (ob, complete_data) => {
      setGenerateLoader(false);
      setMailBody(ob.custom_result.body);
      setMailSubject(ob.custom_result.subject);
    }, (z) => {

    }, login);
  };

  const onSend = () => {
    setGenerateLoader(true);
    fetch_one<{custom_result: {success: boolean}}>('/nl/v3/objects/custom_action', {
      object: 'emission_key',
      class_action: 'custom_api_send_email',
      id: emissionKey.id,
      mail_locale: mailLanguage,
      subject: mailSubject,
      body: mailBody
    }, (ob, complete_data) => {
      setGenerateLoader(false);
      setMailBody('');
      setMailSubject('');
    }, (z) => {

    }, login);
  };

  useEffect(() => {
    fetchMails();
    setRefreshNotes(true);
  }, []);

  return (
    <Box hidden={isHidden} sx={{paddingTop: 2}}>
      <Grid container spacing="8">
        <Grid item container xs={12} md={6} sx={{alignContent: 'baseline'}}>


          <Grid item container xs={12} sx={{border: '1px solid lightgrey', marginBottom: 1}}>
            <Grid item xs={12} sx={{marginBottom: 0.5, position: 'relative', minHeight: '32px'}} onClick={() => {
              if (mailCount > 0 && !!isMailsHidden) setIsMailsHidden(!isMailsHidden);
            }}>
              {mailLoader && <CircularProgress sx={{cursor: 'pointer', position: 'absolute', right: 40, top: 8, zIndex: 1000, height: '24px !important', width: '24px !important'}} />}
              {isMailsHidden && <Avatar sx={{cursor: 'pointer', position: 'absolute', right: 8, top: 8, zIndex: 1000, height: 24, width: 24}}><ExpandLessIcon /></Avatar>}
              {!isMailsHidden && <Avatar sx={{cursor: 'pointer', position: 'absolute', right: 8, top: 8, zIndex: 1000, height: 24, width: 24}} onClick={() => {
                setIsMailsHidden(!isMailsHidden);
              }}><ExpandMoreIcon /></Avatar>}

              <Typography sx={{paddingLeft: 1, paddingTop: 1}}>Mails ({mailCount})</Typography>
              {!isMailsHidden && <Divider sx={{marginTop: 1, marginBottom: 1}} />}
            </Grid>
            {!isMailsHidden && <>
              <Grid container item xs={12} sx={{paddingLeft: 1, paddingRight: 1}}>
                {mails.map(mail => <>
                  <Grid item xs={12} sx={{marginBottom: 1}}>
                    <Typography sx={{
                      cursor: 'pointer'
                    }} onClick={() => {
                      setMailOpen(mail.id);
                    }}>{mail.user_name} ({mail.created_at})</Typography>
                  </Grid>
                  {mailOpen === mail.id && <Grid item xs={12} sx={{marginBottom: 1, paddingBottom: 1}}>
                    <Typography html sx={{borderLeft: "5px solid lightgrey", paddingLeft: 2, marginLeft: 1}}>{mail.message}</Typography>
                  </Grid>}
                </>)}
              </Grid>
            </>}
          </Grid>


          <Grid item container xs={12} sx={{border: '1px solid lightgrey', marginBottom: 1}}>
            <Grid item xs={12} sx={{marginBottom: 0.5, position: 'relative', minHeight: '32px'}} onClick={() => {
              if (!!isNotesHidden) setIsNotesHidden(!isNotesHidden);
            }}>
              {noteLoader && <CircularProgress sx={{cursor: 'pointer', position: 'absolute', right: 40, top: 8, zIndex: 1000, height: '24px !important', width: '24px !important'}} />}
              {isNotesHidden && <Avatar sx={{cursor: 'pointer', position: 'absolute', right: 8, top: 8, zIndex: 1000, height: 24, width: 24}}><ExpandLessIcon /></Avatar>}
              {!isNotesHidden && <Avatar sx={{cursor: 'pointer', position: 'absolute', right: 8, top: 8, zIndex: 1000, height: 24, width: 24}} onClick={() => {
                setIsNotesHidden(!isNotesHidden);
              }}><ExpandMoreIcon /></Avatar>}

              <Typography sx={{paddingLeft: 1, paddingTop: 1}}>Notes ({notesCount})</Typography>
              {!isNotesHidden && <Divider sx={{marginTop: 1, marginBottom: 1}} />}
            </Grid>
            {!isNotesHidden && <>
              <Grid container item xs={12} sx={{paddingLeft: 1, paddingRight: 1}}>
                <NoteModule
                  hideTitle
                  hidden={false}
                  handled_id={emissionKey.id?.toString() || ''}
                  user_id={login?.id?.toString() || ''}
                  handled_type="EmissionKey"
                  refreshNotes={refreshNotes}
                  setRefreshNotes={(b) => {setRefreshNotes(b);}}
                />
              </Grid>
            </>}
          </Grid>


          <Grid item container xs={12} sx={{border: '1px solid lightgrey', marginBottom: 1}}>
            <Grid item xs={12} sx={{marginBottom: 0.5, position: 'relative', minHeight: '32px'}} onClick={() => {
              if (systemNotesCount > 0 && !!isSystemNotesHidden) setIsSystemNotesHidden(!isSystemNotesHidden);
            }}>
              {systemNoteLoader && <CircularProgress sx={{cursor: 'pointer', position: 'absolute', right: 40, top: 8, zIndex: 1000, height: '24px !important', width: '24px !important'}} />}
              {isSystemNotesHidden && <Avatar sx={{cursor: 'pointer', position: 'absolute', right: 8, top: 8, zIndex: 1000, height: 24, width: 24}}><ExpandLessIcon /></Avatar>}
              {!isSystemNotesHidden && <Avatar sx={{cursor: 'pointer', position: 'absolute', right: 8, top: 8, zIndex: 1000, height: 24, width: 24}} onClick={() => {
                setIsSystemNotesHidden(!isSystemNotesHidden);
              }}><ExpandMoreIcon /></Avatar>}

              <Typography sx={{paddingLeft: 1, paddingTop: 1}}>System Notes ({systemNotesCount})</Typography>
              {!isSystemNotesHidden && <Divider sx={{marginTop: 1, marginBottom: 1}} />}
            </Grid>
            {!isSystemNotesHidden && <>
              <Grid container item xs={12} sx={{paddingLeft: 1, paddingRight: 1}}>
                {systemNotes.map(note => <>
                  <Grid item xs={12} sx={{marginBottom: 1}}>
                    <Typography sx={{
                      cursor: 'pointer'
                    }} onClick={() => {
                      setSystemNoteOpen(note.id);
                    }}>{note.user_name} ({note.created_at})</Typography>
                  </Grid>
                  {systemNoteOpen === note.id && <Grid item xs={12} sx={{marginBottom: 1, paddingBottom: 1}}>
                    <Typography html sx={{borderLeft: "5px solid lightgrey", paddingLeft: 2, marginLeft: 1}}>{note.message}</Typography>
                  </Grid>}
                </>)}
              </Grid>
            </>}
          </Grid>


        </Grid>

        <Grid item container xs={12} md={6} sx={{alignContent: 'baseline', flexBasis: "calc(50% + 8px) !important"}} spacing="8">
          <Grid item xs={12}>
            <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Actions</Typography>
          </Grid>

          <Grid item xs={3}>
            <StyledButton
              label="NL"
              id='lang_nl'
              contained={mailLanguage === 'nl'}
              onClick={(v) => {
                setMailLanguage('nl');
              }}
              sx={{width: '100%'}}
            />
          </Grid>
          <Grid item xs={3}>
            <StyledButton
              label="FR"
              id='lang_fr'
              contained={mailLanguage === 'fr'}
              onClick={(v) => {
                setMailLanguage('fr');
              }}
              sx={{width: '100%'}}
            />
          </Grid>
          <Grid item xs={6} sx={{position: 'relative'}}>
            <ContactSupportIcon onClick={() => {setHelpOpen(!helpOpen);}} sx={{
              position: 'absolute',
              right: 0,
              top: -24,
              cursor: 'pointer'
            }} />

            {!generateLoader && <StyledButton
              label="Regenerate mail"
              id='regen_mail'
              contained
              onClick={(v) => {
                onGenerate();
              }}
              sx={{width: '100%'}}
            />}
            {!!generateLoader && <StyledButton
              label="Regenerate mail"
              id='regen_mail2'
              startIcon={<CircularProgress />}
              disabled
              contained
              onClick={(v) => {}}
              sx={{width: '100%'}}
            />}
          </Grid>

          {!!helpOpen && <Grid item xs={12}>
            <Divider />
          </Grid>}
          {!!helpOpen && <Grid item xs={12}>
            <Box sx={{
              backgroundColor: theme.palette.warning.main,
              padding: 1
            }}>
              <Typography>
                De subject is de static <span style={{fontStyle: 'italic'}}>emission_v2_mail_title</span>.<br />
                De body is de static <span style={{fontStyle: 'italic'}}>emission_v2_mail_body</span>.<br />
                De error statics beginnen met <span style={{fontStyle: 'italic'}}>email.emission.errors</span> en kunnen zo teruggevonden worden.
              </Typography>
            </Box>
          </Grid>}

          <Grid item xs={6}>
            <Grid container spacing="8">
              <Grid item xs={12}>
                <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Profile Errors</Typography>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox checked={mailErrors.indexOf(0) > -1} onChange={(v) => {
                      if (v.target.checked) {
                        setMailErrors([...mailErrors, 0]);
                      } else {
                        setMailErrors(mailErrors.filter(o => o !== 0));
                      }
                    }} name="errorProfileMissing" />
                  }
                  label="ID missing"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox checked={mailErrors.indexOf(1) > -1} onChange={(v) => {
                      if (v.target.checked) {
                        setMailErrors([...mailErrors, 1]);
                      } else {
                        setMailErrors(mailErrors.filter(o => o !== 1));
                      }
                    }} name="errorProfileUnrecog" />
                  }
                  label="ID unrecognisable"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox checked={mailErrors.indexOf(2) > -1} onChange={(v) => {
                      if (v.target.checked) {
                        setMailErrors([...mailErrors, 2]);
                      } else {
                        setMailErrors(mailErrors.filter(o => o !== 2));
                      }
                    }} name="errorProfileExpiry" />
                  }
                  label="ID past expiry date"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox checked={mailErrors.indexOf(3) > -1} onChange={(v) => {
                      if (v.target.checked) {
                        setMailErrors([...mailErrors, 3]);
                      } else {
                        setMailErrors(mailErrors.filter(o => o !== 3));
                      }
                    }} name="errorProfileDiffPerson" />
                  }
                  label="ID of other person"
                />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={6}>
            <Grid container spacing="8">
              <Grid item xs={12}>
                <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Guardian Errors</Typography>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox checked={mailGuardianErrors.indexOf(0) > -1} onChange={(v) => {
                      if (v.target.checked) {
                        setMailGuardianErrors([...mailProfileErrors, 0]);
                      } else {
                        setMailGuardianErrors(mailGuardianErrors.filter(o => o !== 0));
                      }
                    }} name="errorGuardianMissing" />
                  }
                  label="ID missing"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox checked={mailGuardianErrors.indexOf(1) > -1} onChange={(v) => {
                      if (v.target.checked) {
                        setMailGuardianErrors([...mailProfileErrors, 1]);
                      } else {
                        setMailGuardianErrors(mailGuardianErrors.filter(o => o !== 1));
                      }
                    }} name="errorGuardianUnrecog" />
                  }
                  label="ID unrecognisable"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox checked={mailGuardianErrors.indexOf(2) > -1} onChange={(v) => {
                      if (v.target.checked) {
                        setMailGuardianErrors([...mailProfileErrors, 2]);
                      } else {
                        setMailGuardianErrors(mailGuardianErrors.filter(o => o !== 2));
                      }
                    }} name="errorGuardianExpiry" />
                  }
                  label="ID past expiry date"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox checked={mailGuardianErrors.indexOf(3) > -1} onChange={(v) => {
                      if (v.target.checked) {
                        setMailGuardianErrors([...mailProfileErrors, 3]);
                      } else {
                        setMailGuardianErrors(mailGuardianErrors.filter(o => o !== 3));
                      }
                    }} name="errorGuardianDiffPerson" />
                  }
                  label="ID of other person"
                />
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item xs={6}>
            <Grid container spacing="8">
              <Grid item xs={12}>
                <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Borderel Errors</Typography>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox checked={mailErrors.indexOf(6) > -1} onChange={(v) => {
                      if (v.target.checked) {
                        setMailErrors([...mailErrors, 6]);
                      } else {
                        setMailErrors(mailErrors.filter(o => o !== 6));
                      }
                    }} name="errorBorderelMissing" />
                  }
                  label="Borderel missing"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox checked={mailErrors.indexOf(7) > -1} onChange={(v) => {
                      if (v.target.checked) {
                        setMailErrors([...mailErrors, 7]);
                      } else {
                        setMailErrors(mailErrors.filter(o => o !== 7));
                      }
                    }} name="errorBorderelUnrecog" />
                  }
                  label="Borderel unrecognisable"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox checked={mailErrors.indexOf(8) > -1} onChange={(v) => {
                      if (v.target.checked) {
                        setMailErrors([...mailErrors, 8]);
                      } else {
                        setMailErrors(mailErrors.filter(o => o !== 8));
                      }
                    }} name="errorBorderelDiffPerson" />
                  }
                  label="Borderel of other person"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox checked={mailErrors.indexOf(9) > -1} onChange={(v) => {
                      if (v.target.checked) {
                        setMailErrors([...mailErrors, 9]);
                      } else {
                        setMailErrors(mailErrors.filter(o => o !== 9));
                      }
                    }} name="errorBorderelReadMissing" />
                  }
                  label="'Read & approved' is missing"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox checked={mailErrors.indexOf(10) > -1} onChange={(v) => {
                      if (v.target.checked) {
                        setMailErrors([...mailErrors, 10]);
                      } else {
                        setMailErrors(mailErrors.filter(o => o !== 10));
                      }
                    }} name="errorBorderelSigMissing" />
                  }
                  label="Signature is missing"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox checked={mailErrors.indexOf(11) > -1} onChange={(v) => {
                      if (v.target.checked) {
                        setMailErrors([...mailErrors, 11]);
                      } else {
                        setMailErrors(mailErrors.filter(o => o !== 11));
                      }
                    }} name="errorBorderelSigDiff" />
                  }
                  label="Signature does not match with ID"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              label="Subject"
              value={mailSubject || ''}
              id='mail_subject'
              onChange={(v) => {setMailSubject(v);}}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <StyledEditorNoTransl
              label="Body"
              value={mailBody || ''}
              getSunEditorInstance={getSunEditorInstance}
              onChange={(v) => {
                setMailBody(v);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {!generateLoader && <StyledButton
              label="Send mail"
              disabled={!mailBody || !mailSubject}
              id='send_mail'
              contained
              onClick={(v) => {
                onSend();
              }}
              sx={{width: '100%'}}
            />}
            {!!generateLoader && <StyledButton
              label="Send mail"
              id='send_mail2'
              startIcon={<CircularProgress />}
              disabled
              contained
              onClick={(v) => {}}
              sx={{width: '100%'}}
            />}
          </Grid>

        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!userSavedSnackOpen && <AlertSaved open={userSavedSnackOpen} setOpen={(b) => {setUserSavedSnackOpen(b);}} />}

      {!!regenBlankSnackOpen && <Snackbar
        open={regenBlankSnackOpen}
        onClose={() => {setRegenBlankSnackOpen(false);}}
        autoHideDuration={2000}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">
          Unsigned document was regenerated!
        </MuiAlert>
      </Snackbar>}
    </Box>
  );
}
