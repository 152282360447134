import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  Chip,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

type BlockTagsProps = {
  
};

export const BlockTags: FunctionComponent<BlockTagsProps> = ({}) => {
  const theme = useTheme();

  const [copyOpen, setCopyOpen] = useState<boolean>(false);

  return (<Box sx={{position: 'sticky', top: 0, left: 0, zIndex: 1205, backgroundColor: 'rgba(0, 0, 0, 0.05)', padding: 0.5, marginLeft: 1, width: '100%'}}>
    <Chip label="meneer|mevrouw" sx={{marginRight: 1, backgroundColor: theme.palette.profile_blox?.internal}} onClick={(e) => {
      e.preventDefault();
      navigator.clipboard.writeText("{{aanspreking}}");
      setCopyOpen(true);
    }} />
    <Chip label="Geachte mr|mevr" sx={{marginRight: 1, backgroundColor: theme.palette.profile_blox?.internal}} onClick={(e) => {
      e.preventDefault();
      navigator.clipboard.writeText("{{aanspreking_long}}");
      setCopyOpen(true);
    }} />
    <Chip label="full_name" sx={{marginRight: 1, backgroundColor: theme.palette.profile_blox?.identity}} onClick={(e) => {
      e.preventDefault();
      navigator.clipboard.writeText("{{name}}");
      setCopyOpen(true);
    }} />
    <Chip label="last_name" sx={{marginRight: 1, backgroundColor: theme.palette.profile_blox?.identity}} onClick={(e) => {
      e.preventDefault();
      navigator.clipboard.writeText("{{last_name}}");
      setCopyOpen(true);
    }} />
    <Chip label="vennr" sx={{marginRight: 1, backgroundColor: theme.palette.profile_blox?.identity}} onClick={(e) => {
      e.preventDefault();
      navigator.clipboard.writeText("{{vennoot_number}}");
      setCopyOpen(true);
    }} />
    <Chip label="user_id" sx={{marginRight: 1, backgroundColor: theme.palette.profile_blox?.identity}} onClick={(e) => {
      e.preventDefault();
      navigator.clipboard.writeText("{{user_id}}");
      setCopyOpen(true);
    }} />
    <Chip label="stock_nr" sx={{marginRight: 1, backgroundColor: theme.palette.profile_blox?.register}} onClick={(e) => {
      e.preventDefault();
      navigator.clipboard.writeText("{{stock_number}}");
      setCopyOpen(true);
    }} />
    <Chip label="stock_left_eur" sx={{marginRight: 1, backgroundColor: theme.palette.profile_blox?.register}} onClick={(e) => {
      e.preventDefault();
      navigator.clipboard.writeText("{{max_left_amount}}");
      setCopyOpen(true);
    }} />

    {!!copyOpen && <Snackbar
      open={copyOpen}
      onClose={() => {setCopyOpen(false);}}
      autoHideDuration={2000}
    >
      <MuiAlert elevation={6} variant="filled" severity="success">Copied!</MuiAlert>
    </Snackbar>}
  </Box>);
}