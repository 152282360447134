import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';
import { loginAtom } from '../../lib/auth';

// import {
//   Tooltip
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import GroupsIcon from '@mui/icons-material/Groups';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  Tooltip
} from '@mui/material';

import {
  DataGrid,
  Page,
  StyledIconButton,
  Typography
} from '../../components/interface';

import { Dividend } from '../../models/Dividend';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

const { DateTime } = require("luxon");

type DividendSettingsProps = {};

export const DividendSettings: FunctionComponent<DividendSettingsProps> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);
  // const [myQueues, setMyQueues] = useAtom(queueAtom);

  const [settings, setSettings] = useState<Dividend[]>([]);
  const [settingsCount, setSettingsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("created_at DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch();
  }, [page, order]);

  // useEffect(() => {
  //   if (!!myQueues?.filter(x => x.type === "FoQueue::Setting") && myQueues?.filter(x => x.type === "FoQueue::Setting").length > 0) {
  //     onSearch('', filters);
  //   } else {
  //     if (!!handleLoader) {
  //       setHandleLoader(false);
  //       onSearch('', filters);
  //     }
  //   }
  // }, [myQueues]);

  const onCalculate = (id: number, is_test: number) => {
    setRemoveLoader(true);
    fetch_one<Dividend>('/nl/v3/objects/custom_action', {
      object: 'dividend',
      class_action: 'custom_api_engage',
      handler_id: login?.id,
      id: id,
      test: is_test
    }, (obs, complete_data) => {
      onSearch();
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onPain = (id: number) => {
    setRemoveLoader(true);
    fetch_one<Dividend>('/nl/v3/objects/custom_action', {
      object: 'dividend_pain',
      class_action: 'custom_api_engage_for_dividend',
      handler_id: login?.id,
      dividend_id: id
    }, (obs, complete_data) => {
      onSearch();
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onGenerate = (id: number) => {
    setRemoveLoader(true);
    fetch_one<Dividend>('/nl/v3/objects/custom_action', {
      object: 'dividend',
      class_action: 'custom_api_do_generate',
      handler_id: login?.id,
      id: id
    }, (obs, complete_data) => {
      onSearch();
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onReset = (id: number) => {
    setRemoveLoader(true);
    fetch_one<Dividend>('/nl/v3/objects/custom_action', {
      object: 'dividend',
      class_action: 'custom_api_do_reset',
      handler_id: login?.id,
      id: id
    }, (obs, complete_data) => {
      onSearch();
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onSearch = () => {
    // console.log(filters);
    setLoader(true);
    fetch_all<Dividend>('/nl/v3/objects/fetch_all', {
      object: 'dividend',
      fields: ['id', 'created_at', 'updated_at', 'extra_data', 'book_year|short_visual', 'pain_downloaded', 'dividend_calculations_with_pdf', 'dividend_calculations|count', 'dividend_pains|count'],
      per_page: 20,
      page: page,
      order: order
    }, (obs, complete_data) => {
      setSettings(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setSettingsCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, sortable: true },
    { field: 'book_year_short_visual', headerName: 'BookYear', width: 150, sortable: false },
    { field: 'dividend_calculations_count', headerName: 'Calcs', width: 100, sortable: false },
    { field: 'dividend_calculations_with_pdf', headerName: 'PDFs', width: 100, sortable: false, renderCell: params => <>
      <Typography sx={{fontSize: '0.9rem'}}>{params.row.dividend_calculations_with_pdf}</Typography>
      {params.row.dividend_calculations_with_pdf < params.row.dividend_calculations_count && <Tooltip title="Generate missing PDFs">
        <NoteAddIcon sx={{marginLeft: 1, cursor: 'pointer'}} onClick={() => {
          onGenerate(params.row.id);
        }} />
      </Tooltip>}
    </> },
    { field: 'created_at', headerName: 'Created', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'pain_downloaded', headerName: 'Vis.', width: 40, sortable: false, renderCell: params => <>
      {!!params.row.pain_downloaded && <CheckIcon sx={{color: theme.palette.tertiary?.main}} />}
      {!params.row.pain_downloaded && <CloseIcon sx={{color: theme.palette.secondary.main}} />}
    </> },
    { field: 'total', headerName: 'Total amount BTO', width: 200, align: 'right', sortable: false, renderCell: params => <>
      <Typography sx={{fontSize: '0.9rem'}}>
        {`${parseFloat(params.row.extra_data.total_br || 0).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR`}
      </Typography>
    </> },
    { field: 'total2', headerName: 'Total amount NTO', width: 200, align: 'right', sortable: false, renderCell: params => <>
      <Typography sx={{fontSize: '0.9rem'}}>
        {`${parseFloat(params.row.extra_data.total_ne || 0).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR`}
      </Typography>
    </> },
    { field: 'total3', headerName: 'Total amount pay', width: 200, align: 'right', sortable: false, renderCell: params => <>
      <Typography sx={{fontSize: '0.9rem'}}>
        {`${parseFloat(params.row.extra_data.total || 0).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR`}
      </Typography>
    </> },
  ];

  return (
    <Page sx={{}} title="Dividend Settings" helper="settings" old_url="https://old.argenco.be/dividends">
      <DataGrid
        action_count={5}
        actions={(params) => {
          return (<>
            <StyledIconButton color="primary" path={`/dividend_setting/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
            <StyledIconButton color="primary" title="Generate calculations for TEST users" onClick={(e) => {
              onCalculate(params.row.id, 1);
            }}>
              <ManageAccountsIcon />
            </StyledIconButton>
            <StyledIconButton color="primary" title="Generate calculations for ALL users" onClick={(e) => {
              onCalculate(params.row.id, 0);
            }}>
              <GroupsIcon />
            </StyledIconButton>
            {(params.row.dividend_pains_count || 0) < 1 && <StyledIconButton color="primary" title="Generate PAIN files" onClick={(e) => {
              onPain(params.row.id);
            }}>
              <RequestPageIcon />
            </StyledIconButton>}
            {(params.row.dividend_calculations_count || 0) > 0 && <StyledIconButton color="primary" title="Reset All" onClick={(e) => {
              onReset(params.row.id);
            }}>
              <RotateLeftIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={settingsCount}
        objects={(settings || []).map(u => ({
          ...u,
          count: 0,
          pdfs: 0
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
