import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';
import { loginAtom } from '../../lib/auth';

import {
  Grid
} from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UpgradeIcon from '@mui/icons-material/Upgrade';

import {
  DataGrid,
  Page,
  StyledButton,
  StyledDropzone,
  StyledIconButton,
  Typography
} from '../../components/interface';
import { GridColDef } from '@mui/x-data-grid-pro';

import { FodList } from '../../models/FodList';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

const { DateTime } = require("luxon");

type ExitFodListsProps = {};

export const ExitFodLists: FunctionComponent<ExitFodListsProps> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);
  const [exits, setFodLists] = useState<FodList[]>([]);
  const [exitsCount, setFodListsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("updated_at DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [items, setItems] = useState<File[]>([]);

  useEffect(() => {
    onSearch();
  }, [page, order]);

  const doSave = () => {
    let formData = new FormData();
    formData.append('object', 'fodlist');
    formData.append('class_action', 'custom_api_add_update');
    formData.append('ob[fake_field]', '');

    items.map(item => {
      formData.append('items[]', item);
      return true;
    });

    fetch_one<FodList>('/nl/v3/objects/custom_action', formData, (obs, complete_data) => {
      setShowEdit(false);
      setItems([]);
      onSearch();
    }, (z) => {

    }, login);
  };

  const onSearch = () => {
    setLoader(true);
    fetch_all<FodList>('/nl/v3/objects/fetch_all', {
      object: 'fodlist',
      fields: ['id', 'created_at', 'get_item', 'is_sent', 'item|filename'],
      per_page: 20,
      page: page,
      order: order
    }, (obs, complete_data) => {
      setFodLists(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setFodListsCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150, sortable: false },
    { field: 'item_filename', headerName: 'Name', width: 350, sortable: false },
    { field: 'created_at', headerName: 'Created', width: 180, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D T") },
    { field: 'is_sent', headerName: 'Sent', width: 100, sortable: false, renderCell: params => <>
      {!!params.row.is_sent && <CheckIcon sx={{
        color: 'green'
      }} />}
      {!params.row.is_sent && <CloseIcon sx={{
        color: 'red'
      }} />}
    </> }
  ];

  return (
    <Page sx={{}} title="FodLists" helper="fodlists" old_url="https://old.argenco.be/fod_lists" actions={<>
      <StyledButton
        label="Add update"
        id='add_update'
        contained
        onClick={() => {
          setShowEdit(true);
        }}
        sx={{marginRight: 3}}
        startIcon={<UpgradeIcon />}
      />
    </>}>
      {!!showEdit && <>
        <Grid container sx={{position: 'relative'}}>
          <Grid item xs={12}>
            <Typography sx={{
              color: theme.palette.secondary.main,
              marginBottom: 2
            }}>
              Make sure that the PDF file names are constructed in the following way:<br />
              FORM201<b>UPD</b>_0823992630_YYYYMMDDYYYYMMDD_YYYYMMDDHHMMSS.pdf<br />
              Where the first date is the birthdate, the second date is the deathdate, and the third datetime is the creation time of the document.</Typography>
          </Grid>
          <Grid item xs={12}>
            <CloseIcon sx={{position: 'absolute', top: 0, right: 0, cursor: 'pointer'}} onClick={() => {setShowEdit(false);}} />
            <StyledDropzone
              acceptedFiles={{
                'application/pdf': []
              }}
              onDrop={acceptedFiles => {
                // console.log(acceptedFiles);
                if (!!acceptedFiles && acceptedFiles.length > 0) {
                  setItems(acceptedFiles);
                } else {
                  setItems([]);
                }
              }}
              multiple={true}
              maxSize={250 * 1000 * 1000}
              items={items}
              dropText="Click/Drop"
              dropHint="All.: PDF files, < 25mb"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{
              fontSize: '0.7rem'
            }}>This will send the update to FOD. Make sure it is correct.</Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledButton
              label="Save"
              id='save'
              contained
              onClick={() => {
                doSave();
              }}
              sx={{marginRight: 3}}
              startIcon={<AddCircleOutlineIcon />}
            />
          </Grid>
        </Grid>
      </>}
      {!showEdit && <>
        <DataGrid
          action_count={1}
          actions={(params) => {
            return (<>
              {!!params.row.get_item && <StyledIconButton color="primary" onClick={() => {
                window.open(params.row.get_item, "_BLANK");
              }}>
                <FileDownloadIcon />
              </StyledIconButton>}
            </>);
          }}
          getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
          objectCount={exitsCount}
          objects={(exits || []).map(u => ({
            ...u
          }))}
          columns={columns}
          loader={loader}
          onSortModelChange={(a, b) => {
            let a_0_field:string = a[0].field;
            setOrder([a_0_field, a[0].sort].join(' '));
          }}
          page={page}
          setPage={(p) => {setPage(p);}}
        />
      </>}

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
