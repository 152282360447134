import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one } from '../../lib/server_helper';

import {
  Box,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LaunchIcon from '@mui/icons-material/Launch';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import { DetailLine } from '../../components/interface/Detail/DetailLine';
import {
  Page,
  StyledButton,
  StyledIconButton,
  Typography,
  VennNr
} from '../../components/interface';
import { UserDetailBase } from '../../components/user_detail/Base';

import { MeetingAttendee } from '../../models/MeetingAttendee';

type MeetingAttendeeDetailProps = {};

export const MeetingAttendeeDetail: FunctionComponent<MeetingAttendeeDetailProps> = () => {
  // const navigate = useNavigate();
  const theme = useTheme();
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['login'],
  //   queryFn: async () => {return },
  // });

  const params = useParams();
  const meetingId: string | undefined = params.id;

  const [login, setLogin] = useAtom(loginAtom);
  const [attendeeDetail, setAttendeeDetail] = useState<MeetingAttendee|null>(null);
  const [loader, setLoader] = useState<boolean>(true);

  const [volmachtNr, setVolmachtNr] = useState<string>('');

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [meetingSavedSnackOpen, setMeetingSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'created_at', 'updated_at', 'extra_data', 'status_level', 'get_item',
    'item_blob|content_type', 'item|filename/byte_size', 'meeting_id', 'confirmed', 'enabled',
    'user|id/first_name/last_name/vennoot_number/birth_date/street/number/zip/city/phone/mobile/gender/cor_street/cor_number/cor_zip/cor_city/email',
    'volmachten|count', 'volmachten_visual', 'volmacht|id/full_name/vennoot_number'
  ];

  const fetchAttendee = () => {
    setLoader(true);
    
    fetch_one<MeetingAttendee>('/nl/v3/objects/fetch_all', {
      object: 'meeting_attendee',
      fields: fields,
      id: meetingId
    }, (ob, complete_data) => {
      openAttendee(ob);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const saveDetail = (pars:object) => {
    fetch_one<MeetingAttendee>('/nl/v3/objects/custom_action', {
      object: 'meeting_attendee',
      fields: fields,
      class_action: 'custom_api_save_detail',
      id: meetingId,
      handler_id: login?.id,
      ...pars
    }, (ob, complete_data) => {
      fetchAttendee();
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const doProxy = (pars:object) => {
    fetch_one<MeetingAttendee>('/nl/v3/objects/custom_action', {
      object: 'meeting_attendee',
      fields: fields,
      class_action: 'custom_api_handle_proxy',
      id: meetingId,
      handler_id: login?.id,
      ...pars
    }, (ob, complete_data) => {
      fetchAttendee();
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  useEffect(() => {
    fetchAttendee();
  }, []);

  const openAttendee = (ob: MeetingAttendee) => {
    setAttendeeDetail(ob);
  }

  return (
    <Page
      sx={{}}
      title={`${!!attendeeDetail && [attendeeDetail.user_first_name, attendeeDetail.user_last_name].join(" ")}`}
      startEndCrumb={<StyledButton
        label="Back to Attendees"
        id='back'
        text
        startIcon={<KeyboardBackspaceIcon />}
        path={`/meeting_attendees/${attendeeDetail?.meeting_id}`}
        sx={{marginBottom: 1}}
      />}
    >
      <Grid container spacing="8">
        <Grid container item xs={12} spacing="8" sx={{alignContent: 'baseline'}}>
          {!!loader && <Grid item xs={12}>
            <CircularProgress />
          </Grid>}

          <Grid container item xs={8} spacing="8">

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.identity
              }}
              title="Identity"
            >
              <DetailLine name="First name" value={!!attendeeDetail ? attendeeDetail.user_first_name : ''} />
              <DetailLine name="Last name" value={!!attendeeDetail ? attendeeDetail.user_last_name : ''} />
              <DetailLine name="Birthdate" date={!!attendeeDetail ? attendeeDetail?.user_birth_date : ''} />
              <DetailLine no_divider copyable name="Vennoot Number" value={!!attendeeDetail ? attendeeDetail.user_vennoot_number : ''} />
            </UserDetailBase>

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.guardian
              }}
              title={`Proxy (${attendeeDetail?.volmachten_count || 0}/2)`}
            >
              {!!attendeeDetail?.volmacht_id && <>
                <Grid item xs={12}>
                  <Typography>Proxy given to<br />{attendeeDetail?.volmacht_full_name} ({attendeeDetail?.volmacht_vennoot_number})</Typography>
                </Grid>
              </>}
              {!attendeeDetail?.volmacht_id && <>
                {(attendeeDetail?.volmachten_count || 0) < 1 && <Grid item xs={12}>
                  <Typography variant="caption" sx={{fontStyle: 'italic', color: 'grey', padding: 1}}>No proxies found...</Typography>
                </Grid>}
                {(attendeeDetail?.volmachten_visual || []).map(proxy => <Grid item xs={12}>
                  <Typography>{proxy.name} ({proxy.vennoot_number}) <StyledIconButton destroyBtn color="primary" onClick={(e) => {
                      doProxy({
                        action_name: 'delete_proxy',
                        proxy_id: proxy.id
                      });
                    }}>
                      <DeleteForeverIcon />
                    </StyledIconButton></Typography>
                </Grid>)}
                <Grid item xs={12} sx={{marginBottom: 1}}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <VennNr
                    label="Vennotennummer of proxy giver"
                    value={volmachtNr}
                    id='search_vennoot_number'
                    onChange={(v:string|ChangeEvent<HTMLInputElement>) => {
                      setVolmachtNr(v.toString());
                    }}
                    sx={{width: '100%', marginLeft: -1}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledButton
                    label="Add"
                    disabled={volmachtNr.length != 10}
                    contained
                    id='add_proxy'
                    onClick={(v) => {
                      doProxy({
                        action_name: 'add_proxy',
                        vennoot_number: volmachtNr
                      });
                    }}
                  />
                </Grid>
              </>}
            </UserDetailBase>

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.contact
              }}
              title="Contact"
              wide
            >
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine name="Gender" gender_icons value={!!attendeeDetail ? attendeeDetail.user_gender : ''} />
                <DetailLine copyable name="E-mail" value={!!attendeeDetail ? attendeeDetail.user_email : ''} />
                <DetailLine no_divider name="Address" value={!!attendeeDetail ? [attendeeDetail.user_street, attendeeDetail.user_number].join(" ") : ''} />
                <DetailLine no_divider name="" value={!!attendeeDetail ? [attendeeDetail.user_zip, attendeeDetail.user_city].join(" ") : ''} />
              </Grid>
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine name="Phone" value={!!attendeeDetail ? attendeeDetail.user_phone : ''} />
                <DetailLine  name="Mobile" value={!!attendeeDetail ? attendeeDetail.user_mobile : ''} />
                {!!attendeeDetail?.user_cor_street && !!attendeeDetail?.user_cor_zip && <DetailLine no_divider name="COR Address" value={!!attendeeDetail ? [attendeeDetail.user_cor_street, attendeeDetail.user_cor_number].join(" ") : ''} />}
                {!!attendeeDetail?.user_cor_street && !!attendeeDetail?.user_cor_zip && <DetailLine no_divider name="" value={!!attendeeDetail ? [attendeeDetail.user_cor_zip, attendeeDetail.user_cor_city].join(" ") : ''} />}
              </Grid>
            </UserDetailBase>

            {!!attendeeDetail?.get_item && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.exit
              }}
              title="Confirmation"
              adornment={!!attendeeDetail && !!attendeeDetail.get_item && <>
                <StyledIconButton
                  path={attendeeDetail.get_item}
                  newTab
                  sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
                ><LaunchIcon /></StyledIconButton>
              </>}
              turnable={!!attendeeDetail.item_blob_content_type && attendeeDetail.item_blob_content_type.indexOf("image") > -1}
            >
              <Grid item xs={12}>
                {!!attendeeDetail.item_blob_content_type && attendeeDetail.item_blob_content_type.indexOf("image") > -1 && <img style={{maxWidth: '100%'}} src={attendeeDetail.get_item} />}
                {!!attendeeDetail.item_blob_content_type && attendeeDetail.item_blob_content_type.indexOf("pdf") > -1 && <iframe style={{width: '100%', height: '300px'}} src={attendeeDetail.get_item} />}
              </Grid>
              <Grid item xs={12}>
                {!!attendeeDetail?.item_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {attendeeDetail?.item_filename} ({(parseInt(attendeeDetail?.item_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>}

          </Grid>
          <Grid container item xs={12} md={4} spacing="8" sx={{alignContent: 'baseline', flexBasis: "calc(33.33% + 8px) !important"}} rowSpacing="4">
            <Grid item xs={12}>
              <Box sx={{
                border: '1px solid transparent',
                // borderColor: theme.palette.primary.main,
                borderRadius: 1,
                padding: 1
              }}>
                <Grid container spacing="8">
                  <Grid item xs={12}>
                    <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Actions</Typography>
                  </Grid>

                  {!!attendeeDetail?.enabled && <Grid item xs={6}>
                    <StyledButton
                      label="Cancel"
                      id='cancel'
                      onClick={(v) => {
                        saveDetail({
                          action_name: 'set_enabled',
                          action_value: 0
                        });
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>}
                  {!attendeeDetail?.enabled && <Grid item xs={6}>
                    <StyledButton
                      label="Attend"
                      id='attend'
                      onClick={(v) => {
                        saveDetail({
                          action_name: 'set_enabled',
                          action_value: 1
                        });
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>}
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={6}>
                    <StyledButton
                      label="Resend email"
                      id='resend_email'
                      onClick={(v) => {
                        saveDetail({
                          action_name: 'resend_email'
                        });
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledButton
                      label="Regen PDF"
                      id='regen_pdf'
                      onClick={(v) => {
                        saveDetail({
                          action_name: 'regen_item'
                        });
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledButton
                      label="Set as POSTED"
                      id='set_posted'
                      onClick={(v) => {
                        saveDetail({
                          action_name: 'set_posted'
                        });
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>

                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!meetingSavedSnackOpen && <AlertSaved open={meetingSavedSnackOpen} setOpen={(b) => {setMeetingSavedSnackOpen(b);}} />}
    </Page>
  );
}
