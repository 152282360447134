import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one } from '../../lib/server_helper';

import {
  CircularProgress,
  Divider,
  Grid,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import LaunchIcon from '@mui/icons-material/Launch';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  Page,
  StyledButton,
  StyledIconButton,
  Typography
} from '../../components/interface';
import { DetailLine } from '../../components/interface/Detail/DetailLine';
import { UserDetailBase } from '../../components/user_detail/Base';

import { DividendCalculation } from '../../models/DividendCalculation';

// const { DateTime } = require("luxon");

type DividendCalculationDetailProps = {};

export const DividendCalculationDetail: FunctionComponent<DividendCalculationDetailProps> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['login'],
  //   queryFn: async () => {return },
  // });

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [calculationDetail, setCalculationDetail] = useState<DividendCalculation|null>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [exitId, setDividendId] = useState<string | undefined>(params.id);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setDividendSavedSnackOpen] = useState<boolean>(false);
  const [regenSnackOpen, setRegenSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'created_at', 'updated_at', 'extra_data', 'dividend|book_year|visual', 'get_fiscal_item',
    'user|first_name/last_name/vennoot_number/street/zip/city/email/phone/mobile/birth_date/stock_number/zicht_account/locale',
    'user|transactions|first|stock_saldo_value', 'fiscal_item_blob|content_type', 'fiscal_item|filename/byte_size'
  ];

  const regenBlank = () => {
    fetch_one<DividendCalculation>('/nl/v3/objects/custom_action', {
      object: 'dividend_calculation',
      fields: fields,
      id: exitId,
      class_action: 'custom_api_regenerate_pdf'
    }, (ob, complete_data) => {
      if (!!ob.custom_result) setCalculationDetail(ob.custom_result);
      setLoader(false);

      setRegenSnackOpen(true);
    }, (z, y) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const fetchCalculation = () => {
    setLoader(true);
    
    fetch_one<DividendCalculation>('/nl/v3/objects/fetch_all', {
      object: 'dividend_calculation',
      fields: fields,
      id: exitId
    }, (ob, complete_data) => {
      openCalculation(ob);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  useEffect(() => {
    fetchCalculation();
  }, []);

  const openCalculation = (ob: DividendCalculation) => {
    setCalculationDetail(ob);
  }

  return (
    <Page sx={{}} title={`Dividend Calculation ${calculationDetail?.user_first_name} ${calculationDetail?.user_last_name} (${calculationDetail?.user_vennoot_number})`}>
      <Grid container spacing="8">
        <Grid container item xs={12} spacing="8" sx={{alignContent: 'baseline'}}>
          {!!loader && <Grid item xs={12}>
            <CircularProgress />
          </Grid>}

          <Grid container item xs={4} spacing="8" sx={{alignContent: 'baseline'}}>
            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.identity
              }}
              title="Identity"
              wide
            >
              <DetailLine name="First name" value={!!calculationDetail ? calculationDetail.user_first_name : ''} />
              <DetailLine name="Last name" value={!!calculationDetail ? calculationDetail.user_last_name : ''} />
              <DetailLine name="Birthdate" date={!!calculationDetail ? calculationDetail?.user_birth_date : ''} />
              <DetailLine no_divider copyable name="Vennoot Number" value={!!calculationDetail ? calculationDetail.user_vennoot_number : ''} />
            </UserDetailBase>

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.register
              }}
              title="Register"
              wide
            >
              <DetailLine name="Stock Number" value={!!calculationDetail ? `${calculationDetail.user_stock_number?.toString()} (${calculationDetail.user_transactions_first_stock_saldo_value?.toFixed(0)} EUR)` : ''} />
              <DetailLine name="Type" value={!!calculationDetail ? (calculationDetail.user_vennoot_number ? calculationDetail.user_vennoot_number[0] : '') : ''} />
              <DetailLine no_divider name="Zicht account" value={!!calculationDetail ? calculationDetail.user_zicht_account : ''} />
            </UserDetailBase>

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.account
              }}
              title="Account"
              wide
            >
              <DetailLine no_divider name="Language" value={!!calculationDetail ? calculationDetail.user_locale : ''} />
            </UserDetailBase>
          </Grid>
          <Grid container item xs={4} rowSpacing="8" sx={{alignContent: 'baseline'}}>
            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.contact
              }}
              title="Contact"
              wide
            >
              <Grid item container xs={12} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine copyable name="E-mail" value={!!calculationDetail ? calculationDetail.user_email : ''} />
                <DetailLine no_divider name="Address" value={!!calculationDetail ? [calculationDetail.user_street, calculationDetail.user_number].join(" ") : ''} />
                <DetailLine name="" value={!!calculationDetail ? [calculationDetail.user_zip, calculationDetail.user_city].join(" ") : ''} />
                <DetailLine name="Phone" value={!!calculationDetail ? calculationDetail.user_phone : ''} />
                <DetailLine no_divider={!(!!calculationDetail?.user_cor_street && !!calculationDetail?.user_cor_zip)} name="Mobile" value={!!calculationDetail ? calculationDetail.user_mobile : ''} />
                {!!calculationDetail?.user_cor_street && !!calculationDetail?.user_cor_zip && <DetailLine no_divider name="COR Address" value={!!calculationDetail ? [calculationDetail.user_cor_street, calculationDetail.user_cor_number].join(" ") : ''} />}
                {!!calculationDetail?.user_cor_street && !!calculationDetail?.user_cor_zip && <DetailLine no_divider name="" value={!!calculationDetail ? [calculationDetail.user_cor_zip, calculationDetail.user_cor_city].join(" ") : ''} />}
              </Grid>
            </UserDetailBase>

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.exit
              }}
              title="Calculation"
              wide
            >
              <DetailLine name="BookYear" value={!!calculationDetail ? calculationDetail.dividend_book_year_visual : ''} />
              <DetailLine name="RV" value={!!calculationDetail ? `${parseFloat(calculationDetail.extra_data?.calculation_data?.rv_pct || '0').toFixed(2)}%` : ''} />
              <DetailLine name="DIV" value={!!calculationDetail ? `${parseFloat(calculationDetail.extra_data?.calculation_data?.div_pct || '0').toFixed(2)}%` : ''} />
              <DetailLine name="Dividend" value={!!calculationDetail ? `${calculationDetail.extra_data?.calculation?.bottomline?.actual_payment?.toFixed(2)} EUR` : ''} />
            </UserDetailBase>
          </Grid>
          <Grid container item xs={4} rowSpacing="8" sx={{alignContent: 'baseline'}}>
            {!!calculationDetail && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.exit
              }}
              title="PDF"
              adornment={!!calculationDetail && !!calculationDetail.get_fiscal_item && <>
                <StyledIconButton
                  path={calculationDetail.get_fiscal_item}
                  newTab
                  sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
                ><LaunchIcon /></StyledIconButton>
                {/* <LaunchIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                  e.preventDefault();
                  window.open(calculationDetail.get_fiscal_item, "_BLANK");
                }} /> */}
              </>}
              turnable={!!calculationDetail.fiscal_item_blob_content_type && calculationDetail.fiscal_item_blob_content_type.indexOf("image") > -1}
              wide
            >
              <Grid item xs={12}>
                {!!calculationDetail.fiscal_item_blob_content_type && calculationDetail.fiscal_item_blob_content_type.indexOf("image") > -1 && <img style={{maxWidth: '100%'}} src={calculationDetail.get_fiscal_item} />}
                {!!calculationDetail.fiscal_item_blob_content_type && calculationDetail.fiscal_item_blob_content_type.indexOf("pdf") > -1 && <iframe style={{width: '100%', height: '300px'}} src={calculationDetail.get_fiscal_item} />}
              </Grid>
              <Grid item xs={12}>
                {!!calculationDetail?.fiscal_item_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {calculationDetail?.fiscal_item_filename} ({(parseInt(calculationDetail?.fiscal_item_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>}
          </Grid>
        
          <Grid item xs={12} spacing="8" sx={{alignContent: 'baseline', textAlign: 'center'}}>
            <Divider sx={{marginTop: 1, marginBottom: 2}} />

            {!!loader && <CircularProgress />}
            {!loader && <StyledButton
              label="Cancel"
              id='cancel'
              // contained
              onClick={(v) => {
                navigate("/dividend_calculations");
              }}
              sx={{marginLeft: 1}}
            />}
            {!loader && <StyledButton
              label="Regenerate PDF"
              id='regenerate'
              // contained
              onClick={(v) => {
                regenBlank();
              }}
              sx={{marginLeft: 1}}
            />}
          </Grid>
        </Grid>
      </Grid>

      {!!regenSnackOpen && <Snackbar
        open={regenSnackOpen}
        onClose={() => {setRegenSnackOpen(false);}}
        autoHideDuration={2000}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">
          Document was regenerated!
        </MuiAlert>
      </Snackbar>}

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setDividendSavedSnackOpen(b);}} />}
    </Page>
  );
}
