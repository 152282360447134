import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all } from '../../lib/server_helper';

// import {
//   IconButton
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { DataGrid } from '../../components/interface/DataGrid';
import { Page } from '../../components/interface/Page';
import { SearchBox } from '../../components/interface/SearchBox';
import { StyledButton } from '../../components/interface/StyledButton';
import { StyledIconButton } from '../../components/interface/StyledIconButton';

import { Share } from '../../models/Share';
import { loginAtom } from '../../lib/auth';

type PlatformSharesProps = {};

export const PlatformShares: FunctionComponent<PlatformSharesProps> = () => {
  // const navigate = useNavigate();

  const login = useAtomValue(loginAtom);
  // const [login, setLogin] = useAtom(loginAtom);
  const [shares, setShares] = useState<Share[]>([]);
  const [sharesCount, setSharesCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  // const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id ASC");

  useEffect(() => {
    onSearch('');
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order]);

  // const onRemove = (id: number) => {
  //   setRemoveLoader(true);
  //   fetch_one<Share>('/nl/v3/objects/remove', {
  //     object: 'share',
  //     ob: {
  //       id: id
  //     }
  //   }, (obs, complete_data) => {
  //     onSearch('');
  //     setRemoveLoader(false);
  //   }, (z) => {

  //   }, login);
  // };

  const onSearch = (s: string) => {
    setLoader(true);
    fetch_all<Share>('/nl/v3/objects/fetch_all', {
      object: 'share',
      fields: ['id', 'tag', 'public_name', 'amount', 'max_per_user', 'votes_per_share', 'is_hidden'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s
      }
    }, (obs, complete_data) => {
      setShares(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setSharesCount(count);

      setLoader(false);
    }, (z) => {

    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'tag', headerName: 'Tag', width: 120 },
    { field: 'public_name', headerName: 'Public name', width: 120 },
    { field: 'amount', headerName: 'Value', width: 120, valueFormatter: params => `${params?.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR`, align: 'right', headerAlign: 'right' },
    { field: 'max_per_user', headerName: 'Max/User', width: 100 },
    { field: 'votes_per_share', headerName: 'Votes/Share', width: 100 }
  ];

  return (
    <Page sx={{}} title="Shares" helper="shares" old_url="https://old.argenco.be/shares" actions={<>
      <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={'/share/new'}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}>
      <SearchBox
        simple={true}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
        memo="shares"
      />
      <DataGrid
        action_count={1}
        actions={(params) => {
          return (<>
            <StyledIconButton color="primary" path={`/share/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={sharesCount}
        objects={(shares || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />
    </Page>
  );
}
