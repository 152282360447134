import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

// import {
//   Box
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledIconButton
} from '../../components/interface';

import { DividendPain } from '../../models/DividendPain';
import { loginAtom } from '../../lib/auth';

const { DateTime } = require("luxon");

type DividendPainsProps = {};

export const DividendPains: FunctionComponent<DividendPainsProps> = () => {
  // const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);
  // const [myQueues, setMyQueues] = useAtom(queueAtom);

  const [pains, setPains] = useState<DividendPain[]>([]);
  const [painsCount, setPainsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [filters, setFilters] = useState<{book_year_id:number}>();
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch('', filters);
  }, [page, order]);

  const onSearch = (s: string, filters?:{book_year_id:number}) => {
    // console.log(filters);
    setLoader(true);
    fetch_all<DividendPain>('/nl/v3/objects/fetch_all', {
      object: 'dividend_pain',
      fields: ['id', 'extra_data', 'created_at', 'updated_at', 'dividend|book_year|short_visual', 'get_item'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          book_year_id: (filters?.book_year_id || 0)
        }
      }
    }, (obs, complete_data) => {
      setPains(obs);
      setFilters(filters);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setPainsCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, sortable: true },
    { field: 'dividend_book_year_short_visual', headerName: 'BookYear', width: 150, sortable: false },
    { field: 'created_at', headerName: 'Created', width: 250, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D TT") }
  ];

  return (
    <Page sx={{}} title="Dividend Pains" helper="pains" old_url="https://old.argenco.be/dividends">
      <SearchBox
        onSearch={(s, x) => {
          setPage(0);
          onSearch(s, x);
        }}
        simple
        filter_bookyear
        memo="dividend_pains"
      />
      <DataGrid
        action_count={1}
        actions={(params) => {
          return (<>
            {!!params.row.get_item && <StyledIconButton color="primary" onClick={() => {
              // window.open(params.row.get_item, "_BLANK");
              fetch(params.row.get_item || '', {
                method: 'GET',
              })
              .then((response) => response.blob())
              .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                  new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                  'download',
                  (params.row.get_item || '').split("/").reverse()[0],
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                // @ts-ignore
                link.parentNode.removeChild(link);
              });
            }}>
              <FileDownloadIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={painsCount}
        objects={(pains || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
