import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Divider,
  Grid,
  Snackbar,
  Tooltip
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FemaleIcon from '@mui/icons-material/Female';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LaunchIcon from '@mui/icons-material/Launch';
import MaleIcon from '@mui/icons-material/Male';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { Typography } from '../Typography';

const { DateTime } = require("luxon");

type DetailLineProps = {
  name: string;
  launchable?: string;
  override_copyable?: string;
  value?: string|undefined;
  time_ago?: string|undefined;
  date?: string|undefined;
  datetime?: string|undefined;
  bool?: boolean|undefined;
  copyable?: boolean;
  no_divider?: boolean;
  gender_icons?: boolean;
  show_warning?: boolean;
};

export const DetailLine: FunctionComponent<DetailLineProps> = ({show_warning, launchable, override_copyable, name, value, date, bool, datetime, time_ago, no_divider, copyable, gender_icons}) => {
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);

  return (<>
    <Grid item xs={12} sx={{position: 'relative', textAlign: 'right'}}>
      <Typography variant="body1" sx={{fontSize: '0.7rem', lineHeight: '24px', position: 'absolute', top: 4, left: 0}}>{name}</Typography>
      {(!time_ago && !date && !datetime && typeof(bool) === 'undefined') && <Typography variant="body1">
        {copyable && (!!value || !!override_copyable) && <ContentCopyIcon
          sx={{cursor: 'pointer', scale: '0.7', marginRight: 1, marginBottom: -1}}
          onClick={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(override_copyable || value || '');
            setOpen(true);
          }}
        />}
        {!!launchable && <LaunchIcon
          sx={{cursor: 'pointer', scale: '0.7', marginRight: 1, marginBottom: -1}}
          onClick={(e) => {
            e.preventDefault();
            window.open(launchable, "_BLANK");
          }}
        />}
        {!!show_warning && <WarningAmberIcon
          sx={{scale: '0.7', marginRight: 1, marginBottom: -1, color: 'orange'}}
        />}
        {gender_icons && !!value && <>
          {value.toUpperCase() === "M" && <MaleIcon sx={{marginRight: 1, marginBottom: -0.5}} />}
          {value.toUpperCase() !== "M" && <FemaleIcon sx={{marginRight: 1, marginBottom: -0.5}} />}
        </>}
        {value || '-'}
      </Typography>}
      {!!time_ago && <Typography variant="body1">
        {DateTime.fromISO(time_ago).toRelative()}
        <Tooltip title={DateTime.fromISO(time_ago).toFormat("DD T")}><AccessTimeIcon sx={{scale: '0.7', cursor: 'pointer', marginLeft: 1, marginBottom: -1}} /></Tooltip>
      </Typography>}
      {!!date && <Typography variant="body1">{DateTime.fromISO(date).toFormat("D")}</Typography>}
      {!!datetime && <Typography variant="body1">{DateTime.fromISO(datetime).toFormat("f")}</Typography>}
      {/* {!value && !date && !datetime && <Typography variant="body1">{bool ? 'Yes' : 'No'}</Typography>} */}
      {!value && !time_ago && !date && !datetime && typeof(bool) !== 'undefined' && <>
        {!!bool && <CheckCircleOutlineIcon sx={{color: theme.palette.tertiary?.main}} />}
        {!bool && <HighlightOffIcon sx={{color: 'red'}} />}
      </>}
    </Grid>
    {!no_divider && <Grid item xs={12}>
      <Divider />
    </Grid>}

    {!!open && !!copyable && <Snackbar
      open={open}
      onClose={() => {setOpen(false);}}
      autoHideDuration={2000}
    >
      <MuiAlert elevation={6} variant="filled" severity="success">Copied!</MuiAlert>
    </Snackbar>}
  </>);
}