import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../lib/server_helper';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Tooltip
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import {
  DataGrid,
  Page,
  StyledButton,
  StyledDropzone,
  StyledIconButton,
  Typography
} from '../components/interface';

import { AlertSomethingWentWrong } from '../components/alerts/SomethingWentWrong';

import { Export } from '../models/Export';
import { loginAtom } from '../lib/auth';

const { DateTime } = require("luxon");

type PainSenderProps = {};

export const PainSender: FunctionComponent<PainSenderProps> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);
  const [exports, setExports] = useState<Export[]>([]);
  const [exportsCount, setExportsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("updated_at DESC");
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [items, setItems] = useState<File[]>([]);

  const [opened, setOpened] = useState<any>(undefined);

  useEffect(() => {
    onSearch();
  }, [page, order]);

  const doSave = () => {
    let formData = new FormData();
    formData.append('object', 'pain_file');
    formData.append('ob[fake_field]', '');

    items.map(item => {
      formData.append('items[]', item);
      return true;
    });

    fetch_one<Export>('/nl/v3/objects/save', formData, (obs, complete_data) => {
      setShowEdit(false);
      setItems([]);
      onSearch();
    }, (z) => {

    }, login);
  };

  const doSend = (id:number) => {
    fetch_one<Export>('/nl/v3/objects/custom_action', {
      object: 'pain_file',
      class_action: 'custom_api_send',
      handler_id: login?.id,
      id: id
    }, (obs, complete_data) => {
      onSearch();
    }, (z) => {

    }, login);
  };

  const onRemove = (id: number) => {
    setRemoveLoader(true);
    fetch_one<Export>('/nl/v3/objects/remove', {
      object: 'pain_file',
      ob: {
        id: id
      }
    }, (obs, complete_data) => {
      onSearch();
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onSearch = () => {
    setLoader(true);
    fetch_all<Export>('/nl/v3/objects/fetch_all', {
      object: 'pain_file',
      fields: ['id', 'created_at', 'note', 'is_sent', 'sent_at', 'unique_name', 'line_count', 'total_amount', 'existing_keys', 'extra_data'],
      per_page: 20,
      page: page,
      order: order
    }, (obs, complete_data) => {
      setExports(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setExportsCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150, sortable: false },
    { field: 'is_sent', headerName: 'Sent', width: 40, sortable: false, renderCell: params => <>
      {params.row.is_sent && <Tooltip title={params.row.sent_at}><CheckIcon sx={{color: theme.palette.tertiary?.main}} /></Tooltip>}
      {!params.row.is_sent && <CloseIcon sx={{color: theme.palette.secondary.main}} />}
    </> },
    { field: 'unique_name', headerName: 'Name', width: 200, sortable: false, renderCell: params => <>
      <Typography sx={{fontSize: '0.9rem'}}>{params.row.unique_name}{!!params.row.existing_keys ? ` ALSO!!!: ${params.row.existing_keys}` : ''}</Typography>
    </> },
    { field: 'line_count', headerName: '# lines', width: 80, sortable: false },
    { field: 'total_amount', headerName: 'Amount', width: 120, sortable: false, valueFormatter: params => parseFloat(params?.value).toFixed(2).toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") },
    { field: 'created_at', headerName: 'Created', width: 200, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D TT") },
  ];

  return (
    <Page sx={{}} title="Pain files" helper="pain_files" actions={<>
      <StyledButton
        label="Create new"
        id='create_new'
        contained
        onClick={() => {
          setShowEdit(true);
        }}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}>
      {!!showEdit && <>
        <Grid container sx={{position: 'relative'}}>
          <Grid item xs={12}>
            <CloseIcon sx={{position: 'absolute', top: 0, right: 0, cursor: 'pointer'}} onClick={() => {setShowEdit(false);}} />
            <StyledDropzone
              acceptedFiles={{
                'text/plain': []
              }}
              onDrop={acceptedFiles => {setItems(acceptedFiles);}}
              multiple={true}
              maxSize={250 * 1000 * 1000}
              items={items}
              dropText="Click/Drop"
              dropHint="All.: pain files, < 25mb"
            />
          </Grid>
          <Grid item xs={12}>
            <StyledButton
              label="Save"
              id='save'
              contained
              onClick={() => {
                doSave();
              }}
              sx={{marginRight: 3}}
              startIcon={<AddCircleOutlineIcon />}
            />
          </Grid>
        </Grid>
      </>}

      <DataGrid
        action_count={3}
        actions={(params) => {
          return (<>
            {!params.row.is_sent && <StyledIconButton color="primary" onClick={(e) => {
              doSend(params.row.id);
            }}>
              <CloudUploadIcon />
            </StyledIconButton>}
            {!removeLoader && !params.row.is_sent && <StyledIconButton destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
            <StyledIconButton color="primary" onClick={(e) => {
              setOpened(params.row);
            }}>
              <OpenInFullIcon />
            </StyledIconButton>
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={exportsCount}
        objects={(exports || []).map(u => ({
          ...u,
          table_name: u.settings?.table
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!opened && <Dialog
        fullScreen
        open={!!opened}
        onClose={() => {setOpened(undefined);}}
      >
        <DialogTitle>Details of PainFile</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={0}>
              <Grid xs={12} sx={{textDecoration: 'underline', marginBottom: 1}}>PAIN information:</Grid>
              <Grid xs={4}>{opened.unique_name}</Grid>
              <Grid xs={4}>#{opened.line_count} lines</Grid>
              <Grid xs={4}>{parseFloat(opened.total_amount).toLocaleString("nl-BE", {style: 'currency', currency: 'EUR'})}</Grid>
              <Grid xs={12}><Divider sx={{marginBottom: 1, marginTop: 1}} /></Grid>
              <Grid xs={12} sx={{textDecoration: 'underline', marginBottom: 1}}>Transactions:</Grid>
              {(opened.extra_data?.lines || []).map((line:any) => <>
                <Grid xs={3}><pre style={{margin: 0}}>{line.name}</pre></Grid>
                <Grid xs={2}><pre style={{margin: 0}}>{line.account}</pre></Grid>
                <Grid xs={1} sx={{textAlign: 'right'}}><pre style={{margin: 0}}>{line.amount.toLocaleString("nl-BE", {style: 'currency', currency: 'EUR'})}</pre></Grid>
                <Grid xs={6} sx={{paddingLeft: 1}}><pre style={{margin: 0}}>{line.note}</pre></Grid>
              </>)}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setOpened(undefined);}}>Close</Button>
        </DialogActions>
      </Dialog>}

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
