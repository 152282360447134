import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one } from '../../lib/server_helper';

import {
  CircularProgress,
  Grid
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import LaunchIcon from '@mui/icons-material/Launch';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import { Page } from '../../components/interface/Page';
import { StyledButton } from '../../components/interface/StyledButton';
import { StyledDateField } from '../../components/interface/StyledDateField';
import { StyledIconButton } from '../../components/interface/StyledIconButton';
import { StyledTextField } from '../../components/interface/StyledTextField';
import { Typography } from '../../components/interface/Typography';
import { UserDetailBase } from '../../components/user_detail/Base';

import { Custom201 } from '../../models/Custom201';

// const { DateTime } = require("luxon");

type ExitCustom201DetailProps = {};

export const ExitCustom201Detail: FunctionComponent<ExitCustom201DetailProps> = () => {
  // const navigate = useNavigate();
  const theme = useTheme();
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['login'],
  //   queryFn: async () => {return },
  // });

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [custom201Detail, setCustom201Detail] = useState<Custom201|null>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [exitId, setExitId] = useState<string | undefined>(params.id);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setExitSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'created_at', 'updated_at', 'extra_data',
    'get_lijst201', 'get_lijst201_kort', 'get_lijst201_fod',
    'lijst201|blob|content_type', 'lijst201|filename/byte_size',
    'lijst201_kort|blob|content_type', 'lijst201_kort|filename/byte_size',
    'lijst201_fod|blob|content_type', 'lijst201_fod|filename/byte_size'
  ];

  const fetchCustom201 = () => {
    setLoader(true);
    
    fetch_one<Custom201>('/nl/v3/objects/fetch_all', {
      object: 'custom201',
      fields: fields,
      id: exitId
    }, (ob, complete_data) => {
      openCustom201(ob);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const saveCustom201 = () => {
    setSaveLoader(true);
    fetch_one<Custom201>('/nl/v3/objects/save', {
      object: 'custom201',
      fields: fields,
      id: exitId,
      ob: {
        ...custom201Detail,
        id: exitId
      },
      handler_id: login?.id
    }, (ob, complete_data) => {
      setExitId(ob.id?.toString());
      openCustom201(ob);
      setSaveLoader(false);
      setExitSavedSnackOpen(true);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  useEffect(() => {
    fetchCustom201();
  }, []);

  const openCustom201 = (ob: Custom201) => {
    // if (!ob.extra_data?.confirmable) {
    //   if (!ob.extra_data) ob.extra_data = {};
    //   if (!ob.extra_data?.confirmable) ob.extra_data.confirmable = {
    //     checked: '0',
    //     pain: '1',
    //     fod: '1',
    //     notify_email: '1',
    //     notary_email: '1',
    //     download: '1'
    //   };
    // }
    // if (!!ob.email && !!ob?.reporter_data) ob.reporter_data.email = ob.email;
    // if (!!ob.office_email && !!ob?.reporter_data) ob.reporter_data.email = ob.office_email;
    // if (!ob.status) ob.status = 1;
    // ob.original_status = ob.status;

    setCustom201Detail(ob);
  }

  return (
    <Page sx={{}} title="Custom 201">
      <Grid container spacing="8">
        <Grid container item xs={12} spacing="8" sx={{alignContent: 'baseline'}}>
          {!!loader && <Grid item xs={12}>
            <CircularProgress />
          </Grid>}

          <Grid container item xs={6}>
            
            <Grid item xs={12} sx={{marginTop: 0}}>
              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.identity
                }}
                title="Deceased data"
                wide
              >
                <Grid item xs={12}>
                  <StyledTextField
                    label="Name"
                    value={custom201Detail?.extra_data?.custom201?.name || ''}
                    id='name'
                    onChange={(v) => {if (!!custom201Detail) setCustom201Detail({
                      ...custom201Detail,
                      extra_data: {
                        ...(custom201Detail?.extra_data || {}),
                        custom201: {
                          ...(custom201Detail?.extra_data?.custom201 || {}),
                          name: v
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Address line 1 (street + number)"
                    value={custom201Detail?.extra_data?.custom201?.address1 || ''}
                    id='address1'
                    onChange={(v) => {if (!!custom201Detail) setCustom201Detail({
                      ...custom201Detail,
                      extra_data: {
                        ...(custom201Detail?.extra_data || {}),
                        custom201: {
                          ...(custom201Detail?.extra_data?.custom201 || {}),
                          address1: v
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Address line 2 (zip + city)"
                    value={custom201Detail?.extra_data?.custom201?.address2 || ''}
                    id='address2'
                    onChange={(v) => {if (!!custom201Detail) setCustom201Detail({
                      ...custom201Detail,
                      extra_data: {
                        ...(custom201Detail?.extra_data || {}),
                        custom201: {
                          ...(custom201Detail?.extra_data?.custom201 || {}),
                          address2: v
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledDateField
                    label="Birth date"
                    value={custom201Detail?.extra_data?.custom201?.birth_date || ''}
                    id='birth_date'
                    onChange={(v) => {if (!!custom201Detail) setCustom201Detail({
                      ...custom201Detail,
                      extra_data: {
                        ...(custom201Detail?.extra_data || {}),
                        custom201: {
                          ...(custom201Detail?.extra_data?.custom201 || {}),
                          birth_date: v.toString()
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label="Birth place"
                    value={custom201Detail?.extra_data?.custom201?.birth_place || ''}
                    id='birth_place'
                    onChange={(v) => {if (!!custom201Detail) setCustom201Detail({
                      ...custom201Detail,
                      extra_data: {
                        ...(custom201Detail?.extra_data || {}),
                        custom201: {
                          ...(custom201Detail?.extra_data?.custom201 || {}),
                          birth_place: v
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledDateField
                    label="Death date"
                    value={custom201Detail?.extra_data?.custom201?.death_date || ''}
                    id='death_date'
                    onChange={(v) => {if (!!custom201Detail) setCustom201Detail({
                      ...custom201Detail,
                      extra_data: {
                        ...(custom201Detail?.extra_data || {}),
                        custom201: {
                          ...(custom201Detail?.extra_data?.custom201 || {}),
                          death_date: v.toString()
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label="Death place"
                    value={custom201Detail?.extra_data?.custom201?.death_place || ''}
                    id='death_place'
                    onChange={(v) => {if (!!custom201Detail) setCustom201Detail({
                      ...custom201Detail,
                      extra_data: {
                        ...(custom201Detail?.extra_data || {}),
                        custom201: {
                          ...(custom201Detail?.extra_data?.custom201 || {}),
                          death_place: v
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Vennoot number"
                    value={custom201Detail?.extra_data?.custom201?.vennoot_number || ''}
                    id='vennoot_number'
                    onChange={(v) => {if (!!custom201Detail) setCustom201Detail({
                      ...custom201Detail,
                      extra_data: {
                        ...(custom201Detail?.extra_data || {}),
                        custom201: {
                          ...(custom201Detail?.extra_data?.custom201 || {}),
                          vennoot_number: v
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Stock type (A | B)"
                    value={custom201Detail?.extra_data?.custom201?.stock_type || ''}
                    id='stock_type'
                    onChange={(v) => {if (!!custom201Detail) setCustom201Detail({
                      ...custom201Detail,
                      extra_data: {
                        ...(custom201Detail?.extra_data || {}),
                        custom201: {
                          ...(custom201Detail?.extra_data?.custom201 || {}),
                          stock_type: v
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Stock amount"
                    value={custom201Detail?.extra_data?.custom201?.stock_amount?.toString() || ''}
                    id='stock_type'
                    onChange={(v) => {if (!!custom201Detail) setCustom201Detail({
                      ...custom201Detail,
                      extra_data: {
                        ...(custom201Detail?.extra_data || {}),
                        custom201: {
                          ...(custom201Detail?.extra_data?.custom201 || {}),
                          stock_amount: parseInt(v, 10)
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>

              </UserDetailBase>
            </Grid>
            
            <Grid item xs={12} sx={{marginTop: 2}}>
              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.account
                }}
                title="Spouse data"
                wide
              >
                <Grid item xs={12}>
                  <StyledTextField
                    label="Name"
                    value={custom201Detail?.extra_data?.custom201?.spouse_name || ''}
                    id='spouse_name'
                    onChange={(v) => {if (!!custom201Detail) setCustom201Detail({
                      ...custom201Detail,
                      extra_data: {
                        ...(custom201Detail?.extra_data || {}),
                        custom201: {
                          ...(custom201Detail?.extra_data?.custom201 || {}),
                          spouse_name: v
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Stock type (A | B)"
                    value={custom201Detail?.extra_data?.custom201?.spouse_stock_type || ''}
                    id='spouse_stock_type'
                    onChange={(v) => {if (!!custom201Detail) setCustom201Detail({
                      ...custom201Detail,
                      extra_data: {
                        ...(custom201Detail?.extra_data || {}),
                        custom201: {
                          ...(custom201Detail?.extra_data?.custom201 || {}),
                          spouse_stock_type: v
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Stock amount"
                    value={custom201Detail?.extra_data?.custom201?.spouse_stock_amount?.toString() || ''}
                    id='spouse_stock_type'
                    onChange={(v) => {if (!!custom201Detail) setCustom201Detail({
                      ...custom201Detail,
                      extra_data: {
                        ...(custom201Detail?.extra_data || {}),
                        custom201: {
                          ...(custom201Detail?.extra_data?.custom201 || {}),
                          spouse_stock_amount: parseInt(v, 10)
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>

              </UserDetailBase>
            </Grid>
            
            {/* <Grid item xs={12} sx={{marginTop: 2}}>
              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.contact
                }}
                title="Document data"
                wide
              >
                <Grid item xs={12}>
                  <StyledTextField
                    label="Name"
                    value={custom201Detail?.extra_data?.custom201?.name || ''}
                    id='name'
                    onChange={(v) => {if (!!custom201Detail) setCustom201Detail({
                      ...custom201Detail,
                      extra_data: {
                        ...(custom201Detail?.extra_data || {}),
                        custom201: {
                          ...(custom201Detail?.extra_data?.custom201 || {}),
                          name: v
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
              </UserDetailBase>
            </Grid> */}

          </Grid>
          <Grid container item xs={6} sx={{alignContent: 'baseline'}}>
          
            <Grid item xs={12} sx={{marginTop: 0, textAlign: 'center'}}>
              {!!saveLoader && <CircularProgress />}
              {!saveLoader && <StyledButton
                label="Save & regenerate file"
                id='regen'
                saveStartIcon
                contained
                onClick={(v) => {
                  saveCustom201();
                }}
              />}
            </Grid>
            {!custom201Detail?.get_lijst201_fod && <Grid item xs={12} sx={{marginTop: 2}}>
              <Typography sx={{fontStyle: 'italic', textAlign: 'center', width: '100%', fontSize: '0.8rem'}}>Wow... Such empty...</Typography>
            </Grid>}
            {!!custom201Detail?.get_lijst201_fod && <Grid container item xs={12} sx={{marginTop: 2}}>
              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.exit
                }}
                title="List 201 (FOD)"
                wide
                adornment={!!custom201Detail && !!custom201Detail.get_lijst201_fod && <>
                  <StyledIconButton
                    path={custom201Detail.get_lijst201_fod}
                    newTab
                    sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
                  ><LaunchIcon /></StyledIconButton>
                  {/* <LaunchIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                    e.preventDefault();
                    window.open(custom201Detail.get_lijst201_fod, "_BLANK");
                  }} /> */}
                </>}
                turnable={!!custom201Detail.lijst201_fod_blob_content_type && custom201Detail.lijst201_fod_blob_content_type.indexOf("image") > -1}
              >
                <Grid item xs={12}>
                  {!!custom201Detail.lijst201_fod_blob_content_type && custom201Detail.lijst201_fod_blob_content_type.indexOf("image") > -1 && <img style={{maxWidth: '100%'}} src={custom201Detail.get_lijst201_fod} />}
                  {!!custom201Detail.lijst201_fod_blob_content_type && custom201Detail.lijst201_fod_blob_content_type.indexOf("pdf") > -1 && <iframe style={{width: '100%', height: '300px'}} src={custom201Detail.get_lijst201_fod} />}
                </Grid>
                <Grid item xs={12}>
                  {!!custom201Detail?.lijst201_fod_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {custom201Detail?.lijst201_fod_filename} ({(parseInt(custom201Detail?.lijst201_fod_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
                </Grid>
              </UserDetailBase>
            </Grid>}

          </Grid>
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setExitSavedSnackOpen(b);}} />}
    </Page>
  );
}
