import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one } from '../../lib/server_helper';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // Avatar,
  CircularProgress,
  Divider,
  Fab,
  Grid,
  Snackbar,
  // SpeedDial,
  // SpeedDialAction,
  // SpeedDialIcon
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  LanguageFab,
  LanguageProgress,
  Page,
  StyledButton,
  StyledDateField,
  StyledEditor,
  // StyledIconButton,
  StyledSelect,
  StyledSwitch,
  StyledTextField,
  StyledTextFieldTransl,
  Typography
} from '../../components/interface';

import { Meeting } from '../../models/Meeting';

// const { DateTime } = require("luxon");

type MeetingSettingDetailProps = {};

export const MeetingSettingDetail: FunctionComponent<MeetingSettingDetailProps> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['login'],
  //   queryFn: async () => {return },
  // });

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [meetingDetail, setMeetingDetail] = useState<Meeting|null>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [exitId, setDividendId] = useState<string | undefined>(params.id);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setDividendSavedSnackOpen] = useState<boolean>(false);
  const [regenSnackOpen, setRegenSnackOpen] = useState<boolean>(false);

  const [accordionEmailActive, setAccordionEmailActive] = useState<string>('');
  const [accordionVotingActive, setAccordionVotingActive] = useState<string>('');

  const [qom, setQom] = useState<boolean>(false);

  const [visEmails, setVisEmails] = useState<boolean>(true);
  const [visGeneral, setVisGeneral] = useState<boolean>(true);
  const [visVoting, setVisVoting] = useState<boolean>(true);

  const [activeLocale, setActiveLocale] = useState<'nl'|'fr'>('nl');

  const fields = [
    'id', 'created_at', 'updated_at', 'name', 'enabled', 'extra_data', 'presentation_mode',
    'is_mandatory', 'meeting_day', 'last_day', 'last_day_vote', 'location', 'intro', 'has_voting', 'is_help_visible',
    'voting_intro', 'voting_documents', 'voting_data'
  ];

  // const regenBlank = () => {
  //   fetch_one<Meeting>('/nl/v3/objects/custom_action', {
  //     object: 'meeting',
  //     fields: fields,
  //     id: exitId,
  //     class_action: 'custom_api_regenerate_pdf'
  //   }, (ob, complete_data) => {
  //     if (!!ob.custom_result) setCalculationDetail(ob.custom_result);
  //     setLoader(false);

  //     setRegenSnackOpen(true);
  //   }, (z, y) => {
  //     setServerErrorSnackOpen(true);
  //   }, login);
  // };

  const saveSetting = () => {
    setSaveLoader(true);
    fetch_one<Meeting>('/nl/v3/objects/save', {
      object: 'meeting',
      fields: fields,
      id: exitId,
      ob: {
        ...meetingDetail,
        id: exitId
      },
      handler_id: login?.id
    }, (ob, complete_data) => {
      setDividendId(ob.id?.toString());
      openCalculation(ob);
      setSaveLoader(false);
      setDividendSavedSnackOpen(true);
      navigate("/meeting_settings");
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const fetchCalculation = () => {
    setLoader(true);
    
    fetch_one<Meeting>('/nl/v3/objects/fetch_all', {
      object: 'meeting',
      fields: fields,
      id: exitId
    }, (ob, complete_data) => {
      openCalculation(ob);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  useEffect(() => {
    fetchCalculation();
  }, []);

  const openCalculation = (ob: Meeting) => {
    setMeetingDetail(ob);
  }

  const checkPercentageInvite = (lang:string) => {
    let count = 1;
    if (lang === 'nl') {
      if (meetingDetail?.extra_data?.emails?.invite?.subject?.nl.replace(/<\/?[^>]+(>|$)/g, "") !== '') count += 33;
      if (meetingDetail?.extra_data?.emails?.invite?.body?.nl.replace(/<\/?[^>]+(>|$)/g, "") !== '') count += 33;
      if (meetingDetail?.extra_data?.emails?.invite?.practical?.nl.replace(/<\/?[^>]+(>|$)/g, "") !== '') count += 33;
    } else {
      if (meetingDetail?.extra_data?.emails?.invite?.subject?.fr.replace(/<\/?[^>]+(>|$)/g, "") !== '') count += 33;
      if (meetingDetail?.extra_data?.emails?.invite?.body?.fr.replace(/<\/?[^>]+(>|$)/g, "") !== '') count += 33;
      if (meetingDetail?.extra_data?.emails?.invite?.practical?.fr.replace(/<\/?[^>]+(>|$)/g, "") !== '') count += 33;
    }
    return count;
  }

  const checkPercentagePhysConf = (lang:string) => {
    let count = 1;
    if (lang === 'nl') {
      if (meetingDetail?.extra_data?.emails?.attend?.subject?.nl.replace(/<\/?[^>]+(>|$)/g, "") !== '') count += 33;
      if (meetingDetail?.extra_data?.emails?.attend?.body?.nl.replace(/<\/?[^>]+(>|$)/g, "") !== '') count += 33;
      if (meetingDetail?.extra_data?.emails?.attend_letter?.body?.nl.replace(/<\/?[^>]+(>|$)/g, "") !== '') count += 33;
    } else {
      if (meetingDetail?.extra_data?.emails?.attend?.subject?.fr.replace(/<\/?[^>]+(>|$)/g, "") !== '') count += 33;
      if (meetingDetail?.extra_data?.emails?.attend?.body?.fr.replace(/<\/?[^>]+(>|$)/g, "") !== '') count += 33;
      if (meetingDetail?.extra_data?.emails?.attend_letter?.body?.fr.replace(/<\/?[^>]+(>|$)/g, "") !== '') count += 33;
    }
    return count;
  }

  const checkPercentageVoteConf = (lang:string) => {
    let count = 0;
    if (lang === 'nl') {
      if (meetingDetail?.extra_data?.emails?.voting?.subject?.nl.replace(/<\/?[^>]+(>|$)/g, "") !== '') count += 50;
      if (meetingDetail?.extra_data?.emails?.voting?.body?.nl.replace(/<\/?[^>]+(>|$)/g, "") !== '') count += 50;
    } else {
      if (meetingDetail?.extra_data?.emails?.voting?.subject?.fr.replace(/<\/?[^>]+(>|$)/g, "") !== '') count += 50;
      if (meetingDetail?.extra_data?.emails?.voting?.body?.fr.replace(/<\/?[^>]+(>|$)/g, "") !== '') count += 50;
    }
    return count;
  }

  return (
    <Page sx={{}} title={`Meeting Detail`}>
      <LanguageFab
        activeLocale={activeLocale}
        setActiveLocale={(lc) => {setActiveLocale(lc);}}
      />
    
    {/* <SpeedDial
      ariaLabel='LanguagePicker'
      sx={{ position: 'fixed', bottom: 16, right: 16 }}
      icon={<SpeedDialIcon />}
    >
      <SpeedDialAction
        sx={{
          ...(activeLocale === 'nl' ? {backgroundColor: theme.palette.tertiary?.main} : {})
        }}
        key='nl'
        icon="NL"
        tooltipTitle="Nederlands"
      />
      <SpeedDialAction
        sx={{
          ...(activeLocale === 'fr' ? {backgroundColor: theme.palette.tertiary?.main} : {})
        }}
        key='fr'
        icon="FR"
        tooltipTitle="Francais"
      />
    </SpeedDial> */}

      <Grid container spacing="8">

        <Grid item xs={12} sx={{position: 'relative', marginBottom: 2, marginTop: 2}}>
          {!!visGeneral && <VisibilityIcon color="primary" onClick={(e) => {
            setVisGeneral(false);
          }} sx={{cursor: 'pointer', position: 'absolute', top: 12, left: 0}} />}
          {!visGeneral && <VisibilityOffIcon color="primary" onClick={(e) => {
            setVisGeneral(true);
          }} sx={{cursor: 'pointer', position: 'absolute', top: 12, left: 0}} />}
          <Typography variant="h2" sx={{paddingLeft: 4}}>General data</Typography>
        </Grid>

        {!!visGeneral && <>
          <Grid item xs={6}>
            <StyledTextField
              label="Internal name"
              value={meetingDetail?.name || ''}
              id="name"
              onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
                ...meetingDetail,
                name: v});
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid item xs={4}>
            <StyledSwitch
              label="Enabled"
              value={!!meetingDetail?.enabled}
              onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
                ...meetingDetail,
                enabled: !!v
              });}}
            />
          </Grid>
          <Grid item xs={4}>
            <StyledSwitch
              label="Mandatory meeting"
              value={!!meetingDetail?.is_mandatory}
              onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
                ...meetingDetail,
                is_mandatory: !!v
              });}}
            />
          </Grid>
          <Grid item xs={4}></Grid>

          <Grid item xs={4}>
            <StyledDateField
              label="Day of meeting"
              value={meetingDetail?.meeting_day || ''}
              id='meeting_day'
              onChange={(v) => {if (!!meetingDetail) setMeetingDetail({...meetingDetail, meeting_day: v.toString()});}}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <StyledDateField
              label="Last day to subscribe (inclusive)"
              value={meetingDetail?.last_day || ''}
              id='last_day'
              onChange={(v) => {if (!!meetingDetail) setMeetingDetail({...meetingDetail, last_day: v.toString()});}}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <StyledDateField
              label="Last day to vote (inclusive)"
              value={meetingDetail?.last_day_vote || ''}
              id='last_day_vote'
              onChange={(v) => {if (!!meetingDetail) setMeetingDetail({...meetingDetail, last_day_vote: v.toString()});}}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <StyledTextFieldTransl
              label="Location"
              activeLocale={activeLocale}
              value={meetingDetail?.location || {nl: '', fr: ''}}
              id="location"
              onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
                ...meetingDetail,
                location: v});
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <StyledTextFieldTransl
              label="Public name"
              activeLocale={activeLocale}
              value={{nl: meetingDetail?.extra_data?.backstage_name_nl || '', fr: meetingDetail?.extra_data?.backstage_name_fr || ''}}
              id="public_name"
              onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
                  ...meetingDetail,
                  extra_data: {
                    ...(meetingDetail.extra_data || {}),
                    backstage_name_nl: v.nl,
                    backstage_name_fr: v.fr
                  }
                });
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <StyledDateField
              label="Invite sent date"
              value={meetingDetail?.extra_data?.letter_time || ''}
              id='letter_time'
              onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
                ...meetingDetail,
                extra_data: {
                  ...(meetingDetail?.extra_data || {}),
                  letter_time: v.toString()
                }
              });}}
              fullWidth
            />
          </Grid>

          <StyledEditor
            label="Intro (Vennoot profile box)"
            value={meetingDetail?.intro || {nl: '', fr: ''}}
            activeLocale={activeLocale}
            onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
              ...meetingDetail,
              intro: v});
            }}
          />
        </>}

        <Grid item xs={12} sx={{position: 'relative', marginBottom: 2, marginTop: 2}}>
          {!!visEmails && <VisibilityIcon color="primary" onClick={(e) => {
            setVisEmails(false);
          }} sx={{cursor: 'pointer', position: 'absolute', top: 12, left: 0}} />}
          {!visEmails && <VisibilityOffIcon color="primary" onClick={(e) => {
            setVisEmails(true);
          }} sx={{cursor: 'pointer', position: 'absolute', top: 12, left: 0}} />}
          <Typography variant="h2" sx={{paddingLeft: 4}}>Email data</Typography>
        </Grid>

        {!!visEmails && <Grid item xs={12}>
          <Accordion expanded={accordionEmailActive === `invite`} onChange={() => {setAccordionEmailActive(accordionEmailActive === `invite` ? '' : `invite`);}}>
            <AccordionSummary id={`invite_header`} sx={{backgroundColor: theme.palette.opacitated?.main_02}}>
              <Typography>Invite email</Typography>
              <LanguageProgress
                name="NL"
                value={checkPercentageInvite('nl')}
                sx={{marginLeft: 'auto'}}
              />
              <LanguageProgress
                name="FR"
                value={checkPercentageInvite('fr')}
                sx={{marginLeft: 1}}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <StyledTextFieldTransl
                  label="Invite Email - subject"
                  activeLocale={activeLocale}
                  value={meetingDetail?.extra_data?.emails?.invite?.subject || {nl: '', fr: ''}}
                  id="invite_subject"
                  onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
                    ...meetingDetail,
                    extra_data: {
                      ...(meetingDetail?.extra_data || {}),
                      emails: {
                        ...(meetingDetail?.extra_data?.emails || {}),
                        invite: {
                          ...(meetingDetail?.extra_data?.emails?.invite || {}),
                          subject: v
                        }
                      }
                    }
                  });
                  }}
                  fullWidth
                />
              </Grid>

              <StyledEditor
                label="Invite Email - body"
                value={meetingDetail?.extra_data?.emails?.invite?.body || {nl: '', fr: ''}}
                activeLocale={activeLocale}
                onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
                  ...meetingDetail,
                  extra_data: {
                    ...(meetingDetail?.extra_data || {}),
                    emails: {
                      ...(meetingDetail?.extra_data?.emails || {}),
                      invite: {
                        ...(meetingDetail?.extra_data?.emails?.invite || {}),
                        body: v
                      }
                    }
                  }
                });
                }}
              />

              <StyledEditor
                label="Invite Email - practical"
                value={meetingDetail?.extra_data?.emails?.invite?.practical || {nl: '', fr: ''}}
                activeLocale={activeLocale}
                onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
                  ...meetingDetail,
                  extra_data: {
                    ...(meetingDetail?.extra_data || {}),
                    emails: {
                      ...(meetingDetail?.extra_data?.emails || {}),
                      invite: {
                        ...(meetingDetail?.extra_data?.emails?.invite || {}),
                        practical: v
                      }
                    }
                  }
                });
                }}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={accordionEmailActive === `phys_conf`} onChange={() => {setAccordionEmailActive(accordionEmailActive === `phys_conf` ? '' : `phys_conf`);}}>
            <AccordionSummary id={`phys_conf_header`} sx={{backgroundColor: theme.palette.opacitated?.main_02}}>
              <Typography>Physical Confirmation</Typography>
              <LanguageProgress
                name="NL"
                value={checkPercentagePhysConf('nl')}
                sx={{marginLeft: 'auto'}}
              />
              <LanguageProgress
                name="FR"
                value={checkPercentagePhysConf('fr')}
                sx={{marginLeft: 1}}
              />
            </AccordionSummary>
            <AccordionDetails>
              <StyledEditor
                label="Physical Confirmation Letter"
                value={meetingDetail?.extra_data?.emails?.attend_letter?.body || {nl: '', fr: ''}}
                activeLocale={activeLocale}
                onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
                  ...meetingDetail,
                  extra_data: {
                    ...(meetingDetail?.extra_data || {}),
                    emails: {
                      ...(meetingDetail?.extra_data?.emails || {}),
                      attend_letter: {
                        ...(meetingDetail?.extra_data?.emails?.attend_letter || {}),
                        body: v
                      }
                    }
                  }
                });
                }}
              />

              <Grid item xs={12}>
                <Divider sx={{marginTop: 1, marginBottom: 2}} />
              </Grid>

              <Grid item xs={12}>
                <StyledTextFieldTransl
                  label="Physical Confirmation Email - subject"
                  activeLocale={activeLocale}
                  value={meetingDetail?.extra_data?.emails?.attend?.subject || {nl: '', fr: ''}}
                  id="invite_subject"
                  onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
                    ...meetingDetail,
                    extra_data: {
                      ...(meetingDetail?.extra_data || {}),
                      emails: {
                        ...(meetingDetail?.extra_data?.emails || {}),
                        attend: {
                          ...(meetingDetail?.extra_data?.emails?.attend || {}),
                          subject: v
                        }
                      }
                    }
                  });
                  }}
                  fullWidth
                />
              </Grid>

              <StyledEditor
                label="Physical Confirmation Email - body"
                value={meetingDetail?.extra_data?.emails?.attend?.body || {nl: '', fr: ''}}
                activeLocale={activeLocale}
                onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
                  ...meetingDetail,
                  extra_data: {
                    ...(meetingDetail?.extra_data || {}),
                    emails: {
                      ...(meetingDetail?.extra_data?.emails || {}),
                      attend: {
                        ...(meetingDetail?.extra_data?.emails?.attend || {}),
                        body: v
                      }
                    }
                  }
                });
                }}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={accordionEmailActive === `vote_conf`} onChange={() => {setAccordionEmailActive(accordionEmailActive === `vote_conf` ? '' : `vote_conf`);}}>
            <AccordionSummary id={`vote_conf_header`} sx={{backgroundColor: theme.palette.opacitated?.main_02}}>
              <Typography>Voting Confirmation</Typography>
              <LanguageProgress
                name="NL"
                value={checkPercentageVoteConf('nl')}
                sx={{marginLeft: 'auto'}}
              />
              <LanguageProgress
                name="FR"
                value={checkPercentageVoteConf('fr')}
                sx={{marginLeft: 1}}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <StyledTextFieldTransl
                  label="Voting Confirmation Email - subject"
                  activeLocale={activeLocale}
                  value={meetingDetail?.extra_data?.emails?.voting?.subject || {nl: '', fr: ''}}
                  id="invite_subject"
                  onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
                    ...meetingDetail,
                    extra_data: {
                      ...(meetingDetail?.extra_data || {}),
                      emails: {
                        ...(meetingDetail?.extra_data?.emails || {}),
                        voting: {
                          ...(meetingDetail?.extra_data?.emails?.voting || {}),
                          subject: v
                        }
                      }
                    }
                  });
                  }}
                  fullWidth
                />
              </Grid>

              <StyledEditor
                label="Voting Confirmation Email - body"
                value={meetingDetail?.extra_data?.emails?.voting?.body || {nl: '', fr: ''}}
                activeLocale={activeLocale}
                onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
                  ...meetingDetail,
                  extra_data: {
                    ...(meetingDetail?.extra_data || {}),
                    emails: {
                      ...(meetingDetail?.extra_data?.emails || {}),
                      voting: {
                        ...(meetingDetail?.extra_data?.emails?.voting || {}),
                        body: v
                      }
                    }
                  }
                });
                }}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>}

        <Grid item xs={12} sx={{position: 'relative', marginBottom: 2, marginTop: 2}}>
          {!!visVoting && <VisibilityIcon color="primary" onClick={(e) => {
            setVisVoting(false);
          }} sx={{cursor: 'pointer', position: 'absolute', top: 12, left: 0}} />}
          {!visVoting && <VisibilityOffIcon color="primary" onClick={(e) => {
            setVisVoting(true);
          }} sx={{cursor: 'pointer', position: 'absolute', top: 12, left: 0}} />}
          <Typography variant="h2" sx={{paddingLeft: 4}}>Voting data</Typography>
        </Grid>

        {!!visVoting && <>
          <Grid item xs={4}>
            <StyledSwitch
              label="Voting is available"
              value={!!meetingDetail?.has_voting}
              onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
                ...meetingDetail,
                has_voting: !!v
              });}}
            />
          </Grid>
          <Grid item xs={4}>
            <StyledSwitch
              label="Show question tips"
              value={!!meetingDetail?.is_help_visible}
              onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
                ...meetingDetail,
                is_help_visible: !!v
              });}}
            />
          </Grid>
          <Grid item xs={4}>
            <StyledSelect
              label="Presentation mode"
              value={meetingDetail?.presentation_mode?.toString() || '0'}
              id='presentation_mode'
              onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
                ...meetingDetail,
                presentation_mode: parseInt(v, 10)
              });}}
              list={[{id: '0', name: 'Survey mode (list of questions)'}, {id: '1', name: 'Slide mode (each question is a screen)'}]}
            />
          </Grid>
          <StyledEditor
            label="Intro Slide"
            value={meetingDetail?.voting_intro || {nl: '', fr: ''}}
            activeLocale={activeLocale}
            onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
              ...meetingDetail,
              voting_intro: v});
            }}
          />
          <StyledEditor
            label="Documents Slide"
            value={meetingDetail?.voting_documents || {nl: '', fr: ''}}
            activeLocale={activeLocale}
            onChange={(v) => {if (!!meetingDetail) setMeetingDetail({
              ...meetingDetail,
              voting_documents: v});
            }}
          />
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {meetingDetail?.voting_data?.map((question, i) => {
              let q = !!meetingDetail?.voting_data ? meetingDetail?.voting_data[i] : {};

              let trans_nl = 0;
              let trans_fr = 0;

              if (q.top_info?.nl.replace(/<\/?[^>]+(>|$)/g, "") !== '') trans_nl += 20;
              if (q.question?.nl.replace(/<\/?[^>]+(>|$)/g, "") !== '') trans_nl += 20;
              if (q.toelichting?.nl.replace(/<\/?[^>]+(>|$)/g, "") !== '') trans_nl += 20;
              if (q.bottom_info?.nl.replace(/<\/?[^>]+(>|$)/g, "") !== '') trans_nl += 20;
              if (q.image_url?.nl.replace(/<\/?[^>]+(>|$)/g, "") !== '') trans_nl += 20;
              if (q.top_info?.fr.replace(/<\/?[^>]+(>|$)/g, "") !== '') trans_fr += 20;
              if (q.question?.fr.replace(/<\/?[^>]+(>|$)/g, "") !== '') trans_fr += 20;
              if (q.toelichting?.fr.replace(/<\/?[^>]+(>|$)/g, "") !== '') trans_fr += 20;
              if (q.bottom_info?.fr.replace(/<\/?[^>]+(>|$)/g, "") !== '') trans_fr += 20;
              if (q.image_url?.fr.replace(/<\/?[^>]+(>|$)/g, "") !== '') trans_fr += 20;

              return <Accordion expanded={accordionVotingActive === `q${i}`} onChange={() => {setAccordionVotingActive((!!qom || accordionVotingActive === `q${i}`) ? '' : `q${i}`);}}>
                <AccordionSummary id={`q${i}_header`} sx={{backgroundColor: theme.palette.opacitated?.main_02}}>
                  {q.type === "profile" && <AccountBoxIcon color="primary" />}
                  {q.type !== "profile" && <HelpCenterIcon color="primary" />}
                  <Typography sx={{marginLeft: 1}}>
                    {!!q.internal && q.internal}
                    {!q.internal && !!q?.toelichting?.nl && `Q${i + 1} - ${q?.question?.nl.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 99)}`}
                    {!q.internal && !q?.toelichting?.nl && `Question #${i + 1}`}
                  </Typography>
                  {!qom && <>
                    <LanguageProgress
                      name="NL"
                      value={trans_nl}
                      sx={{marginLeft: 'auto'}}
                    />
                    <LanguageProgress
                      name="FR"
                      value={trans_fr}
                      sx={{marginLeft: 1}}
                    />
                  </>}
                  {!!qom && <>
                    <ExpandLessIcon 
                      sx={{marginLeft: 'auto', opacity: (i === 0 ? 0 : 1), cursor: 'pointer'}}
                      onClick={() => {
                        if (!!meetingDetail && !!meetingDetail.voting_data && i > 0) {
                          let new_voting_data:any[] = [];
                          (meetingDetail?.voting_data || []).map((xx, ii) => {
                            if ((i - 1) === ii) {
                              new_voting_data.push(meetingDetail?.voting_data[i]);
                              new_voting_data.push(xx);
                            } else {
                              if (i === ii) {
                                // do nothing
                              } else {
                                new_voting_data.push(xx);
                              }
                            }
                          });
                          if (!!meetingDetail) setMeetingDetail({
                            ...meetingDetail,
                            voting_data: new_voting_data});
                        }
                      }}
                    />
                    <ExpandMoreIcon 
                      sx={{marginLeft: 1, opacity: (i === ((meetingDetail?.voting_data || []).length - 1) ? 0 : 1), cursor: 'pointer'}}
                      onClick={() => {
                        if (!!meetingDetail && !!meetingDetail.voting_data && i < ((meetingDetail?.voting_data || []).length - 1)) {
                          let new_voting_data:any[] = [];
                          (meetingDetail?.voting_data || []).map((xx, ii) => {
                            if (i === ii) {
                              new_voting_data.push(meetingDetail?.voting_data[i + 1]);
                              new_voting_data.push(xx);
                            } else {
                              if ((i + 1) === ii) {
                                // do nothing
                              } else {
                                new_voting_data.push(xx);
                              }
                            }
                          });
                          if (!!meetingDetail) setMeetingDetail({
                            ...meetingDetail,
                            voting_data: new_voting_data});
                        }
                      }}
                    />
                  </>}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12} sx={{marginTop: 1}}>
                    <StyledTextField
                      label={`Short internal identifier (#${i + 1})`}
                      value={q.internal?.toString() || `Question ${i + 1}`}
                      id="internal"
                      onChange={(v) => {
                        let new_voting_data = meetingDetail?.voting_data || [];
                        new_voting_data[i] = {
                          ...q,
                          internal: v
                        };
                        if (!!meetingDetail) setMeetingDetail({
                          ...meetingDetail,
                          voting_data: new_voting_data});
                      }}
                      fullWidth
                    />
                  </Grid>
                  <StyledEditor
                    label={`Top Info (#${i + 1})`}
                    value={q.top_info || {nl: '', fr: ''}}
                    activeLocale={activeLocale}
                    onChange={(v) => {
                      let new_voting_data = meetingDetail?.voting_data || [];
                      new_voting_data[i] = {
                        ...q,
                        top_info: v
                      };
                      if (!!meetingDetail) setMeetingDetail({
                        ...meetingDetail,
                        voting_data: new_voting_data});
                    }}
                  />
                  {q.type === "profile" && <>
                    <Grid item xs={12} sx={{marginTop: 1}}>
                      <StyledTextFieldTransl
                        label={`Title (#${i + 1})`}
                        activeLocale={activeLocale}
                        value={q.question || {nl: '', fr: ''}}
                        id="toelichting"
                        onChange={(v) => {
                          let new_voting_data = meetingDetail?.voting_data || [];
                          new_voting_data[i] = {
                            ...q,
                            question: v
                          };
                          if (!!meetingDetail) setMeetingDetail({
                            ...meetingDetail,
                            voting_data: new_voting_data});
                        }}
                        fullWidth
                      />
                    </Grid>
                    <StyledEditor
                      label={`Profile information (#${i + 1})`}
                      value={q.toelichting || {nl: '', fr: ''}}
                      activeLocale={activeLocale}
                      onChange={(v) => {
                        let new_voting_data = meetingDetail?.voting_data || [];
                        new_voting_data[i] = {
                          ...q,
                          toelichting: v
                        };
                        if (!!meetingDetail) setMeetingDetail({
                          ...meetingDetail,
                          voting_data: new_voting_data});
                      }}
                    />
                  </>}
                  {q.type !== "profile" && <>
                    <StyledEditor
                      label={`Question (#${i + 1})`}
                      value={q.question || {nl: '', fr: ''}}
                      activeLocale={activeLocale}
                      onChange={(v) => {
                        let new_voting_data = meetingDetail?.voting_data || [];
                        new_voting_data[i] = {
                          ...q,
                          question: v
                        };
                        if (!!meetingDetail) setMeetingDetail({
                          ...meetingDetail,
                          voting_data: new_voting_data});
                      }}
                    />
                    <Grid item xs={12} sx={{marginTop: 1}}>
                      <StyledTextFieldTransl
                        label={`Toelichting (#${i + 1})`}
                        activeLocale={activeLocale}
                        value={q.toelichting || {nl: '', fr: ''}}
                        id="toelichting"
                        onChange={(v) => {
                          let new_voting_data = meetingDetail?.voting_data || [];
                          new_voting_data[i] = {
                            ...q,
                            toelichting: v
                          };
                          if (!!meetingDetail) setMeetingDetail({
                            ...meetingDetail,
                            voting_data: new_voting_data});
                        }}
                        fullWidth
                      />
                    </Grid>
                  </>}
                  <StyledEditor
                    label={`Bottom Info (#${i + 1})`}
                    value={q.bottom_info || {nl: '', fr: ''}}
                    activeLocale={activeLocale}
                    onChange={(v) => {
                      let new_voting_data = meetingDetail?.voting_data || [];
                      new_voting_data[i] = {
                        ...q,
                        bottom_info: v
                      };
                      if (!!meetingDetail) setMeetingDetail({
                        ...meetingDetail,
                        voting_data: new_voting_data});
                    }}
                  />
                  <Grid item xs={12} sx={{marginTop: 1}}>
                    <StyledTextField
                      label={`Quorum (#${i + 1})`}
                      value={q.quorum?.toString() || '50'}
                      id="quorum"
                      onChange={(v) => {
                        let new_voting_data = meetingDetail?.voting_data || [];
                        new_voting_data[i] = {
                          ...q,
                          quorum: parseInt(v, 10)
                        };
                        if (!!meetingDetail) setMeetingDetail({
                          ...meetingDetail,
                          voting_data: new_voting_data});
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sx={{marginTop: 1}}>
                    <StyledTextFieldTransl
                      label={`Image URL (#${i + 1})`}
                      activeLocale={activeLocale}
                      value={q.image_url || {nl: '', fr: ''}}
                      id="image_url"
                      onChange={(v) => {
                        let new_voting_data = meetingDetail?.voting_data || [];
                        new_voting_data[i] = {
                          ...q,
                          image_url: v
                        };
                        if (!!meetingDetail) setMeetingDetail({
                          ...meetingDetail,
                          voting_data: new_voting_data});
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}><Divider sx={{marginTop: 2, marginBottom: 2}} /></Grid>
                </AccordionDetails>
              </Accordion>
            })}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {!qom && <StyledButton
              label="Add normal question"
              id='add_normal'
              // contained
              onClick={(v) => {
                let new_voting_data = meetingDetail?.voting_data || [];
                new_voting_data.push({
                  top_info: {nl: '', fr: ''},
                  question: {nl: '', fr: ''},
                  toelichting: {nl: '', fr: ''},
                  bottom_info: {nl: '', fr: ''},
                  image_url: {nl: '', fr: ''},
                  quorum: 50,
                  type: 'normal'
                });
                if (!!meetingDetail) setMeetingDetail({
                  ...meetingDetail,
                  voting_data: new_voting_data});
              }}
              sx={{marginLeft: 0}}
            />}
            {!qom && <StyledButton
              label="Add profile question"
              id='add_normal'
              // contained
              onClick={(v) => {
                let new_voting_data = meetingDetail?.voting_data || [];
                new_voting_data.push({
                  top_info: {nl: '', fr: ''},
                  question: {nl: '', fr: ''},
                  toelichting: {nl: '', fr: ''},
                  bottom_info: {nl: '', fr: ''},
                  image_url: {nl: '', fr: ''},
                  quorum: 50,
                  type: 'profile'
                });
                if (!!meetingDetail) setMeetingDetail({
                  ...meetingDetail,
                  voting_data: new_voting_data});
              }}
              sx={{marginLeft: 1}}
            />}
            {!qom && <StyledButton
              label="Question Order Mode"
              id='qom'
              // contained
              onClick={(v) => {
                setAccordionVotingActive('');
                setQom(true);
              }}
              sx={{marginLeft: 1}}
            />}
            {!!qom && <StyledButton
              label="Stop ordering"
              id='qom'
              secondary
              contained
              onClick={(v) => {
                setQom(false);
              }}
              sx={{marginLeft: 1}}
            />}
          </Grid>
        </>}
        
        <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center'}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!!loader && <CircularProgress />}
          {!loader && <StyledButton
            label="Cancel"
            id='cancel'
            // contained
            onClick={(v) => {
              navigate("/meeting_settings");
            }}
            sx={{marginLeft: 1}}
          />}
          {!loader && <StyledButton
            label="Save"
            id='save'
            contained
            onClick={(v) => {
              saveSetting();
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>
      </Grid>

      {!!regenSnackOpen && <Snackbar
        open={regenSnackOpen}
        onClose={() => {setRegenSnackOpen(false);}}
        autoHideDuration={2000}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">
          Document was regenerated!
        </MuiAlert>
      </Snackbar>}

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setDividendSavedSnackOpen(b);}} />}
    </Page>
  );
}
