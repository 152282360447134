import React, { FunctionComponent } from 'react';

import {
  Grid
} from '@mui/material';

import {
  Typography
} from '../../interface';

import { BlockPublicBaseImage } from './BaseImage';

import { BenefitBlock } from '../../../models/BenefitBlock';
import { EmailBlock } from '../../../models/EmailBlock';

type BlockPublicImageRightProps = {
  block: EmailBlock | BenefitBlock;
  marked: boolean;
  activeLocale: 'nl' | 'fr';
};

// function isBenefitBlock(block: EmailBlock | BenefitBlock): block is BenefitBlock {
//   return true;
// }

export const BlockPublicImageRight: FunctionComponent<BlockPublicImageRightProps> = ({block, marked, activeLocale}) => {
  let string_value = undefined;
  let title_value = undefined;

  const isBenBlock = (x: any): x is BenefitBlock => !block.hasOwnProperty('get_item');

  if(isBenBlock(block)){
    if (activeLocale === 'fr') {
      string_value = block.string_value?.fr;
      title_value = block.title_value?.fr;
    }
    if (activeLocale !== 'fr') {
      string_value = block.string_value?.nl;
      title_value = block.title_value?.nl;
    }
  }
  if(!isBenBlock(block)){
    string_value = block.string_value;
    title_value = block.title_value;
  }

  return ( <Grid container item xs={12} id={block.id?.toString()} sx={{
    marginTop: block.settings?.mtop || 0,
    marginBottom: block.settings?.mbot || 0,
    border: `1px solid ${block.settings?.border || 'transparent'}`,
    padding: '12px',
    paddingTop: 0,
    paddingBottom: 0,
    ...(!!marked ? {backgroundColor: 'rgba(255, 255, 0, 0.3)'} : {})
  }}>
    <Grid item xs={7} sx={{paddingRight: 1}}>
      {!!title_value && <Typography variant="h3" nw_title html>{title_value}</Typography>}
      {!title_value && <Typography variant="h3" sx={{fontStyle: 'italic', color: 'lightgrey !important'}}>Title goes here...</Typography>}

      {!!string_value && <Typography nw_text html>{string_value}</Typography>}
      {!string_value && <Typography sx={{fontStyle: 'italic', color: 'lightgrey !important'}}>Content goes here...</Typography>}
    </Grid>
    <Grid item xs={5}>
    <BlockPublicBaseImage block={block} activeLocale={activeLocale} />
    </Grid>
  </Grid>);
};
