import React, { FunctionComponent } from 'react';

import {
  Grid
} from '@mui/material';

import { BlockPublicBaseImage } from './BaseImage';

import { BenefitBlock } from '../../../models/BenefitBlock';
import { EmailBlock } from '../../../models/EmailBlock';

type BlockPublicImageProps = {
  block: EmailBlock | BenefitBlock;
  marked: boolean;
  activeLocale: 'nl' | 'fr';
};

function isBenefitBlock(block: EmailBlock | BenefitBlock): block is BenefitBlock {
  return true;
}

export const BlockPublicImage: FunctionComponent<BlockPublicImageProps> = ({activeLocale, block, marked}) => {
  return ( <Grid xs={12} sx={{
    marginTop: block.settings?.mtop || 0,
    marginBottom: block.settings?.mbot || 0,
    // border: `1px solid ${block.settings?.border || 'transparent'}`,
    padding: '12px',
    ...(!!marked ? {backgroundColor: 'rgba(255, 255, 0, 0.3)'} : {})
  }}>
    <BlockPublicBaseImage block={block} activeLocale={activeLocale} />
  </Grid>);
};
