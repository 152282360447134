import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { useParams } from 'react-router-dom';

import { fetch_one } from '../../lib/server_helper';

import {
  Avatar,
  Box,
  Divider,
  Grid
} from '@mui/material';

import LaunchIcon from '@mui/icons-material/Launch';

import { PieChart } from '@mui/x-charts/PieChart';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  Page,
  StyledButton,
  Typography
} from '../../components/interface';

import { Meeting } from '../../models/Meeting';
import { loginAtom } from '../../lib/auth';

type MeetingVotingProps = {};

export const MeetingVoting: FunctionComponent<MeetingVotingProps> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();

  const login = useAtomValue(loginAtom);
  // const [login, setLogin] = useAtom(loginAtom);
  // const [myQueues, setMyQueues] = useAtom(queueAtom);

  const params = useParams();
  const meetingId: string | undefined = params.id;

  const [voting, setVoting] = useState<Meeting>();
  // const [settingsCount, setVotingCount] = useState<number>(0);
  // const [loader, setLoader] = useState<boolean>(true);
  // const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  // const [page, setPage] = useState<number>(0);
  // const [order, setOrder] = useState<string>("created_at DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch();
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = () => {
    fetch_one<Meeting>('/nl/v3/objects/custom_action', {
      object: 'meeting',
      class_action: 'custom_api_voting_stats',
      id: meetingId,
      handler_id: login?.id
    }, (ob, complete_data) => {
      setVoting(ob);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const onExport = () => {
    fetch_one<{custom_result?: {url: string, success: boolean}}>('/nl/v3/objects/custom_action', {
      object: 'meeting',
      class_action: 'custom_api_export_votes',
      id: meetingId,
      handler_id: login?.id
    }, (ob, complete_data) => {
      if (ob.custom_result?.success) window.open(ob.custom_result?.url, "_BLANK");
      if (!ob.custom_result?.success) setServerErrorSnackOpen(true);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  }

  return (
    <Page sx={{}} title="Meetings" helper="settings" old_url="https://old.argenco.be/meetings" actions={<>
      <StyledButton
        label="List of votes"
        id='votes'
        contained
        onClick={(v) => {
          window.open(`/meeting_votes/${meetingId}`, "_BLANK");
        }}
        startIcon={<LaunchIcon />}
      />

      <StyledButton
        label="Export vote results"
        id='export_votes'
        contained
        onClick={(v) => {
          onExport();
        }}
        sx={{marginLeft: 1}}
      />
    </>}>
      <Grid container spacing="8">
        {(voting?.custom_result?.voting || []).map((v_res, i) => <Grid item xs={12} md={4} lg={3}>
          <Box sx={{
            border: "2px solid transparent",
            borderColor: (v_res.colour === "success" ? theme.palette.tertiary?.main : theme.palette.secondary.main),
            padding: 1,
            borderRadius: 2,
            position: 'relative'
          }}>
            <Avatar sx={{
              position: 'absolute',
              top: -12,
              left: -12,
              height: '32px',
              width: '32px',
              backgroundColor: (v_res.colour === "success" ? theme.palette.tertiary?.main : theme.palette.secondary.main)
            }}>{i + 1}</Avatar>
            <Typography sx={{height: '26px', overflow: 'hidden'}}>{v_res.question.internal || v_res.question.question.nl.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 99)}</Typography>
            <Divider sx={{marginTop: 1, marginBottom: 1, backgroundColor: (v_res.colour === "success" ? theme.palette.tertiary?.main : theme.palette.secondary.main)}} />
            <Typography variant="caption">Geldige stemmers: {v_res.count}</Typography><br/>
            <Typography variant="caption">Geldige aandelen: {v_res.total}</Typography><br/>
            <Typography variant="caption">Quorum: {v_res.question.quorum}%</Typography>
            <Divider sx={{marginTop: 1, marginBottom: 1, backgroundColor: (v_res.colour === "success" ? theme.palette.tertiary?.main : theme.palette.secondary.main)}} />
            <Box sx={{
              paddingLeft: "calc((100% - 200px) / 2)"
            }}>
              <PieChart
                series={[
                  {
                    data: [
                      { id: 0, value: v_res.y, label: 'Yay', color: theme.palette.tertiary?.main },
                      { id: 1, value: v_res.n, label: 'Nay', color: theme.palette.secondary.main }
                    ],
                    innerRadius: 20,
                    outerRadius: 80,
                    paddingAngle: 2,
                    cx: 95,
                    cy: 110,
                    highlightScope: {
                      highlighted: 'item',
                      faded: 'global'
                    }
                  },
                ]}
                slotProps={{
                  legend: {
                    direction: 'row',
                    position: { vertical: 'top', horizontal: 'middle' },
                    padding: 0,
                    labelStyle: {
                      font: "Golos"
                    }
                  },
                }}
                width={200}
                height={200}
              />
            </Box>
          </Box>
        </Grid>)}
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
