import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one } from '../../../lib/server_helper';
import { loginAtom } from '../../../lib/auth';

import {
  Avatar,
  Box,
  Grid,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import IconItsme from "../../../images/itsme.svg";
import LaunchIcon from '@mui/icons-material/Launch';

import { AlertCheckInputs } from '../../../components/alerts/CheckInputs';
import { AlertSaved } from '../../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../../components/alerts/SomethingWentWrong';

import {
  StyledButton,
  StyledDropzone,
  StyledIconButton,
  Typography
} from '../../../components/interface';
import { DetailLine } from '../../../components/interface/Detail/DetailLine';
import { UserDetailBase } from '../../../components/user_detail/Base';

import { EmissionKey } from '../../../models/EmissionKey';

const { DateTime } = require("luxon");

type Props = {
  emissionKey: EmissionKey;
  isHidden: boolean;
  fields: string[];
  refreshKey: () => void;
};

export const EmissionDetailTabsDetailBorderel: FunctionComponent<Props> = ({emissionKey, isHidden, fields, refreshKey}) => {
  // const navigate = useNavigate();
  const theme = useTheme();
  // const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);

  const [item, setItem] = useState<File>();

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [userSavedSnackOpen, setUserSavedSnackOpen] = useState<boolean>(false);
  const [regenBlankSnackOpen, setRegenBlankSnackOpen] = useState<boolean>(false);

  const regenBlank = () => {
    fetch_one<EmissionKey>('/nl/v3/objects/custom_action', {
      object: 'emission_key',
      fields: fields,
      id: emissionKey.id,
      class_action: 'custom_api_regenerate_blank'
    }, (ob, complete_data) => {
      refreshKey();
      setRegenBlankSnackOpen(true);
    }, (z, y) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const setSaveConfirm = () => {
    fetch_one<EmissionKey>('/nl/v3/objects/custom_action', {
      object: 'emission_key',
      class_action: 'custom_api_confirm_step',
      id: emissionKey.id,
      step: 'borderel'
    }, (ob, complete_data) => {
      refreshKey();
    }, (z, y) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  return (
    <Box hidden={isHidden} sx={{paddingTop: 2}}>
      <Grid container spacing="8">
        <Grid item xs={12} md={4} sx={{alignContent: 'baseline'}}>
          <UserDetailBase
            sx={{
              backgroundColor: 'white'
            }}
            title="Quick Info"
            wide
            adornment={!!emissionKey && !!emissionKey.user_itsme_sub ? <Avatar src={IconItsme} sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} /> : <></>}
          >
            <DetailLine name="Name" value={[emissionKey.user_first_name, emissionKey.user_last_name].join(" ")} />
            <DetailLine name="Birthdate" date={!!emissionKey ? emissionKey.user_birth_date : ''} />
            <DetailLine no_divider name="Address" value={`${emissionKey.user_street} ${emissionKey.user_number}`} />
            <DetailLine name="" value={`${emissionKey.user_zip} ${emissionKey.user_city}`} />
            <DetailLine name="Zicht account" value={emissionKey.user_zicht_account} />
            <DetailLine copyable name="ASN" value={emissionKey.modded_asn_key} />
            <DetailLine no_divider copyable name="Emission ASN" value={emissionKey.modded_asn_key_of_user} />
          </UserDetailBase>

          {!!emissionKey?.user_get_identity_front && <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.identity,
              marginTop: 1
            }}
            wide
            title="ID"
            adornment={!!emissionKey && !!emissionKey.user_get_identity_front && <>
              <StyledIconButton
                path={emissionKey.user_get_identity_front}
                newTab
                sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
              ><LaunchIcon /></StyledIconButton>
            </>}
          >
            <Grid item xs={12}>
              {!!emissionKey.user_identity_front_blob_content_type && emissionKey.user_identity_front_blob_content_type.indexOf("image") > -1 && <img style={{maxWidth: '100%'}} src={emissionKey.user_get_identity_front} />}
              {!!emissionKey.user_identity_front_blob_content_type && emissionKey.user_identity_front_blob_content_type.indexOf("pdf") > -1 && <iframe style={{width: '100%', height: '300px'}} src={emissionKey.user_get_identity_front} />}
            </Grid>
            <Grid item xs={12}>
              {!!emissionKey?.user_identity_front_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {emissionKey?.user_identity_front_filename} ({(parseInt(emissionKey?.user_identity_front_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
            </Grid>
          </UserDetailBase>}

          {!!emissionKey && emissionKey.user_has_guardianship && emissionKey.user_guardian_get_identity_front && <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.guardian,
              marginTop: 1
            }}
            title="Guardian ID"
            wide
            adornment={!!emissionKey && !!emissionKey.user_guardian_get_identity_front && <>
              <StyledIconButton
                path={emissionKey.user_guardian_get_identity_front}
                newTab
                sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
              ><LaunchIcon /></StyledIconButton>
            </>}
          >
            <Grid item xs={12}>
              {!!emissionKey.user_guardian_identity_front_blob_content_type && emissionKey.user_guardian_identity_front_blob_content_type.indexOf("image") > -1 && <img style={{maxWidth: '100%'}} src={emissionKey.user_guardian_get_identity_front} />}
              {!!emissionKey.user_guardian_identity_front_blob_content_type && emissionKey.user_guardian_identity_front_blob_content_type.indexOf("pdf") > -1 && <iframe style={{width: '100%', height: '300px'}} src={emissionKey.user_guardian_get_identity_front} />}
            </Grid>
            <Grid item xs={12}>
              {!!emissionKey?.user_guardian_identity_front_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {emissionKey?.user_guardian_identity_front_filename} ({(parseInt(emissionKey?.user_guardian_identity_front_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
            </Grid>
          </UserDetailBase>}

          <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.identity,
              marginTop: 1
            }}
            title="Signed Borderel"
            wide
          >
            <Grid item xs={12}>
              <Typography sx={{fontSize: '0.75rem'}}>Bijlage</Typography>
              <StyledDropzone
                acceptedFiles={{
                  'image/*': [],
                  'application/pdf': []
                }}
                onDrop={acceptedFiles => {setItem(acceptedFiles[0]);}}
                multiple={false}
                maxFiles={1}
                maxSize={3 * 1000 * 1000}
                items={!!item ? [item] : []}
                dropText="Click here, or drop some files."
                dropHint="Allowed: 1 image (.png, .jpeg, .jpg, .gif) or PDF (.pdf), < 3mb"
              />
            </Grid>
          </UserDetailBase>
        </Grid>
        <Grid item xs={12} md={4} sx={{alignContent: 'baseline'}}>
          {!!emissionKey?.borderel_get_item && <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.identity
            }}
            wide
            title="Signed Borderel"
            adornment={!!emissionKey && !!emissionKey.borderel_get_item && <>
              <StyledIconButton
                path={emissionKey.borderel_get_item}
                newTab
                sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
              ><LaunchIcon /></StyledIconButton>
            </>}
          >
            <Grid item xs={12}>
              {!!emissionKey.borderel_item_blob_content_type && emissionKey.borderel_item_blob_content_type.indexOf("image") > -1 && <img style={{maxWidth: '100%'}} src={emissionKey.borderel_get_item} />}
              {!!emissionKey.borderel_item_blob_content_type && emissionKey.borderel_item_blob_content_type.indexOf("pdf") > -1 && <iframe style={{width: '100%', height: '300px'}} src={emissionKey.borderel_get_item} />}
            </Grid>
            <Grid item xs={12}>
              {!!emissionKey?.borderel_item_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {emissionKey?.borderel_item_filename} ({(parseInt(emissionKey?.borderel_item_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
            </Grid>
          </UserDetailBase>}

          {!!emissionKey?.borderel_get_blank && <UserDetailBase
            sx={{
              backgroundColor: theme.palette.profile_blox?.identity,
              marginTop: !!emissionKey?.borderel_get_item ? 1 : 0
            }}
            wide
            title="Blank Borderel"
            adornment={!!emissionKey && !!emissionKey.borderel_get_blank && <>
              <StyledIconButton
                path={emissionKey.borderel_get_blank}
                newTab
                sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
              ><LaunchIcon /></StyledIconButton>
            </>}
          >
            <Grid item xs={12}>
              {!!emissionKey.borderel_blank_blob_content_type && emissionKey.borderel_blank_blob_content_type.indexOf("image") > -1 && <img style={{maxWidth: '100%'}} src={emissionKey.borderel_get_blank} />}
              {!!emissionKey.borderel_blank_blob_content_type && emissionKey.borderel_blank_blob_content_type.indexOf("pdf") > -1 && <iframe style={{width: '100%', height: '300px'}} src={emissionKey.borderel_get_blank} />}
            </Grid>
            <Grid item xs={12}>
              {!!emissionKey?.borderel_blank_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {emissionKey?.borderel_blank_filename} ({(parseInt(emissionKey?.borderel_blank_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
            </Grid>
          </UserDetailBase>}
        </Grid>

        <Grid item container xs={12} md={4} sx={{alignContent: 'baseline', flexBasis: "calc(33.33% + 8px) !important"}} spacing="8">
          {!emissionKey.check_borderel && <Grid item xs={12}>
            <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Actions</Typography>
          </Grid>}

          {!emissionKey.check_borderel && <Grid item xs={6}>
            <StyledButton
              label="Regenerate blank"
              id='regen_blank'
              onClick={(v) => {
                regenBlank();
              }}
              sx={{width: '100%'}}
            />
          </Grid>}

          <Grid item xs={12}>
            <Typography variant="h3" sx={{marginTop: 1, textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Confirmation</Typography>
          </Grid>
          {!!emissionKey.check_borderel && <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Typography sx={{
              fontStyle: 'italic',
              fontSize: '0.8rem'
            }}>Confirmed on {DateTime.fromISO(emissionKey.check_borderel_confirmed_at).toFormat("DDDD")}</Typography>
          </Grid>}
          {!emissionKey.check_borderel && <Grid item xs={6}>
            <StyledButton
              label="Confirm"
              id='set_checked'
              contained
              startIcon={<CheckCircleOutlineIcon />}
              onClick={(v) => {
                // if (!itemConfirmed) setItemConfirmed(1);
                // if (!identityConfirmed) setIdentityConfirmed(1);
                // if (!identityNewConfirmed) setIdentityNewConfirmed(1);
                // if (!identityVoogdConfirmed) setIdentityVoogdConfirmed(1);
                // setTimeout(() => {setSaveConfirm(true);}, 200);
                setSaveConfirm();
              }}
              sx={{width: '100%'}}
            />
          </Grid>}
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!userSavedSnackOpen && <AlertSaved open={userSavedSnackOpen} setOpen={(b) => {setUserSavedSnackOpen(b);}} />}

      {!!regenBlankSnackOpen && <Snackbar
        open={regenBlankSnackOpen}
        onClose={() => {setRegenBlankSnackOpen(false);}}
        autoHideDuration={2000}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">
          Unsigned document was regenerated!
        </MuiAlert>
      </Snackbar>}
    </Box>
  );
}
