import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one } from '../../lib/server_helper';

import {
  Box,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  BankAccount,
  Page,
  StyledButton,
  StyledTextField,
  Typography
} from '../../components/interface';
import { DetailLine } from '../../components/interface/Detail/DetailLine';
import { UserDetailBase } from '../../components/user_detail/Base';

import { UserChange } from '../../models/UserChange';

// const { DateTime } = require("luxon");

type OfficeUserChangeDetailProps = {};

export const OfficeUserChangeDetail: FunctionComponent<OfficeUserChangeDetailProps> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['login'],
  //   queryFn: async () => {return },
  // });

  const params = useParams();

  const login = useAtomValue(loginAtom);
  // const [login, setLogin] = useAtom(loginAtom);
  const [userChangeDetail, setUserChangeDetail] = useState<UserChange|null>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const [exitId, setOfficeId] = useState<string | undefined>(params.id);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setOfficeSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'created_at', 'updated_at', 'extra_data', 'status',
    'user|first_name/last_name/vennoot_number/birth_date/street/number/zip/city/email/zicht_account/cor_street/cor_street_2/cor_number/cor_zip/cor_city',
    'office_account|full_name',
    'office_account|office|mandate_name/city/email/phone'
  ];

  const modChange = (mode: string, accepted: boolean) => {
    fetch_one<UserChange>('/nl/v3/objects/custom_action', {
      object: 'user_change',
      fields: fields,
      handler_id: login?.id, 
      user_change_id: exitId,
      class_action: 'custom_api_judge_it',
      mode: mode,
      accepted: accepted ? 1 : 0,
      changes: (mode === 'res' ? userChangeDetail?.extra_data?.changes?.res : (
        mode === 'cor' ? userChangeDetail?.extra_data?.changes?.cor : (
          mode === 'email' ? userChangeDetail?.extra_data?.changes?.email : (
            mode === 'acc' ? userChangeDetail?.extra_data?.changes?.acc : (
              mode === 'notes' ? userChangeDetail?.extra_data?.changes?.notes : {}
            )
          )
        )
      ))
    }, (ob, complete_data) => {
      if (!!ob.custom_result) setUserChangeDetail(ob.custom_result);
      setLoader(false);

      if (ob.custom_result?.status === 1) {
        navigate("/user_changes");
      }
    }, (z, y) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const fetchUserChange = () => {
    setLoader(true);
    
    fetch_one<UserChange>('/nl/v3/objects/fetch_all', {
      object: 'user_change',
      fields: fields,
      id: exitId
    }, (ob, complete_data) => {
      openUserChange(ob);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  useEffect(() => {
    fetchUserChange();
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params.id !== exitId) setOfficeId(params.id);
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const openUserChange = (ob: UserChange) => {
    setUserChangeDetail(ob);
  }

  return (
    <Page sx={{}} title={`UserChange ${userChangeDetail?.user_first_name} ${userChangeDetail?.user_last_name} (${userChangeDetail?.user_vennoot_number})`}>
      <Grid container spacing="8">
        <Grid container item xs={12} spacing="8" sx={{alignContent: 'baseline'}}>
          {!!loader && <Grid item xs={12}>
            <CircularProgress />
          </Grid>}

          <Grid container item xs={12} md={4} spacing="8" sx={{alignContent: 'baseline'}}>
            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.identity
              }}
              title="Identity"
              wide
            >
              <DetailLine name="First name" value={!!userChangeDetail ? userChangeDetail.user_first_name : ''} />
              <DetailLine name="Last name" value={!!userChangeDetail ? userChangeDetail.user_last_name : ''} />
              <DetailLine name="Birthdate" date={!!userChangeDetail ? userChangeDetail?.user_birth_date : ''} />
              <DetailLine no_divider copyable name="Vennoot Number" value={!!userChangeDetail ? userChangeDetail.user_vennoot_number : ''} />
            </UserDetailBase>

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.register
              }}
              title="Office Information"
              wide
            >
              <DetailLine name="Office" value={!!userChangeDetail ? userChangeDetail.office_account_office_mandate_name : ''} />
              <DetailLine name="Office location" value={!!userChangeDetail ? userChangeDetail.office_account_office_city : ''} />
              <DetailLine name="Office email" value={!!userChangeDetail ? userChangeDetail.office_account_office_email : ''} />
              <DetailLine name="Office phone" value={!!userChangeDetail ? userChangeDetail.office_account_office_phone : ''} />
              <DetailLine name="Employee" value={!!userChangeDetail ? userChangeDetail.office_account_full_name : ''} />
            </UserDetailBase>
          </Grid>
          <Grid container item xs={12} md={4} spacing="8" sx={{alignContent: 'baseline'}}>
            {!!userChangeDetail?.extra_data?.changes?.res && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.email_history
              }}
              title="Change - Main Address"
              wide
            >
              <Grid container item xs={12} spacing="8">
                <Grid item xs={12}>
                  <Typography variant="caption">Original</Typography>
                  <Typography sx={{paddingLeft: 2}}>
                    {userChangeDetail?.user_street} {userChangeDetail?.user_number}<br />
                    {userChangeDetail?.user_zip} {userChangeDetail?.user_city}
                  </Typography>
                  <Typography variant="caption"><br/>Proposed change</Typography>
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label="Street"
                    capitalaction
                    value={userChangeDetail?.extra_data?.changes?.res?.street?.toString() || ''}
                    id='street'
                    onChange={(v) => {if (!!userChangeDetail) setUserChangeDetail({
                      ...userChangeDetail,
                      extra_data: {
                        ...(userChangeDetail?.extra_data || {}),
                        changes: {
                          ...(userChangeDetail?.extra_data?.changes || {}),
                          res: {
                            ...(userChangeDetail?.extra_data?.changes?.res || {}),
                            street: v
                          }
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledTextField
                    label="Number"
                    value={userChangeDetail?.extra_data?.changes?.res?.number?.toString() || ''}
                    id='number'
                    onChange={(v) => {if (!!userChangeDetail) setUserChangeDetail({
                      ...userChangeDetail,
                      extra_data: {
                        ...(userChangeDetail?.extra_data || {}),
                        changes: {
                          ...(userChangeDetail?.extra_data?.changes || {}),
                          res: {
                            ...(userChangeDetail?.extra_data?.changes?.res || {}),
                            number: v
                          }
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledTextField
                    label="Zip"
                    value={userChangeDetail?.extra_data?.changes?.res?.zip?.toString() || ''}
                    id='zip'
                    onChange={(v) => {if (!!userChangeDetail) setUserChangeDetail({
                      ...userChangeDetail,
                      extra_data: {
                        ...(userChangeDetail?.extra_data || {}),
                        changes: {
                          ...(userChangeDetail?.extra_data?.changes || {}),
                          res: {
                            ...(userChangeDetail?.extra_data?.changes?.res || {}),
                            zip: v
                          }
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label="City"
                    capitalaction
                    value={userChangeDetail?.extra_data?.changes?.res?.city?.toString() || ''}
                    id='city'
                    onChange={(v) => {if (!!userChangeDetail) setUserChangeDetail({
                      ...userChangeDetail,
                      extra_data: {
                        ...(userChangeDetail?.extra_data || {}),
                        changes: {
                          ...(userChangeDetail?.extra_data?.changes || {}),
                          res: {
                            ...(userChangeDetail?.extra_data?.changes?.res || {}),
                            city: v
                          }
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                  <StyledButton
                    label="Accept"
                    id='accept'
                    contained
                    saveStartIcon
                    onClick={(v) => {
                      modChange("res", true);
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledButton
                    label="Reject"
                    id='reject'
                    contained
                    secondary
                    saveStartIcon
                    onClick={(v) => {
                      modChange("res", false);
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
              </Grid>
            </UserDetailBase>}

            {!!userChangeDetail?.extra_data?.changes?.cor && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.email_history
              }}
              title="Change - Correspondence Address"
              wide
            >
              <Grid container item xs={12} spacing="8">
                <Grid item xs={12}>
                  <Typography variant="caption">Original</Typography>
                  <Typography sx={{paddingLeft: 2}}>
                    {userChangeDetail?.user_cor_street_2 || '-'}<br />
                    {userChangeDetail?.user_street} {userChangeDetail?.user_number}<br />
                    {userChangeDetail?.user_zip} {userChangeDetail?.user_city}
                  </Typography>
                  <Typography variant="caption"><br/>Proposed change</Typography>
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Street 2"
                    capitalaction
                    value={userChangeDetail?.extra_data?.changes?.cor?.street_2?.toString() || ''}
                    id='street_2'
                    onChange={(v) => {if (!!userChangeDetail) setUserChangeDetail({
                      ...userChangeDetail,
                      extra_data: {
                        ...(userChangeDetail?.extra_data || {}),
                        changes: {
                          ...(userChangeDetail?.extra_data?.changes || {}),
                          cor: {
                            ...(userChangeDetail?.extra_data?.changes?.cor || {}),
                            street_2: v
                          }
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label="Street"
                    capitalaction
                    value={userChangeDetail?.extra_data?.changes?.cor?.street?.toString() || ''}
                    id='street'
                    onChange={(v) => {if (!!userChangeDetail) setUserChangeDetail({
                      ...userChangeDetail,
                      extra_data: {
                        ...(userChangeDetail?.extra_data || {}),
                        changes: {
                          ...(userChangeDetail?.extra_data?.changes || {}),
                          cor: {
                            ...(userChangeDetail?.extra_data?.changes?.cor || {}),
                            street: v
                          }
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledTextField
                    label="Number"
                    value={userChangeDetail?.extra_data?.changes?.cor?.number?.toString() || ''}
                    id='number'
                    onChange={(v) => {if (!!userChangeDetail) setUserChangeDetail({
                      ...userChangeDetail,
                      extra_data: {
                        ...(userChangeDetail?.extra_data || {}),
                        changes: {
                          ...(userChangeDetail?.extra_data?.changes || {}),
                          cor: {
                            ...(userChangeDetail?.extra_data?.changes?.cor || {}),
                            number: v
                          }
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledTextField
                    label="Zip"
                    value={userChangeDetail?.extra_data?.changes?.cor?.zip?.toString() || ''}
                    id='zip'
                    onChange={(v) => {if (!!userChangeDetail) setUserChangeDetail({
                      ...userChangeDetail,
                      extra_data: {
                        ...(userChangeDetail?.extra_data || {}),
                        changes: {
                          ...(userChangeDetail?.extra_data?.changes || {}),
                          cor: {
                            ...(userChangeDetail?.extra_data?.changes?.cor || {}),
                            zip: v
                          }
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label="City"
                    capitalaction
                    value={userChangeDetail?.extra_data?.changes?.cor?.city?.toString() || ''}
                    id='city'
                    onChange={(v) => {if (!!userChangeDetail) setUserChangeDetail({
                      ...userChangeDetail,
                      extra_data: {
                        ...(userChangeDetail?.extra_data || {}),
                        changes: {
                          ...(userChangeDetail?.extra_data?.changes || {}),
                          cor: {
                            ...(userChangeDetail?.extra_data?.changes?.cor || {}),
                            city: v
                          }
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                  <StyledButton
                    label="Accept"
                    id='accept'
                    contained
                    saveStartIcon
                    onClick={(v) => {
                      modChange("cor", true);
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledButton
                    label="Reject"
                    id='reject'
                    contained
                    secondary
                    saveStartIcon
                    onClick={(v) => {
                      modChange("cor", false);
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
              </Grid>
            </UserDetailBase>}

            {!!userChangeDetail?.extra_data?.changes?.acc && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.email_history
              }}
              title="Change - Zicht Account"
              wide
            >
              <Grid container item xs={12} spacing="8">
                <Grid item xs={12}>
                  <Typography variant="caption">Original</Typography>
                  <Typography sx={{paddingLeft: 2}}>
                    {userChangeDetail?.user_zicht_account}
                  </Typography>
                  <Typography variant="caption"><br/>Proposed change</Typography>
                </Grid>
                <Grid item xs={12}>
                  <BankAccount
                    label="Zicht account"
                    value={userChangeDetail?.extra_data?.changes?.acc || ''}
                    id="zicht_account"
                    onChange={(v, checked) => {if (!!userChangeDetail) setUserChangeDetail({
                      ...userChangeDetail,
                      extra_data: {
                        ...(userChangeDetail?.extra_data || {}),
                        changes: {
                          ...(userChangeDetail?.extra_data?.changes || {}),
                          acc: v
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                  <StyledButton
                    label="Accept"
                    id='accept'
                    contained
                    saveStartIcon
                    onClick={(v) => {
                      modChange("acc", true);
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledButton
                    label="Reject"
                    id='reject'
                    contained
                    secondary
                    saveStartIcon
                    onClick={(v) => {
                      modChange("acc", false);
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
              </Grid>
            </UserDetailBase>}

            {!!userChangeDetail?.extra_data?.changes?.notes && userChangeDetail?.extra_data?.changes?.notes.length > 0 && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.email_history
              }}
              title="Change - Zicht Account"
              wide
            >
              <Grid container item xs={12} spacing="8">
                <Grid item xs={12}>
                  <Typography variant="caption">Notes</Typography>
                  {userChangeDetail?.extra_data?.changes?.notes.map(note => <>
                    <Typography sx={{paddingLeft: 2, fontSize: '0.8rem'}}>{note[1]} - ({note[0]})</Typography>
                    <Typography sx={{paddingLeft: 2}}>{note[2]}</Typography>
                  </>)}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                  <StyledButton
                    label="Accept"
                    id='accept'
                    contained
                    saveStartIcon
                    onClick={(v) => {
                      modChange("notes", true);
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledButton
                    label="Reject"
                    id='reject'
                    contained
                    secondary
                    saveStartIcon
                    onClick={(v) => {
                      modChange("notes", false);
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
              </Grid>
            </UserDetailBase>}

            {!!userChangeDetail?.extra_data?.changes?.email && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.email_history
              }}
              title="Change - Email"
              wide
            >
              <Grid container item xs={12} spacing="8">
                <Grid item xs={12}>
                  <Typography variant="caption">Original</Typography>
                  <Typography sx={{paddingLeft: 2}}>
                    {userChangeDetail?.user_email}
                  </Typography>
                  <Typography variant="caption"><br/>Proposed change</Typography>
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Email"
                    value={userChangeDetail?.extra_data?.changes?.email?.toString() || ''}
                    id='email'
                    onChange={(v) => {if (!!userChangeDetail) setUserChangeDetail({
                      ...userChangeDetail,
                      extra_data: {
                        ...(userChangeDetail?.extra_data || {}),
                        changes: {
                          ...(userChangeDetail?.extra_data?.changes || {}),
                          email: v
                        }
                      }
                    });}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                  <StyledButton
                    label="Accept"
                    id='accept'
                    contained
                    saveStartIcon
                    onClick={(v) => {
                      modChange("email", true);
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledButton
                    label="Reject"
                    id='reject'
                    contained
                    secondary
                    saveStartIcon
                    onClick={(v) => {
                      modChange("email", false);
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
              </Grid>
            </UserDetailBase>}
          </Grid>
        
          <Grid container item xs={12} md={4} sx={{alignContent: 'baseline'}} rowSpacing="4">
            <Grid item xs={12}>
              <Box sx={{
                border: '1px solid transparent',
                // borderColor: theme.palette.primary.main,
                borderRadius: 1,
                padding: 1
              }}>
                <Grid container spacing="8">
                  <Grid item xs={12}>
                    <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Actions</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    {!!loader && <CircularProgress />}
                    {!loader && <StyledButton
                      label="Cancel"
                      id='cancel'
                      // contained
                      onClick={(v) => {
                        navigate("/user_changes");
                      }}
                      sx={{width: '100%'}}
                    />}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setOfficeSavedSnackOpen(b);}} />}
    </Page>
  );
}