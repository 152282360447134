import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useMutation, useQuery } from '@tanstack/react-query';

// import { fetch_all, fetch_one } from '../lib/server_helper';
import { loginAtom } from '../lib/auth';
import { fetchAll, fetchOne } from '../lib/v31lib';

import { Line, CartesianGrid, XAxis, YAxis, Bar, ComposedChart, Tooltip, TooltipProps, PieChart, Pie, Cell, Legend } from 'recharts';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';

import {
  Box,
  Divider,
  Grid
} from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import {
  Page,
  StyledButton,
  StyledDateField,
  StyledSelect,
  StyledTextField,
  Typography
} from '../components/interface';

import { BookYear } from '../models/BookYear';

const { DateTime } = require("luxon");

type StockStatsProps = {};
type RawStat = {
  custom_result: {
    age: number;
    stock_value: number;
    amount: number;
  }[]
};
type RawExportStat = {
  custom_result: string
};
type Stat = {
  name: string;
  stock_value: number;
  amount: number;
};
type PieStat = {
  name: string;
  value: number;
};

const COLOURS = ['#105156', '#106F56', '#107956', '#108D56', '#10AB56', '#10C956', '#10E756', '#10DD7E', '#10D3A6', '#10C9CE', '#10BFF6', '#1097F6', '#108DF6'];
const MAXIMUMS = {
  living: 2000,
  exit: 50,
  death: 20
};
const RATIOS = {
  living: 0.00025,
  exit: 0.00025,
  death: 0.00025
};

export const StockStats: FunctionComponent<StockStatsProps> = () => {
  const theme = useTheme();
  
  const [login, setLogin] = useAtom(loginAtom);
  const [ageGroups, setAgeGroups] = useState<[number, number][]>([
    [0, 9], [10, 19], [20, 29],
    [30, 39], [40, 49], [50, 59],
    [60, 69], [70, 79], [80, 89],
    [90, 99], [100, 109], [110, 150]
  ]);
  const [bookYear, setBookYear] = useState<number>();
  const [startDate, setStartDate] = useState<string>(DateTime.now().minus({month: 1}).startOf('month').toFormat("dd/LL/yyyy"));
  const [endDate, setEndDate] = useState<string>(DateTime.now().minus({month: 1}).endOf('month').toFormat("dd/LL/yyyy"));
  const [typeOfExport, setTypeOfExport] = useState<string>('living');
  const [stats, setStats] = useState<Stat[]>();
  const [pieStatsAmount, setPieStatsAmount] = useState<PieStat[]>();
  const [pieStatsValue, setPieStatsValue] = useState<PieStat[]>();
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [totalValue, setTotalValue] = useState<number>(0);
  const [exportUrl, setExportUrl] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    data: bookYears,
    // isLoading: bookYearsLoading,
    // isError: bookYearsError,
    // isSuccess: bookYearsSuccess,
    // refetch: bookYearsRefetch,
  } = useQuery({
    queryKey: ["book_years"],
    queryFn: () =>
      fetchAll<BookYear>(
        "/nl/v3/objects/fetch_all",
        {
          object: "book_year",
          fields: ["id", "short_visual"],
        },
        login
      ),
    enabled: !!login
  });

  const getMax = (tof: 'living'|'exit'|'death') => {
    let max = MAXIMUMS[tof] - 10;

    if (!stats || tof === 'living') {
      return MAXIMUMS[tof];
    } else {
      stats?.forEach(st => {
        if (st.amount > max) max = st.amount;
      });
      max = max * 1.1; // a little extra
      return Math.ceil(max / 10) * 10;
    }
  }

  const mutationCalculate = useMutation({
    mutationFn: () => {
      setLoading(true);
      setStats([]);
      return fetchOne<RawStat>(
        "/nl/v3/objects/custom_action",
        {
          object: 'export',
          class_action: 'custom_api_get_stats',
          export_type: typeOfExport,
          start_date: startDate,
          end_date: endDate,
          book_year: bookYear,
          age_groups: ageGroups
        },
        login
      );
    },
    onSuccess: (data, variables, context) => {
      let s:Stat[] = [];
      let sv:PieStat[] = [];
      let sa:PieStat[] = [];
      let tAmount = 0;
      let tValue= 0;
      let previousAge = -1;

      data.custom_result.sort((a, b) => a.age - b.age).forEach(element => {
        // if (previousAge === -1) previousAge = element.age - 1;

        while (previousAge < element.age ) {
          previousAge += 1;
          if (previousAge < element.age) {
            s.push({
              name: `${previousAge}`,
              stock_value: 0,
              amount: 0
            });
          }
        }

        s.push({
          name: `${element.age}`,
          stock_value: (element.stock_value * RATIOS[typeOfExport as 'living'|'exit'|'death']),
          amount: element.amount
        });
      });

      setStats(s);

      if (ageGroups.length > 0) {
        ageGroups.forEach(ag => {
          let a_start = ag[0];
          let a_end = ag[1];
          sa.push({
            value: data.custom_result.filter(el => el.age >= a_start && el.age <= a_end).reduce((total, xx) => total + xx.amount, 0),
            name: `${a_start}-${a_end}`
          });
          sv.push({
            value: data.custom_result.filter(el => el.age >= a_start && el.age <= a_end).reduce((total, xx) => total + xx.stock_value, 0),
            name: `${a_start}-${a_end}`
          });
          tAmount += data.custom_result.filter(el => el.age >= a_start && el.age <= a_end).reduce((total, xx) => total + xx.amount, 0);
          tValue += data.custom_result.filter(el => el.age >= a_start && el.age <= a_end).reduce((total, xx) => total + xx.stock_value, 0);
        });
        setPieStatsAmount(sa);
        setPieStatsValue(sv);
        setTotalAmount(tAmount);
        setTotalValue(tValue);
      }

      setLoading(false);
    },
  });

  const mutationExport = useMutation({
    mutationFn: () => {
      setExportUrl(undefined);
      return fetchOne<RawExportStat>(
        "/nl/v3/objects/custom_action",
        {
          object: 'export',
          class_action: 'custom_api_export_stats',
          handler_id: login?.id,
          export_type: typeOfExport,
          start_date: startDate,
          end_date: endDate,
          book_year: bookYear,
          age_groups: ageGroups
        },
        login
      );
    },
    onSuccess: (data, variables, context) => {
      setExportUrl(data.custom_result);
    },
  });

  const CustomPieTooltip = ({
    active,
    payload,
    label,
}: TooltipProps<ValueType, NameType>) => {
    if (payload && payload.length) {
      let pld = payload[0];
      let sa:PieStat= (pieStatsAmount?.filter(el => el.name === pld.name)[0] || {} as PieStat);
      let sv:PieStat = (pieStatsValue?.filter(el => el.name === pld.name)[0] || {} as PieStat);
      let index = 0;
      (pieStatsAmount || []).forEach((ob, i) => {
        if (ob.name === pld.name) index = i;
      });
      let clr = COLOURS[index % COLOURS.length]

// console.log(payload, pld, sa, sv);
      return (
        <Box className="custom-tooltip" sx={{backgroundColor: 'white', padding: 1, borderRadius: 2, paddingTop: 1, border: '1px solid transparent', borderColor: theme.palette.tertiary?.main}}>
          <Typography>{`Age: ${pld.name}`}</Typography>
          <Box>
            <Box style={{ display: "inline-block", padding: 1 }}>
              <Box sx={{padding: 0.5, backgroundColor: clr, border: '1px solid transparent', borderColor: clr}}>
                <Typography sx={{color: 'white'}}>{sa.value}</Typography>
              </Box>
              <Box sx={{padding: 0.5, border: '1px solid transparent', borderColor: clr}}>
                <Typography>Vennoten</Typography>
              </Box>
            </Box>
            <Box style={{ display: "inline-block", padding: 1 }}>
              <Box sx={{padding: 0.5, backgroundColor: clr, border: '1px solid transparent', borderColor: clr}}>
                <Typography sx={{color: 'white'}}>{sv.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR</Typography>
              </Box>
              <Box sx={{padding: 0.5, border: '1px solid transparent', borderColor: clr}}>
                <Typography>Kapitaal</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }
  
    return null;
  };

  const CustomTooltip = ({
    active,
    payload,
    label,
}: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {

      return (
        <Box className="custom-tooltip" sx={{backgroundColor: 'white', padding: 1, borderRadius: 2, paddingTop: 1, border: '1px solid transparent', borderColor: theme.palette.tertiary?.main}}>
          <Typography>{`Age: ${label}`}</Typography>
          <Box>
            {payload.map((pld) => (
              <Box style={{ display: "inline-block", padding: 1 }}>
                <Box sx={{padding: 0.5, backgroundColor: pld.color, border: '1px solid transparent', borderColor: pld.color}}>
                  {pld.dataKey !== 'stock_value' &&<Typography sx={{color: 'white'}}>{pld.value}</Typography>}
                  {pld.dataKey === 'stock_value' && !!pld.value &&<Typography sx={{color: 'white'}}>{Math.round(pld.value as number / RATIOS[typeOfExport as 'living'|'exit'|'death']).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR</Typography>}
                </Box>
                <Box sx={{padding: 0.5, border: '1px solid transparent', borderColor: pld.color}}>
                  <Typography>{{'amount': 'Vennoten', 'stock_value': 'Kapitaal'}[pld.dataKey?.toString() || '']}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      );
    }
  
    return null;
  };

  return (<Page sx={{}} title="Stock Stats" helper="stock_stats">
    <Grid container spacing="8">
      <Grid item xs={12}>
        <StyledSelect
          label="Type of statistic"
          value={typeOfExport}
          id='type_of_stat'
          onChange={(v) => {setTypeOfExport(v);}}
          list={[
            {id: 'living', name: "Stats vennoten"},
            {id: 'exit', name: "Stats uittredingen"},
            {id: 'death', name: "Stats overlijdens"}
          ]}
        />
      </Grid>
      <Grid item container xs={12} spacing="8">
        <Grid item xs={12}>
          <Typography sx={{fontSize: '0.8rem'}}>Age groups</Typography>
        </Grid>
        {ageGroups.map((age, i) => <Grid item container xs={2} spacing="8">
          <Grid item xs={5}>
            <StyledTextField
              label="Start ["
              value={age[0].toString()}
              id='start_x'
              onChange={(v) => {
                let newAgeGroups = [...ageGroups];
                newAgeGroups[i][0] = parseInt(v, 10);
                setAgeGroups(newAgeGroups);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={5} sx={{position: 'relative'}}>
            <StyledTextField
              label="End ]"
              value={age[1].toString()}
              id='end_x'
              onChange={(v) => {
                let newAgeGroups = [...ageGroups];
                newAgeGroups[i][1] = parseInt(v, 10);
                setAgeGroups(newAgeGroups);
              }}
              fullWidth
            />
            <RemoveCircleIcon sx={{color: theme.palette.secondary.main, position: 'absolute', top: 0, right: -16, cursor: 'pointer'}} onClick={() => {
              let newAgeGroups: [number, number][] = [];
              ageGroups.forEach((ag, ii) => {
                if (ii !== i) newAgeGroups.push(ag);
              });
              setAgeGroups(newAgeGroups);
            }} />
          </Grid>
        </Grid>)}
        <Grid item xs={2} container spacing="8">
          <Grid item xs={10} sx={{textAlign: 'center'}}>
            <AddCircleOutlineIcon sx={{color: theme.palette.tertiary?.main, marginTop: 1.5, cursor: 'pointer'}} onClick={() => {
              setAgeGroups([...ageGroups, [0, 0]]);
            }} />
          </Grid>
        </Grid>
      </Grid>
      {typeOfExport === 'exit' && <Grid item xs={12}>
        <StyledSelect
          label="Book Year"
          value={bookYear?.toString() || ''}
          id='book_year'
          onChange={(v) => {setBookYear(parseInt(v, 10));}}
          list={[
            {id: '', name: "Unselected"},
            ...((bookYears || []).map(by => {return {id: by.id?.toString() || '', name: by.short_visual};}))
          ]}
        />
      </Grid>}
      {typeOfExport === 'death' && <Grid item container xs={12} spacing="8">
        <Grid item xs={6}>
          <StyledDateField
            label="Start of period"
            value={startDate}
            id='startdate'
            onChange={(v) => {setStartDate(v.toString());}}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <StyledDateField
            label="End of period"
            value={endDate}
            id='enddate'
            onChange={(v) => {setEndDate(v.toString());}}
            fullWidth
          />
        </Grid>
      </Grid>}
      <Grid item xs={12}>
        <StyledButton
          label="Calculate"
          id='calc'
          contained
          onClick={(v) => {
            mutationCalculate.mutate();
          }}
        />
        {!!stats && <StyledButton
          label="Export"
          id='export'
          // contained
          onClick={(v) => {
            mutationExport.mutate();
          }}
          sx={{marginLeft: 1}}
        />}
        {!!exportUrl && <Typography>Download your export here: <a href={exportUrl} target="_BLANK">{exportUrl}</a></Typography>}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      {!!totalAmount && <Grid item container spacing="8" xs={12} sx={{marginTop: 2, marginBottom: 2}}>
        <Grid item xs={2}>
          <Box sx={{padding: 2, textAlign: 'center', backgroundColor: theme.palette.primary.main}}>
            <Typography sx={{fontSize: '2rem', color: 'white'}}>{totalAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</Typography>
            <Divider color="white" />
            <Typography sx={{color: 'white'}}>Amount of users</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{padding: 2, textAlign: 'center', backgroundColor: theme.palette.primary.main}}>
            <Typography sx={{fontSize: '2rem', color: 'white'}}>{totalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}&nbsp;EUR</Typography>
            <Divider color="white" />
            <Typography sx={{color: 'white'}}>Amount of capital</Typography>
          </Box>
        </Grid>
      </Grid>}
      {!!stats && <Grid item xs={12} sx={{
        ".recharts-xAxis": {
          color: theme.palette.primary.main,
          fontFamily: 'Golos Text, sans-serif',
          fontWeight: 400,
          fontSize: '0.8rem'
        },
        ".recharts-yAxis": {
          color: theme.palette.primary.main,
          fontFamily: 'Golos Text, sans-serif',
          fontWeight: 400,
          fontSize: '0.8rem'
        }
      }}>
        <ComposedChart width={1000} height={400} data={stats}>
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" vertical={false} />
          <XAxis dataKey="name" />
          <YAxis
            yAxisId={1}
            dataKey="amount"
            orientation='left'
            tickCount={5}
            domain={[0, getMax(typeOfExport as 'living'|'exit'|'death')]}
            // ticks={!!leftDomain ? generateTicks(leftDomain) : []}
          />
          <YAxis
            yAxisId={0}
            dataKey="stock_value"
            orientation='right'
            tickCount={5}
            domain={[0, getMax(typeOfExport as 'living'|'exit'|'death')]}
            tickFormatter={(t) => {return (t / RATIOS[typeOfExport as 'living'|'exit'|'death']).toString();}}
            allowDataOverflow
            // ticks={!!rightDomain ? generateTicks(rightDomain) : []}
          />

          <Bar dataKey="amount" fill="lightgrey" />
          <Line type="monotone" dataKey="stock_value" stroke="#8884d8" strokeWidth={3} />

          <Tooltip content={<CustomTooltip />} />
        </ComposedChart>
      </Grid>}
      {!!stats && <Grid item xs={12}>
        <PieChart width={1000} height={400}>
          <Pie data={pieStatsAmount} dataKey="value" cx="50%" cy="50%" outerRadius={100} fill="#8884d8">
            {(pieStatsAmount || []).map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLOURS[index % COLOURS.length]} />
            ))}
          </Pie>
          <Pie data={pieStatsValue} dataKey="value" cx="50%" cy="50%" innerRadius={110} outerRadius={140} fill="#82ca9d">
            {(pieStatsAmount || []).map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLOURS[index % COLOURS.length]} />
            ))}
          </Pie>

          <Legend
            layout="vertical"
            align="right"
            verticalAlign="top"
            payload={pieStatsAmount?.map((el, index) => {
              return {
                value: <Box sx={{height: '34px', width: '300px', position: 'relative', display: 'inline-block', marginBottom: -1, marginTop: 1.1}}>
                  <Typography sx={{position: 'absolute', top: 0, left: 20}}>{`Age ${el.name}`}</Typography>
                  <Typography sx={{fontSize: '0.8rem', position: 'absolute', top: 20, left: 20}}>{`(${el.value} users, ${(pieStatsValue || [])[index].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR)`}</Typography>
                </Box>,
                type: 'square',
                color: COLOURS[index % COLOURS.length]
              };
            })}
          />
          <Tooltip content={<CustomPieTooltip />} />
        </PieChart>
      </Grid>}

    </Grid>
  </Page>);
}
