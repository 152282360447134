import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';

import { fetch_all } from '../../lib/server_helper';

// import {
//   CircularProgress
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  SearchBox
} from '../../components/interface';

import { Email } from '../../models/Email';
import { loginAtom } from '../../lib/auth';

const { DateTime } = require("luxon");

type EmailEmailsProps = {};

export const EmailEmails: FunctionComponent<EmailEmailsProps> = () => {
  // const theme = useTheme();

  const [login, setLogin] = useAtom(loginAtom);

  const params = useParams();
  const template_id: string | undefined = params.id;

  const [emails, setEmails] = useState<Email[]>([]);
  const [emailsCount, setEmailsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onSearch = (s: string) => {
    // console.log(filters);
    setLoader(true);
    fetch_all<Email>('/nl/v3/objects/fetch_all', {
      object: 'v2_email',
      fields: ['id', 'created_at', 'updated_at', 'sent_at', 'email', 'user|full_name'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          v2_email_template_id: template_id
        }
      }
    }, (obs, complete_data) => {
      setEmails(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setEmailsCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, sortable: true },
    { field: 'email', headerName: 'Email', width: 200, sortable: false },
    { field: 'user_full_name', headerName: 'Name', width: 200, sortable: false },
    { field: 'created_at', headerName: 'Created', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'sent_at', headerName: 'Sent', width: 200, valueFormatter: params => !!params?.value ? DateTime.fromISO(params?.value).toFormat("D T") : '' }
  ];

  return (
    <Page sx={{}} title="Emails" helper="emails">
      {/* <SearchBox
        simple={true}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
        memo="emails"
      /> */}
      <DataGrid
        action_count={0}
        actions={(params) => {
          return (<></>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={emailsCount}
        objects={(emails || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
