import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

import {
  Avatar,
  Box,
  CircularProgress,
  Grid
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import ContactPageIcon from '@mui/icons-material/ContactPage';
import DescriptionIcon from '@mui/icons-material/Description';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import IconItsme from "../../images/itsme.svg";
import TaskIcon from '@mui/icons-material/Task';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton,
  Typography
} from '../../components/interface';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import { Exit } from '../../models/Exit';
import { loginAtom } from '../../lib/auth';

const { DateTime } = require("luxon");

type ExitExitsProps = {};

export const ExitExits: FunctionComponent<ExitExitsProps> = () => {
  // const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);
  const [exits, setExits] = useState<Exit[]>([]);
  const [exitsCount, setExitsCount] = useState<number>(0);
  const [handleableExitsCount, setHandleableExitsCount] = useState<number>(0);
  const [approvedAmount, setApprovedAmount] = useState<number>(0);
  const [openAmount, setOpenAmount] = useState<number>(0);
  const [approvedCount, setApprovedCount] = useState<number>(0);
  const [openCount, setOpenCount] = useState<number>(0);
  const [filters, setFilters] = useState<{book_year_id:number, checked?: string[], identity_front?: string[], item?: string[]}>();
  const [loader, setLoader] = useState<boolean>(true);
  const [filterOngoing, setFilterOngoing] = useState<boolean>(false);
  const [handleLoader, setHandleLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("updated_at DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch('', filters);
    fetch_handleablesAmount();
  }, [page, order, filterOngoing]);

  useEffect(() => {
    if ((filters?.book_year_id || 0) > 0) {
      fetch_one<Exit>('/nl/v3/objects/custom_action', {
        object: 'exit',
        class_action: 'custom_api_get_stats',
        book_year_id: (filters?.book_year_id || 0)
      }, (ob, complete_data) => {
        if (!!ob.custom_result?.approved_amount) setApprovedAmount(ob.custom_result?.approved_amount);
        if (!!ob.custom_result?.open_amount) setOpenAmount(ob.custom_result?.open_amount);
        if (!!ob.custom_result?.approved_count) setApprovedCount(ob.custom_result?.approved_count);
        if (!!ob.custom_result?.open_count) setOpenCount(ob.custom_result?.open_count);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, login);
    }
  }, [(filters?.book_year_id || 0)]);

  const fetch_handleablesAmount = () => {
    fetch_one<Exit>('/nl/v3/objects/custom_action', {
      object: 'exit',
      class_action: 'custom_api_fetch_handleable_count'
    }, (ob, complete_data) => {
      if (!!ob.custom_result) setHandleableExitsCount(ob.custom_result.handleable_exit_count || 0);
    }, (z, y) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const handleReports = () => {
    if (!!login) {
      setHandleLoader(true);
      fetch_one<Exit>('/nl/v3/objects/custom_action', {
        object: 'exit',
        class_action: 'custom_api_handle_all',
        handler_id: login.id
      }, (obs, complete_data) => {
        onSearch('', filters);

        setHandleLoader(false);
      }, (z) => {
        // setServerErrorSnackOpen(true);
        setHandleLoader(false);
        onSearch('', filters);
      }, login);
    }
  };

  const onSearch = (s: string, filters?:{book_year_id:number, checked?: string[], identity_front?: string[], item?: string[], verified?: string[], cancelled?: string[]}) => {
    // console.log(filters);
    if (!!filters?.book_year_id && filters?.book_year_id > 0) {
      setLoader(true);
      fetch_all<Exit>('/nl/v3/objects/fetch_all', { 
        object: 'exit',
        fields: ['id', 'updated_at', 'given_id', 'user|vennoot_number/first_name/last_name', 'last_handler', 'extra_data', 'get_item', 'get_identity_front', 'verified', 'cancelled', 'notes|count'],
        per_page: 20,
        page: page,
        order: order,
        filter: {
          search: s,
          advanced: {
            book_year_id: (filters?.book_year_id || 0),
            ...(!filters?.checked ? {unchecked: 1} : (filters?.checked?.length === 1 ? (filters?.checked[0] === '0' ? {unchecked: 1} : {checked: 1}) : {})),
            verified: (filters?.verified || [0]),
            cancelled: (filters?.cancelled || [0]),
            f_id_front: filters?.identity_front,
            f_item: filters?.item,
            ongoing: filterOngoing ? 1 : 0
          }
        }
      }, (obs, complete_data) => {
        setExits(obs);
        setFilters(filters);

        let count:number = 0;
        if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
        setExitsCount(count);

        setLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, login);
    }
  };

  const columns: GridColDef[] = [
    { field: 'vennoot_number', headerName: 'VenNR', width: 150, sortable: false },
    { field: 'updated_at', headerName: 'Updated', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'vennoot', headerName: 'Vennoot', width: 200, sortable: false },
    { field: 'indicators', headerName: 'Ind.', width: 100, sortable: false, renderCell: params => <>
      {!!params.row.extra_data?.itsme_data && <>
        <Avatar src={IconItsme} sx={{width: 24, height: 24, marginRight: 3}} />
      </>}
      {!params.row.extra_data?.itsme_data && <>
        <ContactPageIcon sx={{
          color: (!!params.row.get_identity_front ? ((params.row.extra_data?.identity_front_confirmed || '').toString() === '1' ? 'green' : 'orange') : 'red')
        }} />
        <DescriptionIcon sx={{
          color: (!!params.row.get_item ? ((params.row.extra_data?.item_confirmed || '').toString() === '1' ? 'green' : 'orange') : 'red')
        }} />
      </>}
      <TaskIcon sx={{
        color: ((params.row.extra_data?.checked || '').toString() === '1' ? 'green' : 'red')
      }} />
    </> },
    { field: 'notes_count', headerName: 'Notes', width: 100, sortable: false },
    { field: 'last_handler', headerName: 'Handler', width: 400, sortable: false, renderCell: params => (!!params.value[0] ? <>
      {params.value[0]}&nbsp;&nbsp;&nbsp;<Box sx={{fontSize: '0.8rem', fontStyle: 'italic'}}>({DateTime.fromISO(params?.value[1]).toFormat("f")})</Box>
    </> : <></>) },
  ];

  return (
    <Page sx={{}} title="Exits" helper="exits" actions={<>
      {!handleLoader && handleableExitsCount > 0 && <StyledButton
        label={`Handle ${handleableExitsCount}`}
        id='handle_exits'
        contained
        sx={{marginRight: 3}}
        startIcon={<HourglassBottomIcon />}
        onClick={() => {
          handleReports();
        }}
      />}
      {!!handleLoader && <StyledButton
        label="Handling..."
        id='handle_exits_2'
        contained
        disabled
        sx={{marginRight: 3}}
        startIcon={<CircularProgress sx={{width: '20px !important', height: '20px !important'}} />}
        onClick={() => {}}
      />}
      {/* <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={'/exit/new'}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      /> */}
    </>}>
      <SearchBox
        exitSearch={(s, x) => {
          setPage(0);
          onSearch(s, x);
        }}
        filter_bookyear
        collapsable
        memo="exits"
      >
        <Grid xs={12} sx={{
          textAlign: 'right'
        }}>
          <StyledButton
            label="Ongoing only"
            id='set_ongoing'
            contained={filterOngoing}
            onClick={() => {
              setFilterOngoing(!filterOngoing);
            }}
          />
        </Grid>
      </SearchBox>
      <Grid container>
        <Grid item xs={4}>
          <Typography sx={{fontSize: '1.1rem', textDecoration: 'underline'}}>Stats</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>Checked: {parseInt(approvedAmount.toString(), 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR ({approvedCount} users)</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>Unchecked: {parseInt(openAmount.toString(), 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR ({openCount} users)</Typography>
        </Grid>
      </Grid>
      <DataGrid
        action_count={1}
        actions={(params) => {
          return (<>
            <StyledIconButton color="primary" path={`/exit/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled} datagrid-row-cancelled-${params.row.cancelled}`}
        objectCount={exitsCount}
        objects={(exits || []).map(u => ({
          ...u,
          vennoot: `${u.user_first_name} ${u.user_last_name}`,
          vennoot_number: u.user_vennoot_number
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
