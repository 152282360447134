import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  Grid
} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';

import { Typography } from '../interface/Typography';

type UserDetailBaseProps = {
  adornment?: React.ReactNode;
  children: React.ReactNode;
  title: string;
  wide?: boolean;
  fixed?: boolean;
  turnable?: boolean;
  confirmable?: (confirmed:boolean) => void;
  deleteable?: () => void;
  confirmed?: boolean;
  sx?: object;
};

export const UserDetailBase: FunctionComponent<UserDetailBaseProps> = ({children, sx, title, adornment, wide, fixed, turnable, confirmable, deleteable, confirmed}) => {
  const theme = useTheme();

  const [degrees, setDegrees] = useState<number>(0);

  return (<Grid item xs={12} md={wide ? 12 : 6} sx={{
    ...(!!fixed ? {position: 'fixed',
    top: '32px',
    right: '32px',
    width: '38%',
    height: '90%'} : {})
  }}>
    <Box sx={{
      borderRadius: 1,
      padding: 1,
      ...(!!fixed ? {height: '100%'} : {}),
      ...(!!confirmable ? (!!confirmed ? {
        // confirmed
        border: '32px solid transparent',
        borderColor: theme.palette.tertiary?.main,
      } : {
        // unconfirmed
        border: '4px solid transparent',
        borderColor: theme.palette.secondary.main,
      }) : {
        // not confirmable
        border: '1px solid transparent',
        borderColor: theme.palette.primary.main,
      }),
      ...sx
    }}>
      <Grid container spacing="8" sx={{
        ...(!!fixed ? {height: '100%'} : {})
      }}>
        <Grid item container xs={12} sx={{alignContent: 'baseline', overflow: 'hidden', '& div > img': {
            transform: `rotate(${degrees}deg)`
          }}} spacing="4">
          <Grid item xs={12} sx={{position: 'relative', zIndex: 1}}>
            {!!confirmable && <>
              {!confirmed && <CheckCircleIcon sx={{color: theme.palette.tertiary?.main, position: 'absolute', top: 0.5, left: 32, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                e.preventDefault();
                confirmable(true);
              }} />}
              <RemoveCircleIcon sx={{color: theme.palette.secondary.main, position: 'absolute', top: 0.5, left: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                e.preventDefault();
                confirmable(false);
              }} />
            </>}
            {!!deleteable && <>
              <DeleteForeverIcon sx={{color: theme.palette.secondary.main, position: 'absolute', top: 0.5, left: 64, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                e.preventDefault();
                deleteable();
              }} />
            </>}
            <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>{title}</Typography>
            {!!turnable && <>
              <Rotate90DegreesCwIcon sx={{position: 'absolute', top: 0.5, right: 32, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                e.preventDefault();
                setDegrees(degrees+90);
              }} />
              <Rotate90DegreesCcwIcon sx={{position: 'absolute', top: 0.5, right: 60, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                e.preventDefault();
                setDegrees(degrees-90);
              }} />
            </>}
            {!!adornment && adornment}
          </Grid>

          {children}

        </Grid>
      </Grid>
    </Box>
  </Grid>);
}