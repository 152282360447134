import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';
import { loginAtom } from '../../lib/auth';

// import {
//   Box,
//   Tooltip,
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton,
  Typography
} from '../../components/interface';

import { ExitReasonLink } from '../../models/ExitReasonLink';

// const { DateTime } = require("luxon");

type ExitLinkListProps = {};

export const ExitLinkList: FunctionComponent<ExitLinkListProps> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);

  const [deaths, setDeaths] = useState<ExitReasonLink[]>([]);
  const [deathsCount, setDeathsCount] = useState<number>(0);
  const [filters, setFilters] = useState<{book_year_id?: number, status?: string[]}>();
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("updated_at DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch('', filters);
  }, [page, order]);

  const onRemove = (id: number) => {
    setRemoveLoader(true);
    fetch_one<ExitReasonLink>('/nl/v3/objects/remove', {
      object: 'exit_reason_link',
      ob: {
        id: id
      }
    }, (obs, complete_data) => {
      onSearch('', filters);
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const onSearch = (s: string, filters?:{book_year_id?: number, status?: string[]}) => {
    // console.log(filters);
    setLoader(true);
    fetch_all<ExitReasonLink>('/nl/v3/objects/fetch_all', {
      object: 'exit_reason_link',
      fields: ['id', 'created_at', 'updated_at', 'user|id/full_name/vennoot_number', 'link_status', 'other_reason', 'comment'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          fake: 1,
          book_year_id: filters?.book_year_id,
          status: filters?.status
        }
      }
    }, (obs, complete_data) => {
      setDeaths(obs);
      setFilters(filters);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setDeathsCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80, sortable: true },
    { field: 'user_vennoot_number', headerName: 'Vennr', width: 200, sortable: false },
    { field: 'user_full_name', headerName: 'Name', width: 250, sortable: false },
    { field: 'link_status', headerName: 'Status', width: 150, sortable: true, renderCell: (params) => <>
      <Typography sx={{fontSize: '0.8rem'}}>{["Open", "Pending", "Closed", "Removed"][params.row.link_status || 0]}</Typography>
    </> },
    { field: 'other_field', headerName: 'Other', width: 100, sortable: true, renderCell: (params) => <>
      {!!params.row.other_reason && <CheckIcon sx={{color: theme.palette.tertiary?.main}} />}
      {!params.row.other_reason && <CloseIcon sx={{color: theme.palette.secondary.main}} />}
    </> },
    { field: 'comment_field', headerName: 'Office', width: 100, sortable: true, renderCell: (params) => <>
      {!!params.row.comment && <CheckIcon sx={{color: theme.palette.tertiary?.main}} />}
      {!params.row.comment && <CloseIcon sx={{color: theme.palette.secondary.main}} />}
    </> }
  ];

  return (
    <Page sx={{}} title="Exit Reason Links" helper="exit_reason_links">
      <SearchBox
        onSearch={(s, x) => {
          setPage(0);
          onSearch(s, x);
        }}
        simple
        filter_bookyear
        memo="exits"
        statusFilterStrings={[
          ['0', 'Open'],
          ['1', 'Pending'],
          ['2', 'Closed'],
          ['3', 'Removed']
        ]}
      />
      <DataGrid
        action_count={2}
        actions={(params) => {
          return (<>
            <StyledIconButton color="primary" path={`/exit_reason/list/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
            {!removeLoader && !params.row.is_confirmed && <StyledIconButton destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={deathsCount}
        objects={(deaths || []).map(u => ({
          ...u,
          enabled: u.link_status !== 2 && u.link_status !== 3
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
